import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { CountryFlag } from 'src/components/CountryFlag';
import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiAcquiringConfigUpdate,
  useAddAcquiringConfigurationMutation,
  useUpdateAcquiringConfigurationMutation,
} from 'src/models/configurations';
import { mapCreateUpdateAcquiringFormValuesToApiAcquiringConfigUpdate } from 'src/models/configurations';
import { useCountriesQuery } from 'src/models/countryStates/api';
import { ApiCountryLookup } from 'src/models/countryStates/types';
import { useTransferChannelsQuery } from 'src/models/transactions';
import { ApiTransferChannel } from 'src/models/transferChannels';
import { Nullable } from 'src/utils/types';
import { CreateUpdateAcquiringFormValues } from './types-dto';
import { createUpdateAcquiringFormValuesInitialValue } from './utils';
import { acquiringFormValidationSchema } from './validations';

interface FormProps {
  onClose?: () => void;
  acquiring?: CreateUpdateAcquiringFormValues;
  accountHolderId: string;
  isGlobal: boolean;
}
export const AcquiringForm: FC<FormProps> = ({
  onClose,
  acquiring,
  accountHolderId,
  isGlobal,
}) => {

  const transferChannels = useTransferChannelsQuery(
    { accountHolderId },
    { refetchOnMountOrArgChange: true },
  );

  const countries = useCountriesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [addAcquiring] = useAddAcquiringConfigurationMutation();
  const [updateAcquiring] = useUpdateAcquiringConfigurationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const { t } = useTranslation();
  return (
    <Formik<CreateUpdateAcquiringFormValues>
      innerRef={formikRef}
      initialValues={{
        ...createUpdateAcquiringFormValuesInitialValue(!isGlobal ? accountHolderId : ''),
        ...acquiring
        // ...(acquiring
        //   ? mapApiAcquiringConfigToCreateUpdateAcquiringFormValues(acquiring, accountHolderId)
        //   : {}),
      }}
      validationSchema={acquiringFormValidationSchema}
      onSubmit={async (values): Promise<void> => {
        try {
          const acquiringConfigRequest: ApiAcquiringConfigUpdate = await mapCreateUpdateAcquiringFormValuesToApiAcquiringConfigUpdate(values);
          if (acquiring) {
            //  const {acquiringConfigId, accountHolderIdForHeader,...rest} = values;
            await updateAcquiring(acquiringConfigRequest).unwrap();
            toast.success(`Acquiring updated successfully.`);
          } else {
            await addAcquiring(acquiringConfigRequest).unwrap();
            toast.success(`Acquiring created successfully.`);
          }
          onClose();
        } catch (err) {
          // if (err?.data?.errorCode == 'ACCOUNT_LIMIT_REACHED'){
          //   toast.error(`No more accounts with the requested currency can be created` )
          // }
          // else if (err?.data?.errorCode == 'INVALID_CURRENCY'){
          //   toast.error(`Requested currency is not available for this specific bank` )
          // }
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {acquiring ? 'Edit Acquiring' : 'Create Acquiring'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>

              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal || acquiring !== null}
                accountHolderId={accountHolderId} />


              {acquiring && (
                <>
                  <FormikTextField
                    name="acquiringConfigId"
                    label="Id"
                    autoComplete="off"
                    disabled
                    margin='normal'
                  />
                </>
              )}

              <FormikTextField name="mid" label="Mid" autoComplete="off" margin='normal' />

              {transferChannels.data?.length > 0 && (
                <FormControl
                  error={
                    touched?.['transferChannelId'] &&
                    errors?.['transferChannelId']?.length > 0
                  }
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  component="fieldset"
                >
                  <InputLabel id="transferChannelId_label">
                    Transfer Channel
                  </InputLabel>
                  <Select
                    labelId="transferChannelId_label"
                    label="Transfer Channel"
                    name="transferChannelId"
                    id="transferChannelId_select"
                    defaultValue=""
                    value={values.transferChannelId}
                    onChange={(e: SelectChangeEvent<unknown>) =>
                      handleChange('transferChannelId')(e as React.ChangeEvent)
                    }
                    onBlur={handleBlur('transferChannelId')}
                  //   disabled={!(accountHolderIds.data?.length > 1) || acquiring != undefined}
                  >
                    {transferChannels.data?.map((i: ApiTransferChannel) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.description}{' '}
                        {i.categories.map((category) => (
                          <Chip
                            key={category}
                            sx={{ m: 1 }}
                            color="primary"
                            variant="outlined"
                            label={category}
                          />
                        ))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {touched['transferChannelId'] &&
                      errors['transferChannelId']}
                  </FormHelperText>
                </FormControl>
              )}

              <FormControlLabel
                control={
                  <Field
                    label="Requires Three D"
                    name="requiresThreeD"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('requiresThreeD', checked);
                    }}
                    checked={values.requiresThreeD ? true : false}
                  />
                }
                label="Requires Three D"
              />

              <FormControlLabel
                control={
                  <Field
                    label="Requires AVS"
                    name="requiresAvs"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('requiresAvs', checked);
                    }}
                    checked={values.requiresAvs ? true : false}
                  />
                }
                label="Requires AVS"
              />

              <FormControlLabel
                control={
                  <Field
                    label="Allow Non CVV"
                    name="allowNonCvv"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('allowNonCvv', checked);
                    }}
                    checked={values.allowNonCvv ? true : null}
                  />
                }
                label="Allow Non CVV"
              />

              <FormikTextField
                name="transactionDescriptor"
                label="Transaction Descriptor"
                autoComplete="off"
                margin='normal'
              />

              <FormControlLabel
                control={
                  <Field
                    label="Return URL Params Enabled"
                    name="returnUrlParamsEnabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('returnUrlParamsEnabled', checked);
                    }}
                    checked={values.returnUrlParamsEnabled ? true : null}
                  />
                }
                label="Return URL Params Enabled"
              />

              <FormControlLabel
                control={
                  <Field
                    label="Transaction Expiry Enabled"
                    name="transactionExpiryEnabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('transactionExpiryEnabled', checked);
                    }}
                    checked={values.transactionExpiryEnabled ? true : null}
                  />
                }
                label="Transaction Expiry Enabled"
              />

              <FormikTextField
                name="apiKey"
                label="API Key"
                autoComplete="off"
                margin='normal'
              />

              {/* <CountryCodeField
                formControlProps={{
                  fullWidth: true,
                  margin: 'normal',
                  sx: { mb: '24px' },
                }}
                label="Country Code"
                name="countryCode"
                type="nationality"
                selectProps={{
                  variant: 'outlined',
                  // onChange: (event, child) => {
                  //   setFieldValue('countryCode', '');
                  // //   setTimeout(() => setFieldTouched('state', true));
                  // //   setSkip(true);
                  // //   setCountryCode(event.target.value as CountryCode);
                  // //   setSkip(false);
                  // },
                }}
              /> */}
              {countries && countries?.data && countries?.data?.length > 0 && (
                <FormControl fullWidth margin="normal">
                  <Field
                    component={Autocomplete}
                    name={`countryCode`}
                    fullWidth
                    value={values.countryCode}
                    onBlur={handleBlur(`countryCode`)}
                    onChange={(e: any, value: ApiCountryLookup) => {
                      setFieldValue(
                        `countryCode`,
                        value !== null ? value : null,
                      ); // Set Country
                    }}
                    disabled={countries?.data?.length === 1}
                    options={countries.data}
                    autoHighlight
                    variant="outlined"
                    filterOptions={(
                      options: Array<ApiCountryLookup>,
                      state: { inputValue: string },
                    ): Array<ApiCountryLookup> => {
                      // const value = state.inputValue;
                      return options.filter(
                        (x) =>
                          x.name
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()) ||
                          x.code
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()) ||
                          x.alpha3
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(
                      option: ApiCountryLookup,
                      value: Nullable<ApiCountryLookup>,
                    ) => option.code === value?.code}
                    getOptionLabel={(option: ApiCountryLookup) =>
                      `${option.name} (${option.code})` || ''
                    }
                    renderOption={(props: any, option: ApiCountryLookup) => (
                      <Box
                        key={option}
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <CountryFlag countryCode={option.code} />
                        {option.name} ({option.code})
                      </Box>
                    )}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <>
                        <TextField
                          {...params}
                          label={t('common.country')}
                          error={Boolean(
                            touched.countryCode &&
                            errors.countryCode,
                          )}
                          helperText={
                            touched.countryCode &&
                            t(typeof errors.countryCode === 'object' ? errors?.countryCode?.alpha3 : errors.countryCode)
                          }
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                          InputProps={{
                            ...params.InputProps,
                            ...(values.countryCode?.code
                              ? {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <>
                                      <Box sx={{ ml: '8px' }}>
                                        <CountryFlag
                                          countryCode={
                                            values.countryCode?.code
                                          }
                                        />
                                      </Box>
                                      {/* {values[field.name]?.countryCode?.name}  ({values[field.name]?.countryCode?.code}) */}
                                    </>
                                  </InputAdornment>
                                ),
                              }
                              : {}),
                          }}
                        />
                      </>
                    )}
                  ></Field>
                </FormControl>
              )}

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {acquiring ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
