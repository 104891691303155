import { Close } from '@mui/icons-material';
import {
  LoadingButton,
} from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { useTotp } from 'src/hooks/useTotp';
import { ApiKycVerification, kycStatusList, useUpdateKycVerificationMutation } from 'src/models/kycVerifications';
import { kycVerificationFormValidationSchema, kycVerificationToKycVerificationFormValues } from './utils';
interface FormProps {
  onClose?: () => void;
  kycVerification:ApiKycVerification
  accountHolderId:string;
  isGlobal:boolean;
}
export const KycVerificationForm: FC<FormProps> = ({ onClose, kycVerification,accountHolderId, isGlobal }) => {

  const [updateKycVerification] = useUpdateKycVerificationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        ...(kycVerificationToKycVerificationFormValues(kycVerification,accountHolderId)),
      }}
      validationSchema={ kycVerificationFormValidationSchema }
      onSubmit={async (
        values
      ): Promise<void> => {
        try {
             await updateKycVerification(values).unwrap();
             toast.success(`KYC Verification updated successfully.`);
             onClose();

          } catch (err) {
            // 
          }
          }
      }
    >
      {({ 
        handleBlur,
        handleChange,
        errors, 
        handleSubmit, 
        isSubmitting,
        values,
        touched }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                  Edit KYC Verification
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>

            <FormControl fullWidth margin="normal">
              <InputLabel error={Boolean(touched.kycStatus && errors.kycStatus)}>
                KYC Status
              </InputLabel>
              <Select
                error={Boolean(touched.kycStatus && errors.kycStatus)}
                value={values.kycStatus}
                label="KYC Status"
                onBlur={handleBlur}
                onChange={handleChange}
                name="kycStatus"
                variant="outlined"
                // inputProps={{ disabled: account != undefined }}
              >
                  {kycStatusList.map((i) => 
                     <MenuItem key={i} value={i}>
                                {i}
                     </MenuItem>

                  )}
              </Select>
              <FormHelperText error>
                {touched.kycStatus && errors.kycStatus}
              </FormHelperText>
            </FormControl>

           <Box sx={{ mt: '24px' }}>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
