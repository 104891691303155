import {
  Box,
  Button,
  colors,
  Container,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { RestrictionStatusLabel } from '../RestrictionStatusLabel';
import { CurrencyList } from './CurrencyList';
import { CurrencyPending } from './CurrencyPending';
import { CurrencyForm } from './forms/CurrencyForm';
export const Currency: FC<{ accountHolderId: string; isGlobal: boolean }> = ({
  accountHolderId,
  isGlobal,
}) => {
  const { hasPermission } = usePermissions();
  const [addCurrencyOpened, setAddCurrencyOpened] = useState<boolean>(false);
  const [addCurrencyRequestOpened, setAddCurrencyRequestOpened] =
    useState<boolean>(false);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Helmet>
        <title>Currency</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12} sx={{ mt: '30px' }}>
          {isGlobal && (<Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            RESTRICTIONS
          </Typography>)}
        </Grid>

        <Box
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              marginTop: '25px',
              marginBottom: '25px',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }),
          ]}
        >
          <Typography color="textPrimary" variant="h5">
            Currency <RestrictionStatusLabel status="ACTIVE" />
          </Typography>
          <Box
            sx={[
              {
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            <PermissionGuard permission="CREATE_CURRENCY_RESTRICTION">
              <Button
                disabled={!isUserActive}
                sx={{ mr: 0.5 }}
                variant="contained"
                onClick={() => setAddCurrencyOpened(true)}
              >
                Create currency restriction
              </Button>
            </PermissionGuard>
            {!hasPermission('ADMIN') && (
              <PermissionGuard permission="REQUEST_CURRENCY_RESTRICTION">
                <Button
                  disabled={!isUserActive}
                  sx={{ mr: 0.5 }}
                  variant="contained"
                  onClick={() => setAddCurrencyRequestOpened(true)}
                >
                  Create currency request
                </Button>
              </PermissionGuard>
            )}
          </Box>
        </Box>

        <PermissionGuard permission="CREATE_CURRENCY_RESTRICTION">
          <Drawer
            anchor="right"
            open={addCurrencyOpened}
            onClose={() => setAddCurrencyOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <CurrencyForm
              isRequest={false}
              accountHolderId={accountHolderId}
              isGlobal={isGlobal}
              onClose={() => setAddCurrencyOpened(false)}
            />
          </Drawer>
        </PermissionGuard>

        <PermissionGuard permission="REQUEST_CURRENCY_RESTRICTION">
          <Drawer
            anchor="right"
            open={addCurrencyRequestOpened}
            onClose={() => setAddCurrencyRequestOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <CurrencyForm
              isRequest={true}
              accountHolderId={accountHolderId}
              isGlobal={isGlobal}
              onClose={() => setAddCurrencyRequestOpened(false)}
            />
          </Drawer>
        </PermissionGuard>

        <PermissionGuard permission="GET_CURRENCY_RESTRICTION">
          <CurrencyList accountHolderId={accountHolderId} />
        </PermissionGuard>
      </Container>
      <PermissionGuard permission="GET_CURRENCY_RESTRICTION">
        <CurrencyPending
          accountHolderId={accountHolderId}
          isGlobal={isGlobal}
        />
      </PermissionGuard>
    </>
  );
};
