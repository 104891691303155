import { config } from '../../services/config';
import { apiWithTag } from '../emptySplitApi';
import {
  ForgotPasswordConfirmRequest,
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  RegisterResponse,
  TokenResponse,
} from './types';
import { AccountHolderRegisterRequest } from './types-api';

export const authApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        body: new URLSearchParams({
          grant_type: 'password',
          password: credentials.password,
          username: credentials.username,
        }),
        headers: {
          Authorization: `Basic ${config.authToken}`,
        },
        method: 'POST',
        url: 'auth/oauth/token',
      }),
      transformResponse: (response: LoginResponse) => {
        return ({ ...response, expires_on: Date.now() + response.expires_in });
      }
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        method: 'DELETE',
        url: 'auth/oauth/token',
      }),
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordRequest>({
      query: (body) => ({
        headers: {
          Authorization: `Basic ${config.authToken}`,
        },
        body,
        method: 'POST',
        url: 'auth/forgot-password',
      }),
    }),
    forgotPasswordConfirm: builder.mutation<void, ForgotPasswordConfirmRequest>(
      {
        query: (body) => ({
          headers: {
            Authorization: `Basic ${config.authToken}`,
          },
          body,
          method: 'POST',
          url: 'auth/forgot-password/confirm',
        }),
      },
    ),
    refreshToken: builder.mutation<TokenResponse, void>({
      query: () => ({
        method: 'POST',
        url: 'auth/oauth/token/refresh',
      }),
      transformResponse: (response: TokenResponse) => {
        return ({ ...response, expires_on: Date.now() + response.expires_in });
      }
    }),
    // https://dev-api.middlecc.com/auth/swagger-ui/index.html?urls.primaryName=Internal#/registration-controller/createAccountHolder
    register: builder.mutation<RegisterResponse, AccountHolderRegisterRequest>({
      query: (body) => ({
        body,
        headers: {
          Authorization: `Basic ${config.authToken}`,
        },
        method: 'POST',
        url: 'auth/registration/account-holders',
      }),
    }),
    userVerification: builder.mutation<void, { email: string }>({
      query: (body) => ({
        body,
        headers: {
          Authorization: `Basic ${config.authToken}`,
        },
        method: 'POST',
        url: 'auth/verification',
      }),
    }),
    userVerificationConfirm: builder.mutation<void, { verificationId: string, verificationCode: string }>({
      query: (body) => ({
        body,
        headers: {
          Authorization: `Basic ${config.authToken}`,
        },
        method: 'POST',
        url: 'auth/verification/confirm',
      }),
    }),
    userExistsVerification: builder.mutation<{ id: string }, { email: string }>({
      query: (payload) => ({
        headers: {
          Authorization: `Basic ${config.authToken}`,
        },
        method: 'GET',
        params: {
          email: payload.email,
        },
        url: 'auth/verification/user',
      }),
    }),
    // Admin reset password
    resetPassword: builder.mutation<{ userId: string, newPassword: string }, { accountHolderId: string, userId: string, sendByEmail: boolean }>({
      query: ({ accountHolderId, ...body }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        body,
        method: 'POST',
        url: 'auth/reset-password',
      }),
    }),
    impersonate: builder.mutation<LoginResponse, { userId: string, accountHolderId: string }>({
      query: ({ userId, accountHolderId }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        url: `auth/users/${userId}/impersonate`,
        method: 'GET',
      }),
      transformResponse: (response: LoginResponse) => {
        return ({ ...response, expires_on: Date.now() + response.expires_in });
      }
    }),
  }),
});

export const {
  useForgotPasswordConfirmMutation,
  useForgotPasswordMutation,
  useLoginMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
  useRegisterMutation,
  useUserVerificationMutation,
  useUserVerificationConfirmMutation,
  useUserExistsVerificationMutation,
  useResetPasswordMutation,
  useImpersonateMutation
} = authApi;
