import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { AccountHolderListRequest, ApiAccountHolderResponse, ApiAccountHolderUpdate, PageApiAccountHolderResponse } from './types-api';
import { ApiCreateAccountHolderRequest } from './types-api';

export const accountHoldersApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        accountHolders: builder.query<PageApiAccountHolderResponse, AccountHolderListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {

                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'auth/account-holders',
                }
            },
            providesTags: ['AccountHolders']
        }),
        accountHolder: builder.query<ApiAccountHolderResponse, { accountHolderIdForHeader: string; accountHolderId: string; }>({
            query: ({ accountHolderIdForHeader, accountHolderId }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'GET',
                url: `auth/account-holders/${accountHolderId}`
            }),
            providesTags: ['AccountHolders']
        }),
        addAccountHolder: builder.mutation<ApiAccountHolderResponse, ApiCreateAccountHolderRequest>({
            query: ({ accountHolderIdForHeader, ...body }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body,
                url: 'auth/account-holders',
            }),
            invalidatesTags: ['AccountHolders']
        }),
        updateAccountHolder: builder.mutation<ApiAccountHolderResponse, ApiAccountHolderUpdate>({
            query: ({ accountHolderId, accountHolderIdForHeader, ...body }) => ({
                body,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                url: `auth/account-holders/${accountHolderId}`,

            }),
            invalidatesTags: ['AccountHolders']
        }),
        // deleteAccountHolder: builder.mutation<void, string>({
        //     query: (id) => ({
        //         url: `/account-holders/${ id }`,
        //         method: 'DELETE',
        //     }),
        //     invalidatesTags: ['AccountHolders']
        // }),
        // Sub Clients
        subAccountHolders: builder.query<PageApiAccountHolderResponse, AccountHolderListRequest>({
            // query: () => '/accountHolders',
            query: ({ accountHolderIdForHeader, subAccountHolderId, ...params }) => {
                const parameters = removeEmpty({
                    ...params,
                    includeSubAccountHolders: true,
                    // id:subAccountHolderId,
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: `auth/account-holders`,
                }
            },
            providesTags: ['AccountHolders']
        }),
        accountHoldersList: builder.query<ApiAccountHolderResponse[], { accountHolderId: string }>({
            query: ({ accountHolderId }) => ({
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                method: 'GET',
                params: {
                    includeSubAccountHolders: true
                },
                url: 'auth/account-holders/list',
            }),
            transformResponse: (response: ApiAccountHolderResponse[]) => {
                return response
                    .sort((a, b) => a.displayName > b.displayName && 1 || -1)
            },
            providesTags: ['AccountHolders']
        }),
    })
})

export const {
    useAccountHoldersQuery,
    useAccountHolderQuery,
    useAddAccountHolderMutation,
    useUpdateAccountHolderMutation,
    // useDeleteAccountHolderMutation,
    useSubAccountHoldersQuery,
    useAccountHoldersListQuery
} = accountHoldersApi;