import 'moment-timezone';

import {
  AccountBalance,
  AccountBalanceWallet,
} from '@mui/icons-material';
import { Box, Drawer, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import NavSection from 'src/components/NavSection';
import Scrollbar from 'src/components/Scrollbar';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { LogoText } from '../Logo';
// import { SIDEBAR_WIDTH } from './utils/config';
interface IGamingSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const IGamingSidebar: FC<IGamingSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { hasPermission } = usePermissions();
  const { t } = useTranslation();

  const sections = [

    {
      title: t('common.financials'),
      items: [
        {
          title: t('demoIgaming.myTransactions'),
          path: '/igaming/transactions',
          icon: <AccountBalance fontSize="small" />,
          permissions: [],
        },
        {
          title: t('demoIgaming.myWallet'),
          path: '/igaming/wallet',
          icon: <AccountBalanceWallet fontSize="small" />,
          permissions: [],
        },

      ],
    },
  ];


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 2,
        }}
      >
        <RouterLink to="/">
          <LogoText />
        </RouterLink>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >

      </Box>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <React.Fragment key={section.title}>
              {section.items
                .map((i) => i.permissions)
                .some((ps) => ps.every(p => hasPermission(p.permission) === p.shouldHave) || ps.length == 0) && (
                  <NavSection
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 3,
                      },
                    }}
                    {...section}
                  />
                )}
            </React.Fragment>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: '#F3F7FE',
            width: SIDEBAR_WIDTH,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: '#F3F7FE',
          width: SIDEBAR_WIDTH,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default IGamingSidebar;
