import { Backdrop, Theme } from '@mui/material';
import { createStyles,makeStyles } from '@mui/styles';
import React, { FC, useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { resetCredentials, selectExpiresOn, useLogoutMutation, useRefreshTokenMutation } from 'src/models/auth';
import { resetBank } from 'src/models/cms';
import { persistor } from 'src/services/redux';
import { CountdownCircleTimer } from './CountdownCircleTimer';
import { UserIdleConfig } from './idle.config';
import { myworker } from './idle.worker';
import { ColorFormat } from './shared/types';

interface Props {
  // children: React.ReactNode;
  config: UserIdleConfig;
}


const useStyles = makeStyles((theme: Theme) => createStyles({
  'pulse': {
    // border:'1px solid red',
    borderRadius: '50%',
    backgroundColor: '#2B2D2F',
    width: '5px',
    height: '5px',
    position: 'absolute',
    opacity: '1',
    animation: '$scaleIn 1s infinite cubic-bezier(.36, .11, .89, .32)'
  },
  'timer' : {
    fontFamily: "Montserrat",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  'text' : {
    color: 'white'
  },
  'value' : {
    fontSize: '40px'
  },
  '@keyframes scaleIn': {
    from : {
      transform: 'scale(31, 31)',
      opacity: '.5',
    },
    to :{
      transform: 'scale(2.5, 2.5)',
      opacity: '0'
    }
  }
}));


const RenderTime = ({remainingTime, elapsedTime, color}:{remainingTime:number, elapsedTime:number, color: ColorFormat}) => {
  const classes = useStyles();
  // console.log(remainingTime,elapsedTime);
  if (remainingTime === 0) {
    return <div className={classes.timer}>Logging out</div>;
  }

  return (
    <>
    {remainingTime < 6  && <div className={classes.pulse}></div>}
    <div className={classes.timer}>
      <div className={classes.text}>User idle</div>
      <div className={classes.text}>Logging out in</div>
      <div className={classes.value}>{remainingTime}</div>
      <div className={classes.text}>seconds</div>
    </div>
    </>
  );
};


export const Idle: FC<Props> = (props) => {
  const [logout] = useLogoutMutation();
  const [refreshToken] = useRefreshTokenMutation();
  let worker: Worker | undefined;
  const [idleWarning, setIdleWarning] = useState(null);
  const dispatch = useDispatch();
  const expiresOn = useSelector(selectExpiresOn);


  useEffect(() => {
    if (Date.now() > expiresOn){
      persistor.purge();
      logout();
      dispatch(resetCredentials());
      dispatch(resetBank()); 
    }
   }, [expiresOn]);
  
  const handleResetTimer = () => {
    // console.log(`should reset ${idleWarning || 'null'}`);
    // alert(idleWarning);
    // if (idleWarning) {
      // alert('reset idle warning');
      setIdleWarning(null);
      // refresh token
    // }
    resetTimer();

  };

  const startWatching = () => {
    if (typeof Worker !== 'undefined') {
       let code = myworker.toString();
      code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

      const blob = new Blob([code], { type: 'application/javascript' });
      worker = new Worker(URL.createObjectURL(blob));
      // alert(worker);
      worker.onmessage = ({ data }) => {
        // console.log(`message: ${JSON.stringify(data)}`);
        switch (data.command) {
          case 'ping':
            // refresh Token
            refreshToken();
            break;
          case 'timeout':
            // we will not use its countdown
            // setIdleWarning(data.timeout);
            break;
          case 'timeoutstart':
            setIdleWarning(data.timeoutValue);
            break;
          case 'stop':
            // logout
            logout();
            dispatch(resetCredentials());
            dispatch(resetBank()); 
            break;
          case 'log':
            // Log command for debugging
            console.log(data.value);
            break;
          default:
            // Command not found
            break;
        }
      };
      worker.postMessage({ command: 'start', ...props.config });
    } else {
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  };

  const resetTimer = () => {
    if (typeof Worker !== 'undefined') {
      worker?.postMessage({ command: 'restart' });
    }
  };

  const terminate = () => {
    worker?.terminate();
  };

  useEffect(() => {
    // console.log('on create');
    document.addEventListener('mousemove', handleResetTimer);
    document.addEventListener('keydown', handleResetTimer);
    startWatching();
    // Remove event listener on cleanup
    return () => {
      // console.log('on destroy');
      document.removeEventListener('mousemove', handleResetTimer);
      document.removeEventListener('keydown', handleResetTimer);
      terminate();
    };
  }, []);

  return (
    <div>
      {/* <div>
        <h1>Timeout: {props.config.timeout}ms</h1>
        <h1>Idle: {props.config.idle}ms</h1>
        <h1>Ping: {props.config.ping}ms</h1>
        <h1>Countdown: {idleWarning}</h1>
        <h1>Open:  {(idleWarning != null).toString()}</h1>
      </div> */}
      {/* {props.children} */}
      <Outlet />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={idleWarning != null}
        >
          {idleWarning != null &&
                  <CountdownCircleTimer
                  isPlaying
                  duration={idleWarning as unknown as number}
                  // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  colors={["#1976d2", "#616161", "#827717"]}
                  colorsTime={[30, 25, 0]}
                  onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                >
                  {RenderTime}
                </CountdownCircleTimer>

          }
      </Backdrop>
    </div>
  );
};

export default Idle;




// import React, { Component } from 'react';
// import { render } from 'react-dom';
// import Idle from './idle/Idle';
// import './style.css';

// interface AppProps {}
// interface AppState {
//   name: string;
// }

// class App extends Component<AppProps, AppState> {
//   constructor(props:AppProps) {
//     super(props);
//     this.state = {
//       name: 'React',
//     };
//   }

//   render() {
//     const config = { idle: 870, timeout: 30, ping: 60, debug: true };
//     const config = { idle: 10, timeout: 30, ping: 60, debug: true };

//     return (
//       <div>
//         <Idle config={config}>
//           <p>Start editing to see some magic happen :)</p>
//         </Idle>
//       </div>
//     );
//   }
// }

// render(<App />, document.getElementById('root'));
