import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { FormikTextField } from 'src/components/forms/TextField';
import useMounted from 'src/hooks/useMounted';
import { useTotp } from 'src/hooks/useTotp';
import { useCreateInvitationMutation } from 'src/models/invitations';
import { useGetPermissionGroupsQuery } from 'src/models/permission-groups';
interface FormProps {
  onClose?: () => void;
  accountHolderId: string;
}
export const InviteUserForm: FC<FormProps> = ({ onClose, accountHolderId }) => {
  const mounted = useMounted();
  const [createInvitation] = useCreateInvitationMutation();
  const { data: permissionGroups, isLoading } = useGetPermissionGroupsQuery(
    {
      accountHolderId,
    },
    { refetchOnMountOrArgChange: true },
  );
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        email: '',
        permissionGroupIds: [],
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
        permissionGroupIds: Yup.array().min(0),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting },
      ): Promise<void> => {
        try {
          await createInvitation({
            accountHolderId,
            email: values.email,
            permissionGroupIds: values.permissionGroupIds,
          }).unwrap();
          //   console.log(values);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
            onClose();
            toast.success('Invitation sent successfully');
          }
        } catch (err) {
          console.error(err);

          if (!mounted.current) {
            return;
          }

          const errorMsg = err.data?.message || err.message;

          setStatus({ success: false });
          setErrors({ submit: errorMsg });
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Invite User
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <FormikTextField
                name="email"
                label="Email (will be used for login)"
                type="email"
                autoComplete="off"
              />
              {isLoading && <CircularProgress />}
              {permissionGroups && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="select-permission-group-label">
                    Permissions
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    label="Permissions"
                    id="select-permission-group"
                    value={values.permissionGroupIds}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              permissionGroups.find((x) => x.id == value).name
                            }
                          />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('permissionGroupIds', e.target.value);
                    }}
                  >
                    {permissionGroups.map((permissionGroup) => (
                      <MenuItem
                        key={permissionGroup.id}
                        value={permissionGroup.id}
                      >
                        {permissionGroup.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
