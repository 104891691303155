import { FilterAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  colors,
  Container,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { FeeConfigListFilter } from 'src/models/assignedFees';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { AssignedFeeList } from './AssignedFeeList';
import { AssignedFeesFilterForm } from './AssignedFeesFilterForm';
import { AssignFeeForm } from './forms/AssignFeeForm';

const AssignedFeesScreen: FC<{ parentAccountHolderId: string; accountHolderId: string }> = ({
  parentAccountHolderId,
  accountHolderId,
}) => {
  const [filter, setFilter] = useState<FeeConfigListFilter>();
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [assignFeeOpened, setAssignFeeOpened] =
    useState<boolean>(false);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  useEffect(() => {
    setFilterOpen(false);
  }, [filter]);

  useEffect(() => {
    setFilter({
      id: '',
      transactionType: [],
      feeContributor: [],
      feeAccountNumber: '',
      feeAccountId: '',
      accountHolderId: '',
      enabled: true,
      transactionContext: null,
      contributorAccountNumber: '',
      description: '',
      assignable: false,
      relatedFeeId: '',
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Assigned Fees</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12}>
          <Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            FINANCIALS
          </Typography>
        </Grid>
        <Box
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }),
          ]}
        >
          <Typography color="textPrimary" variant="h5">
            Assigned Fees
          </Typography>

          <Box
            sx={[
              {
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            <PermissionGuard permission="ASSIGN_FEE">
              <Button
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setAssignFeeOpened(true)}
                disabled={!isUserActive}
              >
                Assign fee
              </Button>
            </PermissionGuard>

            <Box
              sx={[
                { display: 'flex', justifyContent: 'flex-end' },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    width: 'auto',
                  },
                }),
              ]}
            >
              <FilterAlt
                color="primary"
                fontSize="large"
                sx={{ marginLeft: '10px' }}
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              />
            </Box>
          </Box>
        </Box>

        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >

            <Grid item xs={12}>
              <AssignedFeesFilterForm onFilterApply={setFilter} accountHolderId={accountHolderId} />
            </Grid>
          </Grid>
        </Drawer>
        <PermissionGuard permission="ASSIGN_FEE">
          <Drawer
            anchor="right"
            open={assignFeeOpened}
            onClose={() => setAssignFeeOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <AssignFeeForm
              parentAccountHolderId={parentAccountHolderId}
              accountHolderId={accountHolderId}
              onClose={() => setAssignFeeOpened(false)}
            />
          </Drawer>
        </PermissionGuard>
        <PermissionGuard permission="UNASSIGN_FEE">
          <AssignedFeeList filter={filter} accountHolderId={accountHolderId} />
        </PermissionGuard>
      </Container>
    </>
  );
};

export default AssignedFeesScreen;
