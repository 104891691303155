import { CreateUpdateBeneficiaryFormValues } from "src/screens/Beneficiaries/forms/types-dto";
import { ApiBeneficiary, ApiBeneficiaryCreate, ApiBeneficiaryUpdate } from "./types-api";

export const mapApiBeneficiaryToCreateUpdateBeneficiaryFormValues = (
    beneficiary: ApiBeneficiary, accountHolderIdForHeader: string
): CreateUpdateBeneficiaryFormValues => ({
    firstName: beneficiary.firstName,
    lastName: beneficiary.lastName,
    companyName: beneficiary.companyName,
    accountNumber: beneficiary.accountNumber,
    iban: beneficiary.iban,
    enabled: beneficiary.enabled,
    id: beneficiary.id,
    accountHolderIdForHeader
});

export const mapCreateUpdateBeneficiaryFormValuesToApiBeneficiaryCreate = (
    beneficiaryDto: CreateUpdateBeneficiaryFormValues
): ApiBeneficiaryCreate => ({
    firstName: beneficiaryDto.firstName,
    lastName: beneficiaryDto.lastName,
    companyName: beneficiaryDto.companyName,
    accountNumber: beneficiaryDto.accountNumber,
    iban: beneficiaryDto.iban,
    enabled: beneficiaryDto.enabled,
    accountHolderIdForHeader: beneficiaryDto.accountHolderIdForHeader
});

export const mapCreateUpdateBeneficiaryFormValuesToApiBeneficiaryUpdate = (
    beneficiaryDto: CreateUpdateBeneficiaryFormValues
): ApiBeneficiaryUpdate => ({
    id: beneficiaryDto.id,
    firstName: beneficiaryDto.firstName,
    lastName: beneficiaryDto.lastName,
    companyName: beneficiaryDto.companyName,
    accountNumber: beneficiaryDto.accountNumber,
    iban: beneficiaryDto.iban,
    enabled: beneficiaryDto.enabled,
    accountHolderIdForHeader: beneficiaryDto.accountHolderIdForHeader
});

