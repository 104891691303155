import { apiWithTag } from '../emptySplitApi';
import { PermissionGroup } from './types';

export const permissionGroupsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPermissionGroups: builder.query<PermissionGroup[], { accountHolderId: string }>({
      query: ({ accountHolderId }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'GET',
        url: `auth/permission-groups`,
      }),
      transformResponse: (response: PermissionGroup[]) => {
        return response
          .filter((a) => a.name != 'User Default')
      }
    }),
  }),
});

export const {
  useGetPermissionGroupsQuery,
} = permissionGroupsApi;
