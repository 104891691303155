import {
    CountryCode,
    getCountries,
} from 'libphonenumber-js';
import * as Yup from "yup";


const countries = getCountries();

const countryCodeSchema = Yup.object().nullable().shape({
    callingCode: Yup.string().required('validations.mustSelectACountry'),
    code: Yup.mixed<CountryCode>().oneOf(countries, 'validations.mustSelectACountry'),
    name: Yup.string().required('validations.mustSelectACountry'),
})
    .test(
        'null-check',
        'validations.mustSelectANationality',
        country => country != null
    );

export const nationalityValidationSchema = () => countryCodeSchema.required('Nationality is required');
