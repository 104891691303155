import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { EmailInfo } from '.';

export const EmailForm: FC = () => {
  const [searchParams] = useSearchParams();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<EmailInfo>();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Field
        component={TextField}
        name="email"
        fullWidth
        label={t('common.emailWillBeUsedForLogin')}
        value={values.email}
        margin="normal"
        error={Boolean(touched?.email && !!errors?.email)}
        helperText={touched?.email && t(errors?.email as string)}
        onBlur={(e) => {
          setFieldValue('checkEmail', true);
          handleBlur(`email`)(e);
        }}
        onChange={handleChange(`email`)}
        variant="outlined"
        disabled={searchParams.get('email')?.length > 0}
      />

      {/* <FormikTextField 
            disabled={searchParams.get('email')?.length > 0}
            name="email" 
            label="Email (will be used for login)" 
            type="email"  
            autoComplete='off' 
            onBlur={(e) => {
                // handleBlur(e);
                if (!errors['email']) {
                //   props.setValues({
                //     ...values,
                //     checkEmail: true,
                //    })
                   }
                  setFieldValue('checkEmail', true);
                }
              }
            /> */}

      <Field
        component={TextField}
        name="password"
        fullWidth
        label={t('common.password')}
        value={values.password}
        margin="normal"
        error={Boolean(touched?.password && !!errors?.password)}
        helperText={touched?.password && t(errors?.password as string)}
        onBlur={handleBlur(`password`)}
        onChange={handleChange(`password`)}
        variant="outlined"
        autoComplete="new-password"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* <FormikShowHide
        name="password"
        label="Password"
        autoComplete="new-password"
        formControlProps={{
          sx: { mb: '24px' },
        }}
      /> */}
    </>
  );
};
