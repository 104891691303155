import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../config';
import { RootState } from './store';

const prepareGatewayHeaders = (headers: Headers, { getState }) => {
  const fingerprintId = (getState() as RootState).fingerprint?.id;
  if (fingerprintId) {
    headers.set('X-FINGERPRINT-ID', fingerprintId)
  }
  return headers;
};

export const gatewayBaseQuery = fetchBaseQuery({
  baseUrl: `${config.apiUrl}`,
  prepareHeaders: prepareGatewayHeaders,
});



const prepareCMSHeaders = (headers: Headers, { getState, endpoint }) => {
  const fingerprintId = (getState() as RootState).fingerprint?.id;
  if (fingerprintId) {
    headers.set('X-FINGERPRINT-ID', fingerprintId)
  }
  const token = (getState() as RootState).auth?.accessToken;
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }
  if (!['addAccountHolderTemplate', 'updateAccountHolderTemplate'].includes(endpoint)) {
    headers.set('Content-Type', 'application/json');
  }
  else {
    // This is to allow multipart/form-data to be set and bounderies generated automatically
  }


  const totpCode = (getState() as RootState)?.totp.code;

  if (totpCode) {
    headers.set('X-TOTP-CODE', totpCode);
  }
  return headers;
};

export const cmsBaseQuery = fetchBaseQuery({
  baseUrl: `${config.apiUrl}`,
  prepareHeaders: prepareCMSHeaders,
});
