import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField, } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiBeneficiaryCreate,
  ApiBeneficiaryUpdate,
  mapCreateUpdateBeneficiaryFormValuesToApiBeneficiaryCreate,
  mapCreateUpdateBeneficiaryFormValuesToApiBeneficiaryUpdate,
  useAddBeneficiaryMutation,
  useUpdateBeneficiaryMutation,
} from 'src/models/beneficiaries';
import { CreateUpdateBeneficiaryFormValues } from './types-dto';
import { createBeneficiaryFormValuesInitialValue } from './utils';
import { beneficiaryFormValidationSchema } from './validations';
interface FormProps {
  onClose?: () => void;
  beneficiary?: CreateUpdateBeneficiaryFormValues;
  accountHolderId: string;
  isGlobal: boolean;
}
export const BeneficiaryForm: FC<FormProps> = ({
  onClose,
  beneficiary,
  accountHolderId,
  isGlobal,
}) => {

  const [addBeneficiary] = useAddBeneficiaryMutation();
  const [updateBeneficiary] = useUpdateBeneficiaryMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateUpdateBeneficiaryFormValues>
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        ...createBeneficiaryFormValuesInitialValue(accountHolderId),
        ...beneficiary
      }}
      validationSchema={beneficiaryFormValidationSchema()}
      onSubmit={async (values): Promise<void> => {
        try {
          if (beneficiary) {
            const apiBeneficiaryUpdate: ApiBeneficiaryUpdate = await mapCreateUpdateBeneficiaryFormValuesToApiBeneficiaryUpdate(values);
            await updateBeneficiary(apiBeneficiaryUpdate).unwrap();
            toast.success(`Beneficiary updated successfully.`);
          } else {
            const apiBeneficiaryCreate: ApiBeneficiaryCreate = await mapCreateUpdateBeneficiaryFormValuesToApiBeneficiaryCreate(values);
            await addBeneficiary(apiBeneficiaryCreate).unwrap();
            toast.success(`Beneficiary created successfully.`);
          }
          onClose();
        } catch (err) {
          if (err?.data?.errorCode == 'BENEFICIARY_NOT_FOUND') {
            toast.error(
              `Beneficiary not found`,
            );
          }
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {beneficiary ? 'Edit Beneficiary' : 'Create Beneficiary'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              {!beneficiary && (
                <Field
                  name={`accountHolderIdForHeader`}
                  component={AccountHolderIdField}
                  disabled={!isGlobal}
                  accountHolderId={accountHolderId} />
              )}

              {beneficiary && (
                <FormikTextField
                  name="id"
                  label="Id"
                  autoComplete="off"
                  disabled
                />
              )}

              <FormikTextField
                name="firstName"
                label="First Name"
                autoComplete="off"
              />

              <FormikTextField
                name="lastName"
                label="Last Name"
                autoComplete="off"
              />

              <FormikTextField
                name="companyName"
                label="Company Name"
                autoComplete="off"
              />

              <FormikTextField
                name="accountNumber"
                label="Account Number"
                autoComplete="off"
              />

              <FormikTextField
                name="iban"
                label="Iban"
                autoComplete="off"
              />

              {beneficiary && (
                <FormControlLabel
                  control={
                    <Field
                      label="Enabled"
                      name="enabled"
                      component={Switch}
                      onChange={(ev, checked) => {
                        setFieldValue('enabled', checked);
                      }}
                      checked={values.enabled ? true : null}
                    />
                  }
                  label="Enabled"
                />
              )}

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {beneficiary ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
