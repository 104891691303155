import { accountHolderTypesInitialValue } from "src/components/forms";
import { ClientInfo, clientInfoValidationSchema } from ".";

export const clientFormInitialValues = (clientInfo?: ClientInfo): ClientInfo => {
    return {
        accountHolderType: accountHolderTypesInitialValue(),
        descriptor: '',
        ...clientInfo
    }
}

export const isClientFormCompleted = async (clientInfo: ClientInfo): Promise<boolean> => {
    try {
        await clientInfoValidationSchema().validate(clientInfo);
    } catch (e) {
        return false;
    }
    return true;
}
