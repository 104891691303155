import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { Transaction } from 'src/models/transactions/types';
import { SortOrder } from 'src/models/types';
import { TransactionStatusLabel } from './TransactionStatusLabel';

interface TransactionTable {
  transactions: Transaction[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  append?: string;
}



export const TransactionsTable: FC<TransactionTable> = ({
  transactions,
  orderBy,
  sortDirection,
  onRequestSort,
  append,
}) => {

  const { hasPermission } = usePermissions();

  const headCells = [
    { title: 'Transaction date', id: 'createdDateTime', doSort: true },
    // { title: 'Transaction ID', id: 'id', doSort: true },
    { title: 'Transaction Number', id: 'transactionNumber', doSort: true },
    // ...(hasPermission('ADMIN') ? [{ title: 'Type', id: 'transactionType', doSort: true }] : []),
    { title: 'Status', id: 'transactionStatus', doSort: true },
    { title: 'Amount', id: 'amount.value', doSort: false },
    // { title: 'Currency', id: 'amount.currency', doSort: false },
    // { title: 'MCC', id: 'mmc', doSort: false },
    { title: 'Description', id: 'descriptor', doSort: false },
    { title: 'Summary', id: 'descriptionSummary', doSort: false },
    // { title: 'Ref. #', id: 'referenceNo', doSort: true },
    { title: 'Actions', id: 'actions', doSort: false },
  ];


  const navigate = useNavigate();
  const sortEnabled = orderBy && sortDirection && onRequestSort;

  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <Box sx={{ minWidth: 700 }}>
      <Table size="small" >
        <TableHead>
          <TableRow>
            {headCells.map(({ title, id, doSort }) => (
              <TableCell
                key={id}
                sortDirection={
                  sortEnabled && doSort && orderBy === id
                    ? sortDirection
                    : false
                }
              >
                {sortEnabled && doSort ? (
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? sortDirection : 'asc'}
                    onClick={createSortHandler(id)}
                  >
                    {title}
                  </TableSortLabel>
                ) : (
                  title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => {
            return (
              <TableRow
                hover
                key={transaction.id}
              // onClick={() => navigate(`${transaction.id}`)}
              >
                <TableCell>
                  <Typography noWrap variant="inherit">
                    {format(
                      new Date(transaction.createdDateTime),
                      'dd/MM/yyyy HH:mm:ss',
                    )}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {transaction.id}
                  </Typography>
                </TableCell> */}
                <TableCell>
                  <Typography noWrap variant="inherit">
                    {transaction.transactionNumber}
                  </Typography>
                </TableCell>
                {/* {hasPermission('ADMIN') && (<TableCell>
                  {transaction.transactionTypeDescription ||
                    transaction.transactionType}
                </TableCell>)} */}
                <TableCell>
                  <TransactionStatusLabel
                    status={transaction.transactionStatus}
                  />
                </TableCell>
                <TableCell>
                  {transaction.amount.displayCurrencyCode} {transaction.amount.displayText}
                </TableCell>
                {/* <TableCell>{transaction.amount.value}</TableCell>
                <TableCell>{transaction.amount.currency}</TableCell> */}
                <TableCell sx={{ maxWidth: '150px' }}>
                  <Typography noWrap variant="inherit">
                    {transaction.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap variant="inherit">
                    {transaction.descriptionSummary}
                  </Typography>
                </TableCell>
                {/* <TableCell>{transaction.referenceNo}</TableCell> */}
                <TableCell align="right">
                  <IconButton
                    component={RouterLink}
                    to={`${append || ''}${transaction.id}`}
                  >
                    <ArrowForward fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
