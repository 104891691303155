import { Check, Clear } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { ExpandableTableRow } from 'src/components/ExpandableTableRow';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiRestrictionRequest,
  useUpdateRestrictionMutation,
} from 'src/models/restrictions';
import { SortOrder } from 'src/models/types';
import { RestrictionStatusLabel } from '../RestrictionStatusLabel';
interface PendingsTable {
  pendings: ApiRestrictionRequest[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
}

const headCells = [
  { title: '', id: '', doSort: false },
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  // { title: 'Id', id: 'id', doSort: true },
  { title: 'Restriction Type', id: 'restrictionType', doSort: true },
  { title: 'Requested By', id: 'requestedBy', doSort: true },
  { title: 'Status', id: 'restrictionStatus', doSort: true },
  { title: 'Restriction Action', id: 'restrictionAction', doSort: true },
  // { title: 'Country Code', id: 'countryCode', doSort: true },
  // { title: 'Currency Code', id: 'currencyCode', doSort: true },
  // { title: 'Range From', id: 'rangeFrom', doSort: true },
  // { title: 'Range To', id: 'rangeTo', doSort: true },
  // { title: 'Account Currency Code', id: 'accountCurrencyCode', doSort: true },
  // { title: 'Account Currency Create On Registration', id: 'accountCurrencyCreateOnRegistration', doSort: true },
  { title: 'Client Id', id: 'accountHolderId', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const PendingsTable: FC<PendingsTable> = ({
  pendings,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };
  const { hasPermission } = usePermissions();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const [updateRestriction] = useUpdateRestrictionMutation();
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {/* Action is update and delete only so we remove Actions if update and delete is not possible */}
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pendings.map((pending) => {
              return (
                <ExpandableTableRow
                  hideExpanse={false}
                  hover
                  key={pending.id}
                  expandComponent={
                    <TableCell colSpan={4}>
                      <Table size='small'>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ border: 'none' }}>
                              <Typography
                                noWrap
                                variant="body2"
                                color="textSecondary"
                              >
                                Id
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {pending.id}
                            </TableCell>
                          </TableRow>
                          {pending.countryCode && (
                            <TableRow>
                              <TableCell sx={{ border: 'none' }}>
                                <Typography
                                  noWrap
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Country code
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: 'none',
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {pending.countryCode}
                              </TableCell>
                            </TableRow>
                          )}

                          {pending.currencyCode && (
                            <TableRow>
                              <TableCell sx={{ border: 'none' }}>
                                <Typography
                                  noWrap
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Currency code
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: 'none',
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {pending.currencyCode}
                              </TableCell>
                            </TableRow>
                          )}

                          {pending.rangeFrom && (
                            <TableRow>
                              <TableCell sx={{ border: 'none' }}>
                                <Typography
                                  noWrap
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Range From
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: 'none',
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {pending.rangeFrom}
                              </TableCell>
                            </TableRow>
                          )}

                          {pending.rangeTo && (
                            <TableRow>
                              <TableCell sx={{ border: 'none' }}>
                                <Typography
                                  noWrap
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Range To
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: 'none',
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {pending.rangeTo}
                              </TableCell>
                            </TableRow>
                          )}

                          {pending.accountCurrencyCode && (
                            <TableRow>
                              <TableCell sx={{ border: 'none' }}>
                                <Typography
                                  noWrap
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Account currency code
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: 'none',
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {pending.accountCurrencyCode}
                              </TableCell>
                            </TableRow>
                          )}

                          {pending.accountCurrencyCode && (
                            <TableRow>
                              <TableCell sx={{ border: 'none' }}>
                                <Typography
                                  noWrap
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Create on registration
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: 'none',
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <Chip
                                  label={
                                    pending.accountCurrencyCreateOnRegistration
                                      ? 'Yes'
                                      : 'No'
                                  }
                                  color={
                                    pending.accountCurrencyCreateOnRegistration
                                      ? 'success'
                                      : 'error'
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableCell>
                  }
                >
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(pending.createdDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <RestrictionStatusLabel status={pending.restrictionType} />
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {pending.requestedBy}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <RestrictionStatusLabel
                      status={pending.restrictionStatus}
                    />
                  </TableCell>

                  <TableCell>
                    <RestrictionStatusLabel
                      status={pending.restrictionAction}
                    />
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {pending.accountHolderId}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Box sx={{ display: 'flex' }}>
                      <PermissionGuard permission="UPDATE_RESTRICTION">
                        <IconButton
                          disabled={!isUserActive}
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title:
                                'Are you sure you want to accept this restriction?',
                              subTitle: 'This operation cannot be undone',
                              onConfirm: () => {
                                updateRestriction({
                                  restrictionId: pending.id,
                                  restrictionStatus: 'COMPLETED',
                                  accountHolderIdForHeader: accountHolderId,
                                });
                              },
                            });
                          }}
                        >
                          <Check />
                        </IconButton>
                      </PermissionGuard>
                      {(hasPermission('UPDATE_RESTRICTION') ||
                        (hasPermission('REQUEST_COUNTRY_RESTRICTION') &&
                          pending.restrictionType == 'COUNTRY') ||
                        (hasPermission('REQUEST_CURRENCY_RESTRICTION') &&
                          pending.restrictionType == 'CURRENCY') ||
                        (hasPermission('REQUEST_CARDBIN_RESTRICTION') &&
                          pending.restrictionType == 'CARD_BIN') ||
                        (hasPermission(
                          'REQUEST_ACCOUNT_CURRENCY_RESTRICTION',
                        ) &&
                          pending.restrictionType == 'ACCOUNT_CURRENCY')) && (
                          <IconButton
                            disabled={!isUserActive}
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title:
                                  'Are you sure to you want to cancel this request?',
                                subTitle: 'This operation cannot be undone',
                                onConfirm: () => {
                                  updateRestriction({
                                    restrictionId: pending.id,
                                    restrictionStatus: 'CANCELLED',
                                    accountHolderIdForHeader: accountHolderId,
                                  });
                                },
                              });
                            }}
                          >
                            <Clear />
                          </IconButton>
                        )}
                    </Box>
                  </TableCell>
                </ExpandableTableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
