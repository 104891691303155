import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import {
    ApiTransferChannel,
    ApiTransferChannelCreate,
    ApiTransferChannelUpdate,
    TransferChannelListRequest,
    TransferChannelListResponse,
} from './types';

export const transferChannelsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        transferChannelsList: builder.query<TransferChannelListResponse, TransferChannelListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/transfer-channels',
                }
            },
            providesTags: ['TransferChannel']
        }),
        transferChannel: builder.query<ApiTransferChannel, { id: string, accountHolderId: string }>({
            query: ({ id, accountHolderId }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                url: `gateway//transfer-channels/${id}`,

            }),
            providesTags: ['TransferChannel']
        }),
        addTransferChannel: builder.mutation<ApiTransferChannel, ApiTransferChannelCreate>({
            query: ({ accountHolderId, ...transferChannel }) => ({
                url: 'gateway/transfer-channels',
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                method: 'POST',
                body: transferChannel
            }),
            invalidatesTags: ['TransferChannel']
        }),
        updateTransferChannel: builder.mutation<ApiTransferChannel, ApiTransferChannelUpdate>({
            query: ({ id, accountHolderId, ...rest }) => ({
                url: `gateway/transfer-channels/${id}`,
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['TransferChannel']
        }),
        deleteTransferChannel: builder.mutation<void, { id: string, accountHolderId: string }>({
            query: ({ id, accountHolderId }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                url: `gateway/transfer-channels/${id}`,
            }),
            invalidatesTags: ['TransferChannel']
        }),
        transferChannelProviderTypes: builder.query<string[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: 'gateway/transfer-channels/provider-types',
                }
            },
            transformResponse: (response: string[]) => {
                return (response?.sort((a, b) => (a > b ? 1 : -1)));
            },
            providesTags: ['TransferChannel']
        }),
    })
})

export const {
    useTransferChannelsListQuery,
    useTransferChannelQuery,
    useAddTransferChannelMutation,
    useUpdateTransferChannelMutation,
    useDeleteTransferChannelMutation,
    useTransferChannelProviderTypesQuery
} = transferChannelsApi;