import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FocusEvent, useCallback } from 'react';

interface Props {
  label: string;
  name: string;
  type?: string;
  autoComplete?: string;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  multiline?: boolean;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
}

export const FormikTextField = ({
  name,
  label,
  type,
  autoComplete,
  multiline,
  onBlur,
  disabled,
  size,
  margin
}: Props) => {
  const { errors, handleBlur, touched, handleChange, values } =
    useFormikContext();
  const touch = touched[name];
  const error = errors[name];
  const value = values[name];

  const onBlurHandler = useCallback((e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
    typeof onBlur === 'function' && onBlur(e);
  }, []);

  return (
    <TextField
      disabled={disabled}
      fullWidth
      label={label}
      name={name}
      error={Boolean(touch && error)}
      helperText={touch && error}
      onBlur={onBlurHandler}
      onChange={handleChange}
      value={value}
      variant="outlined"
      type={type}
      autoComplete={autoComplete}
      multiline={multiline}
      size={size}
      margin={margin}
    />
  );
};
