import { Chip, colors } from '@mui/material';
import React from 'react';

import { AccountEntryType, TransactionStatus, TransactionType } from '../types';

const statusColors = {
  COMPLETED: colors.green,
  CREATED: colors.green,
  EXPIRED: colors.grey,
  FAILED: colors.red,
  PENDING: colors.orange,
  PENDING_DEPOSIT: colors.orange,
  PENDING_THREE_D: colors.orange,
  PENDING_TRADE: colors.orange,
  PENDING_WITHDRAW: colors.orange,
  INITIAL: colors.green,
  PENDING_CONFIRMATION: colors.orange,
};

export const TransactionStatusLabel = ({
  status,
}: {
  status: TransactionStatus | TransactionType | AccountEntryType;
}) => {
  const backgroundColor = statusColors[status] && statusColors[status][50];
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
