import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import {
  ApiRestrictionRequest,
  RestrictionListSort,
  useRestrictionsQuery,
} from 'src/models/restrictions';
import { SortOrder } from 'src/models/types';
import { usePagination } from '../../../../hooks/usePagination';
import { PendingFilterForm } from '../../Pending/types';
import { AccountCurrenciesPendingTable } from './AccountCurrenciesPendingTable';

export const AccountCurrencyPendingList = ({
  filter,
  accountHolderId,
  isGlobal,
}: {
  filter: PendingFilterForm;
  accountHolderId?: string;
  isGlobal: boolean;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: RestrictionListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as RestrictionListSort, direction: 'desc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  const [skipFetch, setSkipFetch] = useState<boolean>(true);

  useEffect(() => {
    setSkipFetch(true);
    resetPagination();
    setSkipFetch(false);
  }, [filter]);

  const { data } = useRestrictionsQuery(
    {
      ...filter,
      accountHolderId: !isGlobal ? accountHolderId : '',
      accountHolderIdForHeader: accountHolderId,
      page,
      size: limit,
      sort: [`${sort.orderBy},${sort.direction}` as RestrictionListSort],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipFetch,
    },
  );

  const [accountCurrencies, setAccountCurrencies] = useState<
    ApiRestrictionRequest[]
  >([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    setAccountCurrencies(data?.content || []);
    setTotalElements(data?.totalElements || 0);
  }, [data]);

  return (
    <Card>
      <Scrollbar>
        <AccountCurrenciesPendingTable
          accountHolderId={accountHolderId}
          accountCurrencies={accountCurrencies}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
