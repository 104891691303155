import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from 'src/services/redux';
import { totpbaseQuery } from 'src/services/redux/totpBaseQuery';
import { config } from '../services/config';


const prepareHeaders = (headers: Headers, { getState, endpoint, extra, type, forced }) => {
    // alert('endpoint:' + endpoint)
    // alert('headers:' + JSON.stringify(headers))
    // alert('extra:' + extra)
    const fingerprintId = (getState() as RootState).fingerprint?.id;
    if (fingerprintId) {
        headers.set('X-FINGERPRINT-ID', fingerprintId)
    }
    const token = (getState() as RootState).auth?.accessToken;
    if (token) {
        headers.set('Authorization', `Bearer ${token}`);
        if (!['updateUserIdentificationsKycVerification', 'updateUserAddressesKycVerification', 'updateUserSelfiesKycVerification'].includes(endpoint))
            headers.set('Content-Type', 'application/json');
        else {
            // This is to allow multipart/form-data to be set and bounderies generated automatically
        }
    }
    const accountHolderId = (getState() as RootState).cms?.bank?.accountHolderId;
    if (accountHolderId) {
        headers.set('X-PARENT-ID', accountHolderId);
    }

    const totpCode = (getState() as RootState)?.totp.code;

    if (totpCode) {
        headers.set('X-TOTP-CODE', totpCode);
    }
    return headers;
}

export const baseQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders,
});

export const emptySplitApi = createApi({
    reducerPath: 'api',
    baseQuery: totpbaseQuery(baseQuery),
    // baseQuery: fetchBaseQuery({
    //     baseUrl: config.apiUrl,
    //     prepareHeaders
    // }),
    keepUnusedDataFor: 0.0001,
    endpoints: () => ({}),
});


export const apiWithTag = emptySplitApi.enhanceEndpoints({ addTagTypes: ['AccountHolderTemplate', 'AssignedFees', 'Beneficiary', 'Configurations-Acquiring', "Configurations-Banking", "Configurations-Fee", "Configurations-Telex", 'Configurations-CorrespondentBank', 'Configurations-Attribute', 'Configurations-PartnerBank', 'Event', 'Audit', 'Restrictions-Currency', "Restrictions-Country", "Restrictions-Card-Bin", "Restrictions-Account-Currencies", 'Restrictions-Pending', 'TransferChannel', 'Account', 'AccountHolders', 'ApiKey', 'CheckOut', 'Invitations', 'InvitationsUser', 'KYCManagementAdmin', 'KYCManagementUser', 'Me', 'Onboarding', 'States', 'Other', 'User', 'TransferChannelTemplate'] })
