import { Chip, colors } from '@mui/material';

export const TransferChannelProviderTypeLabel = ({
  status,
}: {
  status: string;
}) => {
  const backgroundColor =
    colors.blueGrey && `${colors.blueGrey[50]} !important`;
  const color = colors.blueGrey && colors.blueGrey[400];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
