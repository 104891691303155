import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { Acquiring } from './Acquiring';
import { AcquiringDetails } from './AcquiringDetails';
import { Banking } from './Banking';
import { Fees } from './Fees';
import { Telex } from './Telex';

export const ConfigurationRoutes: FC<{
  accountHolderId?: string;
  isGlobal: boolean;
}> = ({ accountHolderId, isGlobal }) => {
  return (
    <Routes>
      <Route
        path="banking"
        element={
          <Banking accountHolderId={accountHolderId} isGlobal={isGlobal} />
        }
      />
      <Route
        path="acquiring/:id"
        element={
          <AcquiringDetails
            accountHolderId={accountHolderId}
            isGlobal={isGlobal}
          />
        }
      />
      <Route
        path="acquiring"
        element={
          <Acquiring accountHolderId={accountHolderId} isGlobal={isGlobal} />
        }
      />
      <Route
        path="telex"
        element={
          <Telex accountHolderId={accountHolderId} isGlobal={isGlobal} />
        }
      />
      <Route
        path="fees"
        element={<Fees accountHolderId={accountHolderId} isGlobal={isGlobal} />}
      />
      <Route path="*" element={<Navigate to="banking" replace />} />
    </Routes>
  );
};
