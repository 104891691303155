import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import { getIn, useFormikContext } from 'formik';
import React, { FC } from 'react';

export const ResetPasswordForm: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { values, setFieldValue, touched, errors, handleBlur, handleChange } =
    useFormikContext();

  return (
    <>
      <FormControl component="fieldset" variant="outlined">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked={false} />}
            value={values['sendByEmail']}
            label={'Send by email'}
            name="sendByEmail"
            onChange={handleChange}
          />
        </FormGroup>
      </FormControl>
      {getIn(errors, 'submit') && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{getIn(errors, 'submit')}</FormHelperText>
        </Box>
      )}
    </>
  );
};
