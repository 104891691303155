import { NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { PreviewImageDialog } from 'src/components/PreviewImageDialog';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { selectToken } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { useKycVerificationQuery } from 'src/models/kycVerifications';
import { config } from 'src/services/config';
import { KycVerificationStatusLabel } from '../KycManagement/KycVerificationStatusLabel';
import { KycVerificationForm } from './KycVerificationForm';
import { KycVerificationLogs } from './KycVerificationLogs';
interface DetailRow {
  name: string;
  value: any;
}

export const KycManagementView = ({
  id,
  accountHolderId,
  isGlobal,
}: {
  id: string;
  accountHolderId: string;
  isGlobal: boolean;
}) => {
  const [editKycVerificationOpened, setEditKycVerificationOpened] =
    useState<boolean>(false);
  const [kycVerificationLogsOpened, setKycVerificationLogsOpened] =
    useState<boolean>(false);

  const { t } = useTranslation();
  const { data: kycVerification } = useKycVerificationQuery(
    {
      accountHolderIdForHeader: accountHolderId,
      kycVerificationId: id,
    },
    { refetchOnMountOrArgChange: true },
  );
  const token = useSelector(selectToken);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const { hasPermission } = usePermissions();
  const [previewImageDialog, setPreviewImageDialog] = useState({
    isOpen: false,
    title: '',
    file: null,
    onConfirm: () => void 0,
  });

  const getFile = (fileId: string, title: string) => {
    fetch(`${config.apiUrl}/auth/kyc-verifications/${id}/files/${fileId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'x-account-holder-id': accountHolderId,
      },
    })
      .then((response) => response.json())
      .then((obj) => {
        setPreviewImageDialog({
          ...previewImageDialog,
          isOpen: true,
          title: title,
          file: obj.fileUrl,
        });
      })
      .catch((error) => {
        // throw(error);
      });
  };

  const kycVerificationData: DetailRow[] = useMemo(
    () =>
      kycVerification
        ? [
          {
            name: 'KYC Status',
            value: (
              <KycVerificationStatusLabel
                status={kycVerification.kycStatus}
              />
            ),
          },
          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(kycVerification.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
          {
            name: 'Updated Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(kycVerification.updatedDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.id}
              </Typography>
            ),
          },
          {
            name: 'Identity Attachment Type',
            value: (
              <KycVerificationStatusLabel
                status={kycVerification.identityAttachmentType}
              />
            ),
          },
          {
            name: 'Identity Attachment File name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.identityAttachmentFileName}
              </Typography>
            ),
          },
          {
            name: 'Identity Attachment File Id',
            value: (
              <>
                {hasPermission('UPDATE_KYC_VERIFICATION') ? (
                  <Button
                    sx={{ padding: 0, textAlign: 'left' }}
                    onClick={() => {
                      getFile(
                        kycVerification.identityAttachmentFileId,
                        `Preview ${kycVerification.identityAttachmentFileName}`,
                      );
                    }}
                  >
                    {kycVerification.identityAttachmentFileId}
                  </Button>
                ) : (
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {kycVerification.identityAttachmentFileId}
                  </Typography>
                )}
              </>
            ),
          },
          {
            name: 'Additional Identity Attachment Type',
            value: (
              <KycVerificationStatusLabel
                status={kycVerification.additionalIdentityAttachmentType}
              />
            ),
          },
          {
            name: 'Additional Identity Attachment File name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.additionalIdentityAttachmentFileName}
              </Typography>
            ),
          },
          {
            name: 'Additional Identity Attachment File Id',
            value: (
              <>
                {hasPermission('UPDATE_KYC_VERIFICATION') ? (
                  <Button
                    sx={{ padding: 0, textAlign: 'left' }}
                    onClick={() => {
                      getFile(
                        kycVerification.additionalIdentityAttachmentFileId,
                        `Preview ${kycVerification.additionalIdentityAttachmentFileName}`,
                      );
                    }}
                  >
                    {kycVerification.additionalIdentityAttachmentFileId}
                  </Button>
                ) : (
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {kycVerification.additionalIdentityAttachmentFileId}
                  </Typography>
                )}
              </>
            ),
          },
          {
            name: 'Passport/Identity Number',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.idPassportNo}
              </Typography>
            ),
          },
          {
            name: 'Passport Issue Date',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {moment
                  .utc(kycVerification.idPassportIssueDate)
                  .format('DD/MM/yyyy')}
              </Typography>
            ),
          },
          {
            name: 'Passport Expiry Date',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {moment
                  .utc(kycVerification.idPassportExpiryDate)
                  .format('DD/MM/yyyy')}
              </Typography>
            ),
          },
          {
            name: 'Address Attachment Type',
            value: (
              <KycVerificationStatusLabel
                status={kycVerification.addressAttachmentType}
              />
            ),
          },
          {
            name: 'Address Attachment File Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.addressAttachmentFileName}
              </Typography>
            ),
          },
          {
            name: 'Address Attachment File Id',
            value: (
              <>
                {hasPermission('UPDATE_KYC_VERIFICATION') ? (
                  <Button
                    sx={{ padding: 0, textAlign: 'left' }}
                    onClick={() => {
                      getFile(
                        kycVerification.addressAttachmentFileId,
                        `Preview ${kycVerification.addressAttachmentFileName}`,
                      );
                    }}
                  >
                    {kycVerification.addressAttachmentFileId}
                  </Button>
                ) : (
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {kycVerification.addressAttachmentFileId}
                  </Typography>
                )}
              </>
            ),
          },
          {
            name: 'Face Attachment File Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.faceAttachmentFileName}
              </Typography>
            ),
          },
          {
            name: 'Face Attachment File Id',
            value: (
              <>
                {hasPermission('UPDATE_KYC_VERIFICATION') ? (
                  <Button
                    sx={{ padding: 0, textAlign: 'left' }}
                    onClick={() => {
                      getFile(
                        kycVerification.faceAttachmentFileId,
                        `Preview ${kycVerification.faceAttachmentFileName}`,
                      );
                    }}
                  >
                    {kycVerification.faceAttachmentFileId}
                  </Button>
                ) : (
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {kycVerification.faceAttachmentFileId}
                  </Typography>
                )}
              </>
            ),
          },
          {
            name: 'Client Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.accountHolderId}
              </Typography>
            ),
          },
          {
            name: 'Email',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.email}
              </Typography>
            ),
          },
          {
            name: 'First Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.firstName}
              </Typography>
            ),
          },
          {
            name: 'Last Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.lastName}
              </Typography>
            ),
          },
          {
            name: 'Date of Birth',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {moment.utc(kycVerification.dateOfBirth).format('DD/MM/yyyy')}
              </Typography>
            ),
          },
          {
            name: 'Nationality',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.nationality}
              </Typography>
            ),
          },
          {
            name: 'Address Line 1',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.addressLine1}
              </Typography>
            ),
          },
          {
            name: 'Address Line 2',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.addressLine2}
              </Typography>
            ),
          },
          {
            name: 'City',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.city}
              </Typography>
            ),
          },
          {
            name: 'Zip/Post Code',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.zipPostcode}
              </Typography>
            ),
          },
          {
            name: 'State',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.countryState}
              </Typography>
            ),
          },
          {
            name: 'Country',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {kycVerification.country}
              </Typography>
            ),
          },
        ]
        : [],
    [accountHolderId, kycVerification],
  );

  const breadcrumbs = (email) => [
    <Link key="1" color={blueGrey[600]} component={RouterLink} to={'./..'}>
      <Typography variant="overline">KYC Management</Typography>
    </Link>,
    <Link
      key="1"
      color={blueGrey[500]}
      component={RouterLink}
      to={`./../${id}`}
    >
      <Typography variant="overline">{email}</Typography>
    </Link>,
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          item
          xs={12}
        >
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {kycVerification && breadcrumbs(kycVerification.email)}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sx={[
              {
                width: '100%',
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            <PermissionGuard permission="UPDATE_KYC_VERIFICATION">
              <Button
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setEditKycVerificationOpened(true)}
                disabled={!isUserActive}
              >
                Edit KYC Verification
              </Button>
            </PermissionGuard>

            <PermissionGuard permission="GET_KYC_VERIFICATION_LOG">
              <Button
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() =>
                  setKycVerificationLogsOpened(!kycVerificationLogsOpened)
                }
              >
                KYC Verification Logs
              </Button>
            </PermissionGuard>
          </Grid>
        </Grid>
        {kycVerificationData ? (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6">User info</Typography>
                      </Box>
                    }
                  />

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: '16px' }}
                  >
                    {kycVerificationData.map(({ name, value }) => (
                      <Grid item md={6} sm={12} xs={12} key={name}>
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {name}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      {kycVerification && (
        <>
          <PermissionGuard permission="UPDATE_KYC_VERIFICATION">
            <Drawer
              anchor="right"
              open={editKycVerificationOpened == true}
              onClose={() => setEditKycVerificationOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <KycVerificationForm
                accountHolderId={accountHolderId}
                isGlobal={isGlobal}
                kycVerification={kycVerification}
                onClose={() => setEditKycVerificationOpened(false)}
              />
            </Drawer>
          </PermissionGuard>

          <PermissionGuard permission="GET_KYC_VERIFICATION_LOG">
            <Drawer
              anchor="right"
              onClose={() => setKycVerificationLogsOpened(false)}
              open={kycVerificationLogsOpened}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <KycVerificationLogs
                accountHolderId={accountHolderId}
                onClose={() => setKycVerificationLogsOpened(false)}
                kycVerificationId={kycVerification.id}
              />
            </Drawer>
          </PermissionGuard>
          <PermissionGuard permission="GET_KYC_VERIFICATION_FILE">
            <PreviewImageDialog
              previewImageDialog={previewImageDialog}
              setPreviewImageDialog={setPreviewImageDialog}
            />
          </PermissionGuard>
        </>
      )}
    </>
  );
};

export default KycManagementView;
