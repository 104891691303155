export const kycStatusList = [
    'INITIAL',
    'PENDING',
    'IN_REVIEW',
    'VERIFIED',
    'FAILED',
    'DECLINED'
] as const;

export const identityAttachmentTypeList = [
    'ID_CARD',
    'PASSPORT',
    'DRIVING_LICENSE'
] as const;

export const addressAttachmentTypeList = [
    'UTILITY_BILL',
    'BANK_STATEMENT',
    'RENT_AGREEMENT',
    'EMPLOYER_LETTER',
    'TAX_BILL'
] as const;

