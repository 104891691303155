import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepButton,
  Stepper,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, ReactElement, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AnyObject, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';

import {
  isUserDetailsFormCompleted,
  UserDetailsInfo,
  userDetailsInfoValidationSchema,
  userDetailsInitialValues,
} from 'src/components/forms';
// import {
//   ClientForm,
//   clientFormInitialValues,
//   ClientInfo,
//   clientInfoValidationSchema,
//   isClientFormCompleted,
// } from 'src/components/forms/specific/ClientForm';
import {
  EmailForm,
  emailFormInitialValues,
  EmailInfo,
  emailInfoValidationSchema,
  isEmailFormCompleted,
} from 'src/components/forms/specific/EmailForm';
import { UserDetailsForm } from 'src/components/forms/specific/UserDetailsForm';
import { useTotp } from 'src/hooks/useTotp';
import { useUserExistsVerificationMutation } from 'src/models/auth';
import { selectBank } from 'src/models/cms';
import { useAcceptUserInvitationMutation } from 'src/models/invitations';
import { mapInvitationRegistrationFormValuesToApiAcceptInvitationRequest } from 'src/models/invitations/mapper';
import { InvitationRegisterInfo } from 'src/models/invitations/types-dto';
import { links } from 'src/navigation/constants';
import { Nullable } from 'src/utils/types';

export const RegisterWizard: FC = () => {
  const { t } = useTranslation();
  const [registrationSuccessful, setRegistrationSuccessful] =
    React.useState<boolean>(null);
  const bank = useSelector(selectBank);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { invitationId } = useParams<{ invitationId: string }>();

  const formikRef = useRef(null);
  useTotp(formikRef);

  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [currentValues, setCurrentValues] =
    React.useState<Nullable<InvitationRegisterInfo>>(null);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const [registerAndAcceptInvitation] = useAcceptUserInvitationMutation();
  const userExists = useUserExistsVerificationMutation();

  const steps = React.useMemo(
    (): {
      name: string;
      validationSchema: OptionalObjectSchema<any, AnyObject, TypeOfShape<any>>;
      component: ReactElement<any, any>;
      submit:
      | ((values: EmailInfo) => Promise<void>)
      | ((values: UserDetailsInfo) => Promise<void>);
      completed:
      | ((values: EmailInfo) => Promise<boolean>)
      | ((values: UserDetailsInfo) => Promise<boolean>);
    }[] => [
        {
          name: t('common.userLogin'),
          validationSchema: emailInfoValidationSchema(userExists),
          component: <EmailForm />,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          submit: async (values: InvitationRegisterInfo) => { },
          completed: isEmailFormCompleted(userExists),
        },
        {
          name: t('common.userDetails'),
          validationSchema: userDetailsInfoValidationSchema(),
          component: <UserDetailsForm accountHolderId={bank?.accountHolderId} />,
          submit: async (values: InvitationRegisterInfo) => {
            const acceptInvitationRequest =
              mapInvitationRegistrationFormValuesToApiAcceptInvitationRequest(
                values,
              );

            await registerAndAcceptInvitation({
              invitationId,
              acceptInvitationRequest,
            }).unwrap();
            setRegistrationSuccessful(true);
          },
          completed: isUserDetailsFormCompleted,
        },
      ],
    [t, userExists, bank],
  );

  const [completed, setCompleted] = React.useState<boolean[]>(
    Array(steps.length).fill(false),
  );

  return (
    <>
      <Box>
        {registrationSuccessful == null && (
          <Stepper nonLinear activeStep={currentStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={step.name} completed={completed[index]}>
                <StepButton onClick={() => setCurrentStep(index)}>
                  {step.name}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
        <Formik<InvitationRegisterInfo>
          enableReinitialize
          key={currentStep}
          innerRef={formikRef}
          initialValues={{
            ...emailFormInitialValues({
              email: searchParams.get('email') || '',
              password: '',
            }),
            ...userDetailsInitialValues(),
            ...currentValues,
          }}
          validationSchema={steps[currentStep].validationSchema}
          onSubmit={async (values, { resetForm }): Promise<void> => {
            setSubmitting(true);
            if (currentStep < steps.length) {
              if (currentStep < steps.length - 1) {
                setCurrentStep(currentStep + 1);
              }
              try {
                setCurrentValues(values);
                let isValidationPassed = true;
                let completedTemp = [...completed];
                for (let i = 0; i < steps.length - 1 + 1; i++) {
                  if (!(await steps[i].completed(values))) {
                    completedTemp = [
                      ...completedTemp.map((v, index) =>
                        index === i ? false : v,
                      ),
                    ];
                    setCurrentStep(i);
                    isValidationPassed = false;
                  } else {
                    completedTemp = [
                      ...completedTemp.map((v, index) =>
                        index === i ? true : v,
                      ),
                    ];
                  }
                }
                setCompleted(completedTemp);
                if (isValidationPassed) {
                  await steps[currentStep].submit(values);
                }
              } catch (err) {
                console.log(err);
                if (err?.data?.errorCode == 'EMAIL_NOT_MATCHED') {
                  toast.error(
                    'E-mail submitted does not match the invitation e-mail',
                  );
                } else if (err?.data?.errorCode == 'INVALID_INVITATION_ID') {
                  toast.error(`Invitation is invalid.`);
                  navigate(links.login);
                }
              }
            } else {
              resetForm();
            }
            setSubmitting(false);
          }}
        >
          {({ handleSubmit }): JSX.Element => (
            <>
              <form noValidate onSubmit={handleSubmit}>
                {!isSubmitting &&
                  registrationSuccessful == null &&
                  steps[currentStep].component}
                {isSubmitting && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '10px',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}

                {registrationSuccessful == true && (
                  <Box
                    sx={{
                      typography: 'body1',
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '10px',
                      fontSize: '1.5rem',
                    }}
                  >
                    Your registration was successful. Please check your email to
                    continue registering.
                  </Box>
                )}
                {registrationSuccessful == false && (
                  <Box
                    sx={{
                      typography: 'body1',
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '10px',
                      fontSize: '1.5rem',
                    }}
                  >
                    Registration Failed, please contact support.
                  </Box>
                )}

                {!isSubmitting && registrationSuccessful == null && (
                  <Box sx={{ mt: '24px' }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {currentStep === steps.length - 1
                        ? t('common.submit')
                        : t('common.next')}
                    </Button>
                  </Box>
                )}
              </form>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
};
