import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {  ApiAccountHolderTemplate } from './types';

export type CMSState = {
  bank:ApiAccountHolderTemplate;
};

export const cmsSlice = createSlice({
  name: 'cms',
  initialState: { 
      bank:null,
  } as CMSState,
  reducers: {

    setBank: (
        state,
        {
          payload: { bank },
        }: PayloadAction<{
          bank: ApiAccountHolderTemplate | null;
        }>,
      ) => {
        state.bank = bank;
      },
    resetBank: (state) => {
      state.bank = null;
    },
  },
});

export const { 
    setBank,
    resetBank
} = cmsSlice.actions;

// export default cmsSlice.reducer;
