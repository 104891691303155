import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  isValidNumber,
} from 'libphonenumber-js';
import * as Yup from "yup";

import { Country } from "src/utils/countries";

export const composePhoneNumber = (country: CountryCode, phone: string) =>
  `+${getCountryCallingCode(country)}${phone}`;


const countries = getCountries();

const countryCodeSchema = Yup.object().nullable().shape({
  callingCode: Yup.string().required('validations.mustSelectAPhonePrefix'),
  code: Yup.mixed<CountryCode>().oneOf(countries, 'validations.mustSelectAPhonePrefix'),
  name: Yup.string().required('validations.mustSelectAPhonePrefix'),
})
  .test(
    'null-check',
    'validations.mustSelectAPhonePrefix',
    country => country != null
  );

export const phoneValidationSchema = () =>
  Yup.object({
    phoneCountryCode: countryCodeSchema.required(
      'validations.phoneCountryCodeIsRequired',
    ),
    phoneNumber: Yup.string()
      .required('validations.phoneNumberIsRequired')
      .when('phoneCountryCode', (phoneCountryCode: Country, schema: any) =>
        phoneCountryCode
          ? schema.test(
            'test-phone-number',
            'validations.invalidPhonenumberOrCountryCode',
            (phone: string) =>
              isValidNumber(composePhoneNumber(phoneCountryCode.code, phone)),
          )
          : schema,
      ),
  });


