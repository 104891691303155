import { Chip, colors } from '@mui/material';
import React from 'react';

import {
  FeeContributor,
  TransactionContextType,
} from 'src/models/configurations';

const statusColors = {
  SOURCE: colors.blueGrey,
  DESTINATION: colors.blueGrey,
  OTHER: colors.blueGrey,

  INCOMING: colors.blueGrey,
  OUTGOING: colors.blueGrey,
};

export const FeeStatusLabel = ({
  status,
}: {
  status: FeeContributor | TransactionContextType | string;
}) => {
  const backgroundColor = statusColors[status]
    ? `${statusColors?.[status][50]} !important`
    : `${colors.blueGrey[50]} !important`;
  const color = statusColors?.[status]
    ? statusColors[status][400]
    : colors.blueGrey[300];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
