import { AdminPanelSettings, ArrowDropDown } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Drawer,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { FC, useCallback, useRef } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useToggle } from 'src/hooks/useToggle';
import {
  resetCredentials,
  selectAccountHolderDisplayName,
  selectAccountHolderId,
  setCurrentAccountHolderId,
  useLogoutMutation,
} from 'src/models/auth';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { resetBank } from 'src/models/cms';
import { useMeQuery } from 'src/models/user/api';
import { useDispatch, useSelector } from 'src/services/redux';

const AccountPopover: FC = () => {
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const [isOpen, onOpen, onClose] = useToggle(false);
  const { hasPermission } = usePermissions();
  const [logout] = useLogoutMutation();
  const accountHolderId = useSelector(selectAccountHolderId);
  const bankDisplayName = useSelector(selectAccountHolderDisplayName);

  const { email, username } = useMeQuery(
    { accountHolderId },
    {
      selectFromResult: ({ data }) => ({
        ...data,
        username: `${data?.firstName || ''} ${data?.lastName || ''}`,
      }),
    },
  );

  const onLogout = useCallback(async () => {
    try {
      await logout();
      dispatch(resetCredentials());
      dispatch(resetBank());
      // onClose();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  }, [logout]);

  const onManageProfile = () => {
    onClose();
    navigate('/manage-profile', { replace: true });
  };

  const accountHolderSwitch = () => {
    onClose();
    dispatch(setCurrentAccountHolderId({ accountHolderId: null }));
    // navigate('/account-holder-choice', { replace: true });
  };

  const viewPendingInvitations = () => {
    onClose();
    navigate('/pending-invitations', { replace: true });
  };

  const impersonateUser = () => {
    onClose();
    navigate('/impersonate-user', { replace: true });
  };

  return (
    <>
      <Box
        onClick={onOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: '#EBF4FA',
            cursor: 'pointer',
            boxShadow:
              'rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px',
          },
        }}
      >
        {/* <Typography
          color="textPrimary"
          sx={{
            color: '#18383D',
            fontSize: '16px',
            fontWeight: 400,
            marginRight: '12px',
          }}
        >
          {bankDisplayName}
          {username}
        </Typography> */}
        <Box sx={{ p: 2, pb: 1, pt: 1 }}>
          <Typography
            noWrap
            color="textPrimary"
            sx={{
              color: '#18383D',
              fontSize: {
                lg: '16px',
                md: '14px',
                sm: '12px',
                xs: '10px',
              },
              fontWeight: 700,
            }}
          >
            {bankDisplayName}
          </Typography>
          <Typography
            noWrap
            color="textSecondary"
            sx={{
              color: '#49696E',
              fontSize: {
                lg: '14px',
                md: '12px',
                sm: '10px',
                xs: '9px',
              },
              fontWeight: 400,
            }}
          >
            {username}
          </Typography>
        </Box>

        <Avatar
          sx={[
            {
              backgroundColor: '#19373B',
              height: 32,
              width: 32,
            },
            (theme) => ({
              backgroundColor: theme.palette.primary.main,
            }),
          ]}
        >
          <AdminPanelSettings sx={{ color: '#ffffff' }} fontSize="small" />
        </Avatar>
        <ArrowDropDown
          sx={{ color: '#49696E', marginLeft: '8px' }}
          fontSize="small"
        />
      </Box>
      <Popover
        PaperProps={{
          sx: { borderRadius: '4px', padding: '4px 0 8px 0', width: 240 },
        }}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        onClose={onClose}
        open={isOpen}
      >
        <Box sx={{ p: 2, pb: 1, pt: 1 }}>
          <Typography
            color="textPrimary"
            sx={{
              color: '#18383D',
              fontSize: '16px',
              fontWeight: 700,
            }}
          >
            {username}
          </Typography>
          <Typography
            color="textSecondary"
            sx={{ color: '#49696E', fontSize: '14px', fontWeight: 400 }}
          >
            {email}
          </Typography>
        </Box>
        <Box>
          <MenuItem onClick={onManageProfile}>Manage Profile</MenuItem>
          <MenuItem onClick={accountHolderSwitch}>
            Switch client profile
          </MenuItem>
          <MenuItem onClick={viewPendingInvitations}>
            View pending invitations
          </MenuItem>
          {hasPermission('ADMIN') && (
            <MenuItem onClick={impersonateUser}>Impersonate user</MenuItem>
          )}
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
