import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authSlice } from 'src/models/auth';
import { cmsApi, cmsSlice } from 'src/models/cms';
import { countryStatesApi } from 'src/models/countryStates/api';
import { emptySplitApi } from 'src/models/emptySplitApi';
import { eventsSlice } from 'src/models/events';
import { fingerprintSlice } from 'src/models/fingerprint';
import { i18nSlice } from 'src/models/i18n';
import { totpSlice } from 'src/models/totp';
import { transactionsSlice } from 'src/models/transactions';
import { config } from 'src/services/config';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';


const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  blacklist: [

    // CMS API
    cmsApi.reducerPath,

    // Gateway API
    countryStatesApi.reducerPath,

    // Slices
    cmsSlice.name,
    eventsSlice.name,
    fingerprintSlice.name,
    i18nSlice.name,
    totpSlice.name,
    transactionsSlice.name,
  ]
};

const rootReducer = combineReducers({

  // CMS API
  [cmsApi.reducerPath]: cmsApi.reducer,
  // Gateway API
  [countryStatesApi.reducerPath]: countryStatesApi.reducer,

  // Slices
  [authSlice.name]: authSlice.reducer,
  [cmsSlice.name]: cmsSlice.reducer,
  [eventsSlice.name]: eventsSlice.reducer,
  [fingerprintSlice.name]: fingerprintSlice.reducer,
  [i18nSlice.name]: i18nSlice.reducer,
  [totpSlice.name]: totpSlice.reducer,
  [transactionsSlice.name]: transactionsSlice.reducer,

  // API
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
});

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer,
) as typeof rootReducer;

export const store = configureStore({
  devTools: config.enableReduxDevTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })

      // CMS API
      .concat(cmsApi.middleware)

      // Gateway API
      .concat(countryStatesApi.middleware)
      // API
      .concat(emptySplitApi.middleware)
      .concat(rtkQueryErrorLogger),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
