import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { FieldProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';


export const FormTextField: FC<FieldProps & TextFieldProps> = props => {
    const { t } = useTranslation();
    const isTouched = getIn(props.form.touched, props.field.name);
    const errorMessage = getIn(props.form.errors, props.field.name);

    const { error, helperText, field, ...rest } = props;

    return (
        <>
            <FormControl fullWidth margin="normal">
                <TextField
                    error={error ?? Boolean(isTouched && errorMessage)}
                    helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
                    autoComplete='off'
                    {...rest}
                    {...field}
                />
            </FormControl>
        </>
    );

}