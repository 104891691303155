import { format } from "date-fns";

import { mapPhoneToApiPhoneNumber } from "src/components/forms";
import { AccountHolderInfo } from "src/screens/AccountHolders/forms/CreateAccountHolderWizard/types-dto";
import { DATE_OF_BIRTH_MASK } from "../types";
import { ApiCreateAccountHolderRequest } from "./types-api";
// DTO to API

export const mapOnboardingInfoToApiOnboardingRequest = async (formValues: AccountHolderInfo, accountHolderIdForHeader: string): Promise<ApiCreateAccountHolderRequest> => ({
    userDetail: (() => {
        if (['invite_a_user', 'without_user'].includes(formValues.mode) || formValues.userId?.length > 0) return null
        else // create_new_user
            return {
                dateOfBirth: format(formValues.dateOfBirth, DATE_OF_BIRTH_MASK),
                email: formValues.email,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                nationality: formValues.nationality.code,
                phoneNumber: mapPhoneToApiPhoneNumber(formValues.phone),
                idPassportNo: formValues.idPassportNo,
                addressLine1: formValues.address.addressLine1,
                addressLine2: formValues.address.addressLine2,
                postcode: formValues.address.postcode,
                countryCode: formValues.address.countryCode.code,
                city: formValues.address.city,
                state: formValues.address.state.length > 0 ? formValues.address.state : null,
            }
    })(),
    accountHolderType: formValues.accountHolderType,
    descriptor: formValues.descriptor,
    userId: formValues.userId.length > 0 ? formValues.userId : null,
    parentId: formValues.parentId,
    legacyUserId: null,
    userEmail: formValues.userEmail.length > 0 ? formValues.userEmail : null,
    permissionGroupIds: formValues.permissionGroupIds,
    accountHolderIdForHeader

});