import * as Yup from 'yup';

import { clientAccountValidationSchema } from 'src/components/forms';
import { feeTransactionContextList } from 'src/models/configurations';

export const feeFormValidationSchema =
    Yup.object({
        accountHolderId: Yup.string(),
        percentage: Yup.number()
            .typeError("Please enter a valid number")
            .required("Percentage is required")
            .min(0, "Minimum at least 0")
            .max(100, "Allowed maximum is 100"),
        transactionType: Yup.string().required('Transaction type is required'),
        feeContributor: Yup.string().required('Fee contributor is required'),
        feeAccount: clientAccountValidationSchema(),
        transactionContext: Yup.string().required('Fee transaction context is required').oneOf([...feeTransactionContextList]),
        contributorAccount: Yup.object()
            .when('feeContributor', {
                is: 'OTHER',
                then: clientAccountValidationSchema(),
                otherwise: Yup.object().notRequired()
            }),
        assignable: Yup.bool().required('Assignable is required'),
        description: Yup.string().notRequired(),
    });