import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';

import {  useAcquiringProcessingQuery } from 'src/models/transactions';

interface DetailRow {
  name: string;
  value: any;
}

export const AcquiringProcessingView = ({ id ,accountHolderId}: { id: string, accountHolderId:string}) => {
  const { data: acquiringProcessing , isFetching} = useAcquiringProcessingQuery({
    accountHolderId,
    id
  },{refetchOnMountOrArgChange:true,});


  const acquiringProcessingData: DetailRow[] = useMemo(
    () =>
    acquiringProcessing
        ? [
            {
              name: 'Masked Pan',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.maskedPan}</Typography>,
            },
            {
              name: 'Issuer Response Code',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.issuerResponseCode}</Typography>,
            },
            {
              name: 'ECI',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.eci}</Typography>,
            },
            {
              name: '3D Transaction Id',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.threeDTransactionId}</Typography>,
            },
            {
              name: 'DS Transaction Id',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.dsTransactionId}</Typography>,
            },
            {
              name: '3 Redirect URL',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.threeDRedirectUrl}</Typography>,
            },
            {
              name: '3D Version',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.threeDVersion}</Typography>,
            },
            {
              name: 'CAVV',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.cavv}</Typography>,
            },
            {
              name: 'Customer IP',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.customerIp}</Typography>,
            },
            {
              name: 'Customer Agent',
              value: <Typography variant="body2" sx={{wordWrap: 'break-word'}}>{acquiringProcessing?.customerAgent}</Typography>,
            },
          ]
        : [],
    [acquiringProcessing],
  );

  return (
    <>
     {isFetching && <CircularProgress/>}
     {acquiringProcessingData && <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Acquiring Processing</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                    sx={{
                      padding:'0'
                    }}>
                    <Table>
                    <TableBody>
                      {acquiringProcessingData.map(({ name, value }) => (
                        <TableRow key={name}>
                          <TableCell width={'185px'} sx={{ border: 'none' }}>
                            <Typography variant="body2" color="textSecondary">
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ border: 'none',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}>{value}</TableCell>
                        </TableRow>
                      ))}
                   </TableBody>
                   </Table>
                    </AccordionDetails>
                  </Accordion>
                 }
    </>
  );
};

export default AcquiringProcessingView;
