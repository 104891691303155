import { format } from "date-fns";
import { getCountryCallingCode } from 'libphonenumber-js';

import { composePhoneNumber } from "src/components/forms";
import { DATE_OF_BIRTH_MASK } from "../types";
import { ApiAcceptInvitationRequest } from "./types";
import { InvitationRegisterInfo } from "./types-dto";

export const mapInvitationRegistrationFormValuesToApiAcceptInvitationRequest = (
    formValues: InvitationRegisterInfo,
): ApiAcceptInvitationRequest => ({
    userDetail: {
        dateOfBirth: format(formValues.dateOfBirth, DATE_OF_BIRTH_MASK),
        email: formValues.email,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        nationality: formValues.nationality.code,
        phoneNumber: {
            prefix: `+${getCountryCallingCode(formValues.phone.phoneCountryCode.code)}`,
            number: formValues.phone.phoneNumber,
            phoneNumber: composePhoneNumber(
                formValues.phone.phoneCountryCode.code,
                formValues.phone.phoneNumber,
            ),
        },
        addressLine1: formValues.address.addressLine1,
        addressLine2: formValues.address.addressLine2,
        city: formValues.address.city,
        countryCode: formValues.address.countryCode.code,
        postcode: formValues.address.postcode,
        state: formValues.address.state == '' ? null : formValues.address.state,
        idPassportNo: formValues.idPassportNo,
        password: formValues.password,
    }
});



