import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepButton,
  Stepper,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, ReactElement, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnyObject, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';

import {
  ClientForm,
  clientFormInitialValues,
  ClientInfo,
  clientInfoValidationSchema,
  isClientFormCompleted,
} from 'src/components/forms/specific/ClientForm';
import { useTotp } from 'src/hooks/useTotp';
import { useRefreshTokenMutation, useRegisterMutation } from 'src/models/auth';
import { clientInfoFormValuesToRegisterRequestBody } from 'src/models/auth/mapper';
import { Nullable } from 'src/utils/types';

export const RegisterWizard: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const formikRef = useRef(null);
  useTotp(formikRef);
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [currentValues, setCurrentValues] =
    React.useState<Nullable<ClientInfo>>(null);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const [register] = useRegisterMutation();
  const [refreshToken] = useRefreshTokenMutation();

  const steps = React.useMemo(
    (): {
      name: string;
      validationSchema: OptionalObjectSchema<any, AnyObject, TypeOfShape<any>>;
      component: ReactElement<any, any>;
      submit: (values: ClientInfo) => Promise<void>;
      completed: (values: ClientInfo) => Promise<boolean>;
    }[] => [
      {
        name: t('common.client'),
        validationSchema: clientInfoValidationSchema(),
        component: <ClientForm />,
        submit: async (values: ClientInfo) => {
          const registerBody =
            clientInfoFormValuesToRegisterRequestBody(values);

          if (searchParams.get('parentAccountHolderId')) {
            registerBody.parentId = searchParams.get('parentAccountHolderId');
          }
          if (searchParams.get('userId')) {
            registerBody.userId = searchParams.get('userId');
          }

          const registerResponse = await register(registerBody).unwrap();
          if (registerResponse.status == 'PENDING') {
            refreshToken();
            toast.success('Registration successful');
            navigate('/account-holder-choice', { replace: true });
          }
        },
        completed: isClientFormCompleted,
      },
    ],
    [t],
  );

  const [completed, setCompleted] = React.useState<boolean[]>(
    Array(steps.length).fill(false),
  );

  return (
    <>
      <Box>
        <Stepper nonLinear activeStep={currentStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.name} completed={completed[index]}>
              <StepButton onClick={() => setCurrentStep(index)}>
                {step.name}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Formik<ClientInfo>
          enableReinitialize
          key={currentStep}
          innerRef={formikRef}
          initialValues={{
            ...clientFormInitialValues(),
            ...currentValues,
          }}
          validationSchema={steps[currentStep].validationSchema}
          onSubmit={async (values, { resetForm }): Promise<void> => {
            setSubmitting(true);
            if (currentStep < steps.length) {
              if (currentStep < steps.length - 1) {
                setCurrentStep(currentStep + 1);
              }
              try {
                setCurrentValues(values);
                let isValidationPassed = true;
                let completedTemp = [...completed];
                for (let i = 0; i < steps.length - 1 + 1; i++) {
                  if (!(await steps[i].completed(values))) {
                    completedTemp = [
                      ...completedTemp.map((v, index) =>
                        index === i ? false : v,
                      ),
                    ];
                    setCurrentStep(i);
                    isValidationPassed = false;
                  } else {
                    completedTemp = [
                      ...completedTemp.map((v, index) =>
                        index === i ? true : v,
                      ),
                    ];
                  }
                }
                setCompleted(completedTemp);

                if (isValidationPassed) {
                  await steps[currentStep].submit(values);
                }
              } catch (err) {
                toast.error('Registration failed');
                navigate('/account-holder-choice', { replace: true });
              }
            } else {
              resetForm(); // resets touched/ errors etc
              // //https://stackoverflow.com/questions/55583815/formik-how-to-reset-form-after-confirmation
              // // Move to next step
              // setCurrentStep(currentStep + 1);
            }
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, values }): JSX.Element => (
            <>
              {/* {JSON.stringify(values)} */}
              <form noValidate onSubmit={handleSubmit}>
                {!isSubmitting && steps[currentStep].component}
                {isSubmitting && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '10px',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}

                {!isSubmitting && (
                  <Box sx={{ mt: '24px' }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {currentStep === steps.length - 1
                        ? t('common.submit')
                        : t('common.next')}
                    </Button>
                  </Box>
                )}
              </form>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
};
