import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { isValid } from 'date-fns';
import { useFormikContext } from 'formik';
import { CountryCode } from 'libphonenumber-js';
import React from 'react';
import { FC } from 'react';

import { CountryCodeField } from 'src/components/forms/CountryCodeField';
import { FormikTextField } from 'src/components/forms/TextField';
import { useCountryStatesQuery } from 'src/models/countryStates/api';
import { ApiKycDetailFormValues } from './types';

export const UserDetailsForm: FC = () => {
  const [countryCode, setCountryCode] = React.useState<CountryCode>(null);
  const [skip, setSkip] = React.useState<boolean>(true);
  const countryStates = useCountryStatesQuery(
    { countryCode: countryCode },
    { skip, refetchOnMountOrArgChange: true },
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ApiKycDetailFormValues>();
  return (
    <>
      <Box sx={{ mb: '4px', mt: '30px' }}>
        <Typography variant="h6">User details</Typography>
        <Typography variant="subtitle2">
          Verify the details and change where necessary
        </Typography>
      </Box>
      <FormikTextField
        name="email"
        label="Email"
        type="email"
        autoComplete="off"
      />

      <FormikTextField label="First Name" name="firstName" />

      <FormikTextField label="Last Name" name="lastName" />

      <FormControl fullWidth margin="normal">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            disableFuture
            label="Date of birth"
            onChange={(newValue) => {
              if (!isValid(newValue)) {
                return;
              }
              setFieldValue('dateOfBirth', newValue);
            }}
            openTo="year"
            reduceAnimations
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
            toolbarTitle="Date of birth"
            value={values['dateOfBirth']}
            views={['year', 'day']}
          />
        </LocalizationProvider>
        <FormHelperText error>
          {touched['dateOfBirth'] && errors['dateOfBirth']}
        </FormHelperText>
      </FormControl>

      <CountryCodeField
        formControlProps={{
          fullWidth: true,
          margin: 'normal',
        }}
        label="Nationality"
        name="nationality"
        selectProps={{ variant: 'outlined' }}
        type="nationality"
      />

      <FormikTextField label="Identity/Passport Number" name="idPassportNo" />

      <FormControl fullWidth margin="normal">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            disableFuture
            label="Identity/Passport issue date"
            onChange={(newValue) => {
              if (!isValid(newValue)) {
                return;
              }
              setFieldValue('idPassportIssueDate', newValue);
            }}
            openTo="year"
            reduceAnimations
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                error={Boolean(
                  touched.idPassportIssueDate && errors.idPassportIssueDate,
                )}
              />
            )}
            toolbarTitle="Identity/Passport issue date"
            value={values.idPassportIssueDate}
            views={['year', 'day']}
          />
        </LocalizationProvider>
        <FormHelperText error>
          {touched.idPassportIssueDate && errors.idPassportIssueDate}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            disablePast
            label="Identity/Passport expiry date"
            onChange={(newValue) => {
              if (!isValid(newValue)) {
                return;
              }
              setFieldValue('idPassportExpiryDate', newValue);
            }}
            openTo="year"
            reduceAnimations
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                error={Boolean(
                  touched.idPassportExpiryDate && errors.idPassportExpiryDate,
                )}
              />
            )}
            toolbarTitle="Identity/Passport expiry date"
            value={values['idPassportExpiryDate']}
            views={['year', 'day']}
          />
        </LocalizationProvider>
        <FormHelperText error>
          {touched.idPassportExpiryDate && errors.idPassportExpiryDate}
        </FormHelperText>
      </FormControl>

      <FormikTextField label="Address Line 1" name="addressLine1" />
      <FormikTextField label="Address Line 2" name="addressLine2" />
      <FormikTextField label="Zip/Postcode" name="zipPostcode" />
      <FormikTextField label="City" name="city" />

      {countryStates.data?.length > 0 && (
        <FormControl
          error={touched?.countryState && errors?.countryState?.length > 0}
          fullWidth
          margin="normal"
          variant="outlined"
          component="fieldset"
        >
          <InputLabel id="state_label">State</InputLabel>
          <Select
            labelId="state_label"
            label="State"
            name="countryState"
            id="state_select"
            defaultValue=""
            onChange={(e: SelectChangeEvent<unknown>) =>
              handleChange('countryState')(e as React.ChangeEvent)
            }
            onBlur={handleBlur('countryState')}
            disabled={!(countryStates.data?.length > 0)}
          >
            {countryStates.data?.map((i) => (
              <MenuItem key={i.code} value={i.code}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {touched.countryState && errors.countryState}
          </FormHelperText>
        </FormControl>
      )}
      <CountryCodeField
        formControlProps={{
          fullWidth: true,
          margin: 'normal',
          sx: { mb: '24px' },
        }}
        label="Country"
        name="country"
        type="nationality"
        selectProps={{
          variant: 'outlined',
          onChange: (event, child) => {
            setFieldValue('countryState', '');
            setTimeout(() => setFieldTouched('countryState', true));
            setSkip(true);
            setCountryCode(event.target.value as CountryCode);
            setSkip(false);
          },
        }}
      />
    </>
  );
};
