import { ArrowUpward, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  colors,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import Audits from 'src/components/audits';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import {
  useAccountHolderQuery,
  useAccountHoldersListQuery,
} from 'src/models/accountHolders';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { selectAccountHolderId } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { useGetAccountHolderTemplateQuery } from 'src/models/cms';
import { useSelector } from 'src/services/redux';
import { lightOrDark } from 'src/utils/colours';
import { AccountHolderStatusLabel } from './AccountHolderStatusLabel';
import { AccountHolderTemplateForm } from './forms/AccountHolderTemplateForm';
import { EditAccountHolderForm } from './forms/EditAccountHolderForm';
import { TriggerKycForm } from './forms/TriggerKycForm';
import SubAccountHolders from './SubAccountHolders';
interface DetailRow {
  name: string;
  value: any;
}

export const AccountHolderView = ({
  accountHolderId,
  accountHolderIdForHeader,
}: {
  accountHolderId: string;
  accountHolderIdForHeader: string;
}) => {
  const { hasPermission } = usePermissions();
  const [editDetailsOpened, setEditDetailsOpened] = useState<boolean>(false);
  const [editAccountHolderTemplateOpened, setEditAccountHolderTemplateOpened] =
    useState<boolean>(false);
  const [
    createAccountHolderTemplateOpened,
    setCreateAccountHolderTemplateOpened,
  ] = useState<boolean>(false);
  const [triggerKycOpened, setTriggerKycOpened] = useState<boolean>(false);

  const chosenAccountHolderId = useSelector(selectAccountHolderId);
  const accountHolderIds = useAccountHoldersListQuery(
    { accountHolderId: chosenAccountHolderId },
    { refetchOnMountOrArgChange: true },
  );
  const { data: accountHolderTemplate } = useGetAccountHolderTemplateQuery(
    { accountHolderId },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: accountHolder,
    refetch: refetchAccountHolder,
  } = useAccountHolderQuery(
    {
      accountHolderIdForHeader,
      accountHolderId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const isUserActive = useSelector(isAccountHolderStatusActive);
  const accountHolderData: DetailRow[] = useMemo(
    () =>
      accountHolder
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {accountHolder.id}
              </Typography>
            ),
          },
          {
            name: 'Descriptor',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {accountHolder.descriptor}
              </Typography>
            ),
          },
          {
            name: 'Status',
            value: <AccountHolderStatusLabel status={accountHolder.status} />,
          },
          {
            name: 'Type',
            value: (
              <AccountHolderStatusLabel
                status={accountHolder.accountHolderType}
              />
            ),
          },
          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(accountHolder.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
          {
            name: 'Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {accountHolder.displayName}
              </Typography>
            ),
          },
          ...(hasPermission('ADMIN') ? [{
            name: 'Allow Registration',
            value: (
              <Chip
                label={accountHolder.allowRegistration ? 'Yes' : 'No'}
                color={accountHolder.allowRegistration ? 'success' : 'error'}
              />
            ),
          },
          ...(accountHolder?.parentDisplayName
            ? [
              {
                name: 'Parent Name',
                value: (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ wordWrap: 'break-word' }}
                    >
                      {accountHolder.parentDisplayName}
                    </Typography>
                    {accountHolder?.parentId &&
                      accountHolder?.parentId != accountHolderIdForHeader &&
                      accountHolderIds.data?.length > 0 &&
                      accountHolderIds.data
                        .map((x) => x.id)
                        .includes(accountHolder.parentId) && (
                        <Tooltip
                          title={`Go to ${accountHolder.parentDisplayName}`}
                        >
                          <IconButton
                            component={RouterLink}
                            to={`./../../${accountHolder.parentId}`}
                            aria-label="parent client"
                            color="primary"
                          >
                            <ArrowUpward />
                          </IconButton>
                        </Tooltip>
                      )}
                  </>
                ),
              },

            ]
            : []),
          ...(accountHolder?.parentId
            ? [
              {
                name: 'Parent Id',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >
                    {accountHolder.parentId}
                  </Typography>
                ),
              },
            ]
            : []),

          {
            name: 'Domain',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {accountHolder.domain}
              </Typography>
            ),
          }, {
            name: 'Display Currency Code',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {accountHolder.displayCurrencyCode}
              </Typography>
            ),
          }] : []),
          ...(accountHolder.senderEmail
            ? [
              {
                name: 'Sender email',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >
                    {accountHolder.senderEmail}
                  </Typography>
                ),
              },
            ]
            : []),
        ]
        : [],
    [accountHolderId, accountHolder, accountHolderIds],
  );

  const accountHolderTemplateData: DetailRow[] = useMemo(
    () =>
      accountHolderTemplate
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {accountHolderTemplate.id}
              </Typography>
            ),
          },
          {
            name: 'Display name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {accountHolderTemplate.displayName}
              </Typography>
            ),
          },
          {
            name: 'Enforce KYC',
            value: (
              <Chip
                label={accountHolderTemplate.kycCheckEnabled ? 'Yes' : 'No'}
                color={
                  accountHolderTemplate.kycCheckEnabled ? 'success' : 'error'
                }
              />
            ),
          },
          {
            name: 'Status',
            value: (
              <AccountHolderStatusLabel
                status={accountHolderTemplate.status}
              />
            ),
          },
          {
            name: 'Logo',
            value: (
              <Box
                sx={{ maxWidth: 200, height: 'auto' }}
                component="img"
                src={`${accountHolderTemplate.logoUrl}`}
              />
            ),
          },
          ...(accountHolderTemplate.themeColourHex
            ? [
              {
                name: 'Theme Colour HEX',
                value: (
                  <Chip
                    variant="outlined"
                    label={accountHolderTemplate.themeColourHex?.toUpperCase()}
                    sx={{
                      backgroundColor: `${accountHolderTemplate.themeColourHex} !important`,
                      color: 'white',
                    }}
                  />
                ),
              },
            ]
            : []),
          ...(accountHolderTemplate.checkoutBackgroundColourHex
            ? [
              {
                name: 'Checkout Background Colour HEX',
                value: (
                  <Chip
                    variant="outlined"
                    label={accountHolderTemplate.checkoutBackgroundColourHex?.toUpperCase()}
                    sx={{
                      backgroundColor: `${accountHolderTemplate.checkoutBackgroundColourHex} !important`,
                      color: 'white',
                    }}
                  />
                ),
              },
            ]
            : []),
          ...(accountHolderTemplate.checkoutForegroundColourHex
            ? [
              {
                name: 'Checkout Foreground Colour HEX',
                value: (
                  <Chip
                    variant="outlined"
                    label={accountHolderTemplate.checkoutForegroundColourHex?.toUpperCase()}
                    sx={{
                      backgroundColor: `${accountHolderTemplate.checkoutForegroundColourHex} !important`,
                      color: 'white',
                    }}
                  />
                ),
              },
            ]
            : []),
          ...(accountHolderTemplate.checkoutTextColourHex
            ? [
              {
                name: 'Checkout Text Colour HEX',
                value: (
                  <Chip
                    variant="outlined"
                    label={accountHolderTemplate.checkoutTextColourHex?.toUpperCase()}
                    sx={{
                      backgroundColor: `${accountHolderTemplate.checkoutTextColourHex} !important`,
                      color:
                        lightOrDark(
                          accountHolderTemplate.checkoutTextColourHex,
                        ) == 'light'
                          ? 'black'
                          : 'white',
                    }}
                  />
                ),
              },
            ]
            : []),
          ...(accountHolderTemplate.checkoutButtonColourHex
            ? [
              {
                name: 'Checkout Button Colour HEX',
                value: (
                  <Chip
                    variant="outlined"
                    label={accountHolderTemplate.checkoutButtonColourHex?.toUpperCase()}
                    sx={{
                      backgroundColor: `${accountHolderTemplate.checkoutButtonColourHex} !important`,
                      color: 'white',
                    }}
                  />
                ),
              },
            ]
            : []),
          ...(accountHolderTemplate.checkoutFontReference
            ? [
              {
                name: 'Checkout Font',
                value: (
                  <Box
                    sx={{
                      fontFamily: JSON.parse(
                        accountHolderTemplate.checkoutFontReference,
                      ).fontName,
                    }}
                  >
                    {
                      JSON.parse(
                        accountHolderTemplate.checkoutFontReference,
                      ).fontName
                    }
                  </Box>
                  // <Chip
                  //   variant="outlined"
                  //   label={accountHolderTemplate.checkoutButtonColourHex?.toUpperCase()}
                  //   sx={{
                  //     backgroundColor: `${accountHolderTemplate.checkoutButtonColourHex} !important`,
                  //     color: 'white',
                  //   }}
                  // />
                ),
              },
            ]
            : []),
        ]
        : [],
    [accountHolderTemplate],
  );

  return (
    <>
      {accountHolderTemplate?.checkoutFontReference && (
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            rel="stylesheet"
            href={
              JSON.parse(accountHolderTemplate.checkoutFontReference).fontURL
            }
            crossOrigin="anonymous"
          />
        </Helmet>
      )}
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
            marginBottom: '25px',
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }),
        ]}
      >
        <Box>
          <Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            USER MANAGEMENT
          </Typography>
          <Typography color="textPrimary" variant="h5">
            Client
          </Typography>
        </Box>
        <Box
          sx={[
            {
              display: 'flex',
              gap: 1,
              marginBottom: '10px',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '10px',
              },
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            }),
          ]}
        >
          {accountHolderTemplateData.length == 0 && (
            <PermissionGuard permission="CREATE_ACCOUNT_HOLDER_TEMPLATE">
              <Button
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setCreateAccountHolderTemplateOpened(true)}
                disabled={!isUserActive}
              >
                Create client template
              </Button>
            </PermissionGuard>
          )}
          <PermissionGuard permission="INIT_KYC_VERIFICATION">
            <Button
              sx={[
                {
                  whiteSpace: 'nowrap',
                },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    minWidth: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    minWidth: 'auto',
                  },
                }),
              ]}
              variant="contained"
              onClick={() => setTriggerKycOpened(true)}
              disabled={!isUserActive}
            >
              Trigger KYC
            </Button>
          </PermissionGuard>
          <PermissionGuard permission="UPDATE_ACCOUNT_HOLDER">
            <Button
              sx={[
                {
                  whiteSpace: 'nowrap',
                },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    minWidth: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    minWidth: 'auto',
                  },
                }),
              ]}
              variant="contained"
              onClick={() => setEditDetailsOpened(true)}
              disabled={!isUserActive}
            >
              Edit client
            </Button>
          </PermissionGuard>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {accountHolderData ? (
                    <Card>
                      {/* <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6">User info</Typography>
                      </Box>
                    }
                  /> */}

                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={2}
                        sx={{ p: '16px' }}
                      >
                        {accountHolderData.map(({ name, value }) => (
                          <Grid item md={6} sm={12} xs={12} key={name}>
                            <Grid container>
                              <Grid item sm={6} xs={12}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {name}
                                </Typography>
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                {value}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      {accountHolder && (
                        <Audits
                          accountHolderId={accountHolderId}
                          entityType={'ACCOUNT_HOLDER'}
                          entityId={accountHolder.id}
                        />
                      )}
                    </Card>
                  ) : null}

                  {accountHolderTemplate && (
                    <>
                      <Box
                        sx={[
                          {
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '25px',
                            marginBottom: '25px',
                          },
                          (theme) => ({
                            [theme.breakpoints.down('md')]: {
                              flexDirection: 'column',
                            },
                            [theme.breakpoints.up('md')]: {
                              flexDirection: 'row',
                            },
                          }),
                        ]}
                      >
                        <Box>
                          <Typography color="textPrimary" variant="h5">
                            Client Template
                          </Typography>
                        </Box>
                        <Box
                          sx={[
                            {
                              display: 'flex',
                              gap: 1,
                              marginBottom: '10px',
                            },
                            (theme) => ({
                              [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column',
                                gap: '10px',
                              },
                              [theme.breakpoints.up('sm')]: {
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                            }),
                          ]}
                        >
                          <PermissionGuard permission="UPDATE_ACCOUNT_HOLDER_TEMPLATE">
                            <Button
                              sx={[
                                {
                                  whiteSpace: 'nowrap',
                                },
                                (theme) => ({
                                  [theme.breakpoints.down('sm')]: {
                                    minWidth: '100%',
                                  },
                                  [theme.breakpoints.up('sm')]: {
                                    minWidth: 'auto',
                                  },
                                }),
                              ]}
                              variant="contained"
                              onClick={() =>
                                setEditAccountHolderTemplateOpened(true)
                              }
                              disabled={!isUserActive}
                            >
                              Edit client template
                            </Button>
                          </PermissionGuard>
                        </Box>
                      </Box>

                      <Card>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={2}
                          sx={{ p: '16px' }}
                        >
                          {accountHolderTemplateData.map(({ name, value }) => (
                            <Grid item md={6} sm={12} xs={12} key={name}>
                              <Grid container>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {name}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  {value}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        {accountHolderTemplate.checkoutBannerSource && (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography variant="h6">Banner</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                ml: '20px',
                                mr: '20px',
                                padding: '0',
                              }}
                            >
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'space-between',
                                    justifyContent: 'space-between',
                                    // border: "1px solid red",
                                  }}
                                  component="div"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      accountHolderTemplate?.checkoutBannerSource,
                                  }}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {accountHolderTemplate.checkoutBackgroundSource && (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography variant="h6">Background</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                ml: '20px',
                                mr: '20px',
                                mb: '20px',
                                padding: '0',
                              }}
                            >
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'space-between',
                                    justifyContent: 'space-between',
                                    // border: "1px solid red",
                                  }}
                                  component="div"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      accountHolderTemplate?.checkoutBackgroundSource.replace(
                                        'fixed',
                                        'relative',
                                      ),
                                  }}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Card>
                    </>
                  )}

                  {accountHolder && (
                    <SubAccountHolders
                      accountHolderId={accountHolderId}
                      subAccountHolderId={accountHolder.id}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {accountHolder ? (
        <>
          <PermissionGuard permission="INIT_KYC_VERIFICATION">
            <Drawer
              anchor="right"
              open={triggerKycOpened}
              onClose={() => {
                setTriggerKycOpened(false);
                refetchAccountHolder();
              }}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <TriggerKycForm
                accountHolderIdForHeader={accountHolderId}
                onClose={() => setTriggerKycOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
          <PermissionGuard permission="UPDATE_ACCOUNT_HOLDER">
            <Drawer
              anchor="right"
              open={editDetailsOpened}
              onClose={() => setEditDetailsOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <EditAccountHolderForm
                accountHolderIdForHeader={accountHolderId}
                onClose={() => setEditDetailsOpened(false)}
                accountHolder={accountHolder}
              />
            </Drawer>
          </PermissionGuard>
        </>
      ) : null}

      {accountHolderTemplate && (
        <PermissionGuard permission="UPDATE_ACCOUNT_HOLDER_TEMPLATE">
          <Drawer
            anchor="right"
            open={editAccountHolderTemplateOpened}
            onClose={() => setEditAccountHolderTemplateOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <AccountHolderTemplateForm
              accountHolderTemplate={accountHolderTemplate}
              accountHolderIdForHeader={accountHolderId}
              onClose={() => setEditAccountHolderTemplateOpened(false)}
              accountHolder={accountHolder}
            />
          </Drawer>
        </PermissionGuard>
      )}
      {!accountHolderTemplate && (
        <PermissionGuard permission="CREATE_ACCOUNT_HOLDER_TEMPLATE">
          <Drawer
            anchor="right"
            open={createAccountHolderTemplateOpened}
            onClose={() => setCreateAccountHolderTemplateOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <AccountHolderTemplateForm
              accountHolderIdForHeader={accountHolderId}
              onClose={() => setCreateAccountHolderTemplateOpened(false)}
              accountHolder={accountHolder}
              accountHolderTemplate={accountHolderTemplate}
            />
          </Drawer>
        </PermissionGuard>
      )}
    </>
  );
};

export default AccountHolderView;
