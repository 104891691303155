import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { isValid } from 'date-fns';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from 'src/utils/types';

export interface DateOfBirthFieldProps {
  disabled: boolean;
}
export const DateOfBirthField: FC<
  DateOfBirthFieldProps & FieldProps<Date, Date>
> = ({
  disabled = false,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* {JSON.stringify(values)} */}
      <FormControl fullWidth margin="normal">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            disableFuture
            label={t('common.dateOfBirth')}
            onChange={(newValue: Nullable<Date>) => {
              if (!isValid(newValue)) {
                return;
              }
              setFieldValue(`${field.name}`, newValue);
            }}
            openTo="year"
            reduceAnimations
            renderInput={(params: any) => (
              <>
                {/* {JSON.stringify(params.inputProps.value)} <br />
                {JSON.stringify(params.error)}
                <br />
                {JSON.stringify(Object.keys(params))}
                <br />
                {JSON.stringify(Object.keys(params.inputProps))}
                <br /> */}
                <TextField
                  {...params}
                  onBlur={handleBlur(`${field.name}`)}
                  error={Boolean(touched[field.name] && errors[field.name])}
                />
              </>
            )}
            toolbarTitle={t('common.dateOfBirth')}
            value={values[field.name]}
            views={['year', 'month', 'day']}
            disabled={disabled}
          />
        </LocalizationProvider>
        <FormHelperText error>
          {touched[field.name] && t(errors[field.name] as string)}
        </FormHelperText>
      </FormControl>
    </>
  );
};
