import {
  Box,
  Button,
  colors,
  Container,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { RestrictionStatusLabel } from '../RestrictionStatusLabel';
import { AccountCurrencyList } from './AccountCurrencyList';
import { AccountCurrencyPending } from './AccountCurrencyPending';
import { AccountCurrencyForm } from './forms/AccountCurrencyForm';
export const AccountCurrency: FC<{
  accountHolderId: string;
  isGlobal: boolean;
}> = ({ accountHolderId, isGlobal }) => {
  const { hasPermission } = usePermissions();
  const [addAccountCurrencyOpened, setAddAccountCurrencyOpened] =
    useState<boolean>(false);
  const [addAccountCurrencyRequestOpened, setAddAccountCurrencyRequestOpened] =
    useState<boolean>(false);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Helmet>
        <title>Account Currency</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12} sx={{ mt: '30px' }}>
          {isGlobal && (<Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            RESTRICTIONS
          </Typography>)}
        </Grid>
        <Box
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              marginTop: '25px',
              marginBottom: '25px',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }),
          ]}
        >
          <Typography color="textPrimary" variant="h5">
            Account Currency <RestrictionStatusLabel status="ACTIVE" />
          </Typography>
          <Box
            sx={[
              {
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            <PermissionGuard permission="CREATE_ACCOUNT_CURRENCY_RESTRICTION">
              <Button
                disabled={!isUserActive}
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setAddAccountCurrencyOpened(true)}
              >
                Create account currency restriction
              </Button>
            </PermissionGuard>
            {!hasPermission('ADMIN') && (
              <PermissionGuard permission="REQUEST_ACCOUNT_CURRENCY_RESTRICTION">
                <Button
                  sx={[
                    {
                      whiteSpace: 'nowrap',
                    },
                    (theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        minWidth: '100%',
                      },
                      [theme.breakpoints.up('sm')]: {
                        minWidth: 'auto',
                      },
                    }),
                  ]}
                  variant="contained"
                  onClick={() => setAddAccountCurrencyRequestOpened(true)}
                >
                  Create account currency restriction request
                </Button>
              </PermissionGuard>
            )}
          </Box>
        </Box>

        <PermissionGuard permission="REQUEST_ACCOUNT_CURRENCY_RESTRICTION">
          <Drawer
            anchor="right"
            open={addAccountCurrencyRequestOpened}
            onClose={() => setAddAccountCurrencyRequestOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <AccountCurrencyForm
              isRequest={true}
              accountHolderId={accountHolderId}
              isGlobal={isGlobal}
              onClose={() => setAddAccountCurrencyRequestOpened(false)}
            />
          </Drawer>
        </PermissionGuard>
        <PermissionGuard permission="CREATE_ACCOUNT_CURRENCY_RESTRICTION">
          <Drawer
            anchor="right"
            open={addAccountCurrencyOpened}
            onClose={() => setAddAccountCurrencyOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <AccountCurrencyForm
              isRequest={false}
              accountHolderId={accountHolderId}
              isGlobal={isGlobal}
              onClose={() => setAddAccountCurrencyOpened(false)}
            />
          </Drawer>
        </PermissionGuard>

        <PermissionGuard permission="GET_ACCOUNT_CURRENCY_RESTRICTION">
          <AccountCurrencyList accountHolderId={accountHolderId} />
        </PermissionGuard>
      </Container>
      <PermissionGuard permission="GET_ACCOUNT_CURRENCY_RESTRICTION">
        <AccountCurrencyPending
          accountHolderId={accountHolderId}
          isGlobal={isGlobal}
        />
      </PermissionGuard>
    </>
  );
};
