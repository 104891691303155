import { FileDownload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Drawer,
  FormHelperText,
  Grid,
  MenuItem,
  Popover,
  Tooltip,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { DateRangeForm } from 'src/components/forms';
import { selectToken } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { config } from 'src/services/config';
import { Nullable } from 'src/utils/types';
import { removeEmpty } from '../../../../utils/removeEmpty';
import { AccountEntryExportForm, accountEntryExportFormInitialValues, accountEntryExportFormValidationSchema, ApiAccountEntryExportForm, mapApiAccountEntryExportFormToApiAccountEntryExportForm } from './AccountEntries';
import { AccountReportExportForm, accountReportExportFormInitialValues, accountReportExportFormValidationSchema, ApiAccountReportExportForm, mapApiAccountReportExportFormToApiAccountReportExportForm } from './AccountReport';

interface FormProps {
  accountHolderId: string;
  accountId: string;
}
export const AccountReportDownloadForm: FC<FormProps> = ({ accountHolderId, accountId }) => {
  const token = useSelector(selectToken);
  const [exportOpen, setExportOpen] = useState(false);
  const [reportType, setReportType] = useState<Nullable<string>>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { hasPermission } = usePermissions();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onBalanceStatement = () => {
    setReportType("BALANCE_STATEMENT");
    setExportOpen(!exportOpen);
    handleClose();
  };

  const onProofOfFunds = () => {
    setReportType("PROOF_OF_FUNDS");
    setExportOpen(!exportOpen);
    handleClose();
  };

  const triggerDownload = (params: AccountReportExportForm) => {
    const request: ApiAccountReportExportForm = mapApiAccountReportExportFormToApiAccountReportExportForm(params);
    const parameters = removeEmpty({
      ...request
    });
    const filename = `${new Date().toJSON().slice(0, 10).replaceAll('-', '_')}_${new Date().toJSON().slice(11, 19).replaceAll(':', '_')}.pdf`;
    const queryString = new URLSearchParams(parameters).toString();
    fetch(`${config.apiUrl}/gateway/accounts/${accountId}/reports?${queryString}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + token,
        'x-account-holder-id': accountHolderId
      }
    })
      .then(response => {
        return response.blob()
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
        setExportOpen(false);
      }).catch((error) => {
        setExportOpen(false);
      });
  }


  // Account Entries Export Start
  const [exportAccountEntriesOpen, setExportAccountEntriesOpen] = useState(false);

  const OnAccountEntries = () => {
    setExportAccountEntriesOpen(!exportAccountEntriesOpen);
  };

  const triggerAccountEntriesDownload = (params: AccountEntryExportForm) => {
    const request: ApiAccountEntryExportForm = mapApiAccountEntryExportFormToApiAccountEntryExportForm(params);
    const parameters = removeEmpty({
      ...request
    });
    const filename = `${new Date().toJSON().slice(0, 10).replaceAll('-', '_')}_${new Date().toJSON().slice(11, 19).replaceAll(':', '_')}.csv`;
    const queryString = new URLSearchParams(parameters).toString();
    fetch(`${config.apiUrl}/gateway/reports/transactions?${queryString}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + token,
        'x-account-holder-id': accountHolderId
      }
    })
      .then(response => {
        return response.blob()
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
        setExportOpen(false);
      }).catch((error) => {
        setExportOpen(false);
      });
  }

  // Account Entries Export End



  return (
    <>
      <Tooltip title={`Download Account Reports`}>
        <Button
          aria-describedby={id} variant="contained" onClick={handleClick}>
          <FileDownload />
        </Button>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >

        <Box>
          <MenuItem onClick={onBalanceStatement}>Balance Statement </MenuItem>
          <MenuItem onClick={onProofOfFunds}>Proof of Funds</MenuItem>
          {hasPermission('GET_ACCOUNT_ENTRY_REPORT') && (<MenuItem onClick={OnAccountEntries}>Export Account Entries</MenuItem>)}
        </Box>
      </Popover>


      <Drawer
        anchor="top"
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ padding: '20px' }}
          xs={12}
        >
          <Grid item xs={12}>
            <Formik<AccountReportExportForm>
              initialStatus={{ error: '' }}
              enableReinitialize
              initialValues={accountReportExportFormInitialValues(reportType)}
              validationSchema={accountReportExportFormValidationSchema()}
              onSubmit={(values, { resetForm }) => {
                triggerDownload(values);
                resetForm();
              }}
            >
              {({
                handleSubmit,
                isSubmitting,
                status,
                resetForm,
              }): JSX.Element => (
                <>
                  <form noValidate>
                    <Grid container spacing={1}>


                      <Grid item xs={12} sm={6} md={4}>
                        <DateRangeForm size='medium' />
                      </Grid>
                    </Grid>

                    {!isSubmitting && status.error && (
                      <Box sx={{ mt: 1 }}>
                        <FormHelperText error>{status.error}</FormHelperText>
                      </Box>
                    )}
                  </form>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'right',
                      padding: '24px',
                    }}
                  >
                    <Button
                      color="error"
                      onClick={() => {
                        resetForm();
                        setExportOpen(false);
                      }}
                      variant="contained"
                      sx={{ marginRight: '20px' }}
                    >
                      Clear X
                    </Button>
                    <LoadingButton
                      color="primary"
                      loading={isSubmitting}
                      onClick={() => handleSubmit()}
                      variant="contained"
                    >
                      Export
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Drawer>


      <PermissionGuard permission="GET_ACCOUNT_ENTRY_REPORT">

        <Drawer
          anchor="top"
          open={exportAccountEntriesOpen}
          onClose={() => setExportAccountEntriesOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >
            <Grid item xs={12}>
              <Formik<AccountEntryExportForm>
                initialStatus={{ error: '' }}
                initialValues={accountEntryExportFormInitialValues(accountId)}
                validationSchema={accountEntryExportFormValidationSchema()}
                onSubmit={(values, { resetForm }) => {
                  triggerAccountEntriesDownload(values);
                  resetForm();
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  status,
                  resetForm,
                }): JSX.Element => (
                  <>
                    <form noValidate>
                      <Grid container spacing={1}>


                        <Grid item xs={12} sm={6} md={4}>
                          <DateRangeForm size='medium' />
                        </Grid>
                      </Grid>

                      {!isSubmitting && status.error && (
                        <Box sx={{ mt: 1 }}>
                          <FormHelperText error>{status.error}</FormHelperText>
                        </Box>
                      )}
                    </form>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'right',
                        padding: '24px',
                      }}
                    >
                      <Button
                        color="error"
                        onClick={() => {
                          resetForm();
                          setExportAccountEntriesOpen(false);
                        }}
                        variant="contained"
                        sx={{ marginRight: '20px' }}
                      >
                        Clear X
                      </Button>
                      <LoadingButton
                        color="primary"
                        loading={isSubmitting}
                        onClick={() => handleSubmit()}
                        variant="contained"
                      >
                        Export
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Drawer>
      </PermissionGuard >
    </ >
  );
};
