import * as Yup from 'yup';

import { ApiCorrespondentBank } from 'src/models/configurations';
import { CreateUpdateCorrespondentBankFormValues } from './types';

export const correspondentBankFormValidationSchema =
  Yup.object({
    enabled: Yup.bool().required('Enabled is required'),
    bankName: Yup.string().required('Bank Name is required'),
  });

export const correspondentBankToCorrespondentBankFormValues = (
  banking: ApiCorrespondentBank, accountHolderIdForHeader: string, bankingConfigId: string
): CreateUpdateCorrespondentBankFormValues => ({
  accountHolderIdForHeader,
  enabled: banking.enabled,
  bankName: banking.bankName,
  correspondentBankId: banking.id,
  bankingConfigId: bankingConfigId,
});