import { Delete } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiCurrency,
  useDeleteCurrencyRestrictionMutation,
  useDeleteCurrencyRestrictionRequestMutation,
} from 'src/models/restrictions';
interface CurrenciesTable {
  currencies: ApiCurrency[];
  accountHolderId: string;
}

const headCells = [
  //   { title: 'Id', id: 'id', doSort: true },
  { title: 'Currency Code', id: 'currencyCode', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const CurrenciesTable: FC<CurrenciesTable> = ({
  currencies,
  accountHolderId,
}) => {
  const { hasPermission } = usePermissions();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });

  const [deleteCurrencyRestriction] = useDeleteCurrencyRestrictionMutation();
  const [deleteCurrencyRestrictionRequest] =
    useDeleteCurrencyRestrictionRequestMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            currencyId: null,
            permission: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              if (values.permission == 'DELETE_CURRENCY_RESTRICTION') {
                await deleteCurrencyRestriction({
                  currencyId: values.currencyId,
                  accountHolderIdForHeader: accountHolderId,
                }).unwrap();
              }
              if (values.permission == 'REQUEST_CURRENCY_RESTRICTION') {
                await deleteCurrencyRestrictionRequest({
                  currencyId: values.currencyId,
                  accountHolderIdForHeader: accountHolderId,
                }).unwrap();
              }
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id }) => (
                    <TableCell key={id}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currencies.map((currency) => {
                  return (
                    <TableRow
                      hover
                      key={currency.id}
                    // onClick={() => navigate(`${currency.id}`)}
                    >
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {currency.id}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Chip
                          sx={{ pl: '5px', m: 1 }}

                          label={`${currency.currencyCode}`}
                          icon={<CurrencyFlag currency={currency.currencyCode.toLocaleLowerCase()} size="md" />}
                        />
                      </TableCell>

                      <TableCell>
                        <PermissionGuard permission="DELETE_CURRENCY_RESTRICTION">
                          <IconButton
                            disabled={!isUserActive}
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title:
                                  'Are you sure to remove this currency restriction?',
                                subTitle: 'This operation cannot be undone',
                                onConfirm: () => {
                                  setFieldValue('currencyId', currency.id);
                                  setFieldValue(
                                    'permission',
                                    'DELETE_CURRENCY_RESTRICTION',
                                  );
                                  formikRef?.current?.submitForm();
                                },
                              });
                            }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </PermissionGuard>
                        {!hasPermission('ADMIN') && (
                          <PermissionGuard permission="REQUEST_CURRENCY_RESTRICTION">
                            <IconButton
                              disabled={!isUserActive}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    'Are you sure to remove this currency restriction request?',
                                  subTitle: 'This operation cannot be undone',
                                  onConfirm: () => {
                                    setFieldValue('currencyId', currency.id);
                                    setFieldValue(
                                      'permission',
                                      'REQUEST_CURRENCY_RESTRICTION',
                                    );
                                    formikRef?.current?.submitForm();
                                  },
                                });
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </PermissionGuard>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
