import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import { App } from './App';
import { ReduxProvider } from './services/redux/Provider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider>
        <App />
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
