import './components/i18n/i18n';

import { CacheProvider } from '@emotion/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { Navigation } from 'src/navigation';
import { createTheme } from 'src/services/theme/';
import { HelpPage } from './components/Help/HelpPage';
import { Pwa } from './components/Pwa';
import { RTL } from './components/rtl';
import { Signature } from './components/Signature';
import Totp from './components/totp';
import {
  SettingsConsumer,
  SettingsProvider,
} from './contexts/settings-context';
import { AuthManager } from './models/auth/components/AuthManager';
import { selectThemeColour } from './models/cms';
import { setFingerprint } from './models/fingerprint';
import { createEmotionCache } from './utils/create-emotion-cache';
export const App = (props) => {
  const emotionCache = createEmotionCache();
  const themeColour = useSelector(selectThemeColour);
  const dispatch = useDispatch();

  const overrides = useMemo(() => {
    return themeColour
      ? {
        palette: {
          primary: {
            main: themeColour,
          },
        },
      }
      : {};
  }, [themeColour]);

  useEffect(() => {
    const fetchFingerprintData = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      dispatch(setFingerprint({ id: result.visitorId }));
    };
    fetchFingerprintData().catch(console.error);
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeProvider
              theme={createTheme({
                direction: settings.direction,
                responsiveFontSizes: settings.responsiveFontSizes,
                mode: settings.theme,
                overrides: overrides,
              })}
            >
              <RTL direction={settings.direction}>
                <CssBaseline />
                <Toaster position="top-center" />
                <AuthManager>
                  <>
                    <Navigation />
                    <Pwa />
                  </>
                  <Totp />
                </AuthManager>
              </RTL>
              {/* {window.location.pathname != '/api-docs' && <HelpPage />} */}
              <Signature />
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </CacheProvider>
  );
}; 
