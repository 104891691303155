export const formatCreditCardNumber = (cardNumber: string, secure = false) =>
  cardNumber
    .split('')
    .reduce(
      (acc, val, i) => [
        ...acc,
        `${secure && i < 12 ? '*' : val}${(i + 1) % 4 ? '' : ' '}`,
      ],
      [],
    )
    .join('')
    .trim();
