import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { FC, ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import IGamingNavbar from './IGamingNavbar';
import IGamingSidebar from './IGamingSidebar';
interface IGamingLayoutProps {
  children?: ReactNode;
}

const IGamingLayoutRoot = styled('div')(() => ({
  backgroundColor: '#ffffff',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const IGamingLayoutWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: `${SIDEBAR_WIDTH}px`,
  },
}));

const IGamingLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const IGamingLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  minHeight: '100%',
  padding: '80px 0 16px',
});

const IGamingLayout: FC<IGamingLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  // 49ABE6,738095,4288C9,344E98,93B3D3
  const theme = createTheme({
    palette: {
      primary: {
        main: '#4288C9',
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <IGamingLayoutRoot>
          <IGamingSidebar
            onMobileClose={(): void => setIsSidebarMobileOpen(false)}
            openMobile={isSidebarMobileOpen}
          />
          <IGamingLayoutWrapper>
            <IGamingNavbar
              onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
            />
            <IGamingLayoutContainer>
              <IGamingLayoutContent>
                <Outlet />
              </IGamingLayoutContent>
            </IGamingLayoutContainer>
          </IGamingLayoutWrapper>
        </IGamingLayoutRoot>
      </ThemeProvider>
    </>
  );
};

export default IGamingLayout;
