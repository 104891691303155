import { Close } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  colors,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { FC, useState } from 'react';

import { LogType, useTransactionLogsQuery } from 'src/models/transactions';
import { LogTypeList } from 'src/models/transactions/constants';
import Scrollbar from '../../components/Scrollbar';

interface Props {
  accountHolderId: string;
  transactionId: string;
  onClose: () => void;
}

export const ProcessingLogs: FC<Props> = ({
  accountHolderId,
  transactionId,
  onClose,
}) => {
  const [logType, setLogType] = useState<LogType | string>('');
  const { data: logs } = useTransactionLogsQuery({
    accountHolderId,
    id: transactionId,
    logType: logType as LogType,
  },{refetchOnMountOrArgChange:true,});

  if (!logs) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 38px 24px 32px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{ mr: 2 }}
            aria-label="close"
            size="medium"
            onClick={() => onClose()}
          >
            <Close color="inherit" fontSize="inherit" />
          </IconButton>
          <Typography color={'textPrimary'} variant="h5">
            Processing Logs
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          marginTop: '30px',
          padding: '0 20px',
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="select-log-type-label">Log type</InputLabel>
          <Select
            labelId="select-log-type-label"
            id="select-log-type"
            label="Log type"
            value={logType}
            onChange={(e) => {
              setLogType(e.target.value);
            }}
          >
            <MenuItem value="">
              <em>Clear X</em>
            </MenuItem>
            {LogTypeList.map((logTypeName) => (
              <MenuItem key={logTypeName} value={logTypeName}>
                {logTypeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Card
        sx={{
          padding: '20px',
          margin: '10px 20px',
        }}
      >
        <Scrollbar>
          {logs.length === 0 ? <Typography>No Logs found</Typography> : null}
          {logs.map((l) => (
            <Accordion
              key={l.id}
              sx={{
                border: 'none',
                boxShadow: 'none',
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                sx={{
                  cursor: 'pointer',
                  padding: '0 15px',
                  ':hover': {
                    backgroundColor: colors.grey[100],
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography>{l.logType}</Typography>
                  <Typography>{l.data.length > 35 ? l.data.substring(0, 32) + "..." : l.data}</Typography>
                  <Typography>
                    {format(new Date(l.createdDateTime), 'dd MMM, HH:mm')}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Scrollbar>
                  <Typography sx={{ maxHeight: '60vh', overflow: 'auto' }}>
                    {l.data}
                  </Typography>
                </Scrollbar>
              </AccordionDetails>
            </Accordion>
          ))}
        </Scrollbar>
      </Card>
    </>
  );
};
