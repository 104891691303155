import { Box, TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DateOfBirthField, NationalityField } from '../..';
import { AddressField } from '../Address';
import { PhoneField } from '../Phone';
import { UserDetailsInfo } from './types';

export interface UserDetailsFieldProps {
  disabled?: boolean;
  accountHolderId?: string;
}
export const UserDetailsField: FC<
  UserDetailsFieldProps & FieldProps<UserDetailsInfo, UserDetailsInfo>
> = ({
  disabled = false,
  accountHolderId,
  field,
  form: { values, touched, errors, handleBlur, handleChange },
  ...props
}) => {
    const { t } = useTranslation();

    return (
      <>
        <Box sx={{ typography: 'subtitle2', mb: '4px' }}>Account details</Box>

        <Field name="phone" component={PhoneField} disabled={disabled} />

        <Box sx={{ typography: 'subtitle2' }}>Personal info</Box>


        <Field
          component={TextField}
          name={`firstName`}
          fullWidth
          label={t('common.firstName')}
          value={values.firstName}
          margin="normal"
          error={Boolean(touched?.firstName && !!errors?.firstName)}
          helperText={touched?.firstName && t(errors?.firstName as string)}
          onBlur={handleBlur(`firstName`)}
          onChange={handleChange(`firstName`)}
          variant="outlined"
          disabled={disabled}
        />


        <Field
          component={TextField}
          name={`lastName`}
          fullWidth
          label={t('common.lastName')}
          value={values.lastName}
          margin="normal"
          error={Boolean(touched?.lastName && !!errors?.lastName)}
          helperText={touched?.lastName && t(errors?.lastName as string)}
          onBlur={handleBlur(`lastName`)}
          onChange={handleChange(`lastName`)}
          variant="outlined"
          disabled={disabled}
        />

        <Field
          name={'dateOfBirth'}
          component={DateOfBirthField}
          disabled={disabled}
        />

        <Field
          name={'nationality'}
          component={NationalityField}
          disabled={disabled}
        />

        <Field
          component={TextField}
          name={`idPassportNo`}
          fullWidth
          label={t('common.idPassportNo')}
          value={values.idPassportNo}
          margin="normal"
          error={Boolean(touched?.idPassportNo && !!errors?.idPassportNo)}
          helperText={touched?.idPassportNo && t(errors?.idPassportNo as string)}
          onBlur={handleBlur(`idPassportNo`)}
          onChange={handleChange(`idPassportNo`)}
          variant="outlined"
          disabled={disabled}
        />

        <Field name="address" component={AddressField} disabled={disabled} accountHolderId={accountHolderId} />
      </>
    );
  };
