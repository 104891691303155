import { AttachFile, Preview } from '@mui/icons-material';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { ChangeEvent, useState } from 'react';

import { PreviewImageDialog } from '../PreviewImageDialog';

export const FileInputField: React.FC<{
  label: string;
  fileFieldName: string;
  withPreview: boolean;
  defaultFileUrl?: string;
  defaultFileName?: string;
}> = ({
  label,
  fileFieldName,
  withPreview,
  defaultFileUrl,
  defaultFileName,
}) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();
  const [file, setFile] = useState<File>(null);

  const [previewImageDialog, setPreviewImageDialog] = useState({
    isOpen: false,
    title: '',
    file: null,
    onConfirm: () => void 0,
  });

  const scriptInputRef = React.useRef<HTMLInputElement>(null);
  const fileSelect = (
    e: ChangeEvent<HTMLInputElement>,
    fileFieldName: string,
  ) => {
    setFieldValue(fileFieldName, e.currentTarget.files.item(0));
    setFile(e.currentTarget.files.item(0));
    if (scriptInputRef.current) {
      scriptInputRef.current.focus();
    }
  };

  return (
    <>
      {(file || defaultFileUrl) && withPreview && (
        <Box mt={2} textAlign="center">
          <div>Image Preview:</div>
          <img
            src={file ? URL.createObjectURL(file) : defaultFileUrl}
            alt={file ? values[fileFieldName].name : defaultFileName}
            height="100px"
          />
        </Box>
      )}
      <TextField
        margin="normal"
        error={Boolean(touched[fileFieldName] && errors[fileFieldName])}
        helperText={touched[fileFieldName] && errors[fileFieldName]}
        fullWidth
        id="outlined-basic"
        variant="outlined"
        label={label}
        inputRef={scriptInputRef} // To focus on the field after clicking icon
        value={file?.name || ''}
        onBlur={() => setFieldTouched(fileFieldName, true)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              {(file || defaultFileUrl) && (
                <Tooltip title={`Preview ${label}`} arrow>
                  <IconButton
                    aria-label="upload"
                    component="label"
                    onClick={() => {
                      setPreviewImageDialog({
                        isOpen: true,
                        title: `Preview ${
                          file ? values[fileFieldName]?.name : defaultFileName
                        }`,
                        file: file ? URL.createObjectURL(file) : defaultFileUrl,
                        onConfirm: () => void 0,
                      });
                    }}
                  >
                    <Preview
                      sx={(theme) => ({
                        ...(touched[fileFieldName] && errors[fileFieldName]
                          ? { color: theme.palette.error.main }
                          : {}),
                      })}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={`Upload ${label}`} arrow>
                <IconButton aria-label="upload" component="label">
                  <AttachFile
                    sx={(theme) => ({
                      ...(touched[fileFieldName] && errors[fileFieldName]
                        ? { color: theme.palette.error.main }
                        : {}),
                    })}
                  />
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    onChange={(e) => fileSelect(e, fileFieldName)}
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
        }}
      />

      {
        <PreviewImageDialog
          previewImageDialog={previewImageDialog}
          setPreviewImageDialog={setPreviewImageDialog}
        />
      }
    </>
  );
};
