import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { ApiCreateUserRequest, ApiUser, ApiUserResponse, PageApiUserResponse, UserListRequest } from '.';

export const usersApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        usersList: builder.query<PageApiUserResponse, UserListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'auth/users',
                }
            },
            providesTags: ['User']
        }),
        user: builder.query<ApiUserResponse, { id: string, accountHolderIdForHeader: string }>({
            query: ({ id, accountHolderIdForHeader }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'GET',
                url: `auth/users/${id}`,

            }),
            providesTags: ['User']
        }),
        addUser: builder.mutation<ApiUser, ApiCreateUserRequest>({
            query: ({ accountHolderIdForHeader, ...user }) => ({
                body: user,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                url: 'auth/users',
            }),
            invalidatesTags: ['User']
        }),
        updateUser: builder.mutation<ApiUser, ApiCreateUserRequest>({
            query: ({ id, accountHolderIdForHeader, ...rest }) => ({
                body: { ...rest, id },
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                url: `auth/users/${id}`,
            }),
            invalidatesTags: ['User']
        }),
        deleteUser: builder.mutation<void, string>({
            query: (id) => ({
                method: 'DELETE',
                url: `auth/users/${id}`,
            }),
            invalidatesTags: ['User']
        }),
        updateUserPermissions: builder.mutation<void, { accountHolderIdForHeader: string, accountHolderId: string, userId: string, permissionGroupIds: string[] }>({
            query: ({ accountHolderIdForHeader, accountHolderId, userId, permissionGroupIds }) => ({
                body: { permissionGroupIds },
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                url: `auth/account-holders/${accountHolderId}/users/${userId}`,
            }),
            invalidatesTags: ['User']
        }),
        deleteUserPermissions: builder.mutation<void, { accountHolderIdForHeader: string, accountHolderId: string, userId: string }>({
            query: ({ accountHolderIdForHeader, accountHolderId, userId }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'DELETE',
                url: `auth/account-holders/${accountHolderId}/users/${userId}`,
            }),
            invalidatesTags: ['User']
        }),
        users: builder.query<ApiUser[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'GET',
                params: {
                    includeSubAccountHolders: true
                },
                url: 'auth/users/list',
            }),
            transformResponse: (response: ApiUser[], meta, arg) => {
                return response.sort((a, b) => a.email.localeCompare(b.email));
            },
            providesTags: ['User']
        }),

        // Admin disable 2FA
        disableUser2FA: builder.mutation<{ userId: string }, { accountHolderIdForHeader: string, userId: string }>({
            query: ({ accountHolderIdForHeader, userId }) => ({
                body: { userId },
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'DELETE',
                url: `auth/users/${userId}/totp`,
            }),
            invalidatesTags: ['User']
        }),

        // hack for the above
        // users: builder.query<ApiUser[], {accountHolderIdForHeader}>({
        //     // query: () => '/users',
        //     query: ({ accountHolderIdForHeader}) => {
        //         const parameters = removeEmpty({
        //             phoneNumber: '',
        //             firstName: '',
        //             lastName: '',
        //             nationality: '',
        //             userStatus: [],
        //             query: '',
        //             email: '',
        //             accountHolderId: '',
        //             id: '',
        //             includeSubAccountHolders: false,
        //           });
        //     return {
        //         headers: {
        //           'x-account-holder-id': accountHolderIdForHeader,
        //         },
        //         method: 'GET',
        //         params: parameters,
        //         url: 'users',
        //     }
        //       },
        //       transformResponse: (response:UserListResponse) => {
        //         return ([...createApiresponse.content]);
        //       },
        //     providesTags: ['User']
        // }),
    })
})

export const {
    useUsersListQuery,
    useUserQuery,
    useAddUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useUpdateUserPermissionsMutation,
    useDeleteUserPermissionsMutation,
    useUsersQuery,
    useDisableUser2FAMutation
} = usersApi;