import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';

import {
  selectAccountHolderId,
  selectExpiresOn,
  selectToken,
} from 'src/models/auth';
import { addEvent, ApiEvent, setConnected } from 'src/models/events';
import { config } from 'src/services/config';

interface SocketHandlerProps {
  children: ReactNode;
}

let recInterval = null;
let stompClient = null;
let socket = null;

export const SocketHandler: FC<SocketHandlerProps> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const accountHolderId = useSelector(selectAccountHolderId);
  const token = useSelector(selectToken);
  const expiresOn = useSelector(selectExpiresOn);
  let delay = 10000;

  useEffect(() => {
    connect();
  }, [accountHolderId, token]);

  const connectCallBack = (frame) => {
    delay = 10000;
    dispatch(setConnected({ status: true }));
    // console.log(`Connected: ${frame}`);
    stompClient.subscribe('/user/topic/alerts', function (message) {
      // console.log("MESSAGE");
      console.log(message.body);
      const event = JSON.parse(message.body) as ApiEvent;
      event.isFresh = true;
      // console.log(event.id);
      dispatch(addEvent({ event }));
    });
  };

  useEffect(() => {
    return () => {
      stompClient?.disconnect &&
        stompClient.disconnect(() => {
          // alert("See you next time!");
        });
      socket?.end && socket.end();
    };
  }, []);

  const errorCallback = () => {
    dispatch(setConnected({ status: false }));
    console.log('error');
    console.log(token);
    if (token) {
      delay = delay * 2;
      console.log(`reconnecting in ${delay}`);
      if (Date.now() < expiresOn) {
        setTimeout(function () {
          connect();
        }, delay);
      }
    }
  };

  const connect = () => {
    if (token && accountHolderId) {
      // let socket = new SockJS('http://localhost:8080/our-websocket');
      stompClient?.disconnect && stompClient.disconnect();
      socket?.end && socket.end();
      socket = new SockJS(`${config.apiUrl}/event/subscribe`);

      clearInterval(recInterval);

      socket.onopen = () => {
        dispatch(setConnected({ status: true }));
      };

      socket.onclose = () => {
        dispatch(setConnected({ status: false }));
        socket = null;
        recInterval = setInterval(() => {
          connect();
        }, 2000);
      };
      stompClient = over(socket);
      stompClient.connect(
        {
          Authorization: `Bearer ${token}`,
          'X-ACCOUNT-HOLDER-ID': accountHolderId,
        },
        connectCallBack,
        errorCallback,
      );
    } else {
      clearInterval(recInterval);
    }
  };

  return (
    <>
      {/* <Button
        variant="text"
        onClick={sendMessage}
      >
        sendMessage();
      </Button>
      <Button
        variant="text"
        onClick={sendPrivateMessage}
      >
        resetNotificationCount();
      </Button> */}
      {/* <Button
        variant="text"
        onClick={resetNotificationCount}
      >
        resetNotificationCount();
      </Button>
      <div>
        Notifications Count:
        {' '}
        {notificationCount}
      </div>
      <div>
        Message:
        {' '}
        {displayMessage}
      </div> */}
      {children}
    </>
  );
};
