import { InviteUserFormValues } from "./types";
import { createAccountHolderInviteUserFormValidationSchema } from "./validations";

export const inviteUserInitialValues = (): InviteUserFormValues => ({
    userEmail: ''
});

export const isInviteUserFormCompleted = (userExists: any) => async (inviteUser: InviteUserFormValues): Promise<boolean> => {
    try {
        await createAccountHolderInviteUserFormValidationSchema(userExists).validate(inviteUser);
    } catch (e) {
        return false;
    }
    return true;
}