import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          common: {
            rowsPerPage: "Rows per page",
            id: "Id",
            created: "Created",
            status: "Status",
            amount: "Amount",
            currency: "Currency",
            description: "Description",
            accountOwner: "Account Owner",
            externalReferenceId: "External Reference Id",
            referenceNo: "Reference Number",
            next: "Next",
            accountNumber: 'Account Number',
            username: 'Username',
            transferChannel: 'Transfer channel',
            actions: 'Actions',
            transactionType: 'Transaction type',
            createdDate: 'Created date',
            createdTime: 'Created time',
            submit: 'Submit',
            proceed: 'Proceed',
            back: 'Back',
            ipAddress: 'IP Address',
            actualAmount: 'Actual amount',
            actualCurrency: 'Actual Currency',
            accountHolderId: 'Client Id',
            bankingProfileId: 'Banking profile Id',
            transactionDetails: 'Transaction details',
            close: 'Close',
            details: 'Details',
            sessionExpired: 'Session expired',
            sessionExpiredDetails: 'Go back to banking to renew the session',
            accessDenied: 'Access denied',
            errorMessage: 'Error message',
            feeAmount: 'Fee amount',
            total: 'Total',
            includeCustomerDetails: "Include Customer Details",
            customAmount: "Custom Amount",
            demoPayment: "Demo Payment",
            pleaseChooseATransactionTypetoProceed: "Please choose transaction type to proceed",
            redirect: "Redirect",
            embedded: "Embedded",
            language: 'Language',
            width: 'Width',
            height: 'Height',
            email: 'Email',
            bankCardNumber: 'Card Number',
            bankCardHolderName: 'Card Holder Name',
            bankExpiryDate: 'Expiration Date',
            month: 'Month',
            year: 'Year',
            cvv: 'CVV',
            expiry: 'Expiry',
            title: "Title",
            firstName: "First Name",
            lastName: "Last Name",
            fullName: "Full Name",
            dateOfBirth: "Date Of Birth",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            country: "Country",
            zipPostCode: "Zip/Postcode",
            billingAddress: "Billing Address",
            deliveryAddress: "Delivery Address",
            phoneNumber: "Phone Number",
            customerInfo: "Customer Info",
            addressInfo: "Address Info",
            cardDetails: "Card Details",
            newPayment: "New Payment",
            copyFromBilling: "Copy From Billing",
            paymentComplete: "Payment Complete",
            thankYouForYourPayment: "Thank you for your payment",
            paymentFailed: "Payment Failed",
            pleaseContactSupport: "Please contact support",
            invalidTransaction: 'Invalid Transaction',
            cardNumber: 'Card Number',
            thePageYouAreLookingforIsntHere: 'The page you are looking for isn’t here',
            idPassportNo: 'Passport Number/ID Number',
            nationality: 'Nationality',
            password: 'Password',
            userLogin: 'User Login',
            userDetails: 'User Details',
            administratorDetails: 'Administrator Details',
            client: 'Client',
            accountHolderType: 'Account Type',
            descriptor: 'Descriptor',
            emailWillBeUsedForLogin: 'Email (will be used for login)',
            bankName: 'Bank Name',
            fullBankAddress: 'Full Bank Address',
            bankOwnerName: 'Bank Owner Name',
            bankDetails: 'Bank Details',
            displayName: 'Display Name',
            domain: 'Domain',
            bankAdministrativeEmail: 'Bank Administrative Email',
            bankSenderEmail: 'Bank Sender Email',
            imagePreview: 'Image Preview',
            bankLogo: 'Bank Logo',
            currencies: 'Currencies',
            domainDetails: 'Domain Details',
            domainName: 'Domain Name',
            domainProvider: 'Domain Provider',
            domainProviderUsername: 'Domain Provider Username',
            domainProviderPassword: 'Domain Provider Password',
            additionalRequirements: 'Additional Requirements',
            permissions: 'Permissions',
            inviteUser: 'Invite User',
            emailAlreadyUsed: 'Email already used',
            clientSuccessfullyCreated: 'Client successfully created',
            clientNotCreatedLimitReached: 'Client not created, limit reached',
            createClient: 'Create Client',
            createNewUser: 'Create New User',
            inviteAUser: 'Invite a User',
            withoutUser: 'Without User',
            administration: 'Administration',
            onboarding: 'Onboarding',
            bankOwner: 'Bank Owner',
            editOnboarding: 'Edit Onboarding',
            accept: 'Accept',
            reject: 'Reject',
            administrators: 'Administrators',
            phonePrefix: 'Phone Prefix',
            apiKeyType: 'Key Type',
            parentClient: 'Parent Client',
            accountType: 'Account Type',
            accountCurrency: 'Account Currency',
            fee: 'Fee',
            financials: 'Financials',
            balance: 'Balance',
            transactionNumber: 'Transaction Number',
            summary: 'Summary',
            type: 'Type',
            feeClient: 'Fee Client',
            feeAccount: 'Fee Account',
            contributorClient: 'Contributor Client',
            contributorAccount: 'Contributor Account',
            account: 'Account',
            chooseYourClientsAccount: 'Choose your client\'s account',
            noAccountsFound: 'No accounts found',

          },
          navigation: {
            backToTransfers: "Back to transfers",
            backToBanking: "Back to banking"
          },
          title: {
            pleaseChooseTransactionTypeToProceed: "Please, choose transaction type to proceed",
            specifyUsernameToSeeTransactionsHistory: "Specify username to see transactions history",
            transactionsHistory: "Transactions history",
            specifyTransferData: "Specify transfer data",
            chooseTransferMethod: "Choose Transfer Method",
            chooseSourceAccount: "Choose Source Account",
          },
          menu: {
            systemTransfer: "System Transfer",
            systemTransferDescription: "View and manage transfers for the System Accounts",
            userTransfer: "User Transfer",
            userTransferDescription: "View and manage transfer for Individual User Accounts",
            viewTransactions: "view transactions",
            makeATransfer: "make a transfer",
            beneficiaries: "beneficiaries",
          },
          validations: {
            amountIsRequired: 'Amount is required',
            amountMustBeANumber: 'Amount must be a number',
            amountMustBeGreaterThan0: 'Amount must greater than 0',
            amountMustNotExceed2DecimalSpaces: 'Amount must not exceed 2 decimal spaces',
            titleIsRequired: 'Title is required',
            emailIsRequired: 'Email is required',
            dateOfBirthIsRequired: 'Date of birth is required',
            dateOfBirthIsInvalid: 'Date of birth is invalid',
            dateOfBirthCannotBeInTheFuture: 'Date of birth cannot be in the future',
            firstNameIsRequired: 'First name is required',
            lastNameIsRequired: 'Last name is required',
            phoneCountryCodeIsRequired: 'Phone country code is required',
            phoneNumberIsRequired: 'Phone number is required',
            invalidPhonenumberOrCountryCode: 'Invalid phone number or country code',
            mustSelectACountry: 'Must select a country',
            addressIsRequired: 'Address is required',
            postcodeIsRequired: 'Zip/Post code is required',
            cityIsRequired: 'City is required',
            stateIsRequired: 'State is required',
            countryIsRequired: 'Country is required',
            creditCardNumberIsInvalid: 'Credit Card number is invalid',
            nameIsInvalid: 'Name is invalid',
            expiryMonthIsInvalid: 'Expiry month is invalid',
            expiryYearIsInvalid: 'Expiry year is invalid',
            cvvNumberIsInvalid: 'CVV number is invalid',
            mustBeAValidEmail: 'Must be a valid email address',
            mustSelectANationality: 'Must select a nationality',
            bankNameIsRequired: 'Bank Name is required',
            fullBankAddressIsRequired: 'Full Bank Address is required',
            bankAdminIsRequired: 'Bank Admin is required',
            displayNameIsRequired: 'Display Name is required',
            imageIsRequired: 'Image is required',
            fileIsTooLarge: 'The file is too large',
            onlyImageFormatsAccepted: 'Only the following formats are accepted: .jpeg, .jpg, .png, .pdf',
            domainIsRequired: 'Domain is required',
            bankAdministrativeEmailIsRequired: 'Bank Administrative Email is required',
            bankAdministrativeEmailIsInvalid: 'Bank Administrative Email must be a valid email address',
            bankSenderEmailIsRequired: 'Bank Sender Email is required',
            bankSenderEmailIsInvalid: 'Bank Sender Email must be a valid email address',
            domainIsInvalid: 'Url is invalid',
            mustChooseAtLeastOne: 'Must choose at least one',
            currenciesAreRequired: 'Currencies are required',
            domainNameIsRequired: 'Domain Name is required',
            parentClientIsRequired: "Parent Client is required",
            anAccountWiththisEmailIsAlreadyRegistered: "An account with this e-mail is already registered",
            atLeastOnePermissionGroupNeedsToBeSelected: "At least one permission group needs to be selected",
            permissionIsRequired: "Permission is required",
            mustHaveAtLeastOneAdministrator: 'Must have at least one Administrator',
            cannotBeLongerThan400Characters: 'Cannot be longer than 400 characters',
            mustSelectAPhonePrefix: 'Must select a Prefix',
            currencyCodeIsRequired: 'Currency code is required',
            createOnRegistrationIsRequired: 'Create on registration is required',
            clientIsRequired: "Client is required",
            accountTypeIsRequired: 'Account Type is required',
            feeIsRequired: 'Fee is Required',
            accountIsRequired: 'Account is Required'

          },
          demoIgaming: {
            myTransactions: 'My Transactions',
            myWallet: 'My Wallet',
            depositViaCard: 'Deposit Via Card',
            depositViaGoMagicPay: 'Deposit Via Gomagicpay',
            depositViaFliwp: 'Deposit Via FLIWP',
          }
        }
      },
      es: {
        translation: {
          common: {
            rowsPerPage: "Filas por página",
            id: "Id",
            created: "Creado",
            status: "Estado",
            amount: "Monto",
            currency: "Divisa",
            description: "Descripción",
            accountOwner: "Dueño de cuenta",
            externalReferenceId: "Id de referencia externa",
            referenceNo: "Número de referencia",
            next: "Próximo",
            accountNumber: 'Número de cuenta',
            username: 'Nombre de usuario',
            transferChannel: 'Canal de transferencia',
            actions: 'Acciónes',
            transactionType: 'Tipo de transacción',
            createdDate: 'Fecha de creación',
            createdTime: 'Tiempo creado',
            submit: 'Enviar',
            proceed: 'Simular',
            back: 'Espalda',
            ipAddress: 'Dirección IP',
            actualAmount: 'Monto Real',
            actualCurrency: 'Moneda real',
            accountHolderId: 'Id del titular de la cuenta',
            bankingProfileId: 'Id de perfil bancario',
            transactionDetails: 'Detalles de la transacción',
            close: 'Cerrar',
            details: 'Detalles',
            sessionExpired: 'Sesión expirada',
            sessionExpiredDetails: 'Volver a la banca para renovar la sesión',
            accessDenied: 'Acceso denegado',
            errorMessage: 'Mensaje de error',
            feeAmount: 'Importe de la cuota',
            total: 'Total',
            includeCustomerDetails: "Incluir detalles del cliente",
            customAmount: 'Otro valor',
            demoPayment: "Pago de demostración",
            pleaseChooseATransactionTypetoProceed: "Elija el tipo de transacción para continuar",
            redirect: "Redirigir",
            embedded: "Embebidos",
            language: 'Idioma',
            width: 'Ancho',
            height: 'Altura',
            financials: 'Finanzas',
            balance: 'Saldo',
            transactionNumber: 'Número de transacción',
            summary: 'Resumen',
            type: 'Tipo',
          },
          navigation: {
            backToTransfers: "Volver a las transacciones",
            backToBanking: "Volver a la banca"
          },
          title: {
            pleaseChooseTransactionTypeToProceed: "Por favor, elija el tipo de transacción para continuar",
            specifyUsernameToSeeTransactionsHistory: "Especifique el nombre de usuario para ver el historial de transacciones",
            transactionsHistory: "Historial de transacciones",
            specifyTransferData: "Especificar datos de transferencia",
            chooseTransferMethod: "Elija el método de transferencia",
            chooseSourceAccount: "Elija la cuenta de origen"
          },
          menu: {
            systemTransfer: "Transferencia del sistema",
            systemTransferDescription: "Ver y administrar transferencias para las cuentas del sistema",
            userTransfer: "Transferencia de usuario",
            userTransferDescription: "Ver y administrar transferencias para cuentas de usuario individuales",
            viewTransactions: "ver transacciones",
            makeATransfer: "haz una transferencia",
            beneficiaries: "beneficiarios",
          },
          validations: {
            amountIsRequired: 'Se requiere cantidad',
            amountMustBeANumber: 'La cantidad debe ser un número',
            amountMustBeGreaterThan0: 'La cantidad debe ser mayor que 0',
            amountMustNotExceed2DecimalSpaces: 'La cantidad no debe exceder los 2 decimales',
          },
          demoIgaming: {
            myTransactions: 'Mis transacciones',
            myWallet: 'Mi billetera',
            depositViaCard: 'Depósito a tarjeta',
            depositViaGoMagicPay: 'Depósito a Gomagicpay',
            depositViaFliwp: 'Depósito a FLIWP',
          }
        }
      },
      pt: {
        translation: {
          common: {
            rowsPerPage: "Linhas por página",
            id: "Id",
            created: "Creado",
            status: "Estado",
            amount: "Montante",
            currency: "Moeda",
            description: "Descrição",
            accountOwner: "Dono da conta",
            externalReferenceId: "Id de referência externa",
            referenceNo: "Número de referência",
            next: "Próximo",
            accountNumber: 'Número de conta',
            username: 'Nome do usuário',
            transferChannel: 'Transferir canal',
            actions: 'Ações',
            transactionType: 'Tipo de transação',
            createdDate: 'Data de criação',
            createdTime: 'Hora de criação',
            submit: 'Enviar',
            proceed: 'Simular',
            back: 'De volta',
            ipAddress: 'Endereço de IP',
            actualAmount: 'Montante atual',
            actualCurrency: 'Moeda atual',
            accountHolderId: 'Id do titular da conta',
            bankingProfileId: 'Id do perfil bancário',
            transactionDetails: 'Detalhes da transação',
            close: 'Fechar',
            details: 'Detalhes',
            sessionExpired: 'Sessão expirada',
            sessionExpiredDetails: 'Volte ao banco para renovar a sessão',
            accessDenied: 'Acesso negado',
            errorMessage: 'Mensagem de erro',
            feeAmount: 'Valor da taxa',
            total: 'Total',
            includeCustomerDetails: "Incluir detalhes do cliente",
            customAmount: "Outro valor",
            demoPayment: "Pagamento de demonstração",
            pleaseChooseATransactionTypetoProceed: "Por favor, escolha o tipo de transação para continuar",
            redirect: "Redirecionar",
            embedded: "Embarcados",
            language: 'Língua',
            width: 'Largura',
            height: 'Altura',
            financials: 'Finanças',
            balance: 'Saldo',
            transactionNumber: 'Número da transação',
            summary: 'Resumo',
            type: 'Tipo',
          },
          navigation: {
            backToTransfers: "Voltar para transferências",
            backToBanking: "De volta ao banco"
          },
          title: {
            pleaseChooseTransactionTypeToProceed: "Por favor, escolha o tipo de transação para continuar",
            specifyUsernameToSeeTransactionsHistory: "Especifique o nome de usuário para ver o histórico de transações",
            transactionsHistory: "Histórico de transações",
            specifyTransferData: "Especificar dados de transferência",
            chooseTransferMethod: "Escolha o método de transferência",
            chooseSourceAccount: "Escolha a conta de origem"
          },
          menu: {
            systemTransfer: "Transferência do sistema",
            systemTransferDescription: "Visualize e gerencie transferências para as contas do sistema",
            userTransfer: "Transferência de usuário",
            userTransferDescription: "Visualize e gerencie a transferência para contas de usuário individuais",
            viewTransactions: "ver transações",
            makeATransfer: "fazer uma transferência",
            beneficiaries: "beneficiários",
          },
          validations: {
            amountIsRequired: 'O valor é obrigatório',
            amountMustBeANumber: 'O valor deve ser um número',
            amountMustBeGreaterThan0: 'O valor deve ser maior que 0',
            amountMustNotExceed2DecimalSpaces: 'O valor não deve exceder 2 casas decimais',

          },
          demoIgaming: {
            myTransactions: 'Minhas Transações',
            myWallet: 'Minha carteira',
            depositViaCard: 'Depósito via cartão',
            depositViaGoMagicPay: 'Depósito via Gomagicpay',
            depositViaFliwp: 'Depósito via FLIWP',
          }
        }
      },
      nl: {
        translation: {
          common: {
            rowsPerPage: "Rijen per pagina",
            financials: 'Financiën',
            balance: 'Evenwicht',
            createdDate: 'Gemaakte datum',
            status: 'Status',
            amount: 'Hoeveelheid',
            description: 'Omschrijving',
            actions: 'Acties',
            transactionNumber: 'Transactie nummer',
            summary: 'Overzicht',
            type: 'Soort',
            currency: 'Munteenheid',
            submit: 'Verzenden',
          },
          validations: {
            amountIsRequired: 'Bedrag is vereist',
            amountMustBeANumber: 'Bedrag moet een getal zijn',
            amountMustBeGreaterThan0: 'Bedrag moet groter zijn dan 0',
            amountMustNotExceed2DecimalSpaces: 'Het bedrag mag niet groter zijn dan 2 decimalen',
          },
          demoIgaming: {
            myTransactions: 'Mijn transacties',
            myWallet: 'Mijn portemonnee',
            depositViaCard: 'Storting via kaart',
            depositViaGoMagicPay: 'Storten via Gomagicpay',
            depositViaFliwp: 'Storten via FLIWP',
          }
        }

      },
      de: {
        translation: {
          common: {
            rowsPerPage: "Zeilen pro Seite",
            financials: 'Finanzen',
            balance: 'Gleichgewicht',
            createdDate: 'Erstellungsdatum',
            status: 'Status',
            amount: 'Betrag',
            description: 'Beschreibung',
            actions: 'Aktionen',
            transactionNumber: 'Transaktionsnummer',
            summary: 'Zusammenfassung',
            type: 'Art',
            currency: 'Währung',
            submit: 'Senden'
          },
          validations: {
            amountIsRequired: 'Betrag ist erforderlich',
            amountMustBeANumber: 'Der Betrag muss eine Zahl sein',
            amountMustBeGreaterThan0: 'Betrag muss größer als 0 sein',
            amountMustNotExceed2DecimalSpaces: 'Der Betrag darf 2 Dezimalstellen nicht überschreiten',
          },
          demoIgaming: {
            myTransactions: 'Meine Transaktionen',
            myWallet: 'Mein Geldbeutel',
            depositViaCard: 'Einzahlung per Karte',
            depositViaGoMagicPay: 'Einzahlung über Gomagicpay',
            depositViaFliwp: 'Einzahlung über FLIWP',
          }
        }
      },
      fr: {
        translation: {
          common: {
            rowsPerPage: "Lignes par page",
            financials: 'Finances',
            balance: 'Solde',
            createdDate: 'Date de création',
            status: 'Statut',
            amount: 'Somme',
            description: 'Description',
            actions: 'Actions',
            transactionNumber: 'Numéro de transaction',
            summary: 'Sommaire',
            type: 'Type',
            currency: 'Devise',
            submit: 'Soumettre'
          },
          validations: {
            amountIsRequired: 'Le montant est requis',
            amountMustBeANumber: 'Le montant doit être un nombre',
            amountMustBeGreaterThan0: 'Le montant doit être supérieur à 0',
            amountMustNotExceed2DecimalSpaces: 'Le montant ne doit pas dépasser 2 décimales',
          },
          demoIgaming: {
            myTransactions: 'Mes opérations',
            myWallet: 'Mon portefeuille',
            depositViaCard: 'Dépôt par carte',
            depositViaGoMagicPay: 'Dépôt via Gomagicpay',
            depositViaFliwp: 'Dépôt via FLIWP',
          }
        }
      },
      be: {
        translation: {
          common: {
            rowsPerPage: "Rijen per pagina",
            financials: 'Financiën',
            balance: 'Evenwicht',
            createdDate: 'Gemaakte datum',
            status: 'Status',
            amount: 'Hoeveelheid',
            description: 'Omschrijving',
            actions: 'Acties',
            transactionNumber: 'Transactie nummer',
            summary: 'Overzicht',
            type: 'Soort',
            currency: 'Munteenheid',
            submit: 'Verzenden',
          },
          validations: {
            amountIsRequired: 'Bedrag is vereist',
            amountMustBeANumber: 'Bedrag moet een getal zijn',
            amountMustBeGreaterThan0: 'Bedrag moet groter zijn dan 0',
            amountMustNotExceed2DecimalSpaces: 'Het bedrag mag niet groter zijn dan 2 decimalen',
          },
          demoIgaming: {
            myTransactions: 'Mijn transacties',
            myWallet: 'Mijn portemonnee',
            depositViaCard: 'Storting via kaart',
            depositViaGoMagicPay: 'Storten via Gomagicpay',
            depositViaFliwp: 'Storten via FLIWP',
          }
        }
      }
    }
  });

export default i18n;