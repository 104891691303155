import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';

import { ColorInputField } from 'src/components/forms/ColorInputField';
import { FileInputField } from 'src/components/forms/FileInputField';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import { ApiAccountHolderResponse } from 'src/models/accountHolders';
import {
  ApiAccountHolderTemplate,
  ApiAccountHolderTemplateUpdateRequest,
  useAddAccountHolderTemplateMutation,
  useUpdateAccountHolderTemplateMutation,
} from 'src/models/cms';
import {
  accountHolderTemplateFormValidationSchema,
  accountHolderTemplateInitialValues,
  accountHolderTemplateToAccountHolderTemplateFormValues,
} from '../utils';
interface FormProps {
  onClose?: () => void;
  accountHolder?: ApiAccountHolderResponse;
  accountHolderIdForHeader: string;
  accountHolderTemplate?: ApiAccountHolderTemplate;
}
export const AccountHolderTemplateForm: FC<FormProps> = ({
  onClose,
  accountHolder,
  accountHolderIdForHeader,
  accountHolderTemplate,
}) => {
  const [createAccountHolderTemplate] = useAddAccountHolderTemplateMutation();
  const [updateAccountHolderTemplate] =
    useUpdateAccountHolderTemplateMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  const [logoFile, setLogoFile] = React.useState<File>(null);

  const blobUrlToFile = (blobUrl: string): Promise<File> =>
    new Promise((resolve) => {
      fetch(blobUrl).then((res) => {
        res.blob().then((blob) => {
          const link = new URL(blobUrl);
          const pieces = link.pathname.split('/');
          const fileName = pieces[pieces.length - 1];
          // or create a utility function to parse from URL
          const file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        });
      });
    });

  useEffect(() => {
    const fetchData = async () => {
      const response = await blobUrlToFile(accountHolderTemplate.logoUrl);
      setLogoFile(response);
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [accountHolderTemplate]);

  return (
    <Formik<ApiAccountHolderTemplateUpdateRequest>
      innerRef={formikRef}
      initialValues={{
        ...accountHolderTemplateInitialValues(
          accountHolder.id,
          accountHolderIdForHeader,
        ),
        ...(accountHolderTemplate
          ? accountHolderTemplateToAccountHolderTemplateFormValues(
            accountHolderTemplate,
          )
          : {}),
      }}
      validationSchema={accountHolderTemplateFormValidationSchema}
      onSubmit={async (values): Promise<void> => {
        try {
          if (accountHolderTemplate) {
            const updateAccountHolderTemplateResponse =
              await updateAccountHolderTemplate({
                ...values,
                ...(values?.logo ? { logo: values.logo } : { logo: logoFile }),
              }).unwrap();
            if (updateAccountHolderTemplateResponse?.status) {
              toast.success(`Client template updated successfully.`);
              onClose();
            }
          } else {
            const createAccountHolderTemplateResponse =
              await createAccountHolderTemplate(values).unwrap();

            if (createAccountHolderTemplateResponse?.status) {
              toast.success(`Client template created successfully.`);
              onClose();
            }
          }
        } catch (err) {
          // toast.error('An error has occured');
          console.log(err);
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {accountHolderTemplate ? 'Edit' : 'Create'} Client Template
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px', pb: '100px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <FileInputField
                label={'Client Logo'}
                fileFieldName={'logo'}
                withPreview={true}
                defaultFileUrl={accountHolderTemplate?.logoUrl}
                defaultFileName="logo"
              />
              <FormikTextField name="displayName" label="Display Name" margin='normal' />
              <ColorInputField
                label={'Theme color'}
                fieldName={'themeColourHex'}
              />

              <ColorInputField
                label={'Checkout Foreground color'}
                fieldName={'checkoutForegroundColourHex'}
              />

              <ColorInputField
                label={'Checkout Background color'}
                fieldName={'checkoutBackgroundColourHex'}
              />

              <ColorInputField
                label={'Checkout Text color'}
                fieldName={'checkoutTextColourHex'}
              />

              <ColorInputField
                label={'Checkout Button color'}
                fieldName={'checkoutButtonColourHex'}
              />

              <FormControlLabel
                control={
                  <Field
                    label="Enforce KYC"
                    name="kycCheckEnabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('kycCheckEnabled', checked);
                    }}
                    checked={values.kycCheckEnabled ? true : false}
                  />
                }
                label="Enforce KYC"
              />

              <FormikTextField
                multiline
                name="checkoutBannerSource"
                label="Checkout Banner Source"
                margin='normal'
              />

              <FormikTextField
                multiline
                name="checkoutBackgroundSource"
                label="Checkout Background Source"
                margin='normal'
              />

              <FormikTextField
                multiline
                name="checkoutFontReference"
                label="Checkout Font Reference"
                margin='normal'
              />
              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  // loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
