
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import {
    ApiBeneficiary, ApiBeneficiaryCreate, ApiBeneficiaryUpdate,
    BeneficiaryListRequest, BeneficiaryListResponse,
} from './types-api';

export const beneficiariesApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        beneficiariesList: builder.query<BeneficiaryListResponse, BeneficiaryListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/beneficiaries',
                }
            },
            providesTags: ['Beneficiary']
        }),
        beneficiary: builder.query<ApiBeneficiary, { id: string, accountHolderIdForHeader: string }>({
            query: ({ id, accountHolderIdForHeader }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/beneficiaries/${id}`,

            }),
            providesTags: ['Beneficiary']
        }),

        addBeneficiary: builder.mutation<ApiBeneficiary, ApiBeneficiaryCreate>({
            query: ({ accountHolderIdForHeader, ...beneficiary }) => ({
                url: 'gateway/beneficiaries',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: beneficiary
            }),
            invalidatesTags: ['Beneficiary']
        }),
        updateBeneficiary: builder.mutation<ApiBeneficiary, ApiBeneficiaryUpdate>({
            query: ({ id, accountHolderIdForHeader, ...rest }) => ({
                url: `gateway/beneficiaries/${id}`,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Beneficiary']
        }),
        deleteBeneficiary: builder.mutation<void, { id: string, accountHolderId: string }>({
            query: ({ id, accountHolderId }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                url: `gateway/beneficiaries/${id}`,
            }),
            invalidatesTags: ['Beneficiary']
        }),


        beneficiaries: builder.query<ApiBeneficiary[], { accountHolderId: string }>({
            query: ({ accountHolderId }) => {
                const parameters = removeEmpty({
                    enabled: true,
                    accountHolderId
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderId,
                    },
                    method: 'GET',
                    params: parameters,
                    url: `gateway/beneficiaries/list`,
                }
            },
            providesTags: ['Beneficiary']
        }),


    })
})

export const {
    useBeneficiariesListQuery,
    useBeneficiaryQuery,
    useAddBeneficiaryMutation,
    useUpdateBeneficiaryMutation,
    useDeleteBeneficiaryMutation,
    useBeneficiariesQuery,
} = beneficiariesApi;