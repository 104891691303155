import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { accountHolderTypes } from './constants';

export interface AccountHolderTypeFieldProps {
  disabled: boolean;
}
export const AccountHolderTypeField: FC<
  AccountHolderTypeFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel
          htmlFor={`accountHolderType`}
          error={Boolean(touched[field.name] && errors[field.name])}
        >
          {t('common.accountHolderType')}
        </InputLabel>
        <Field
          component={Select}
          name={`accountHolderType`}
          fullWidth
          label={t('common.accountHolderType')}
          value={values[field.name]}
          error={Boolean(touched[field.name] && errors[field.name])}
          onBlur={handleBlur(`accountHolderType`)}
          onChange={handleChange(`accountHolderType`)}
          disabled={disabled}
        >
          {accountHolderTypes.map((i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Field>
        <FormHelperText error>{t(errors[field.name] as string)}</FormHelperText>
      </FormControl>
    </>
  );
};
