import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import { ApiFeeConfig, FeeConfigListFilter, FeeConfigListSort, useAssignedFeesListQuery } from 'src/models/assignedFees';
import { SortOrder } from 'src/models/types';
import { usePagination } from '../../hooks/usePagination';
import { AssignedFeesTable } from './AssignedFeesTable';

export const AssignedFeeList = ({
  filter,
  accountHolderId,
}: {
  filter: FeeConfigListFilter;
  accountHolderId?: string;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: FeeConfigListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as FeeConfigListSort, direction: 'desc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  const [skipFetch, setSkipFetch] = useState<boolean>(true);

  useEffect(() => {
    setSkipFetch(true);
    resetPagination();
    setSkipFetch(false);
  }, [filter]);

  const { data } = useAssignedFeesListQuery(
    {
      ...filter,
      accountHolderIdForHeader: accountHolderId,
      page,
      size: limit,
      sort: [`${sort.orderBy},${sort.direction}` as FeeConfigListSort],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipFetch,
    },
  );

  const [feeConfigs, setFeeConfigs] = useState<ApiFeeConfig[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    setFeeConfigs(data?.content || []);
    setTotalElements(data?.totalElements || 0);
  }, [data]);

  return (
    <Card>
      <Scrollbar>
        <AssignedFeesTable
          feeConfigs={feeConfigs}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
          accountHolderIdForHeader={accountHolderId}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
