import 'moment-timezone';

import {
  AccountBalance,
  AccountBox,
  BarChart,
  Computer,
  CreditCard,
  FolderShared,
  Handshake,
  Key,
  LocalAtm,
  Lock,
  People,
  PointOfSale,
  Print,
  SyncAlt,
  ThreeP,
  Tune,
  WifiCalling,
} from '@mui/icons-material';
import { Box, Drawer, Theme, Tooltip, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import React, { useState } from 'react';
import { FC, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { LogoText } from 'src/components/logo/LogoText';
import NavSection from 'src/components/NavSection';
import Scrollbar from 'src/components/Scrollbar';
import useMounted from 'src/hooks/useMounted';
import { AccountHolderPermission } from 'src/models/accountHolder';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { SIDEBAR_WIDTH } from './utils/config';
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'Reports',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <BarChart fontSize="small" />,
        permissions: [],
      },
    ],
  },
  {
    title: 'Financials',
    items: [
      {
        title: 'Accounts',
        path: '/accounts',
        icon: <AccountBox fontSize="small" />,
        permissions: [{ permission: 'GET_ACCOUNT' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Beneficiaries',
        path: '/beneficiaries',
        icon: <ThreeP fontSize="small" />,
        permissions: [{ permission: 'GET_BENEFICIARY' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Transfer',
        path: `/transfer/${new Date()}`,
        icon: <LocalAtm fontSize="small" />,
        permissions: [{ permission: 'PERFORM_TRANSFER' as AccountHolderPermission, shouldHave: true }, { permission: 'BLOCK_TRANSFER' as AccountHolderPermission, shouldHave: false }],
      },
      {
        title: 'Transactions',
        path: '/transactions',
        icon: <AccountBalance fontSize="small" />,
        permissions: [{ permission: 'GET_TRANSACTION' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Adjustment',
        path: `/adjustment/${new Date()}`,
        icon: <Tune fontSize="small" />,
        permissions: [{ permission: 'PERFORM_ADJUSTMENT' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Telexes',
        path: '/telexes',
        icon: <Print fontSize="small" />,
        permissions: [{ permission: 'GET_TELEX' as AccountHolderPermission, shouldHave: true }],
      },
    ],
  },
  {
    title: 'User Management',
    items: [
      {
        title: 'Clients',
        path: '/account-holders',
        icon: <FolderShared fontSize="small" />,
        permissions: [{ permission: 'MANAGE_ACCOUNT_HOLDER' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Users',
        path: '/users',
        icon: <People fontSize="small" />,
        permissions: [{ permission: 'GET_USER' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Api Keys',
        path: '/api-keys',
        icon: <Key fontSize="small" />,
        permissions: [{ permission: 'GET_API_KEY' as AccountHolderPermission, shouldHave: true }],
      },
    ],
  },
  {
    title: 'Administration',
    items: [
      {
        title: 'Transfer Channels',
        path: '/transfer-channels',
        icon: <SyncAlt fontSize="small" />,
        permissions: [{ permission: 'GET_TRANSFER_CHANNEL' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Onboarding',
        path: '/onboarding',
        icon: <Handshake fontSize="small" />,
        permissions: [{ permission: 'ADMIN' as AccountHolderPermission, shouldHave: true }],
      },
    ],
  },
  {
    title: 'Configuration',
    items: [
      {
        title: 'Banking',
        path: '/configuration/banking',
        icon: <Computer fontSize="small" />,
        permissions: [{ permission: 'GET_BANKING_CONFIGURATION' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Acquiring',
        path: '/configuration/acquiring',
        icon: <CreditCard fontSize="small" />,
        permissions: [{ permission: 'GET_ACQUIRING_CONFIGURATION' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Telex',
        path: '/configuration/telex',
        icon: <WifiCalling fontSize="small" />,
        permissions: [{ permission: 'GET_TELEX_CONFIGURATION' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Fees',
        path: '/configuration/fees',
        icon: <PointOfSale fontSize="small" />,
        permissions: [{ permission: 'GET_FEE_CONFIGURATION' as AccountHolderPermission, shouldHave: true }],
      },
    ],
  },
  {
    title: 'Restrictions',
    items: [
      {
        title: 'Country',
        path: '/restriction/country',
        icon: <Lock fontSize="small" />,
        permissions: [{ permission: 'GET_COUNTRY_RESTRICTION' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Currency',
        path: '/restriction/currency',
        icon: <Lock fontSize="small" />,
        permissions: [{ permission: 'GET_CURRENCY_RESTRICTION' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Card Bin',
        path: '/restriction/card-bin',
        icon: <Lock fontSize="small" />,
        permissions: [{ permission: 'GET_CARDBIN_RESTRICTION' as AccountHolderPermission, shouldHave: true }],
      },
      {
        title: 'Account Currency',
        path: '/restriction/account-currency',
        icon: <Lock fontSize="small" />,
        permissions: [{
          permission:
            'GET_ACCOUNT_CURRENCY_RESTRICTION' as AccountHolderPermission, shouldHave: true
        }],
      },
      {
        title: 'Pending',
        path: '/restriction/pending',
        icon: <Lock fontSize="small" />,
        permissions: [{ permission: 'GET_RESTRICTION' as AccountHolderPermission, shouldHave: true }],
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { hasPermission } = usePermissions();
  const mounted = useMounted();
  // const associations = useSelector(selectAssociations);
  // const accountHolderId = useSelector(selectAccountHolderId);
  // const [parentAccountHolderId, setParentAccountHolderId] =
  //   React.useState<string>(null);
  // const parentBankData = useGetBankProfileQuery(
  //   { accountHolderId: parentAccountHolderId },
  //   { skip: parentAccountHolderId == null, refetchOnMountOrArgChange: true },
  // );
  // const bankData = useGetBankProfileQuery(
  //   { accountHolderId: accountHolderId },
  //   { skip: accountHolderId == null, refetchOnMountOrArgChange: true },
  // );
  // const dispatch = useDispatch();
  const [myInterval, setMyInterval] =
    React.useState<ReturnType<typeof setInterval>>(null);

  // useEffect(() => {
  //   if (!parentBankData.isFetching && parentBankData.data !== undefined)
  //     dispatch(setBank({ bank: parentBankData.data }));
  // }, [parentBankData]);

  // useEffect(() => {
  //   if (
  //     !parentBankData.isFetching &&
  //     parentBankData.data == undefined &&
  //     !bankData.isFetching &&
  //     bankData.data !== undefined
  //   )
  //     dispatch(setBank({ bank: bankData.data }));
  // }, [bankData, parentBankData]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const [localdateState, setLocalDateState] = useState<string>('');
  const [cetdateState, setCETDateState] = useState<string>('');
  useEffect(() => {
    // setParentAccountHolderId(
    //   associations.find((x) => x.id == accountHolderId)?.parentAccountHolderId,
    // );
    const interval = setInterval(() => {
      if (mounted.current) {
        setCETDateState(
          moment().tz('Europe/Berlin').format('DD-MM-YYYY HH:mm:ss'),
        );
        setLocalDateState(moment().format('DD-MM-YYYY HH:mm:ss'));
      }
    }, 1000);
    setMyInterval(interval);
    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 2,
        }}
      >
        <RouterLink to="/">
          <LogoText />
        </RouterLink>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tooltip title={`CET ${cetdateState}`}>
          <Typography
            sx={{
              color: '#7B8B9A',
              fontSize: '0.75rem',
              lineHeight: 2.5,
              fontWeight: 600,
              textTransform: 'uppercase',
            }}
          >
            {/* {moment.tz.guess()} */}
            {localdateState}
          </Typography>
        </Tooltip>
      </Box>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <React.Fragment key={section.title}>
              {section.items
                .map((i) => i.permissions)
                .some((ps) => ps.every(p => hasPermission(p.permission) === p.shouldHave) || ps.length == 0) && (
                  <NavSection
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 3,
                      },
                    }}
                    {...section}
                  />
                )}
            </React.Fragment>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: '#F3F7FE',
            width: SIDEBAR_WIDTH,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: '#F3F7FE',
          width: SIDEBAR_WIDTH,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
