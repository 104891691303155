import * as Yup from "yup";

import { dateOfBirthValidationSchema, nationalityValidationSchema } from "../..";
import { addressValidationSchema } from "../Address/validations";
import { phoneValidationSchema } from "../Phone/validations";

// const countries = getCountries();

// const countryCodeSchema = Yup.object().nullable().shape({
//   callingCode: Yup.string().required('validations.mustSelectACountry'),
//   code: Yup.mixed<CountryCode>().oneOf(countries, 'validations.mustSelectACountry'),
//   name: Yup.string().required('validations.mustSelectACountry'),
// })
//   .test(
//     'null-check',
//     'validations.mustSelectACountry',
//     country => country != null
//   );


export const userDetailsInfoValidationSchema = () =>
  Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    dateOfBirth: dateOfBirthValidationSchema(),
    nationality: nationalityValidationSchema(),
    idPassportNo: Yup.string()
      .max(255)
      .required('Passport/Id Number is required'),
    phone: phoneValidationSchema(),
    address: addressValidationSchema(),
  });


