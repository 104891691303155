import { NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import { Formik } from 'formik';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { mapApiAccountToCreateUpdateAccountFormValues, useAccountQuery, useDeleteAccountMutation } from 'src/models/accounts';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { Nullable } from 'src/utils/types';
import { AccountStatusLabel } from '../Accounts/AccountStatusLabel';
import { AccountForm } from '../Accounts/forms/AccountForm';
import { CreateUpdateAccountFormValues } from '../Accounts/forms/types-dto';
import { AccountEntryList } from './AccountEntryList';
import { Attributes } from './Attributes';
// import { AccountEntriesExportForm } from './forms/AccountEntriesExportForm';
import { AccountReportDownloadForm } from './forms/AccountReportDownloadForm';
interface DetailRow {
  name: string;
  value: any;
}

interface AccountViewProps {
  id: string;
  accountHolderId?: string;
  isGlobal: boolean;
}

export const AccountView: FC<AccountViewProps> = ({
  id,
  accountHolderId,
  isGlobal,
}) => {
  const [editAccountOpened, setEditAccountOpened] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteAccount] = useDeleteAccountMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: apiAccount } = useAccountQuery(
    {
      id,
      accountHolderIdForHeader: accountHolderId,
    },
    { refetchOnMountOrArgChange: true },
  );
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const formikRef = useRef(null);
  useTotp(formikRef);


  const [accountInfo, setAccountInfo] =
    React.useState<Nullable<CreateUpdateAccountFormValues>>(null);

  useEffect(() => {
    (async () => {
      if (apiAccount != null) {
        const onboardingInfo = await mapApiAccountToCreateUpdateAccountFormValues(apiAccount, accountHolderId);
        setAccountInfo(onboardingInfo);
      }
    })()

  }, [apiAccount, accountHolderId])


  const accountInfoData: DetailRow[] = useMemo(
    () =>
      apiAccount
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiAccount.id}
              </Typography>
            ),
          },
          {
            name: 'Friendly Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiAccount.friendlyName}
              </Typography>
            ),
          },
          {
            name: 'Account Type',
            value: <AccountStatusLabel status={apiAccount.accountType} />,
          },
          {
            name: 'Currency Code',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiAccount.currencyCode}
              </Typography>
            ),
          },
          {
            name: 'Account Number',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiAccount.accountNumber}
              </Typography>
            ),
          },
          {
            name: 'Account Status',
            value: <AccountStatusLabel status={apiAccount.accountStatus} />,
          },
          {
            name: 'Actual Balance',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiAccount.actualBalance.displayText}
              </Typography>
            ),
          },
          {
            name: 'Available Balance',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiAccount.availableBalance.displayText}
              </Typography>
            ),
          },
          {
            name: 'Client Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiAccount.accountHolderId}
              </Typography>
            ),
          },
          // {
          //   name: 'Old Account Number',
          //   value: (
          //     <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          //       {apiAccount.oldAccountNumber}
          //     </Typography>
          //   ),
          // },
          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(apiAccount.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
        ]
        : [],
    [accountHolderId, apiAccount],
  );

  const breadcrumbs = [
    <Link key="1" color={blueGrey[600]} component={RouterLink} to={'./..'}>
      <Typography variant="overline">ACCOUNTS MANAGEMENT</Typography>
    </Link>,
    <Link
      key="1"
      color={blueGrey[500]}
      component={RouterLink}
      to={`./../${id}`}
    >
      <Typography variant="overline">{id}</Typography>
    </Link>,
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }),
          ]}
        >
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sx={[
              {
                width: '100%',
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            <AccountReportDownloadForm
              accountId={apiAccount?.id}
              accountHolderId={accountHolderId}
            />

            <PermissionGuard permission="UPDATE_ACCOUNT">
              <Button
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setEditAccountOpened(true)}
                disabled={!isUserActive}
              >
                Edit Account
              </Button>
            </PermissionGuard>

            {apiAccount?.accountStatus != 'DELETED' && (
              <PermissionGuard permission="DELETE_ACCOUNT">
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    id: null,
                  }}
                  onSubmit={async (values): Promise<void> => {
                    try {
                      const result = await deleteAccount({
                        id: values.id,
                        accountHolderId: accountHolderId,
                      }).unwrap();
                      if (result == null) {
                        toast.success('Account removed successfully');
                        navigate(`/accounts`);
                      }
                    } catch (err) {
                      //
                    }
                  }}
                >
                  {({ setFieldValue }): JSX.Element => (
                    <Button
                      sx={[
                        {
                          whiteSpace: 'nowrap',
                        },
                        (theme) => ({
                          [theme.breakpoints.down('sm')]: {
                            minWidth: '100%',
                          },
                          [theme.breakpoints.up('sm')]: {
                            minWidth: 'auto',
                          },
                        }),
                      ]}
                      variant="contained"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: 'Are you sure to remove this Account?',
                          subTitle: 'This operation cannot be undone',
                          onConfirm: async () => {
                            setFieldValue('id', apiAccount.id);
                            formikRef?.current?.submitForm();
                          },
                        });
                      }}
                      disabled={!isUserActive}
                    >
                      Close Account
                    </Button>
                  )}
                </Formik>
              </PermissionGuard>
            )}
          </Grid>
        </Grid>

        {accountInfoData && apiAccount ? (
          <>
            <Grid item xs={12} sx={{ mb: '35px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      disableTypography
                      title={
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="h6">Account info</Typography>
                        </Box>
                      }
                    />
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={2}
                      sx={{ p: '16px' }}
                    >
                      {accountInfoData.map(({ name, value }) => (
                        <Grid item md={6} sm={12} xs={12} key={name}>
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {name}
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              {value}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>



                  <Grid
                    alignItems="center"
                    container
                    item
                    justifyContent="space-between"
                    sx={{ marginBottom: '25px', marginTop: '25px' }}
                    xs={12}
                  >
                    <Grid item justifySelf="left">
                      <Typography color="textPrimary" variant="h5">
                        Account Entries
                      </Typography>
                    </Grid>

                    <Grid item justifySelf="right">
                      {/* <AccountEntriesExportForm
                        accountHolderId={accountHolderId}
                        accountId={apiAccount?.id}
                      /> */}
                    </Grid>
                  </Grid>

                  <AccountEntryList
                    id={apiAccount.id}
                    accountHolderId={accountHolderId}
                  />
                </Grid>
              </Grid>
              <PermissionGuard permission="CREATE_ACCOUNT_CONFIGURATION">
                <Attributes
                  accountHolderId={accountHolderId}
                  accountId={apiAccount.id}
                />
              </PermissionGuard>
            </Grid>
          </>
        ) : null}
      </Grid>
      {apiAccount && (
        <>
          <PermissionGuard permission="UPDATE_ACCOUNT">
            <Drawer
              anchor="right"
              open={editAccountOpened == true}
              onClose={() => setEditAccountOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <AccountForm
                isGlobal={isGlobal}
                accountHolderId={accountHolderId}
                account={accountInfo}
                onClose={() => setEditAccountOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
        </>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default AccountView;
