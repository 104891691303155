import { serialize } from 'object-to-formdata';

import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { ApiKycAddressesRequest, ApiKycDetailRequest, ApiKycIdentificationsRequest, ApiKycInitRequest, ApiKycSelfiesRequest, ApiKycVerification, ApiKycVerificationLog, ApiKycVerificationUpdate, KycVerificationListRequest, PageApiKycVerificationResponse } from './types';
import { kycVerificationValuesToFormDataBody } from './utils';
export const kycVerificationsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        kycVerifications: builder.query<PageApiKycVerificationResponse, KycVerificationListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {

                const parameters = removeEmpty({
                    ...params
                });

                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'auth/kyc-verifications',
                }
            },
            providesTags: ['KYCManagementAdmin']
        }),
        kycVerification: builder.query<ApiKycVerification, { accountHolderIdForHeader: string; kycVerificationId: string; }>({
            query: ({ accountHolderIdForHeader, kycVerificationId }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'GET',
                url: `auth/kyc-verifications/${kycVerificationId}`
            }),
            providesTags: ['KYCManagementAdmin']
        }),
        kycVerificationLogs: builder.query<ApiKycVerificationLog[], { accountHolderIdForHeader: string; kycVerificationId: string; }>({
            query: ({ accountHolderIdForHeader, kycVerificationId }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'GET',
                url: `auth/kyc-verifications/${kycVerificationId}/logs`
            }),
            providesTags: ['KYCManagementAdmin']
        }),
        updateKycVerification: builder.mutation<ApiKycVerification, ApiKycVerificationUpdate>({
            query: ({ kycVerificationId, accountHolderIdForHeader, ...body }) => ({
                body,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                url: `auth/kyc-verifications/${kycVerificationId}`,

            }),
            invalidatesTags: ['KYCManagementAdmin']
        }),
        triggerKycVerification: builder.mutation<ApiKycVerification, ApiKycInitRequest>({
            query: ({ accountHolderIdForHeader, ...body }) => ({
                body,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                url: `auth/kyc-verifications`,

            }),
            invalidatesTags: ['KYCManagementAdmin', 'AccountHolders']
        }),

        kycVerificationPending: builder.query<ApiKycVerification, { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => {

                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: {
                        kycStatus: 'INITIAL'
                    },
                    url: 'auth/kyc-verifications',
                }
            },
            transformResponse: (response: PageApiKycVerificationResponse) => {
                return response.content?.[0];

            },
            providesTags: ['KYCManagementUser']
        }),


        updateUserDetailsKycVerification: builder.mutation<ApiKycVerification, ApiKycDetailRequest>({
            query: ({ accountHolderIdForHeader, kycVerificationId, ...body }) => ({
                body,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                url: `auth/kyc-verifications/${kycVerificationId}/details`,
            }),
            invalidatesTags: ['KYCManagementUser']
        }),

        updateUserIdentificationsKycVerification: builder.mutation<ApiKycVerification, ApiKycIdentificationsRequest>({
            query: ({ accountHolderIdForHeader, kycVerificationId, ...body }) => {
                const formData = kycVerificationValuesToFormDataBody(body)
                return ({
                    body: formData,
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'PUT',
                    url: `auth/kyc-verifications/${kycVerificationId}/documents/identifications`,
                })
            },
            invalidatesTags: ['KYCManagementUser']
        }),

        updateUserAddressesKycVerification: builder.mutation<ApiKycVerification, ApiKycAddressesRequest>({
            query: ({ accountHolderIdForHeader, kycVerificationId, ...body }) => {
                // const formData = kycVerificationValuesToFormDataBody(body)
                const formData = serialize(body)

                return ({
                    body: formData,
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'PUT',
                    url: `auth/kyc-verifications/${kycVerificationId}/documents/addresses`,
                })
            },
            invalidatesTags: ['KYCManagementUser']
        }),

        updateUserSelfiesKycVerification: builder.mutation<ApiKycVerification, ApiKycSelfiesRequest>({
            query: ({ accountHolderIdForHeader, kycVerificationId, ...body }) => {
                const formData = serialize(body)
                return ({
                    body: formData,
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'PUT',
                    url: `auth/kyc-verifications/${kycVerificationId}/documents/selfies`,
                })
            },
            invalidatesTags: ['KYCManagementUser']
        }),

        submitUserKycVerification: builder.mutation<ApiKycVerification, { accountHolderIdForHeader: string, kycVerificationId: string }>({
            query: ({ accountHolderIdForHeader, kycVerificationId }) => {
                return ({
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'POST',
                    url: `auth/kyc-verifications/${kycVerificationId}`,
                })
            },
            invalidatesTags: ['KYCManagementUser']
        }),



        kycVerificationImage: builder.query<string, { accountHolderIdForHeader: string; kycVerificationId: string; fileId: string }>({
            query: ({ accountHolderIdForHeader, kycVerificationId, fileId }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'GET',
                url: `auth/kyc-verifications/${kycVerificationId}/files/${fileId}`
            }),
            transformResponse: (response: {
                fileId: string;
                fileUrl: string;
            }, meta, arg) => {
                return response.fileUrl
            },
            providesTags: ['KYCManagementUser']
        }),

    })
})

export const {
    // Admin
    useKycVerificationsQuery,
    useKycVerificationQuery,
    useKycVerificationLogsQuery,
    useUpdateKycVerificationMutation,
    useTriggerKycVerificationMutation,
    // User
    // useAddUserKycVerificationMutation,
    useKycVerificationPendingQuery,
    useUpdateUserDetailsKycVerificationMutation,
    useUpdateUserIdentificationsKycVerificationMutation,
    useUpdateUserAddressesKycVerificationMutation,
    useUpdateUserSelfiesKycVerificationMutation,
    useSubmitUserKycVerificationMutation,
    useKycVerificationImageQuery,
    useLazyKycVerificationImageQuery

} = kycVerificationsApi;