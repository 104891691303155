import { Box, Card, CardContent, Divider, Link } from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import AuthTemplate from 'src/components/AuthTemplate';
import { useResetCredentials } from 'src/models/auth/hooks/useResetCredentials';
import { RegisterWizard } from './RegisterWizard';

const InvitationRegisterScreen: FC = () => {
  useResetCredentials({ immediately: true });
  const { search } = useLocation();
  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <AuthTemplate>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ typography: 'h4', mb: '32px' }}>Register</Box>
            <RegisterWizard />
            <Divider sx={{ my: 3 }} />
            <Box sx={{ typography: 'body2', color: 'text.secondary' }}>
              Already have an account?{' '}
              <Link
                color="primary"
                component={RouterLink}
                to={`/auth/login${search}`}
                variant="body2"
              >
                Log in
              </Link>
            </Box>
          </CardContent>
        </Card>
      </AuthTemplate>
    </>
  );
};

export default InvitationRegisterScreen;
