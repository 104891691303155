import { FilterAlt } from '@mui/icons-material';
import { Box, Button, Drawer, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { AccountHolderFilterForm } from '../forms/AccountHolderFilterForm';
import { CreateAccountHolderWizard } from '../forms/CreateAccountHolderWizard';
import { SubAccountHolderList } from './SubAccountHolderList';
import { SubAccountHoldersFilterForm } from './SubAccountHoldersFilterForm';

const SubAccountHolders: FC<{
  accountHolderId: string;
  subAccountHolderId: string;
}> = ({ accountHolderId, subAccountHolderId }) => {
  const [filter, setFilter] = useState<AccountHolderFilterForm>();
  const [filterOpen, setFilterOpen] = useState(false);
  const [addAccountHolderOpened, setAddAccountHolderOpened] =
    useState<boolean>(false);
  useEffect(() => {
    setFilterOpen(false);
  }, [filter]);

  useEffect(() => {
    setFilter({
      type: [],
      status: [],
      descriptor: '',
      id: subAccountHolderId,
      ownerEmail: '',
      includeSubAccountHolders: true,
      parentsOnly: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Sub Clients</title>
      </Helmet>
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',
            marginTop: '25px',
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }),
        ]}
      >
        <Typography color="textPrimary" variant="h5">
          Sub Clients
        </Typography>

        <Box
          sx={[
            {
              display: 'flex',
              gap: 1,
              marginBottom: '10px',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '10px',
              },
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            }),
          ]}
        >
          <PermissionGuard permission="CREATE_ACCOUNT_HOLDER">
            <Button
              sx={[
                {
                  whiteSpace: 'nowrap',
                },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    minWidth: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    minWidth: 'auto',
                  },
                }),
              ]}
              variant="contained"
              onClick={() => setAddAccountHolderOpened(true)}
            >
              Create Sub Client
            </Button>
          </PermissionGuard>
          <Box
            sx={[
              { display: 'flex', justifyContent: 'flex-end' },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                  width: 'auto',
                },
              }),
            ]}
          >
            <FilterAlt
              color="primary"
              fontSize="large"
              sx={{ marginLeft: '10px' }}
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            />
          </Box>
        </Box>
      </Box>

      <Drawer
        anchor="top"
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ padding: '20px' }}
          xs={12}
        >
          <Grid item xs={12}>
            <SubAccountHoldersFilterForm onFilterApply={setFilter} />
          </Grid>
        </Grid>
      </Drawer>
      <PermissionGuard permission="CREATE_ACCOUNT_HOLDER">
        <Drawer
          anchor="right"
          open={addAccountHolderOpened}
          onClose={() => setAddAccountHolderOpened(false)}
          PaperProps={{
            sx: [
              {
                backgroundColor: '#F3F7FE',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: `100%`,
                },
                [theme.breakpoints.up('md')]: {
                  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                },
              }),
            ],
          }}
        >
          <CreateAccountHolderWizard
            accountHolderIdOverride={subAccountHolderId}
            accountHolderIdForHeader={accountHolderId}
            //   accountHolderId={accountHolderId}
            onClose={() => setAddAccountHolderOpened(false)}
          />
        </Drawer>
      </PermissionGuard>
      <PermissionGuard permission="GET_ACCOUNT_HOLDER">
        <SubAccountHolderList
          filter={filter}
          accountHolderId={subAccountHolderId}
        />
      </PermissionGuard>
    </>
  );
};

export default SubAccountHolders;
