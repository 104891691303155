import { Box, Divider } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { LogoText } from 'src/components/logo/LogoText';
import {
  useGetBankProfileByDomainQuery,
  useGetBankProfileQuery,
} from 'src/models/cms/api';
import { setBank } from 'src/models/cms/slice';

export const Help: FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const { path } = useParams<{ path: string }>();

  const [skip, setSkip] = React.useState<boolean>(true);
  const [skipDomain, setSkipDomain] = React.useState<boolean>(true);
  const [url, setUrl] = React.useState<string>('');
  const bankData = useGetBankProfileQuery(
    { accountHolderId: searchParams.get('parentAccountHolderId') },
    {
      skip,
      refetchOnMountOrArgChange: true,
    },
  );
  const bankDataFromDomain = useGetBankProfileByDomainQuery(
    { domain: window.location.hostname },
    {
      skip: skipDomain,
      refetchOnMountOrArgChange: true,
    },
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('parentAccountHolderId') != null) setSkip(false);
    else setSkipDomain(false);
  }, []);

  useEffect(() => {
    if (!bankData.isFetching && bankData.data !== undefined) {
      if (bankData.data?.status == 'SUSPENDED') {
        navigate(`/maintenance${search}`);
      }
      dispatch(setBank({ bank: bankData.data }));
    }
  }, [bankData]);

  useEffect(() => {
    if (
      !bankDataFromDomain.isFetching &&
      bankDataFromDomain.data !== undefined
    ) {
      if (bankDataFromDomain.data?.status == 'SUSPENDED') {
        navigate(`/maintenance${search}`);
      }
      dispatch(setBank({ bank: bankDataFromDomain.data }));
    }
  }, [bankDataFromDomain]);

  useEffect(() => {
    const incoming = path == 'incoming' ? '/docs/integration-with-our-api' : '';
    const outgoing =
      path == 'outgoing' ? '/docs/required-apis-from-correspondent-bank' : '';
    setUrl(`https://middlec.readme.io${incoming}${outgoing}`);
  }, [path]);

  return (
    <>
      <Box sx={{
        display: 'flex', width: '100%', minHeight: '95vh', flexDirection: 'column'
      }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '24px 38px 24px 32px',
            // height: '100%'
          }}
        >
          {!bankData.isFetching &&
            !bankData.data &&
            !bankDataFromDomain.isFetching &&
            !bankDataFromDomain.data && <LogoText />}
          {!bankData.isFetching && bankData.data && (
            <Box
              sx={{ maxWidth: 300, height: 'auto' }}
              component="img"
              src={`${bankData.data?.logoUrl}`}
            />
          )}

          {!bankDataFromDomain.isFetching && bankDataFromDomain.data && (
            <Box
              sx={{ maxWidth: 300, height: 'auto' }}
              component="img"
              src={`${bankDataFromDomain.data?.logoUrl}`}
            />
          )}
        </Box>
        <Divider />
        <Box component='iframe' sx={{

          flexGrow: '1', border: 'none', margin: '0', padding: '0', height: '100%'
        }} width="100%" height="100%" src={url} />
      </Box>
    </>
  );
};
