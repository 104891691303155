import { Box, Container } from '@mui/material';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import TransferChannelView from './TransferChannelView';

const TransferChannelDetails: FC<{accountHolderId:string}> = ({accountHolderId}) => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Helmet>
        <title>Transfer Channel Details</title>
      </Helmet>
      <Box>
        <Container maxWidth={false}>
          <PermissionGuard permission="GET_TRANSFER_CHANNEL">
            <TransferChannelView id={id} accountHolderId={accountHolderId}/>
          </PermissionGuard>
        </Container>
      </Box>
    </>
  );
};

export default TransferChannelDetails;
