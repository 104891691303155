import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC } from 'react';

import {
  invitationStatusList,
} from '../../../models/invitations';
import { InvitationFilterForm } from './types';


interface FormProps {
  onFilterApply: (values: InvitationFilterForm) => void;
}
export const InvitationsFilterForm: FC<FormProps> = ({ onFilterApply }) => {

  const initialValues: InvitationFilterForm = {
    status: 'PENDING',
  };


  const onSubmit = (values: InvitationFilterForm) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size='small' >
                  <InputLabel id="select-invitation-status-label">
                    Invitation Status
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="select-invitation-status-label"
                    label="Invitation Status"
                    id="select-invitation-status"
                    value={values.status}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {/* {selected.map((value) => ( */}
                        <Chip key={selected} label={selected} />
                        {/* ))} */}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('status', e.target.value);
                    }}
                  >
                    {invitationStatusList.map((trStatus) => (
                      <MenuItem key={trStatus} value={trStatus}>
                        {trStatus}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>


            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
