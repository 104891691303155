import * as Yup from 'yup';

import { ApiBankingConfig } from 'src/models/configurations';
import { CreateUpdateBankingFormValues } from './types';

export const bankingFormValidationSchema =
  Yup.object({
    enabled: Yup.bool().required('Enabled is required'),
    bankName: Yup.string().required('Bank Name is required'),
    bankAddress: Yup.string().required('Bank Address is required'),
    bankAdmin: Yup.string().required('Bank Admin is required'),
  });

export const bankingToBankingFormValues = (
  banking: ApiBankingConfig, accountHolderIdForHeader: string
): CreateUpdateBankingFormValues => ({
  accountHolderIdForHeader,
  bankingConfigId: banking.id,
  enabled: banking.enabled,
  bankName: banking.bankName,
  bankAddress: banking.bankAddress,
  bankAdmin: banking.bankAdmin,
});