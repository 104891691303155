import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Scrollbar from 'src/components/Scrollbar';
import WizardTemplate from 'src/components/WizardTemplate';
import { selectLoggedInUserId } from 'src/models/auth';
import { useUserInvitationsQuery } from 'src/models/invitations';
import { PendingInvitationsTable } from './PendingInvitationsTable';

const PendingInvitations: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = useSelector(selectLoggedInUserId);
  const pendingInvitations = useUserInvitationsQuery(
    {
      userId,
      status: 'PENDING',
    },
    { refetchOnMountOrArgChange: true },
  );
  useEffect(() => {
    pendingInvitations.refetch();
  }, []);

  return (
    <>
      <Helmet>
        <title>Pending Invitations</title>
      </Helmet>
      <WizardTemplate>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline', mb: '16px' }}>
              <Box sx={{ typography: 'h4' }}>Pending Invitations</Box>
            </Box>

            {pendingInvitations.isLoading && <CircularProgress />}
            {pendingInvitations.data?.length == 0 && (
              <Box sx={{ typography: 'body1', mb: '16px' }}>
                There are no pending invitations
              </Box>
            )}
            {pendingInvitations.data?.length > 0 && (
              <Scrollbar>
                <PendingInvitationsTable
                  invitations={pendingInvitations.data}
                />
              </Scrollbar>
            )}
            <Box sx={{ typography: 'body1', mb: '24px' }}>
              <Button
                onClick={() => navigate('..')}
                variant="outlined"
                startIcon={<ArrowBack />}
              >
                Back to Clients
              </Button>
            </Box>
          </CardContent>
        </Card>
      </WizardTemplate>
    </>
  );
};

export default PendingInvitations;
