import { Box } from '@mui/material';
import React, { FC } from 'react';

import { useGetAccountHolderTemplateQuery } from 'src/models/cms';
export const AccountHolderLogo: FC<{ accountHolderId: string }> = ({ accountHolderId }) => {

    const accountHolderTemplate = useGetAccountHolderTemplateQuery(
        { accountHolderId },
        { refetchOnMountOrArgChange: true },
    );

    return accountHolderTemplate.data ? (
        <Box
            component="img"
            sx={{
                margin: 'auto',
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
                width: '100%',
                height: '80%',

            }}
            src={accountHolderTemplate.data.logoUrl}
        />
    ) : (
        <></>

    );
};
