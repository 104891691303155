import { Add, Close, Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { FastField, Field, FieldArray, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import { FormTextField } from 'src/screens/Transfer/components/forms/FormTextField';
import {
  ApiTransferChannelTemplate,
  transferChannelTemplateParameterTypeList,
  useAddTransferChannelTemplateMutation,
  useUpdateTransferChannelTemplateMutation,
} from '..';
import { transferChannelTemplateFormValidationSchema } from '../utils';
interface FormProps {
  onClose?: () => void;
  index?: number;
  transferChannelId: string;
  transferChannelTemplate?: ApiTransferChannelTemplate;
  accountHolderId: string;
}
export const TransferChannelTemplateForm: FC<FormProps> = ({
  onClose,
  index,
  transferChannelTemplate,
  accountHolderId,
  transferChannelId,
}) => {
  const [addTransferChannelTemplate] = useAddTransferChannelTemplateMutation();
  const [updateTransferChannelTemplate] =
    useUpdateTransferChannelTemplateMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik
      // <UpdateTransferChannelTemplateFormValues>
      innerRef={formikRef}
      initialValues={{
        parameterName: '',
        parameterDescription: '',
        friendlyName: '',
        required: true,
        order:
          transferChannelTemplate &&
            transferChannelTemplate.parameters.length > 0
            ? transferChannelTemplate.parameters.length
            : 0,
        channelParameterType: transferChannelTemplateParameterTypeList[0],
        allowedValues: [],
        customScript: '',
        staticValue: '',
        ...(index > -1 ? transferChannelTemplate.parameters[index] : {}),
      }}
      validationSchema={transferChannelTemplateFormValidationSchema}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting },
      ): Promise<void> => {
        try {
          if (transferChannelTemplate) {
            const updatabletransferChannelTemplate = {
              ...transferChannelTemplate,
            };

            if (index > -1) {
              updatabletransferChannelTemplate.parameters = [
                ...updatabletransferChannelTemplate.parameters.filter(
                  (p, i) => i != index,
                ),
                values,
              ];
            } else {
              updatabletransferChannelTemplate.parameters = [
                ...updatabletransferChannelTemplate.parameters,
                values,
              ];
            }
            const updateTransferChannelResponse =
              await updateTransferChannelTemplate({
                accountHolderId,
                ...updatabletransferChannelTemplate,
              }).unwrap();
            toast.success(`Transfer channel updated successfully.`);
          } else {
            const createTransferChannelResponse =
              await addTransferChannelTemplate({
                transferChannelId,
                accountHolderId,
                parameters: [{ ...values }],
              }).unwrap();
            toast.success(`Transfer channel template created successfully.`);
          }
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            {/* <>{JSON.stringify(transferChannelTemplate)}</> */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {index > -1 ? 'Edit Parameter' : 'Create Parameter'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <FormikTextField
                name="parameterName"
                label="Parameter Name"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="parameterDescription"
                label="Parameter Description"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="friendlyName"
                label="Friendly Name"
                autoComplete="off"
                margin='normal'
              />

              <FormControlLabel
                control={
                  <Field
                    label="Required"
                    name="required"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('required', checked);
                    }}
                    checked={values.required ? true : null}
                  />
                }
                label="Required"
              />

              <FormControl fullWidth margin="normal">
                <InputLabel
                  error={Boolean(
                    touched.channelParameterType && errors.channelParameterType,
                  )}
                >
                  Channel Paramater Type
                </InputLabel>
                <Select
                  error={Boolean(
                    touched.channelParameterType && errors.channelParameterType,
                  )}
                  value={values.channelParameterType}
                  label="Channel Paramater Type"
                  onBlur={handleBlur}
                  // onChange={handleChange}
                  onChange={(e) => {
                    // call the built-in handleBur
                    handleChange(e);
                    const { value } = e.target;
                    if (value === 'DROP_DOWN') {
                      values.allowedValues.push({ value: '', description: '' });
                    } else {
                      values.allowedValues = [];
                    }
                  }}
                  name="channelParameterType"
                  variant="outlined"
                  inputProps={{ disabled: index > -1 }}
                >
                  {transferChannelTemplateParameterTypeList.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {touched.channelParameterType && errors.channelParameterType}
                </FormHelperText>
              </FormControl>
              {/* {JSON.stringify(values.allowedValues)} */}

              {['DROP_DOWN'].includes(values.channelParameterType) && (
                <Box
                  sx={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: '#E6E8F0',
                    borderRadius: '4px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingBottom: '16px',
                  }}
                >
                  <InputLabel
                    error={Boolean(
                      touched.allowedValues && errors.allowedValues,
                    )}
                    sx={{ mt: '10px', mb: '10px' }}
                  >
                    Allowed Values
                  </InputLabel>

                  <FieldArray name="allowedValues">
                    {({ push, remove }) => (
                      <>
                        {values.allowedValues.length > 0 &&
                          values.allowedValues.map((a, index) => {
                            return (
                              <div key={index}>
                                <Box
                                  sx={{
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: '#E6E8F0',
                                    borderRadius: '4px',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    paddingBottom: '16px',
                                    marginBottom: '16px',
                                  }}
                                >
                                  {/* <InputLabel>#{index}</InputLabel>  */}
                                  <Chip
                                    sx={{ mt: '10px' }}
                                    label={`#${index}`}
                                    variant="outlined"
                                  />
                                  <FastField
                                    fullWidth
                                    required
                                    margin="normal"
                                    error={
                                      errors?.[
                                      `allowedValues.${index}.value`
                                      ] &&
                                      touched?.[`allowedValues.${index}.value`]
                                    }
                                    variant="outlined"
                                    label={'Value'}
                                    name={`allowedValues.${index}.value`}
                                    component={FormTextField}
                                    className={
                                      'form-control' +
                                      (errors?.[
                                        `allowedValues.${index}.value`
                                      ] &&
                                        touched?.[`allowedValues.${index}.value`]
                                        ? ' is-invalid'
                                        : '')
                                    }
                                  />
                                  <FastField
                                    fullWidth
                                    required
                                    margin="normal"
                                    error={
                                      errors?.[
                                      `allowedValues.${index}.description`
                                      ] &&
                                      touched?.[
                                      `allowedValues.${index}.description`
                                      ]
                                    }
                                    variant="outlined"
                                    label={'Description'}
                                    name={`allowedValues.${index}.description`}
                                    component={FormTextField}
                                    className={
                                      'form-control' +
                                      (errors?.[
                                        `allowedValues.${index}.description`
                                      ] &&
                                        touched?.[
                                        `allowedValues.${index}.description`
                                        ]
                                        ? ' is-invalid'
                                        : '')
                                    }
                                  />
                                  {index > 0 && (
                                    <Tooltip title="Remove this allowed value">
                                      <IconButton
                                        aria-label="remove"
                                        size="medium"
                                        onClick={() => remove(index)}
                                      >
                                        <Remove
                                          color="inherit"
                                          fontSize="inherit"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </div>
                            );
                          })}

                        <Tooltip title="Add new allowed value">
                          <IconButton
                            aria-label="add"
                            size="medium"
                            onClick={() => push({ value: '', description: '' })}
                          >
                            <Add color="inherit" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </FieldArray>
                </Box>
              )}

              {['CUSTOM'].includes(values.channelParameterType) && (
                <FormikTextField
                  name="customScript"
                  label="Custom Script"
                  autoComplete="off"
                />
              )}

              {['STATIC'].includes(values.channelParameterType) && (
                <FormikTextField
                  name="staticValue"
                  label="Static Value"
                  autoComplete="off"
                />
              )}

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {index > -1 ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
