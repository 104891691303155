import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiConfigAttribute,
  useAddBankingAttributeMutation,
  useAttributeDefinitionsQuery,
  useUpdateAttributeMutation,
} from 'src/models/configurations';
import { CreateUpdateBankingConfigAttributeFormValues } from '../types';
import {
  attributeFormValidationSchema,
  attributeToAttributeFormValues,
} from '../utils';

interface FormProps {
  onClose?: () => void;
  attribute?: ApiConfigAttribute;
  accountHolderId: string;
  bankingConfigId?: string;
}
export const AttributeForm: FC<FormProps> = ({
  onClose,
  attribute,
  accountHolderId,
  bankingConfigId,
}) => {

  const attributeDefinitions = useAttributeDefinitionsQuery(
    {
      accountHolderIdForHeader: accountHolderId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const [addAttribute] = useAddBankingAttributeMutation();
  const [updateAttribute] = useUpdateAttributeMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateUpdateBankingConfigAttributeFormValues>
      innerRef={formikRef}
      initialValues={{
        bankingConfigId: bankingConfigId,
        id: '',
        attributeName: '',
        attributeValue: '',
        accountHolderIdForHeader: (() => {
          if (accountHolderId != undefined)
            return accountHolderId;
          else return '';
        })(),
        ...(attribute
          ? attributeToAttributeFormValues(
            attribute,
            accountHolderId,
            bankingConfigId,
          )
          : {}),
      }}
      validationSchema={attributeFormValidationSchema}
      onSubmit={async (values): Promise<void> => {
        try {
          if (attribute) {
            await updateAttribute(values).unwrap();
            toast.success(`Attribute updated successfully.`);
          } else {
            await addAttribute(values).unwrap();
            toast.success(`Attribute created successfully.`);
          }
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        values,
        touched,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {attribute ? 'Edit Attribute' : 'Create Attribute'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>

              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled
                accountHolderId={accountHolderId} />


              {attribute && (
                <FormikTextField
                  name="id"
                  label="Id"
                  autoComplete="off"
                  disabled
                  margin='normal'
                />
              )}
              {attributeDefinitions.data && (
                <FormControl fullWidth margin="normal">
                  <InputLabel
                    error={Boolean(
                      touched.attributeName && errors.attributeName,
                    )}
                  >
                    Attribute Name
                  </InputLabel>

                  <Select
                    error={Boolean(
                      touched.attributeName && errors.attributeName,
                    )}
                    value={values.attributeName}
                    label="Attribute Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="attributeName"
                    variant="outlined"
                  >
                    {attributeDefinitions.data.map((i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {touched.attributeName && errors.attributeName}
                  </FormHelperText>
                </FormControl>
              )}
              <FormikTextField
                name="attributeValue"
                label="Attribute Value"
                autoComplete="off"
                margin='normal'
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {attribute ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
