import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { AuditsRequest, EventsRequest, PageApiAudit, PageApiEvent } from './types';


export const eventsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        events: builder.query<PageApiEvent, EventsRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'event/events',
                }
            },
            providesTags: ['Event']
        }),
        audits: builder.query<PageApiAudit, AuditsRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {

                const parameters = removeEmpty({
                    ...params
                });

                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'event/audits',
                }
            },
            providesTags: ['Audit']
        }),
    })
})

export const {
    useEventsQuery,
    useAuditsQuery,
} = eventsApi;