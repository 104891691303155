import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, FormControl, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { FC, useState } from 'react';

import { Nullable } from 'src/utils/types';
import { DateRangeInfo } from './types';


export const DateRangeForm: FC<{ size: "small" | "medium"; margin?: "none" | "dense" | "normal"; }> = ({ size = 'medium', margin = 'none' }
) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<DateRangeInfo>();
  const [currentField, setCurrentField] = useState<Nullable<string>>(null);
  return (
    <FormControl fullWidth size={size} margin={margin}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          inputFormat="dd/MM/yyyy"
          value={[values['startDateTime'], values['endDateTime']]}
          disableFuture={true}
          onChange={(date: DateRange<Date>, keyboardInputValue?: string) => {

            if (date[0]) {
              setFieldValue('startDateTime', date[0]);
            }
            if ((date[1])) {
              setFieldValue('endDateTime', date[1]);
            }
            if (moment(keyboardInputValue, 'DD/MM/YYYY', true).isValid()) {
              console.log(keyboardInputValue)
              setFieldValue(currentField, moment(keyboardInputValue, 'DD/MM/YYYY').toDate());
            }
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} fullWidth size={size} margin={margin} sx={{ margin: 0 }}
                onFocus={() => setCurrentField('startDateTime')}
                error={Boolean(touched['startDateTime'] && errors['startDateTime'])}
                helperText={touched['startDateTime'] && errors['startDateTime']}
              />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} fullWidth size={size} margin={margin} sx={{ margin: 0 }}
                onFocus={() => setCurrentField('endDateTime')}
                error={Boolean(touched['endDateTime'] && errors['endDateTime'])}
                helperText={touched['endDateTime'] && errors['endDateTime']}
              />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
