import {
  Box,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { FC } from 'react';

import { UserDetailsField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';


export const UserDetailsForm: FC<{ accountHolderId: string; }> = ({ accountHolderId }) => {

  const {
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();
  return (
    <>
      <Box sx={{ typography: 'subtitle2', mb: '4px' }}>Account details</Box>

      <FormikTextField
        name="email"
        label="Email (will be used for login)"
        type="email"
        autoComplete="off"
        onBlur={() => {
          // if (!errors['email']) {
          //   props.setValues({
          //     ...values,
          //     checkEmail: true,
          //    })
          // }
          setFieldValue('checkEmail', true);
        }}
      />

      <Field
        errors={errors}
        touched={touched}
        values={values}
        component={UserDetailsField}
        accountHolderId={accountHolderId}
      />
    </>
  );
};
