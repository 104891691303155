import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useMemo } from 'react';

import { DateRangeForm } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { TransactionListFilter } from 'src/models/transactions';
import {
    transactionStatusList,
    transactionTypeList,
} from '../../../../models/transactions/constants';
import { TransactionFilterForm } from '../TransactionsFilterForm';
import { transactionsFilterFormValuesInitialValue } from './utils';

interface FormProps {
    size?: "small" | "medium";
    filter: TransactionFilterForm;
    onFilterApply: (values: TransactionFilterForm) => void;
    hidden?: string[];
}
export const TransactionsFilterForm: FC<FormProps> = ({ onFilterApply, size, hidden = [], filter }) => {
    const onSubmit = (values: TransactionFilterForm) => {
        onFilterApply(values);
        return Promise.resolve();
    };

    const initialValues = useMemo(
        (): TransactionListFilter => (transactionsFilterFormValuesInitialValue(filter)), [filter]);

    return (
        <Formik
            initialStatus={{ error: '' }}
            initialValues={initialValues}
            validationSchema={null}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                handleSubmit,
                isSubmitting,
                status,
                resetForm,
                values,
                setFieldValue,
            }): JSX.Element => (
                <>
                    <form noValidate>
                        <Grid container spacing={1}>

                            {(!hidden.includes("startDateTime") || !hidden.includes("endDateTime")) && (<Grid item xs={12} sm={6} md={4}>
                                <DateRangeForm size={size} />
                            </Grid>)}
                            {!hidden.includes("transactionType") && (<Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth size={size}>
                                    <InputLabel id="select-transaction-type-label">
                                        Transaction type
                                    </InputLabel>
                                    <Select
                                        multiple
                                        fullWidth
                                        labelId="select-transaction-type-label"
                                        label="Transaction type"
                                        id="select-transaction-type"
                                        value={values.transactionType}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        onChange={(e) => {
                                            setFieldValue('transactionType', e.target.value);
                                        }}
                                    >
                                        {transactionTypeList.map((trType) => (
                                            <MenuItem key={trType} value={trType}>
                                                {trType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>)}
                            {!hidden.includes("transactionStatus") && (<Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth size={size}>
                                    <InputLabel id="select-transaction-status-label">
                                        Transaction Status
                                    </InputLabel>
                                    <Select
                                        multiple
                                        fullWidth
                                        labelId="select-transaction-status-label"
                                        label="Transaction Status"
                                        id="select-transaction-status"
                                        value={values.transactionStatus}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        onChange={(e) => {
                                            setFieldValue('transactionStatus', e.target.value);
                                        }}
                                    >
                                        {transactionStatusList.map((trStatus) => (
                                            <MenuItem key={trStatus} value={trStatus}>
                                                {trStatus}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>)}
                            {!hidden.includes("customerDetailId") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Customer Detail Id"
                                    name="customerDetailId"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("customerEmail") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Customer Email"
                                    name="customerEmail"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("customerFirstName") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Customer First Name"
                                    name="customerFirstName"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("customerLastName") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Customer Last Name"
                                    name="customerLastName"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("referenceNo") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Reference Number"
                                    name="referenceNo"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("bankingConfigId") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Banking Config Id"
                                    name="bankingConfigId"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("accountHolderId") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Client Id"
                                    name="accountHolderId"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("id") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Id"
                                    name="id"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("transactionNumber") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Transaction Number"
                                    name="transactionNumber"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}

                            {!hidden.includes("source") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Source"
                                    name="source"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}
                            {!hidden.includes("destination") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Destination"
                                    name="destination"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}

                            {!hidden.includes("acquiringConfigId") && (<Grid item xs={12} sm={6} md={4}>
                                <FormikTextField
                                    label="Acquiring config Id"
                                    name="acquiringConfigId"
                                    type="text"
                                    size={size}
                                />
                            </Grid>)}

                            {!hidden.includes("includeSubAccountHolders") && (<Grid item xs={12} sm={6} md={4}>
                                <FormControlLabel
                                    control={
                                        <Field name="includeSubAccountHolders" component={Switch} />
                                    }
                                    label="Include Sub-Clients"
                                    name="includeSubAccountHolders"
                                    checked={values.includeSubAccountHolders}
                                    onChange={(ev, checked) => {
                                        setFieldValue('includeSubAccountHolders', checked);
                                    }}
                                />
                            </Grid>)}

                        </Grid>

                        {!isSubmitting && status.error && (
                            <Box sx={{ mt: 1 }}>
                                <FormHelperText error>{status.error}</FormHelperText>
                            </Box>
                        )}
                    </form>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'right',
                            paddingTop: '10px',
                        }}
                    >
                        <Button
                            color="error"
                            onClick={() => {
                                onFilterApply(transactionsFilterFormValuesInitialValue());
                                resetForm();
                                handleSubmit();
                            }}
                            variant="contained"
                            sx={{ marginRight: '20px' }}
                            size={size}
                        >
                            Clear X
                        </Button>
                        <LoadingButton
                            color="primary"
                            loading={isSubmitting}
                            onClick={() => handleSubmit()}
                            variant="contained"
                            size={size}
                        >
                            Apply
                        </LoadingButton>
                    </Box>
                </>
            )}
        </Formik>
    );
};
