import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { assignableFeeInitialValue, assignableFeeValidationSchema } from 'src/components/forms';
import { AssignableFeeField } from 'src/components/forms/elements/simple/assignableFee/AssignableFee';
import { useTotp } from 'src/hooks/useTotp';
import { mapAssignFeeFormValuesToApiAssignFeeRequest, useAddAssignedFeeMutation } from 'src/models/assignedFees';
import { AssignFeeFormValues } from './types-dto';
interface FormProps {
    onClose?: () => void;
    accountHolderId: string;
    parentAccountHolderId: string;
}
export const AssignFeeForm: FC<FormProps> = ({
    onClose,
    accountHolderId,
    parentAccountHolderId,
}) => {
    const [assignFee] = useAddAssignedFeeMutation();
    const formikRef = useRef(null);
    useTotp(formikRef);
    const { t } = useTranslation();

    return (
        <Formik<AssignFeeFormValues>
            innerRef={formikRef}
            initialValues={{
                accountHolderIdForHeader: accountHolderId,
                fee: assignableFeeInitialValue()
            }}
            validationSchema={Yup.object().shape({
                fee: assignableFeeValidationSchema()
            })}
            onSubmit={async (
                values,
            ): Promise<void> => {
                try {
                    const assignFeeApi =
                        mapAssignFeeFormValuesToApiAssignFeeRequest(values);
                    const assignFeeResponse = await assignFee(
                        assignFeeApi,
                    ).unwrap();

                    if (assignFeeResponse?.id) {
                        toast.success(`Fee assigned successfully.`);
                        onClose();
                    }
                } catch (err) {
                    // toast.error('An error has occured');
                    console.log(err);
                }
            }}
        >
            {({
                handleSubmit,
            }): JSX.Element => (
                <>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '24px 38px 24px 32px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                sx={{ mr: 2 }}
                                aria-label="close"
                                size="medium"
                                onClick={() => onClose()}
                            >
                                <Close color="inherit" fontSize="inherit" />
                            </IconButton>
                            <Typography color={'textPrimary'} variant="h5">
                                Assign Fee
                            </Typography>
                        </Box>
                    </Box>

                    <Divider />
                    <Box sx={{ padding: '32px' }}>
                        <form noValidate onSubmit={handleSubmit}>


                            <Field
                                name={`fee`}
                                component={AssignableFeeField}
                                accountHolderId={parentAccountHolderId} />


                            <Box sx={{ mt: '24px' }}>
                                <LoadingButton
                                    color="primary"
                                    // loading={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {t('common.submit')}
                                </LoadingButton>
                            </Box>
                        </form>
                    </Box>
                </>
            )}
        </Formik>
    );
};
