import { Box, Button, Card, Drawer, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import Scrollbar from 'src/components/Scrollbar';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePartnerBanksQuery } from 'src/models/configurations';
import { PartnerBankForm } from './forms/PartnerBankForm';
import { PartnerBanksTable } from './PartnerBanksTable';
export const PartnerBanks: FC<{
  accountHolderId: string;
}> = ({ accountHolderId }) => {
  const [addPartnerBankOpened, setAddPartnerBankOpened] =
    useState<boolean>(false);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const { data } = usePartnerBanksQuery(
    {
      accountHolderIdForHeader: accountHolderId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const partnerBanks = data || [];

  return (
    <>
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
            marginBottom: '25px',
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }),
        ]}
      >
        <Typography color="textPrimary" variant="h5">
          Partner banks
        </Typography>

        <Box
          sx={[
            {
              display: 'flex',
              gap: 1,
              marginBottom: '10px',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '10px',
              },
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            }),
          ]}
        >
          <PermissionGuard permission="UPDATE_BANKING_CONFIGURATION">
            <Button
              disabled={!isUserActive}
              variant="contained"
              onClick={() => setAddPartnerBankOpened(true)}
            >
              Create partner bank
            </Button>
          </PermissionGuard>
        </Box>
      </Box>

      <PermissionGuard permission="UPDATE_BANKING_CONFIGURATION">
        <Drawer
          anchor="right"
          open={addPartnerBankOpened}
          onClose={() => setAddPartnerBankOpened(false)}
          PaperProps={{
            sx: {
              backgroundColor: '#F3F7FE',
              width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            },
          }}
        >
          <PartnerBankForm
            accountHolderId={accountHolderId}
            onClose={() => setAddPartnerBankOpened(false)}
          />
        </Drawer>
      </PermissionGuard>
      <PermissionGuard permission="GET_BANKING_CONFIGURATION">
        <Card>
          <Scrollbar>
            <PartnerBanksTable
              partnerBanks={partnerBanks}
              accountHolderId={accountHolderId}
            />
          </Scrollbar>
        </Card>
      </PermissionGuard>
    </>
  );
};
