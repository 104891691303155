import { ArrowForward, PeopleAlt } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import moment from 'moment';
import { FC, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { selectAccountHolderId, useImpersonateMutation } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { SortOrder } from 'src/models/types';
import { ApiUser } from 'src/models/users';
import { UserStatusLabel } from './UserStatusLabel';
interface UserTable {
  users: ApiUser[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
}

const headCells = [
  // { title: 'User ID', id: 'id', doSort: true },
  { title: 'First Name', id: 'firstName', doSort: false },
  { title: 'Last Name', id: 'lastName', doSort: false },
  { title: 'Email', id: 'email', doSort: true },
  { title: 'Status', id: 'userStatus', doSort: false },
  { title: 'Nationality', id: 'nationality', doSort: false },
  { title: 'Date of Birth', id: 'dateOfBirth', doSort: false },
  { title: 'Phone number', id: 'phoneNumber', doSort: false },
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const UsersTable: FC<UserTable> = ({
  users,
  orderBy,
  sortDirection,
  onRequestSort,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  // const [editUserOpened, setEditUserOpened] = useState<ApiUser>(null);
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  const accountHolderId = useSelector(selectAccountHolderId);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [impersonate] = useImpersonateMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{ user: null }}
          onSubmit={async (values): Promise<void> => {
            try {
              await impersonate({
                userId: values.user.id,
                accountHolderId,
              });
              toast.success(`Impersonating ${values.user.email}.`);
            } catch (err) {
              console.error(err);
              // if (mounted.current) {
              //   setStatus({ success: false });
              //   setSubmitting(false);
              // }
            }

          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id, doSort }) => (
                    <TableCell
                      key={id}
                      sortDirection={
                        sortEnabled && doSort && orderBy === id
                          ? sortDirection
                          : false
                      }
                    >
                      {sortEnabled && doSort ? (
                        <TableSortLabel
                          active={orderBy === id}
                          direction={orderBy === id ? sortDirection : 'asc'}
                          onClick={createSortHandler(id)}
                        >
                          {title}
                        </TableSortLabel>
                      ) : (
                        title
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => {
                  return (
                    <TableRow
                      hover
                      key={user.id}
                    // onClick={() => navigate(`${user.id}`)}
                    >
                      <TableCell>{user.firstName}</TableCell>
                      <TableCell>{user.lastName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <UserStatusLabel
                          status={user.userStatus}
                        />
                      </TableCell>
                      <TableCell>{user.nationality}</TableCell>
                      <TableCell>
                        {moment.utc(user.dateOfBirth).format('DD/MM/yyyy')}
                      </TableCell>
                      <TableCell>{user?.phoneNumber?.phoneNumber}</TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {format(
                            new Date(user.createdDateTime),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {user.id}
                  </Typography>
                </TableCell> */}

                      <TableCell align="right">
                        <Box sx={{ display: 'flex' }}>
                          <PermissionGuard permission="ADMIN">
                            <Tooltip title={`Impersonate ${user.email}`}>
                              <IconButton
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      `Are you sure you want to impersonate ${user.email}?`,
                                    subTitle: 'You need to relog to get back to your personal account.',
                                    onConfirm: () => {
                                      setFieldValue('user', user);
                                      formikRef?.current?.submitForm();
                                    },
                                  });
                                }}
                              >
                                <PeopleAlt fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </PermissionGuard>
                          <IconButton
                            component={RouterLink}
                            to={`${user.id}`}
                          >
                            <ArrowForward fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
