import { apiWithTag } from '../emptySplitApi';
import { ApiTransferChannelTemplate, ApiTransferChannelTemplateCreate, ApiTransferChannelTemplateUpdate } from './types';

export const transferChannelTemplatesApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        transferChannelTemplatesList: builder.query<ApiTransferChannelTemplate, { transferChannelId: string; accountHolderIdForHeader: string; }>({
            query: ({ transferChannelId, accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `cms/transfer-channels/${transferChannelId}/templates`,
                }
            },
            transformResponse: (response: ApiTransferChannelTemplate, meta, arg) => {
                return { ...response, parameters: response.parameters.sort((a, b) => a.order - b.order) };
            },
            providesTags: ['TransferChannelTemplate']
        }),
        addTransferChannelTemplate: builder.mutation<ApiTransferChannelTemplate, ApiTransferChannelTemplateCreate>({
            query: ({ transferChannelId, accountHolderId, ...rest }) => ({
                url: `cms/transfer-channels/${transferChannelId}/templates`,
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                method: 'POST',
                body: { transferChannelId, ...rest }
            }),
            invalidatesTags: ['TransferChannelTemplate']
        }),
        updateTransferChannelTemplate: builder.mutation<ApiTransferChannelTemplate, ApiTransferChannelTemplateUpdate>({
            query: ({ transferChannelId, accountHolderId, ...rest }) => ({
                url: `cms/transfer-channels/${transferChannelId}/templates`,
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                method: 'PUT',
                body: { transferChannelId, ...rest }
            }),
            invalidatesTags: ['TransferChannelTemplate']
        }),

    })
})

export const {
    useTransferChannelTemplatesListQuery,
    useAddTransferChannelTemplateMutation,
    useUpdateTransferChannelTemplateMutation,
} = transferChannelTemplatesApi;