import { Chip, colors } from '@mui/material';
import { capitalCase } from 'change-case';
import React from 'react';

import { InvitationStatus } from '../types';

const statusColors = {
//   COMPLETE: colors.green,
//   CREATED: colors.green,
//   EXPIRED: colors.grey,
//   FAILED: colors.red,
  PENDING: colors.orange,
//   PENDING_DEPOSIT: colors.orange,
//   PENDING_THREE_D: colors.orange,
//   PENDING_TRADE: colors.orange,
//   PENDING_WITHDRAW: colors.orange,
};

export const InvitationStatusLabel = ({
  status,
}: {
  status: InvitationStatus;
}) => {
  const backgroundColor = statusColors[status] && statusColors[status][50];
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={capitalCase(status)} sx={{ backgroundColor, color }} />;
};
