import { ApiTransactionExportForm, TransactionExportForm } from "."

export const mapTransactionExportFormToApiTransactionExportForm = (filter: TransactionExportForm): ApiTransactionExportForm => {
    return {
        id: filter.id,
        transactionStatus: filter.transactionStatus,
        accountId: filter.accountId,
        accountNumber: filter.accountNumber,
        acquiringConfigId: filter.acquiringConfigId,
        customerDetailId: filter.customerDetailId,
        customerFirstName: filter.customerFirstName,
        customerLastName: filter.customerLastName,
        customerEmail: filter.customerEmail,
        referenceNo: filter.referenceNo,
        transactionType: filter.transactionType,
        startDateTime: filter.startDateTime.toISOString(),
        endDateTime: filter.endDateTime.toISOString(),
        bankingConfigId: filter.bankingConfigId,
        accountHolderId: filter.accountHolderId,
        includeSubAccountHolders: filter.includeSubAccountHolders,
        transactionNumber: filter.transactionNumber,
        source: filter.source,
        destination: filter.destination
    }
}

