import { Box, Button, Card, CardContent} from '@mui/material';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AuthTemplate from 'src/components/AuthTemplate';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { useResetCredentials } from 'src/models/auth/hooks/useResetCredentials';
import { useDeclineUserInvitationMutation } from 'src/models/invitations';
import { links } from 'src/navigation/constants';

const Invitation: FC = () => {
  useResetCredentials({ immediately: true });
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
    const { invitationId } = useParams<{ invitationId: string }>();
    const navigate = useNavigate();
  
const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' , onConfirm: () => void 0 })
const [declineInvitation] = useDeclineUserInvitationMutation();

  return (
    <>
      <Helmet>
        <title>Handle your invitation</title>
      </Helmet>
      <AuthTemplate>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline', mb: '16px' }}>
              <Box sx={{ typography: 'h4' }}>Handle your invitation</Box>
            </Box>

            <Box sx={{ typography: 'body2', color: 'text.secondary' }}>


                 {searchParams.get("email")?.length > 0 
                 ?(
                    <Button color="primary" sx={{ ml: 2 }} variant="outlined"
                      onClick={() => navigate(`/invitations/register/${invitationId}${search}`)}>
                      Register and Accept
                    </Button>
                 ):
                 (
                    <Button color="primary" sx={{ ml: 2 }} variant="outlined"
                      onClick={() => navigate(`${links.login}?invitation=${invitationId}`)}>
                      Login
                    </Button>
                 )
                }
                    <Button color="error" sx={{ ml: 2 }} variant="outlined"
                    onClick={() => {
                        setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure to decline this invitation?',
                            subTitle: "This operation cannot be undone",
                            onConfirm: async () => { 
                              try {
                              await declineInvitation({invitationId}).unwrap(); 
                              toast.success(`Your invitation has been cancelled. Thanks.`);
                              navigate(links.login);
                            } catch (err) {
                              console.log(err);
                              if(err?.data?.errorCode == 'INVALID_INVITATION_ID'){
                                 toast.error(`Invitation is invalid.`);
                                 navigate(links.login);
                              }
                              // Might need to handle expired invitation here
                            }
                            }
                        })
                    }}>
                    Decline
                  </Button>
            </Box>
        
          </CardContent>
        </Card>
      </AuthTemplate>

      <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
    </>
  );
};

export default Invitation;
