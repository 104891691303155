import { Box, Button, Drawer, SvgIcon } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';

import { PromptData } from '../types';
import HomeScreenIcon from './HomeScreenIcon';
import { PWAPromptStyles } from './PWAPrompt.styles';
import ShareIcon from './ShareIcon';

interface PWAPromptProps {
  delay: number;
  copyTitle: string;
  copyBody: string;
  copyAddHomeButtonLabel: string;
  copyShareButtonLabel: string;
  copyClosePrompt: string;
  permanentlyHideOnDismiss: boolean;
  promptData: PromptData;
  maxVisits: number;
  onClose?: () => void;
}

const PWAPrompt = ({
  delay,
  copyTitle,
  copyBody,
  copyAddHomeButtonLabel,
  copyShareButtonLabel,
  copyClosePrompt,
  permanentlyHideOnDismiss,
  promptData,
  maxVisits,
  onClose,
}: PWAPromptProps) => {
  const [isVisible, setVisibility] = useState(!delay);

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        // Prevent keyboard appearing over the prompt if a text input has autofocus set
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

        setVisibility(true);
      }, delay);
    }
  }, []);

  const isiOS13 = /OS 13/.test(window.navigator.userAgent);

  const dismissPrompt = () => {
    setVisibility(false);

    if (permanentlyHideOnDismiss) {
      localStorage.setItem(
        'iosPwaPrompt',
        JSON.stringify({
          ...promptData,
          visits: maxVisits,
        }),
      );
    }
    onClose();
  };

  const onTransitionOut = (event: React.TransitionEvent<HTMLDivElement>) => {
    if (!isVisible) {
      event.currentTarget.style.display = 'none';
      onClose();
    }
  };

  return (
    <Fragment>
      <Box
        component="div"
        sx={{
          ...PWAPromptStyles.pwaPromptOverlay,
          ...(isVisible
            ? PWAPromptStyles.pwaPromptOverlay_visible
            : PWAPromptStyles.pwaPromptOverlay_hidden),
        }}
        aria-label="Close"
        role="button"
        onClick={dismissPrompt}
        onTransitionEnd={onTransitionOut}
      />
      <Box
        component="div"
        sx={{
          ...PWAPromptStyles.pwaPrompt,
          ...(isVisible
            ? PWAPromptStyles.pwaPrompt_visible
            : PWAPromptStyles.pwaPrompt_hidden),
        }}
        aria-describedby="pwa-prompt-description"
        aria-labelledby="pwa-prompt-title"
        role="dialog"
        onTransitionEnd={onTransitionOut}
      >
        <Box
          component="div"
          sx={{
            ...PWAPromptStyles.pwaPromptHeader,
          }}
        >
          <Box
            component="p"
            sx={{
              ...PWAPromptStyles.pwaPromptHeader__pwaPromptTitle,
            }}
            id="pwa-prompt-title"
          >
            {copyTitle}
          </Box>
          <Button
            sx={{
              ...PWAPromptStyles.pwaPromptHeader__pwaPromptTitle,
            }}
            onClick={dismissPrompt}
          >
            {copyClosePrompt}
          </Button>
        </Box>
        <Box
          component="div"
          sx={{
            ...PWAPromptStyles.pwaPromptBody,
          }}
        >
          <Box
            component="div"
            sx={{
              ...PWAPromptStyles.pwaPromptBody__pwaPromptDescription,
            }}
          >
            <Box
              component="p"
              sx={{
                ...PWAPromptStyles.pwaPromptCopy,
              }}
              id="pwa-prompt-description"
            >
              {copyBody}
            </Box>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            ...PWAPromptStyles.pwaPromptInstruction,
          }}
        >
          <Box
            component="div"
            sx={{
              ...PWAPromptStyles.pwaPromptInstruction__pwaPromptInstructionStep,
            }}
          >
            <SvgIcon>
              <ShareIcon modern={isiOS13} />
            </SvgIcon>
            <Box
              component="p"
              sx={{
                ...PWAPromptStyles.pwaPromptCopy,
                ...PWAPromptStyles.pwaPromptCopy_bold,
                ml: '5px',
              }}
            >
              {copyShareButtonLabel}
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              ...PWAPromptStyles.pwaPromptInstruction__pwaPromptInstructionStep,
            }}
          >
            <SvgIcon>
              <HomeScreenIcon modern={isiOS13} />
            </SvgIcon>
            <Box
              component="p"
              sx={{
                ...PWAPromptStyles.pwaPromptCopy,
                ...PWAPromptStyles.pwaPromptCopy_bold,
                ml: '5px',
              }}
            >
              {copyAddHomeButtonLabel}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default PWAPrompt;
