import { ApiKycAddressesRequestBody, ApiKycIdentificationsRequestBody, ApiKycSelfiesRequestBody } from "./types";


export const kycVerificationValuesToFormDataBody = (
    formValues: ApiKycIdentificationsRequestBody | ApiKycAddressesRequestBody | ApiKycSelfiesRequestBody,
): FormData => {
    const formData = new FormData();
    for (const value in formValues) {
        if (formValues[value] != null) {
            formData.append(value, formValues[value]);
        }
    }

    return formData;
};