export const transactionStatusList = [
  'INITIAL',
  'COMPLETED',
  'EXPIRED',
  'FAILED',
  'PENDING',
  'PENDING_DEPOSIT',
  'PENDING_THREE_D',
  'PENDING_TRADE',
  'PENDING_WITHDRAW',
  'PENDING_CONFIRMATION'
] as const;

export const transactionTypeList = [
  'AUTH',
  'CAPTURE',
  'CARD_PAYMENT',
  'CHECKOUT_PAYMENT',
  'PAYMENT',
  'REFUND',
  'TRADE',
  'VOID',
  'TRANSFER',
  'CREDIT',
  'DEBIT',
  'INWARD_REMITTANCE',
  'OUTWARD_REMITTANCE'
] as const;


export const genderTypeList = [
  'MALE',
  'FEMALE',
  'OTHER'
] as const;


export const accountEntryTypeList = [
  'CREDIT',
  'DEBIT',
  'FEE',
  'FEE_PROCESSING'
] as const;


export const accountEntryStatusList = [
  'CREATED',
  'PENDING',
  'COMPLETED',
  'REVERTED'
] as const;


export const LogTypeList = [
  'CALLOUT_REQUEST',
  'CALLOUT_RESPONSE',
  'ERROR',
  'REQUEST',
  'RESPONSE',
] as const;
