import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountCurrencyField, AccountHolderIdField, AccountTypeField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiAccountCreate,
  ApiAccountUpdate,
  mapCreateUpdateAccountFormValuesToApiAccountCreate,
  mapCreateUpdateAccountFormValuesToApiAccountUpdate,
  useAddAccountMutation,
  useUpdateAccountMutation,
} from 'src/models/accounts';
import { CreateUpdateAccountFormValues } from './types-dto';
import { createAccountFormValuesInitialValue } from './utils';
import { accountFormValidationSchema } from './validations';
interface FormProps {
  onClose?: () => void;
  account?: CreateUpdateAccountFormValues;
  accountHolderId: string;
  isGlobal: boolean;
}
export const AccountForm: FC<FormProps> = ({
  onClose,
  account,
  accountHolderId,
  isGlobal,
}) => {

  const [addAccount] = useAddAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateUpdateAccountFormValues>
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        ...createAccountFormValuesInitialValue(accountHolderId),
        ...account
      }}
      validationSchema={accountFormValidationSchema()}
      onSubmit={async (values): Promise<void> => {
        try {
          if (account) {
            const apiAccountUpdate: ApiAccountUpdate = await mapCreateUpdateAccountFormValuesToApiAccountUpdate(values);
            await updateAccount(apiAccountUpdate).unwrap();
            toast.success(`Account updated successfully.`);
          } else {
            const apiAccountCreate: ApiAccountCreate = await mapCreateUpdateAccountFormValuesToApiAccountCreate(values);
            await addAccount(apiAccountCreate).unwrap();
            toast.success(`Account created successfully.`);
          }
          onClose();
        } catch (err) {
          if (err?.data?.errorCode == 'ACCOUNT_LIMIT_REACHED') {
            toast.error(
              `No more accounts with the requested currency can be created`,
            );
          } else if (err?.data?.errorCode == 'INVALID_CURRENCY') {
            toast.error(
              `Requested currency is not available for this specific bank`,
            );
          }
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {account ? 'Edit Account' : 'Create Account'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal || account}
                accountHolderId={accountHolderId} />

              {account && (
                <FormikTextField
                  name="id"
                  label="Id"
                  autoComplete="off"
                  disabled
                  margin='normal'
                />
              )}

              <FormikTextField
                name="friendlyName"
                label="Friendly Name"
                autoComplete="off"
                margin='normal'
              />

              <Field name={`accountType`} component={AccountTypeField} disabled={account != null} />


              <Field
                name={`currencyCode`}
                component={AccountCurrencyField}
                disabled={account}
                accountHolderId={accountHolderId} />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {account ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
