import {LoadingButton} from '@mui/lab';
import { Box,  Card, CardContent, Link } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { FC, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import AuthTemplate from 'src/components/AuthTemplate';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import { useUserVerificationMutation } from 'src/models/auth';
const UserVerification: FC = () => {
    const { t } = useTranslation();
    const [userVerification, {isLoading,isSuccess, isError, error}] = useUserVerificationMutation({ });
    const formikRef = useRef(null);
    useTotp(formikRef);
  
  return (
    <>
      <Helmet>
        <title>Re-send User Verification Link</title>
      </Helmet>
      <AuthTemplate>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline', mb: '16px' }}>
              <Box sx={{ typography: 'h4' }}>Re-send User Verification Link</Box>
            </Box>
              {isLoading && <CircularProgress />}
              {isSuccess && 
                              <Box sx={{ typography: 'body1', mb: '16px' }}>
                                Check your e-mail for verification link.
                              </Box>
              }
              {isError && error?.['data'].errorCode == 'USERNAME_NOT_FOUND' &&  <Box sx={{ typography: 'body1', mb: '16px' }}>
                                 User is already verified, Click <Link
                                  color="primary"
                                  component={RouterLink}
                                  to={`/auth/login`}
                                  variant="body2"
                                >
                                  here
                                </Link> to login.
                             </Box> 
                }    
           {!isSuccess && !isError && !isLoading && <Formik
                innerRef={formikRef}
                initialValues={{ email: "" , submit: null}}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Must be a valid email')
                      .max(255)
                      .required('Email is required'),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting },
                  ): Promise<void> => {
                    try {
                      const user = await userVerification({
                        email: values.email,
                      }).unwrap();
            
                    } catch (err) {
                        const errorMsg = err?.data?.message || err.message || "";
              
                        setStatus({ success: false });
                        setErrors({ submit: errorMsg });
                        setSubmitting(false);
                      }
                  }}
            >
         {({ errors, handleSubmit, isSubmitting }): JSX.Element => (
                <form noValidate onSubmit={handleSubmit}>
                           <FormikTextField label="Email" name="email" type="email" />
                            <Box sx={{ mt: '24px', display:'flex', justifyContent:'flex-end'}}>
                                <LoadingButton
                                color="primary"
                                loading={isSubmitting}
                                size="large"
                                type="submit"
                                variant="contained"
                                >
                                {t('common.submit')}
                                </LoadingButton>
                            </Box>
                    </form>
                    )}
            </Formik>
            }
          </CardContent>
        </Card>
      </AuthTemplate>
    </>
  );
};

export default UserVerification;
