import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { AccountHolderIdField } from 'src/components/forms';
import { useTotp } from 'src/hooks/useTotp';
import {
  useAddPartnerBankMutation,
  useAllPartnerBanksQuery,
} from 'src/models/configurations';

interface FormProps {
  onClose?: () => void;
  accountHolderId: string;
}
export const PartnerBankForm: FC<FormProps> = ({
  onClose,
  accountHolderId,
}) => {

  const partnerBanks = useAllPartnerBanksQuery(
    { accountHolderIdForHeader: accountHolderId },
    { refetchOnMountOrArgChange: true },
  );

  const [addPartnerBank] = useAddPartnerBankMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        id: '',
        accountHolderIdForHeader: (() => {
          if (accountHolderId != undefined)
            // if override
            return accountHolderId;
          else return '';
        })(),
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required('Partner Bank is required'),
        accountHolderIdForHeader: Yup.string().required('Client is required'),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting },
      ): Promise<void> => {
        try {
          const createPartnerBankResponse = await addPartnerBank(
            values,
          ).unwrap();
          toast.success(`Partner bank created successfully.`);
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        values,
        touched,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Create Partner Bank
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>

              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled
                accountHolderId={accountHolderId} />

              {partnerBanks.data?.length > 0 && (
                <FormControl
                  error={touched?.['id'] && errors?.['id']?.length > 0}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  component="fieldset"
                >
                  <InputLabel id="id_label">Partner Bank</InputLabel>
                  <Select
                    labelId="id_label"
                    label="Partner Bank"
                    name="id"
                    id="id_select"
                    defaultValue=""
                    value={values.id}
                    onChange={(e: SelectChangeEvent<unknown>) =>
                      handleChange('id')(e as React.ChangeEvent)
                    }
                    onBlur={handleBlur('id')}
                  >
                    {partnerBanks.data?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.bankName} ({i.id})
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {touched['id'] && errors['id']}
                  </FormHelperText>
                </FormControl>
              )}
              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
