import { Circle } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectBank } from 'src/models/cms';

export const SubmitForm: FC = () => {
  const bank = useSelector(selectBank);

  const displayName = useMemo(
    () => (bank ? bank.displayName : 'Aykla Financial'),
    [bank],
  );

  const { errors, touched, handleChange } =
    useFormikContext<{ termsOfService1: boolean; termsOfService2: boolean }>();
  return (
    <>
      <Box sx={{ mb: '4px', mt: '30px' }}>
        <Typography variant="h6">Terms and conditions</Typography>
      </Box>

      <Typography variant="inherit">
        I hereby declare that the details given by me in this application are
        true and correct to the best of my knowledge and believe and I undertake
        to inform you of any changes therein, immediately
      </Typography>
      <FormControl component="fieldset" variant="outlined">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked={false} />}
            label={'I accept'}
            name="termsOfService1"
            onChange={handleChange}
          />
        </FormGroup>

        {touched.termsOfService1 && errors.termsOfService1 ? (
          <FormHelperText error>
            {touched.termsOfService1 && errors.termsOfService1}
          </FormHelperText>
        ) : null}
      </FormControl>
      {/* <Typography variant="inherit">{JSON.stringify(bank)}</Typography> */}
      <Typography variant="inherit">
        {displayName} will process the data contained herein and any other data
        which you subsequently provide to the Bank in any manner for the purpose
        of:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <Circle />
          </ListItemIcon>
          <ListItemText
            primary={`Providing you with ${displayName}’s products and services;`}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Circle />
          </ListItemIcon>
          <ListItemText
            primary={`Detection and prevention of money laundering, fraud and other criminal 
  activity which ${displayName} is bound to report; and`}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Circle />
          </ListItemIcon>
          <ListItemText
            primary={`Developing and improving ${displayName}’s products and services.`}
          />
        </ListItem>
      </List>
      <Typography variant="inherit">
        Your personal data will be made available to employees within our
        organisation who require the information to carry out their duties and
        provide you with the requested services. This information may also be
        shared with:`
      </Typography>

      <List>
        <ListItem>
          <ListItemIcon>
            <Circle />
          </ListItemIcon>
          <ListItemText
            primary={`Other entities within ${displayName} and/or any sub-contractors, agents or 
  service providers who work for or provide services to ${displayName}`}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Circle />
          </ListItemIcon>
          <ListItemText
            primary="Law enforcement, government, courts, dispute resolution bodies, our 
  regulators, auditors, advisors, consultants and any party appointed or 
  requested by our regulators to carry out investigations or audits of our 
  activities;"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Circle />
          </ListItemIcon>
          <ListItemText
            primary="Fraud prevention agencies who will also use the information to detect and 
  prevent fraud and other financial crime; and"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Circle />
          </ListItemIcon>
          <ListItemText
            primary="Any assigned person given legal rights to operate any of your accounts, 
  products or services on your behalf."
          />
        </ListItem>
      </List>
      <Typography variant="inherit">
        Your personal data will not be disclosed to any other persons or
        entities other than those mentioned above unless we are required to do
        so by law
      </Typography>

      <FormControl component="fieldset" variant="outlined">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked={false} />}
            label={'I accept'}
            name="termsOfService2"
            onChange={handleChange}
          />
        </FormGroup>

        {touched.termsOfService2 && errors.termsOfService2 ? (
          <FormHelperText error>
            {touched.termsOfService2 && errors.termsOfService2}
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
};
