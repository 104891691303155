import {
    getCountryCallingCode,
    parsePhoneNumber,
} from 'libphonenumber-js';

import { ApiPhoneNumber } from "src/models/types"
import { countries } from "src/utils/countries";
import { composePhoneNumber } from "."
import { Phone } from "./types"

export const mapPhoneToApiPhoneNumber = (phone: Phone): ApiPhoneNumber => {
    return {
        prefix: `+${getCountryCallingCode(phone.phoneCountryCode.code)}`,
        number: phone.phoneNumber,
        phoneNumber: composePhoneNumber(phone.phoneCountryCode.code, phone.phoneNumber)
    }
}

export const mapApiPhoneNumberToPhone = (apiPhone: ApiPhoneNumber): Phone => {
    return {
        phoneCountryCode: countries.find(x => x.code == parsePhoneNumber(apiPhone.phoneNumber).country),
        phoneNumber: apiPhone.number
    }
}