import { Delete } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { ApiFeeConfig, useDeleteAssignedFeeMutation } from 'src/models/assignedFees';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { SortOrder } from 'src/models/types';
import { FeeStatusLabel } from '../Configuration/Fees/FeeStatusLabel';

interface AssignedFeeTable {
  feeConfigs: ApiFeeConfig[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderIdForHeader: string;
}




export const AssignedFeesTable: FC<AssignedFeeTable> = ({
  feeConfigs,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderIdForHeader
}) => {

  const { hasPermission } = usePermissions();
  const [deleteAssignedMutation] = useDeleteAssignedFeeMutation();
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const formikRef = useRef(null);
  useTotp(formikRef);


  const headCells = [
    { title: 'Created date', id: 'createdDateTime', doSort: true },
    //   { title: 'Id', id: 'id', doSort: true },
    { title: 'Description', id: 'description', doSort: true },
    // { title: 'Client', id: 'accountHolder', doSort: true },
    { title: 'Percentage', id: 'percentage', doSort: true },
    { title: 'Transaction Type', id: 'transactionType', doSort: true },
    { title: 'Fee Contributor', id: 'feeContributor', doSort: true },
    { title: 'Fee Transaction Context', id: 'transactionContext', doSort: true },
    // { title: 'Fee Account Id', id: 'feeAccountId', doSort: true },
    { title: 'Fee Account No', id: 'feeAccountNumber', doSort: true },
    {
      title: 'Contributor Account No',
      id: 'contributorAccountNumber',
      doSort: true,
    },

    { title: 'Enabled', id: 'enabled', doSort: true },
    ...(hasPermission('ADMIN') ? [{ title: 'Assignable', id: 'assignable', doSort: true }] : []),
    { title: 'Actions', id: 'actions', doSort: false },
  ];

  return (
    <>
      <Box sx={{ minWidth: 700 }}>

        <Formik
          innerRef={formikRef}
          initialValues={{
            feeId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              await deleteAssignedMutation({
                feeId: values.feeId,
                accountHolderIdForHeader: accountHolderIdForHeader,
              }).unwrap();
            } catch (err) {
              if (err?.data.errorCode == 'FEE_CANNOT_BE_UNLINKED') {
                toast.error(`Fee cannot be unlinked.`);
              }
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id, doSort }) => (
                    <TableCell
                      key={id}
                      sortDirection={
                        sortEnabled && doSort && orderBy === id
                          ? sortDirection
                          : false
                      }
                    >
                      {sortEnabled && doSort ? (
                        <TableSortLabel
                          active={orderBy === id}
                          direction={orderBy === id ? sortDirection : 'asc'}
                          onClick={createSortHandler(id)}
                        >
                          {title}
                        </TableSortLabel>
                      ) : (
                        title
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {feeConfigs.map((feeConfig) => {
                  return (
                    <TableRow
                      hover
                      key={feeConfig.id}
                    // onClick={() => navigate(`${feeConfig.id}`)}
                    >
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {format(
                            new Date(feeConfig.createdDateTime),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {feeConfig.description}
                        </Typography>
                      </TableCell>

                      {/* <TableCell>
                        <Typography noWrap variant="inherit">
                          {feeConfig.accountHolderId}
                        </Typography>
                      </TableCell> */}

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {feeConfig.percentage}%
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <FeeStatusLabel status={feeConfig.transactionType} />
                      </TableCell>

                      <TableCell>
                        <FeeStatusLabel status={feeConfig.feeContributor} />
                      </TableCell>
                      <TableCell>
                        {feeConfig.transactionContext && (
                          <FeeStatusLabel status={feeConfig.transactionContext} />
                        )}
                      </TableCell>
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {fee.feeAccountId}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {feeConfig.feeAccountNumber}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {feeConfig.contributorAccountNumber}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={feeConfig.enabled ? 'Yes' : 'No'}
                          color={feeConfig.enabled ? 'success' : 'error'}
                        />
                      </TableCell>

                      {hasPermission('ADMIN') && (
                        <TableCell>
                          <Chip
                            label={feeConfig.assignable ? 'Yes' : 'No'}
                            color={feeConfig.assignable ? 'success' : 'error'}
                          />
                        </TableCell>
                      )},
                      <TableCell align="right">
                        <PermissionGuard permission="UNASSIGN_FEE">
                          <Tooltip title="Unassign Fee">
                            <IconButton
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    'Are you sure to unassign this fee?',
                                  subTitle: 'This operation cannot be undone',
                                  onConfirm: () => {
                                    setFieldValue('feeId', feeConfig.id);
                                    formikRef?.current?.submitForm();
                                  },
                                });
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </PermissionGuard>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
