import { apiWithTag } from '../emptySplitApi';
import { ApiAcceptInvitationRequest, ApiCreateInvitationRequest, ApiInvitation, InvitationStatus } from './types';

export const invitationsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        userInvitations: builder.query<ApiInvitation[], {
            accountHolderId?: string,
            userId?: string,
            status: InvitationStatus
        }>({
            query: (payload) => ({
                headers: {
                    ...(payload.accountHolderId && { 'x-account-holder-id': payload.accountHolderId })
                },
                method: 'GET',
                params: {
                    ...payload
                },
                url: `auth/invitations/users`,
            }),
            providesTags: ['InvitationsUser']
        }),
        userInvitation: builder.query<ApiInvitation, { invitationId: string }>({
            query: ({ invitationId }) => `/users/${invitationId}`,
            providesTags: ['Invitations']
        }),
        acceptUserInvitation: builder.mutation<{}, { invitationId: string, acceptInvitationRequest?: ApiAcceptInvitationRequest }>({
            query: (payload) => ({
                ...(payload.acceptInvitationRequest && { body: payload.acceptInvitationRequest }),
                method: 'POST',
                url: `auth/invitations/${payload.invitationId}/users`,
            }),
            invalidatesTags: ['InvitationsUser']
        }),
        declineUserInvitation: builder.mutation<void, { invitationId: string }>({
            query: ({ invitationId }) => ({
                headers: {
                    // 'x-account-holder-id': `${payload.accountHolderId}`,
                },
                method: 'DELETE',
                url: `auth/invitations/${invitationId}/users`,
            }),
            invalidatesTags: ['InvitationsUser']
        }),

        // Create invitation for other users
        createInvitation: builder.mutation<{}, ApiCreateInvitationRequest>({
            query: ({ accountHolderId, ...body }) => ({
                body,
                headers: {
                    'x-account-holder-id': `${accountHolderId}`,
                },
                method: 'POST',
                url: `auth/invitations/users`,
            }),
            invalidatesTags: ['Invitations']
        }),
        invitations: builder.query<ApiInvitation[], {
            accountHolderId?: string,
            status: InvitationStatus
        }>({
            query: (payload) => ({
                headers: {
                    ...(payload.accountHolderId && { 'x-account-holder-id': payload.accountHolderId })
                },
                method: 'GET',
                params: {
                    ...payload
                },
                url: `auth/invitations/users`,
            }),
            providesTags: ['Invitations']
        }),
        resendInvitation: builder.mutation<{}, { accountHolderId: string, invitationId: string }>({
            query: ({ accountHolderId, invitationId }) => ({
                headers: {
                    'x-account-holder-id': `${accountHolderId}`,
                },
                method: 'POST',
                url: `auth/invitations/${invitationId}`,
            }),
            invalidatesTags: ['Invitations']
        }),

    })
})

export const {
    useUserInvitationsQuery,
    useUserInvitationQuery,
    useAcceptUserInvitationMutation,
    useDeclineUserInvitationMutation,
    useCreateInvitationMutation,
    useInvitationsQuery,
    useResendInvitationMutation
} = invitationsApi;