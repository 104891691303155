import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { useTotp } from 'src/hooks/useTotp';
import {
  ApiKycInitRequest,
  useTriggerKycVerificationMutation,
} from 'src/models/kycVerifications';
interface FormProps {
  onClose?: () => void;
  accountHolderIdForHeader: string;
}
export const TriggerKycForm: FC<FormProps> = ({
  onClose,
  accountHolderIdForHeader,
}) => {
  const [triggerKycVerification] = useTriggerKycVerificationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        accountHolderIdForHeader: accountHolderIdForHeader,
        addressVerificationRequired: false,
        selfieVerificationRequired: false,
      }}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting },
      ): Promise<void> => {
        try {
          const triggerKycVerificationResponse = await triggerKycVerification(
            values as ApiKycInitRequest,
          ).unwrap();

          if (triggerKycVerificationResponse?.kycStatus) {
            toast.success(`KYC triggered successfully.`);
            onClose();
          }
        } catch (err) {
          // toast.error('An error has occured');
          console.log(err);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleBlur,
        handleChange,
        values,
        touched,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Trigger KYC
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <FormControl component="fieldset" variant="outlined">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={false} />}
                    label={'Address Verification'}
                    name="addressVerificationRequired"
                    onChange={handleChange}
                  />
                </FormGroup>

                {touched.addressVerificationRequired &&
                errors.addressVerificationRequired ? (
                  <FormHelperText error>
                    {touched.addressVerificationRequired &&
                      errors.addressVerificationRequired}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <FormControl component="fieldset" variant="outlined">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={false} />}
                    label={'Selfie Verification'}
                    name="selfieVerificationRequired"
                    onChange={handleChange}
                  />
                </FormGroup>

                {touched.selfieVerificationRequired &&
                errors.selfieVerificationRequired ? (
                  <FormHelperText error>
                    {touched.selfieVerificationRequired &&
                      errors.selfieVerificationRequired}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  // loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
