import { NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Chip,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import { Formik } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import {
  useBankingConfigurationsQuery,
  useDeleteBankingConfigurationMutation,
} from 'src/models/configurations';
import { Attributes } from './Attributes';
import { CorrespondentBanks } from './CorrespondentBanks';
import { BankingForm } from './forms/BankingForm';
import { PartnerBanks } from './PartnerBanks';

interface DetailRow {
  name: string;
  value: any;
}

export const BankingView = ({
  accountHolderId,
  isGlobal,
}: {
  accountHolderId: string;
  isGlobal: boolean;
}) => {
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const [addBankingOpened, setAddBankingOpened] = useState<boolean>(false);
  const [editBankingOpened, setEditBankingOpened] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteBanking] = useDeleteBankingConfigurationMutation();
  const { data: banking, isFetching } = useBankingConfigurationsQuery(
    {
      accountHolderIdForHeader: accountHolderId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const formikRef = useRef(null);
  useTotp(formikRef);

  const bankingInfoData: DetailRow[] = useMemo(
    () =>
      banking
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {banking.id}
              </Typography>
            ),
          },
          {
            name: 'Bank Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {banking.bankName}
              </Typography>
            ),
          },
          {
            name: 'Bank Address',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {banking.bankAddress}
              </Typography>
            ),
          },
          {
            name: 'Bank Admin',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {banking.bankAdmin}
              </Typography>
            ),
          },
          {
            name: 'Enabled',
            value: (
              <Chip
                label={banking.enabled ? 'Enabled' : 'Disabled'}
                color={banking.enabled ? 'success' : 'error'}
              />
            ),
          },
          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(banking.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
        ]
        : [],
    [accountHolderId, banking],
  );

  const breadcrumbs = (description) => [
    <Link
      key="1"
      color={blueGrey[600]}
      component={RouterLink}
      to={'/transfer-channels'}
    >
      <Typography variant="overline">CONFIGURATION</Typography>
    </Link>,
    <Link key="1" color={blueGrey[500]} component={RouterLink} to={`banking`}>
      <Typography variant="overline">{description}</Typography>
    </Link>,
  ];

  return (
    <>
      <Grid container spacing={3} sx={{ mb: '35px' }}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          item
          xs={12}
        >
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {banking && breadcrumbs(banking.bankName)}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sx={[
              {
                width: '100%',
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            {!banking && !isFetching && (
              <PermissionGuard permission="CREATE_BANKING_CONFIGURATION">
                <Button
                  disabled={!isUserActive}
                  variant="contained"
                  onClick={() => setAddBankingOpened(true)}
                >
                  Create banking configuration
                </Button>
              </PermissionGuard>
            )}

            {banking && !isFetching && (
              <PermissionGuard permission="UPDATE_BANKING_CONFIGURATION">
                <Button
                  disabled={!isUserActive}
                  variant="contained"
                  onClick={() => setEditBankingOpened(true)}
                >
                  Edit banking configuration
                </Button>
              </PermissionGuard>
            )}

            {banking?.enabled && (
              <PermissionGuard permission="DELETE_BANKING_CONFIGURATION">
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    bankingConfigId: null,
                  }}
                  onSubmit={async (values): Promise<void> => {
                    try {
                      const result = await deleteBanking({
                        bankingConfigId: values.bankingConfigId,
                        accountHolderIdForHeader: accountHolderId,
                      }).unwrap();
                      if (result == null) {
                        toast.success(
                          'Banking Configuration removed successfully',
                        );
                      }
                    } catch (err) {
                      //
                    }
                  }}
                >
                  {({ setFieldValue }): JSX.Element => (
                    <Button
                      disabled={!isUserActive}
                      variant="contained"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            'Are you sure to remove this Banking Configuration?',
                          subTitle: 'This operation cannot be undone',
                          onConfirm: async () => {
                            setFieldValue('bankingConfigId', banking.id);
                            formikRef?.current?.submitForm();
                          },
                        });
                      }}
                    >
                      Delete banking configuration
                    </Button>
                  )}
                </Formik>
              </PermissionGuard>
            )}
          </Grid>
        </Grid>

        {banking ? (
          <>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      disableTypography
                      title={
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="h6">
                            Banking configuration
                          </Typography>
                        </Box>
                      }
                    />

                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={2}
                      sx={{ p: '16px' }}
                    >
                      {bankingInfoData.map(({ name, value }) => (
                        <Grid item md={6} sm={12} xs={12} key={name}>
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {name}
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              {value}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                  <PartnerBanks
                    accountHolderId={accountHolderId}
                  // bankingConfigId={banking.id}
                  />
                  <CorrespondentBanks
                    accountHolderId={accountHolderId}
                    bankingConfigId={banking.id}
                  />
                  <Attributes
                    accountHolderId={accountHolderId}
                    bankingConfigId={banking.id}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      {!banking && (
        <>
          <PermissionGuard permission="CREATE_BANKING_CONFIGURATION">
            <Drawer
              anchor="right"
              open={addBankingOpened}
              onClose={() => setAddBankingOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <BankingForm
                accountHolderId={accountHolderId}
                isGlobal={isGlobal}
                onClose={() => setAddBankingOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
        </>
      )}

      {banking && (
        <>
          <PermissionGuard permission="UPDATE_BANKING_CONFIGURATION">
            <Drawer
              anchor="right"
              open={editBankingOpened == true}
              onClose={() => setEditBankingOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <BankingForm
                accountHolderId={accountHolderId}
                isGlobal={isGlobal}
                banking={banking}
                onClose={() => setEditBankingOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
        </>
      )}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default BankingView;
