import { lazy } from 'react';
import { Route, Routes } from 'react-router';

import { Loadable } from 'src/components/Loadable';
import LoadingScreen from '../Loading';
const LazyLoadableScreen = (screen: () => any) =>
  Loadable(lazy(screen), <LoadingScreen />);

const PaymentSelectionScreen = LazyLoadableScreen(
  () => import('./PaymentSelection'),
);
const EmbeddedScreen = LazyLoadableScreen(() => import('./embedded'));

export const CheckoutDemoRoutes = () => (
  <Routes>
    <Route path="/" element={<PaymentSelectionScreen />} />
    <Route path="embedded" element={<EmbeddedScreen />} />
  </Routes>
);

// Demo Overrides
// http://localhost:3000/demo?demoAccountHolderId=&demoAcquiringConfigId=&demoApiKey=
