import { Chip, colors } from '@mui/material';

import { InvitationStatus } from 'src/models/invitations';




const statusColors = {
    CANCELED: colors.red,
    DECLINED: colors.red,
    PENDING: colors.orange,
    Accepted: colors.blueGrey,
};


export const InvitationStatusLabel = ({
  status,
}: {
  status: InvitationStatus;
}) => {
  const backgroundColor = statusColors[status] && `${statusColors[status][50]} !important`;
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
