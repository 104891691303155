import { Delete } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiAccountCurrency,
  useDeleteAccountCurrencyRestrictionMutation,
  useDeleteAccountCurrencyRestrictionRequestMutation,
} from 'src/models/restrictions';
interface AccountCurrenciesTable {
  accountCurrencies: ApiAccountCurrency[];
  accountHolderId: string;
}

const headCells = [
  //   { title: 'Id', id: 'id', doSort: true },
  { title: 'Currency code', id: 'currencyCode', doSort: true },
  { title: 'Create on registration', id: 'createOnRegistration', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const AccountCurrenciesTable: FC<AccountCurrenciesTable> = ({
  accountCurrencies,
  accountHolderId,
}) => {
  const { hasPermission } = usePermissions();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteAccountCurrencyRestriction] =
    useDeleteAccountCurrencyRestrictionMutation();
  const [deleteAccountCurrencyRestrictionRequest] =
    useDeleteAccountCurrencyRestrictionRequestMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            accountCurrency: null,
            accountCurrencyId: null,
            permission: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              if (values.permission == 'DELETE_ACCOUNT_CURRENCY_RESTRICTION') {
                await deleteAccountCurrencyRestriction({
                  accountCurrencyId: values.accountCurrencyId,
                  accountHolderIdForHeader: accountHolderId,
                }).unwrap();
              }
              if (values.permission == 'REQUEST_ACCOUNT_CURRENCY_RESTRICTION') {
                await deleteAccountCurrencyRestrictionRequest({
                  ...values.accountCurrency,
                  accountHolderIdForHeader: accountHolderId,
                }).unwrap();
              }
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id }) => (
                    <TableCell key={id}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {accountCurrencies.map((accountCurrency) => {
                  return (
                    <TableRow
                      hover
                      key={accountCurrency.id}
                    // onClick={() => navigate(`${accountCurrency.id}`)}
                    >
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {accountCurrency.id}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Chip
                          sx={{ pl: '5px', m: 1 }}

                          label={`${accountCurrency.currencyCode}`}
                          icon={<CurrencyFlag currency={accountCurrency.currencyCode.toLocaleLowerCase()} size="md" />}
                        />
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={
                            accountCurrency.createOnRegistration ? 'Yes' : 'No'
                          }
                          color={
                            accountCurrency.createOnRegistration
                              ? 'success'
                              : 'error'
                          }
                        />
                      </TableCell>

                      <TableCell>
                        <PermissionGuard permission="DELETE_ACCOUNT_CURRENCY_RESTRICTION">
                          <Tooltip title="Delete">
                            <IconButton
                              disabled={!isUserActive}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    'Are you sure to remove this account currency restriction?',
                                  subTitle: 'This operation cannot be undone',
                                  onConfirm: () => {
                                    setFieldValue(
                                      'accountCurrencyId',
                                      accountCurrency.id,
                                    );
                                    setFieldValue(
                                      'permission',
                                      'DELETE_ACCOUNT_CURRENCY_RESTRICTION',
                                    );
                                    formikRef?.current?.submitForm();
                                  },
                                });
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </PermissionGuard>

                        {!hasPermission('ADMIN') && (
                          <PermissionGuard permission="REQUEST_ACCOUNT_CURRENCY_RESTRICTION">
                            <Tooltip title="Request delete">
                              <IconButton
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      'Are you sure to request this account currency restriction deletion?',
                                    subTitle: 'This operation cannot be undone',
                                    onConfirm: () => {
                                      setFieldValue(
                                        'accountCurrency',
                                        accountCurrency,
                                      );
                                      setFieldValue(
                                        'permission',
                                        'REQUEST_ACCOUNT_CURRENCY_RESTRICTION',
                                      );
                                      formikRef?.current?.submitForm();
                                    },
                                  });
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </PermissionGuard>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
