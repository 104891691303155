import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import Idle from 'src/components/idle/Idle';
import { selectAccountHolderId } from 'src/models/auth';
import { AccountHolderSelectedGuard } from 'src/models/auth/components/AccountHolderSelectedGuard';
import { AuthGuard } from 'src/models/auth/components/AuthGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import AccountDetails from 'src/screens/AccountDetails';
import { AccountHolderTabs } from 'src/screens/AccountHolders/AccountHolderTabs';
import AccountHolderChoice from 'src/screens/Authentication/AccountHolderChoice';
import AccountHolderRegisterScreen from 'src/screens/Authentication/AccountHolderRegister';
import Invitation from 'src/screens/Authentication/Invitation';
import InvitationRegisterScreen from 'src/screens/Authentication/InvitationRegister';
import KYCScreen from 'src/screens/Authentication/KYC';
import KYCPendingScreen from 'src/screens/Authentication/KYCPending';
import PendingInvitations from 'src/screens/Authentication/PendingInvitations';
import UserVerification from 'src/screens/Authentication/UserVerification';
import UserVerificationConfirm from 'src/screens/Authentication/UserVerificationConfirm';
import BeneficiaryDetails from 'src/screens/BeneficiaryDetails';
import { CheckoutDemoRoutes } from 'src/screens/CheckoutDemo';
import { ConfigurationRoutes } from 'src/screens/Configuration';
import { DiagnosticsRoutes } from 'src/screens/Diagnostics';
import { Help } from 'src/screens/Help';
import { IGamingDemoRoutes } from 'src/screens/IGamingDemo';
import { OnboardingRoutes } from 'src/screens/Onboarding/Onboarding';
import { RestrictionRoutes } from 'src/screens/Restriction';
import { TelexRoutes } from 'src/screens/Telex';
import TransactionDetails from 'src/screens/TransactionDetails';
import TransferChannelDetails from 'src/screens/TransferChannelDetails';
import UserDetails from 'src/screens/UserDetails';
import { LazyLoadableScreen } from './LazyLoadableScreen';

const DashboardScreen = LazyLoadableScreen(() => import('src/screens/Home'));
const ForgotPasswordScreen = LazyLoadableScreen(
  () => import('src/screens/Authentication/ForgotPassword'),
);
const ForgotPasswordConfirmScreen = LazyLoadableScreen(
  () => import('src/screens/Authentication/ForgotPasswordConfirm'),
);
const ChangePasswordScreen = LazyLoadableScreen(
  () => import('src/screens/Authentication/ChangePassword'),
);
const LoginScreen = LazyLoadableScreen(
  () => import('src/screens/Authentication/Login'),
);
const NotFoundScreen = LazyLoadableScreen(() => import('src/screens/NotFound'));
const PasswordResetScreen = LazyLoadableScreen(
  () => import('src/screens/Authentication/PasswordReset'),
);
const MaintenanceScreen = LazyLoadableScreen(
  () => import('src/screens/Authentication/Maintenance'),
);
const OnboardingBusinessScreen = LazyLoadableScreen(
  () => import('src/screens/Onboarding/Business'),
);
const RegisterScreen = LazyLoadableScreen(
  () => import('src/screens/Authentication/Register'),
);
const TransactionsScreen = LazyLoadableScreen(
  () => import('src/screens/Transactions'),
);

const UsersScreen = LazyLoadableScreen(() => import('src/screens/Users'));

const ApiKeysScreen = LazyLoadableScreen(() => import('src/screens/ApiKeys'));

const AccountsScreen = LazyLoadableScreen(() => import('src/screens/Accounts'));

const TransferChannelsScreen = LazyLoadableScreen(
  () => import('src/screens/TransferChannels'),
);

const TransferScreen = LazyLoadableScreen(() => import('src/screens/Transfer'));
const AdjustmentScreen = LazyLoadableScreen(
  () => import('src/screens/Adjustments'),
);

const AccountHoldersScreen = LazyLoadableScreen(
  () => import('src/screens/AccountHolders'),
);

const ManageProfileScreen = LazyLoadableScreen(
  () => import('src/screens/ManageProfile'),
);

const ImpersonateUserScreen = LazyLoadableScreen(
  () => import('src/screens/ImpersonateUser'),
);

const BeneficiariesScreen = LazyLoadableScreen(() => import('src/screens/Beneficiaries'));

const config = { idle: 870, timeout: 30, ping: 400, debug: false };

export const Navigation = () => {
  const accountHolderId = useSelector(selectAccountHolderId);
  const { hasPermission } = usePermissions();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth">
          <Route path="forgot-password">
            <Route path="confirm" element={<ForgotPasswordConfirmScreen />} />
            <Route index element={<ForgotPasswordScreen />} />
          </Route>
          <Route path="login" element={<LoginScreen />} />
          <Route path="register" element={<RegisterScreen />} />
          <Route
            path="reset-password/:forgotPasswordId"
            element={<PasswordResetScreen />}
          />

          <Route
            path="change-password"
            element={
              <AuthGuard>
                <ChangePasswordScreen />
              </AuthGuard>
            }
          />

          {/* default redirect to login */}
          {/* <Route index element={<Navigate to="/auth/login" replace />} /> */}
          <Route
            index
            element={<Navigate to={`/auth/login${location.search}`} replace />}
          />
        </Route>
        <Route
          path="business/onboarding"
          element={<OnboardingBusinessScreen />}
        />
        <Route path="maintenance" element={<MaintenanceScreen />} />
        <Route path="invitations/:invitationId" element={<Invitation />} />
        <Route
          path="invitations/register/:invitationId"
          element={<InvitationRegisterScreen />}
        />
        <Route
          path="user/:verificationId/verification/:verificationCode"
          element={<UserVerificationConfirm />}
        />
        <Route path="user/verification" element={<UserVerification />} />

        <Route path="api-docs">
          <Route index element={<Help />} />
          <Route path=":path" element={<Help />} />
        </Route>

        <Route
          path="kyc-pending"
          element={
            <AuthGuard>
              <KYCPendingScreen />
            </AuthGuard>
          }
        />

        <Route path="/" element={<Idle config={config} />}>
          <Route
            path="account-holder-choice"
            element={
              <AuthGuard>
                <AccountHolderChoice />
              </AuthGuard>
            }
          />
          <Route
            path="kyc"
            element={
              <AuthGuard>
                <KYCScreen accountHolderId={accountHolderId} />
              </AuthGuard>
            }
          />

          <Route
            path="account-holder-register"
            element={
              <AuthGuard>
                <AccountHolderRegisterScreen />
              </AuthGuard>
            }
          />
          <Route
            path="pending-invitations"
            element={
              <AuthGuard>
                <PendingInvitations />
              </AuthGuard>
            }
          />
          <Route
            path="/"
            element={
              <AuthGuard>
                <AccountHolderSelectedGuard>
                  <DashboardLayout />
                </AccountHolderSelectedGuard>
              </AuthGuard>
            }
          >
            <Route
              path="manage-profile"
              element={
                <ManageProfileScreen accountHolderId={accountHolderId} />
              }
            />
            <Route
              path="impersonate-user"
              element={<ImpersonateUserScreen />}
            />
            <Route
              path="dashboard"
              element={<DashboardScreen accountHolderId={accountHolderId} />}
            />
            <Route
              path="transactions"
              element={
                <TransactionsScreen
                  accountHolderId={accountHolderId}
                  isGlobal={true}
                />
              }
            />
            <Route
              path="transactions/:id"
              element={<TransactionDetails accountHolderId={accountHolderId} />}
            />
            <Route
              path="telexes/*"
              element={<TelexRoutes accountHolderId={accountHolderId} />}
            />
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route
              path="users"
              element={
                <UsersScreen
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="users/:id"
              element={
                <UserDetails
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="accounts"
              element={
                <AccountsScreen
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="accounts/:id"
              element={
                <AccountDetails
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="beneficiaries"
              element={
                <BeneficiariesScreen
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="beneficiaries/:id"
              element={
                <BeneficiaryDetails
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="transfer-channels"
              element={
                <TransferChannelsScreen accountHolderId={accountHolderId} />
              }
            />
            <Route
              path="transfer-channels/:id"
              element={
                <TransferChannelDetails accountHolderId={accountHolderId} />
              }
            />
            <Route
              path="onboarding/*"
              element={
                <OnboardingRoutes
                  accountHolderId={accountHolderId}
                />
              }
            />
            <Route
              path="api-keys"
              element={
                <ApiKeysScreen
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="account-holders/:id/*"
              element={<AccountHolderTabs accountHolderId={accountHolderId} />}
            />
            <Route
              path="account-holders"
              element={
                <AccountHoldersScreen accountHolderId={accountHolderId} />
              }
            />
            <Route
              path="transfer"
              element={<TransferScreen accountHolderId={accountHolderId} />}
            />
            <Route
              path="transfer/*"
              element={<Navigate replace to="../transfer" />}
            />
            <Route
              path="adjustment"
              element={<AdjustmentScreen accountHolderId={accountHolderId} />}
            />
            <Route
              path="adjustment/*"
              element={<Navigate replace to="../adjustment" />}
            />
            <Route
              path="configuration/*"
              element={
                <ConfigurationRoutes
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
            <Route
              path="restriction/*"
              element={
                <RestrictionRoutes
                  accountHolderId={accountHolderId}
                  isGlobal={hasPermission('ADMIN')}
                />
              }
            />
          </Route>
        </Route>
        <Route path="demo/*" element={<CheckoutDemoRoutes />} />
        <Route path="igaming/*" element={<IGamingDemoRoutes />} />
        <Route
          path="diagnostics/*"
          element={
            <AuthGuard>
              <DiagnosticsRoutes />
            </AuthGuard>
          }
        />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
};
