import type { FC } from 'react';
import toast from 'react-hot-toast';
import { Navigate, useLocation } from 'react-router-dom';

import { selectIsAuthorized, selectUserStatus } from 'src/models/auth/selectors';
import { useSelector } from 'src/services/redux/store';

export const AuthGuard: FC = ({ children }) => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const userStatus = useSelector(selectUserStatus);
  const location = useLocation();
  if (!isAuthorized ) {
    return <Navigate to="/auth" />;
  }
  if (userStatus == 'EXPIRED' && location.pathname != "/auth/change-password") {
    toast.error('Password expired. Please change password.');
    return <Navigate to="/auth/change-password" />;
  }

  return <>{children}</>;
};
