import * as Yup from "yup";
import YupPassword from 'yup-password';

import { localConfig } from "src/services/config";

YupPassword(Yup);

export const PASSWORD_SUBMIT_YUP_RULE = Yup.string()
    .password()
    .min(localConfig.PASSWORD_MIN_LENGTH)
    .required('Password is required');
