import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AccountHolderPermission } from 'src/models/accountHolder';
import { selectPermissionsMap } from '../selectors';

export const usePermissions = () => {
  const permissionsMap = useSelector(selectPermissionsMap);

//   useEffect(() => {
// alert(JSON.stringify(permissionsMap));
//   },[permissionsMap]
//   )

  const hasPermission = useCallback(
    // (permission: AccountHolderPermission) => !!permissionsMap[permission],
    (permission: AccountHolderPermission) => permissionsMap?.includes(permission),
    [permissionsMap],
  );

  return { hasPermission, permissionsMap };
};
