
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { AcquiringRequest, ApiAccountHolderAttributeCreate, ApiAccountsAttributeCreate, ApiAcquiringConfig, ApiAcquiringConfigAttributeCreate, ApiAcquiringConfigCreate, ApiAcquiringConfigUpdate, ApiBankingConfig, ApiBankingConfigAttributeCreate, ApiBankingConfigCreate, ApiBankingConfigUpdate, ApiConfigAttribute, ApiConfigAttributeUpdate, ApiCorrespondentBank, ApiCorrespondentBankCreate, ApiCorrespondentBankUpdate, ApiFeeConfig, ApiFeeConfigCreateUpdate, ApiPartnerBank, ApiTelexConfig, ApiTelexConfigCreate, ApiTelexConfigUpdate, FeesRequest, PageApiAcquiringConfig, PageApiFeeConfig, PageApiTelexConfig, TelexesRequest } from './types';


export const configurationsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        telexConfigurations: builder.query<PageApiTelexConfig, TelexesRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/telex-configurations',
                }
            },
            providesTags: ['Configurations-Telex']
        }),
        telexConfiguration: builder.query<ApiTelexConfig, { telexConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ telexConfigId, accountHolderIdForHeader }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/telex-configurations/${telexConfigId}`,

            }),
            providesTags: ['Configurations-Telex']
        }),
        addTelexConfiguration: builder.mutation<ApiTelexConfig, ApiTelexConfigCreate>({
            query: ({ accountHolderIdForHeader, ...telex }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: telex,
                url: 'gateway/telex-configurations',
            }),
            invalidatesTags: ['Configurations-Telex']
        }),
        updateTelexConfiguration: builder.mutation<ApiTelexConfig, ApiTelexConfigUpdate>({
            query: ({ telexConfigId, accountHolderIdForHeader, ...telex }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...telex },
                url: `gateway/telex-configurations/${telexConfigId}`,
            }),
            invalidatesTags: ['Configurations-Telex']
        }),
        deleteTelexConfiguration: builder.mutation<void, { telexConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ telexConfigId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/telex-configurations/${telexConfigId}`,
            }),
            invalidatesTags: ['Configurations-Telex']
        }),


        feeConfigurations: builder.query<PageApiFeeConfig, FeesRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/fee-configurations',
                }
            },
            providesTags: ['Configurations-Fee']
        }),
        feeConfiguration: builder.query<ApiFeeConfig, { feeConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ feeConfigId, accountHolderIdForHeader }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/fee-configurations/${feeConfigId}`,

            }),
            providesTags: ['Configurations-Fee']
        }),
        addFeeConfiguration: builder.mutation<ApiFeeConfig, ApiFeeConfigCreateUpdate>({
            query: ({ accountHolderIdForHeader, ...account }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: account,
                url: 'gateway/fee-configurations',
            }),
            invalidatesTags: ['Configurations-Fee']
        }),
        updateFeeConfiguration: builder.mutation<ApiFeeConfig, ApiFeeConfigCreateUpdate>({
            query: ({ feeConfigId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest },
                url: `gateway/fee-configurations/${feeConfigId}`,
            }),
            invalidatesTags: ['Configurations-Fee']
        }),
        deleteFeeConfiguration: builder.mutation<void, { feeConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ feeConfigId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/fee-configurations/${feeConfigId}`,
            }),
            invalidatesTags: ['Configurations-Fee']
        }),
        feeConfigurationsTransactionTypes: builder.query<string[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: 'gateway/fee-configurations/transaction-types',
                }
            },
            transformResponse: (response: string[]) => {
                return (response?.sort((a, b) => (a > b ? 1 : -1)));
            },
            providesTags: ['Configurations-Fee']
        }),

        acquiringConfigurations: builder.query<PageApiAcquiringConfig, AcquiringRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/acquiring-configurations',
                }
            },
            providesTags: ['Configurations-Acquiring']
        }),
        acquiringConfiguration: builder.query<ApiAcquiringConfig, { acquiringConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ acquiringConfigId, accountHolderIdForHeader }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/acquiring-configurations/${acquiringConfigId}`,

            }),
            providesTags: ['Configurations-Acquiring']
        }),
        addAcquiringConfiguration: builder.mutation<ApiAcquiringConfig, ApiAcquiringConfigCreate>({
            query: ({ accountHolderIdForHeader, ...account }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: account,
                url: 'gateway/acquiring-configurations',
            }),
            invalidatesTags: ['Configurations-Acquiring']
        }),
        updateAcquiringConfiguration: builder.mutation<ApiAcquiringConfig, ApiAcquiringConfigUpdate>({
            query: ({ acquiringConfigId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest },
                url: `gateway/acquiring-configurations/${acquiringConfigId}`,
            }),
            invalidatesTags: ['Configurations-Acquiring']
        }),
        deleteAcquiringConfiguration: builder.mutation<void, { acquiringConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ acquiringConfigId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/acquiring-configurations/${acquiringConfigId}`,
            }),
            invalidatesTags: ['Configurations-Acquiring']
        }),
        attributeDefinitions: builder.query<string[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/attributes/definitions`,
                }
            },
            providesTags: ['Configurations-Attribute']
        }),
        attributes: builder.query<ApiConfigAttribute[], { acquiringConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ acquiringConfigId, accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/acquiring-configurations/${acquiringConfigId}/attributes`,
                }
            },
            providesTags: ['Configurations-Attribute']
        }),
        addAttribute: builder.mutation<ApiConfigAttribute, ApiAcquiringConfigAttributeCreate>({
            query: ({ acquiringConfigId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: rest,
                url: `gateway/acquiring-configurations/${acquiringConfigId}/attributes`,
            }),
            invalidatesTags: ['Configurations-Attribute']
        }),
        updateAttribute: builder.mutation<ApiConfigAttribute, ApiConfigAttributeUpdate>({
            query: ({ id, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest },
                url: `gateway/attributes/${id}`,
            }),
            invalidatesTags: ['Configurations-Attribute']
        }),
        deleteAttribute: builder.mutation<void, { attributeId: string, accountHolderIdForHeader: string }>({
            query: ({ attributeId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/attributes/${attributeId}`,
            }),
            invalidatesTags: ['Configurations-Attribute']
        }),



        bankingConfigurations: builder.query<ApiBankingConfig, { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: 'gateway/banking-configurations',
                }
            },
            providesTags: ['Configurations-Banking']
        }),
        bankingConfiguration: builder.query<ApiBankingConfig, { bankingConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ bankingConfigId, accountHolderIdForHeader }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/banking-configurations/${bankingConfigId}`,

            }),
            providesTags: ['Configurations-Banking'],
        }),
        addBankingConfiguration: builder.mutation<ApiBankingConfig, ApiBankingConfigCreate>({
            query: ({ accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: rest,
                url: 'gateway/banking-configurations',
            }),
            invalidatesTags: ['Configurations-Banking']
        }),
        updateBankingConfiguration: builder.mutation<ApiBankingConfig, ApiBankingConfigUpdate>({
            query: ({ bankingConfigId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest },
                url: `gateway/banking-configurations/${bankingConfigId}`,
            }),
            invalidatesTags: ['Configurations-Banking']
        }),
        deleteBankingConfiguration: builder.mutation<void, { bankingConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ bankingConfigId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/banking-configurations/${bankingConfigId}`,
            }),
            invalidatesTags: ['Configurations-Banking']
        }),
        bankingAttributes: builder.query<ApiConfigAttribute[], { bankingConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ bankingConfigId, accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/banking-configurations/${bankingConfigId}/attributes`,
                }
            },
            providesTags: ['Configurations-Attribute']
        }),
        addBankingAttribute: builder.mutation<ApiConfigAttribute, ApiBankingConfigAttributeCreate>({
            query: ({ bankingConfigId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: rest,
                url: `gateway/banking-configurations/${bankingConfigId}/attributes`,
            }),
            invalidatesTags: ['Configurations-Attribute']
        }),



        correspondentBanks: builder.query<ApiCorrespondentBank[], { bankingConfigId: string, accountHolderIdForHeader: string }>({
            query: ({ bankingConfigId, accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/banking-configurations/${bankingConfigId}/correspondent-banks`,
                }
            },
            providesTags: ['Configurations-CorrespondentBank']
        }),
        addCorrespondentBank: builder.mutation<ApiBankingConfig, ApiCorrespondentBankCreate>({
            query: ({ bankingConfigId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: rest,
                url: `gateway/banking-configurations/${bankingConfigId}/correspondent-banks`,
            }),
            invalidatesTags: ['Configurations-CorrespondentBank']
        }),
        updateCorrespondentBank: builder.mutation<ApiBankingConfig, ApiCorrespondentBankUpdate>({
            query: ({ correspondentBankId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest },
                url: `gateway/correspondent-banks/${correspondentBankId}`,
            }),
            invalidatesTags: ['Configurations-CorrespondentBank']
        }),
        deleteCorrespondentBank: builder.mutation<void, { correspondentBankId: string, accountHolderIdForHeader: string }>({
            query: ({ correspondentBankId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/correspondent-banks/${correspondentBankId}`,
            }),
            invalidatesTags: ['Configurations-CorrespondentBank']
        }),

        allPartnerBanks: builder.query<ApiPartnerBank[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/partner-banks/list`,
                }
            },
            providesTags: ['Configurations-PartnerBank']
        }),

        partnerBanks: builder.query<ApiPartnerBank[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/partner-banks`,
                }
            },
            providesTags: ['Configurations-PartnerBank']
        }),
        addPartnerBank: builder.mutation<any, { id: string, accountHolderIdForHeader: string }>({
            query: ({ id, accountHolderIdForHeader }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                url: `gateway/partner-banks/${id}`,
            }),
            invalidatesTags: ['Configurations-PartnerBank']
        }),
        deletePartnerBank: builder.mutation<void, { id: string, accountHolderIdForHeader: string }>({
            query: ({ id, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/partner-banks/${id}`,
            }),
            invalidatesTags: ['Configurations-PartnerBank']
        }),






        accountAttributes: builder.query<ApiConfigAttribute[], { accountId: string, accountHolderIdForHeader: string }>({
            query: ({ accountId, accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/accounts/${accountId}/attributes`,
                }
            },
            providesTags: ['Configurations-Attribute']
        }),
        addAccountAttribute: builder.mutation<ApiConfigAttribute, ApiAccountsAttributeCreate>({
            query: ({ accountId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: rest,
                url: `gateway/accounts/${accountId}/attributes`,
            }),
            invalidatesTags: ['Configurations-Attribute']
        }),

        accountHolderAttributes: builder.query<ApiConfigAttribute[], { accountHolderId: string, accountHolderIdForHeader: string }>({
            query: ({ accountHolderId, accountHolderIdForHeader }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: `gateway/attributes`,
                }
            },
            providesTags: ['Configurations-Attribute']
        }),
        addAccountHolderAttribute: builder.mutation<ApiConfigAttribute, ApiAccountHolderAttributeCreate>({
            query: ({ accountHolderId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: rest,
                url: `gateway/attributes`,
            }),
            invalidatesTags: ['Configurations-Attribute']
        }),


    })
})

export const {
    useTelexConfigurationsQuery,
    useTelexConfigurationQuery,
    useAddTelexConfigurationMutation,
    useUpdateTelexConfigurationMutation,
    useDeleteTelexConfigurationMutation,

    useFeeConfigurationsQuery,
    useFeeConfigurationQuery,
    useAddFeeConfigurationMutation,
    useUpdateFeeConfigurationMutation,
    useDeleteFeeConfigurationMutation,

    useFeeConfigurationsTransactionTypesQuery,

    useAcquiringConfigurationsQuery,
    useAcquiringConfigurationQuery,
    useAddAcquiringConfigurationMutation,
    useUpdateAcquiringConfigurationMutation,
    useDeleteAcquiringConfigurationMutation,


    useBankingConfigurationsQuery,
    useBankingConfigurationQuery,
    useAddBankingConfigurationMutation,
    useUpdateBankingConfigurationMutation,
    useDeleteBankingConfigurationMutation,
    useBankingAttributesQuery,
    useAddBankingAttributeMutation,

    useCorrespondentBanksQuery,
    useAddCorrespondentBankMutation,
    useUpdateCorrespondentBankMutation,
    useDeleteCorrespondentBankMutation,

    useAttributeDefinitionsQuery,
    useAttributesQuery,
    useAddAttributeMutation,
    useUpdateAttributeMutation,
    useDeleteAttributeMutation,

    useAllPartnerBanksQuery,
    usePartnerBanksQuery,
    useAddPartnerBankMutation,
    useDeletePartnerBankMutation,

    useAccountAttributesQuery,
    useAddAccountAttributeMutation,
    useAccountHolderAttributesQuery,
    useAddAccountHolderAttributeMutation,
} = configurationsApi;