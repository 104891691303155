import { Close } from '@mui/icons-material';
import {
  LoadingButton,
} from '@mui/lab';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, useRef } from 'react';

import { useTotp } from 'src/hooks/useTotp';
import { useGetPermissionGroupsQuery } from 'src/models/permission-groups';
import { ApiUserResponse, useUpdateUserPermissionsMutation } from 'src/models/users';
import { UpdateUserPermissionFormValues } from 'src/models/users';
import { userPermissionFormValidationSchema } from '../utils';
interface FormProps {
  onClose?: () => void;
  user?: ApiUserResponse;
  accountHolderId: string;
  accountHolderIdForHeader: string;
}
export const UserPermissionsForm: FC<FormProps> = ({ onClose, user, accountHolderId, accountHolderIdForHeader }) => {

  const { data: permissionGroups, isLoading } = useGetPermissionGroupsQuery({
    accountHolderId
  }, {
    refetchOnMountOrArgChange: true,
    skip: accountHolderId == null
  });

  const [updateUserPermissions] = useUpdateUserPermissionsMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<UpdateUserPermissionFormValues>
      innerRef={formikRef}
      initialValues={{
        permissionGroupIds: user.associations.find(x => x.accountHolderId == accountHolderId)?.permissionGroups.map(p => p.id),
      }}
      validationSchema={userPermissionFormValidationSchema

      }
      onSubmit={async (
        values
      ): Promise<void> => {

        try {
          const registerResponse = await updateUserPermissions({ ...values, userId: user.id, accountHolderId, accountHolderIdForHeader }).unwrap();
          onClose();
        } catch (err) {
          //  console.log(err);
        }

      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Edit Permissions
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>


              {isLoading && <CircularProgress />}
              {permissionGroups &&
                <FormControl fullWidth margin="normal">
                  <InputLabel id="select-permission-group-label">
                    Permissions
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    label='Permissions'
                    id="select-permission-group"
                    value={values.permissionGroupIds}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.filter(x => permissionGroups.filter(x => !x.userDefault).some(y => y.id == x)).map((value) => (
                          <Chip key={value} label={permissionGroups.find(x => x.id == value)?.name} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('permissionGroupIds', e.target.value);
                    }}
                  >
                    {permissionGroups.filter(x => !x.userDefault).map((permissionGroup) => (
                      <MenuItem key={permissionGroup.id} value={permissionGroup.id}>
                        {permissionGroup.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
