import { Button } from '@mui/material';
import { Box, Container } from '@mui/material';
import PropTypes from 'prop-types';
import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useBackGroundShadowLogo } from 'src/hooks/useBackGroundShadowLogo';
import {
  resetCredentials,
  selectIsAuthorized,
  useLogoutMutation,
} from 'src/models/auth';
import { useGetBankProfileQuery } from 'src/models/cms/api';
import { useGetBankProfileByDomainQuery } from 'src/models/cms/api';
import { resetBank, setBank } from 'src/models/cms/slice';
import { LogoText } from './logo/LogoText';

interface IAuthProps {
  children: ReactNode;
}

const AuthTemplate: FC<IAuthProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const [skip, setSkip] = React.useState<boolean>(true);
  const [logout] = useLogoutMutation();
  const isAuthorized = useSelector(selectIsAuthorized);
  const [skipDomain, setSkipDomain] = React.useState<boolean>(true);

  const bgLogo = useBackGroundShadowLogo();
  const bankData = useGetBankProfileQuery(
    { accountHolderId: searchParams.get('parentAccountHolderId') },
    {
      skip,
      refetchOnMountOrArgChange: true,
    },
  );
  const bankDataFromDomain = useGetBankProfileByDomainQuery(
    { domain: window.location.hostname },
    {
      skip: skipDomain,
      refetchOnMountOrArgChange: true,
    },
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('parentAccountHolderId') != null) setSkip(false);
    else setSkipDomain(false);
  }, []);

  useEffect(() => {
    if (!bankData.isFetching && bankData.data !== undefined) {
      if (bankData.data?.status == 'SUSPENDED') {
        navigate(`/maintenance${search}`);
      }
      dispatch(setBank({ bank: bankData.data }));
    }
  }, [bankData]);


  useEffect(() => {
    if (
      !bankDataFromDomain.isFetching &&
      bankDataFromDomain.data !== undefined
    ) {
      if (bankDataFromDomain.data?.status == 'SUSPENDED') {
        navigate(`/maintenance${search}`);
      }
      dispatch(setBank({ bank: bankDataFromDomain.data }));
    }
  }, [bankDataFromDomain]);

  const [heightSX, setHeightSX] = React.useState({ height: `200px` });
  const ref = React.useRef(null);

  const handleScroll = (e: Event) => {
    // console.log(`${ref.current.clientHeight} = ${window.scrollY}`);
    if (window.scrollY == 0) setHeightSX({ height: `200px` });
    else if (window.scrollY < ref.current.clientHeight)
      setHeightSX({ height: `calc(100vh - ${ref.current.clientHeight}px)` });
    else setHeightSX({ height: `100vh` });
  };

  React.useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener('scroll', (e) => handleScroll(e));
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${bgLogo})`,
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          backgroundSize: '100vh',
          height: '100%',
          width: '100%',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          backgroundImage:
            'linear-gradient(253.55deg, #F6F9FF 9.01%, #F4F8FF 99.52%)',
          position: 'fixed',
          top: 0,
          bottom: 0,
          width: '100%',
          zIndex: -2,
        }}
      />

      <Box
        id="container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          maxHeight: '100vh',
        }}
      >
        {/* Logo  */}
        <Box ref={ref} sx={{ display: 'flex', justifyContent: 'space-around' }}>
          {!bankData.isFetching &&
            !bankData.data &&
            !bankDataFromDomain.isFetching &&
            !bankDataFromDomain.data && <LogoText />}
          {!bankData.isFetching && bankData.data && (
            <Box
              sx={{ maxWidth: 300, height: 'auto', padding: '15px' }}
              component="img"
              src={`${bankData.data?.logoUrl}`}
            />
          )}

          {!bankDataFromDomain.isFetching && bankDataFromDomain.data && (
            <Box
              sx={{ maxWidth: 300, height: 'auto', padding: '15px' }}
              component="img"
              src={`${bankDataFromDomain.data?.logoUrl}`}
            />
          )}
        </Box>
        {/* Rest */}
        {(bankDataFromDomain.isSuccess || bankData.isSuccess || (bankDataFromDomain.isError || bankData.isError)) && (
          <Container
            sx={[
              {
                display: 'flex',
                flexDirection: 'row',
                gap: '5em',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  gap: '3em',
                },
              }),
            ]}
          >
            {/* left box */}
            <Box sx={{
              flex: '1 1 0px',
            }}>
              <Box
                sx={[
                  {
                    position: 'sticky',
                    top: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    transition: 'all 0.5s ease-in-out',
                    ...heightSX,
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      height: 'inherit'
                    },
                  }),
                ]}
              >
                <Box >
                  <Box component="h1" sx={{ typography: 'h2', mt: '52px' }}>

                    {!bankData.isFetching && bankData.data && (
                      <>{bankData.data?.displayName}</>
                    )}
                    {!bankDataFromDomain.isFetching && bankDataFromDomain.data && (
                      <>{bankDataFromDomain.data?.displayName}</>
                    )}
                  </Box>
                  {/* <Box>
                    {bankDataFromDomain.data?.accountHolderId == 'a3fa3c95-f6a2-4003-aa91-375e45d25b99' && (
                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                    )}
                  </Box> */}
                </Box>
              </Box>


            </Box>
            {/* right box */}
            <Box
              sx={{
                flex: '1 1 0px',
                marginBottom: '24px'

              }}>
              <Box>
                {isAuthorized && (
                  <Button
                    onClick={() => {
                      logout();
                      dispatch(resetCredentials());
                      dispatch(resetBank());
                    }}
                  >
                    Logout
                  </Button>
                )}
              </Box>
              {children}
            </Box>
            {/* </Box> */}
          </Container>
        )}
      </Box>
    </>
  );
};

AuthTemplate.propTypes = {
  children: PropTypes.node,
};

export default AuthTemplate;
