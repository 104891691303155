import { Close } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';

import { useCardTokensQuery } from 'src/models/transactions';
import { formatCreditCardNumber } from 'src/utils/creditCard';

interface Props {
  accountHolderId: string;
  cardTokenId: string;
  onClose: () => void;
}

interface DetailRow {
  name: string;
  value: any;
}

export const CardTokenDetails: FC<Props> = ({
  accountHolderId,
  cardTokenId,
  onClose,
}) => {
  const { data: cardToken } = useCardTokensQuery({
    accountHolderId,
    id: cardTokenId,
  }, { refetchOnMountOrArgChange: true, });

  const cardVaultData: DetailRow[] = useMemo(
    () => [
      {
        name: 'Token ID',
        value: cardToken?.id || '-',
      },
      {
        name: 'Vault ID',
        value: cardToken?.vaultId || '-',
      },
      {
        name: 'Card Number',
        value: cardToken?.cardNumber
          ? formatCreditCardNumber(cardToken.cardNumber)
          : '-',
      },
      {
        name: 'Card Expiry',
        value: cardToken?.expiryDate || '-',
      },
      {
        name: 'Name on Card',
        value: cardToken?.nameOnCard || '-',
      },
    ],
    [cardToken],
  );

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px 38px 24px 32px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            size="medium"
            sx={{ mr: 2 }}
          >
            <Close color="inherit" fontSize="inherit" />
          </IconButton>
          <Typography color={'textPrimary'} variant="h5">
            Card Vault Details
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ padding: '16px' }}>
        <Table >
          <TableBody>
            {cardToken
              ? cardVaultData.map(({ name, value }) => (
                <TableRow key={name}>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography color="textSecondary" variant="body2">
                      {name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none', wordWrap: 'break-word' }}>
                    <Typography
                      color="textPrimary"
                      component="div"
                      sx={{ maxWidth: '320px' }}
                      variant="body2"
                    >
                      {value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
              : null}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
