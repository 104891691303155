import { FilterAlt } from '@mui/icons-material';
import { Box, Button, colors, Container, Drawer, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
// import { KycVerificationForm } from 'src/models/KycVerifications/components/KycVerificationForm';
import { KycVerificationList } from './KycVerificationList';
import { KycVerificationsFilterForm } from './KycVerificationsFilterForm';
import { KycVerificationFilterForm } from './types';

const KycManagementScreen: FC<{accountHolderId:string; isGlobal:boolean}> = ({accountHolderId, isGlobal}) => {
  const [filter, setFilter] = useState<KycVerificationFilterForm>();
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    setFilterOpen(false);
  }, [filter]);


  useEffect(() => {
    setFilter(    
       { 
        kycStatus: [],
        idPassportNo:'',
        id:'',
        accountHolderId: (!isGlobal?accountHolderId:''),
        includeSubAccountHolders:false
       });
       }, []);

  return (
    <>
      <Helmet>
        <title>KYC Management</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12}>
          <Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            KYC
          </Typography>
        </Grid>
        <Box
        sx={[{
          display: 'flex',
          justifyContent:'space-between'
        },
        (theme) => ({
          [theme.breakpoints.down('md')]: {
             flexDirection: 'column',
          },
          [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
          },
      })
      ]}
      >
          <Typography color="textPrimary" variant="h5">
             KYC Management
          </Typography>

          <Box
           sx={[{
            display: 'flex',
            gap:1,
            marginBottom:'10px'
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
               flexDirection: 'column',
               gap:'10px',
            },
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent:'flex-end'  
            },
        })
        ]}
          >
              <Box sx={[{  display:'flex', justifyContent:'flex-end' },
                        (theme) => ({
                          [theme.breakpoints.down('sm')]: {
                            width:'100%'
                          },
                          [theme.breakpoints.up('sm')]: {
                            width: 'auto'
                          },
                      })]}>
             <FilterAlt
              color="primary"
              fontSize="large"
              sx={{ marginLeft: '10px' }}
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            />
              </Box>
          </Box>
      </Box>
       
        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >
            <Grid item xs={12}>
              <KycVerificationsFilterForm onFilterApply={setFilter} isGlobal={isGlobal} accountHolderId={accountHolderId}/>
            </Grid>
          </Grid>
        </Drawer>
         <PermissionGuard permission="GET_KYC_VERIFICATION">
          <KycVerificationList filter={filter}  accountHolderId={accountHolderId} />
        </PermissionGuard>
      </Container>
    </>
  );
};

export default KycManagementScreen;
