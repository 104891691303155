import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TransactionListResponse } from '.';

export type TransactionsState = {
transactions:TransactionListResponse;
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: { 
      transactions:null
  } as TransactionsState,
  reducers: {

    setTransactions: (
      state,
      {
        payload: { transactions },
      }: PayloadAction<{
        transactions: TransactionListResponse | null;
      }>,
    ) => {
      state.transactions = transactions;
    },
  },
});

export const { 
    setTransactions,
} = transactionsSlice.actions;

