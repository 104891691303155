import * as Yup from 'yup';

import { accountHolderIdValidationSchema } from 'src/components/forms';

export const currencyFormValidationSchema = () =>
    Yup.object({
        currencyCode: Yup.string().required('Currency code is required'),
        accountHolderIdForHeader: accountHolderIdValidationSchema()
    });

