import { Box, Container } from '@mui/material';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import AcquiringView from './AcquiringView';

export const AcquiringDetails: FC<{
  accountHolderId: string;
  isGlobal: boolean;
}> = ({ accountHolderId, isGlobal }) => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Helmet>
        <title>Acquiring Details</title>
      </Helmet>
      <Box>
        <Container maxWidth={false}>
          <PermissionGuard permission="GET_ACQUIRING_CONFIGURATION">
            <AcquiringView
              id={id}
              accountHolderId={accountHolderId}
              isGlobal={isGlobal}
            />
          </PermissionGuard>
        </Container>
      </Box>
    </>
  );
};
