import { format, parseISO } from 'date-fns';
import {
    CountryCode,
    getCountries,
} from 'libphonenumber-js';
import * as Yup from 'yup';

import { ApiKycDetailRequest, ApiKycVerification } from 'src/models/kycVerifications';
import { DATE_OF_BIRTH_MASK } from 'src/models/types';
import { ApiKycDetailFormValues } from './types';
const countries = getCountries();

const countryCodeSchema = Yup.mixed<CountryCode>().oneOf(countries);

export const userDetailsFormInitialValues = (accountHolderId: string, kycVerification: ApiKycVerification): ApiKycDetailFormValues => ({
    accountHolderIdForHeader: accountHolderId,
    kycVerificationId: kycVerification?.id,
    idPassportNo: kycVerification?.idPassportNo || '',
    idPassportIssueDate: parseISO(kycVerification?.idPassportIssueDate),
    idPassportExpiryDate: parseISO(kycVerification?.idPassportExpiryDate),
    email: kycVerification?.email || '',
    firstName: kycVerification?.firstName || '',
    lastName: kycVerification?.lastName || '',
    dateOfBirth: parseISO(kycVerification?.dateOfBirth),
    nationality: kycVerification?.nationality || '',
    addressLine1: kycVerification?.addressLine1 || '',
    addressLine2: kycVerification?.addressLine2 || '',
    city: kycVerification?.city || '',
    zipPostcode: kycVerification?.zipPostcode || '',
    countryState: kycVerification?.countryState || '',
    country: kycVerification?.country || '',
});

export const userDetailsFormValuesToUserDetailsRequestBody = (
    formValues: ApiKycDetailFormValues,
): ApiKycDetailRequest => ({
    accountHolderIdForHeader: formValues.accountHolderIdForHeader,
    kycVerificationId: formValues.kycVerificationId,
    dateOfBirth: format(formValues.dateOfBirth, DATE_OF_BIRTH_MASK),
    email: formValues.email,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    nationality: formValues.nationality as CountryCode,
    addressLine1: formValues.addressLine1,
    addressLine2: formValues.addressLine2,
    city: formValues.city,
    country: formValues.country as CountryCode,
    zipPostcode: formValues.zipPostcode,
    countryState: formValues.countryState == '' ? null : formValues.countryState,
    idPassportNo: formValues.idPassportNo,
    idPassportIssueDate: format(formValues.idPassportIssueDate, DATE_OF_BIRTH_MASK),
    idPassportExpiryDate: format(formValues.idPassportExpiryDate, DATE_OF_BIRTH_MASK),
});


export const updateUserDetailsFormValidationSchema =
    Yup.object({
        dateOfBirth: Yup.date()
            .max(new Date())
            .required('Date of birth is required')
            .nullable(),
        firstName: Yup.string().required('First name is required'),

        lastName: Yup.string().required('Last name is required'),
        nationality: countryCodeSchema.required('Nationality is required'),
        idPassportNo: Yup.string()
            .max(255)
            .required('Passport/Id Number is required'),
        idPassportIssueDate: Yup.date()
            .max(new Date())
            .required('Identity/Passport issue date is required')
            .nullable(),
        idPassportExpiryDate: Yup.date()
            .min(new Date())
            .required('Identity/Passport expiry date is required')
            .nullable(),
        addressLine1: Yup.string()
            .max(255)
            .required('Address is required'),
        addressLine2: Yup.string()
            .max(255)
            .notRequired(),
        zipPostcode: Yup.string()
            .max(255)
            .required('Zip/Post code is required'),
        city: Yup.string()
            .max(255)
            .required('City is required'),
        countryState: Yup.string().nullable(true)
            .when('country', {
                is: 'US',
                then: Yup.string().required('State is required'),
                otherwise: Yup.string().when('country', {
                    is: 'CA',
                    then: Yup.string().required('State is required'),
                    otherwise: Yup.string().notRequired().nullable(true),
                })
            }),
        country: countryCodeSchema.required('Country is required'),
    });

export const isUserDetailsFormCompleted = (kycVerification: ApiKycVerification): boolean => {
    try {
        updateUserDetailsFormValidationSchema.validateSync(kycVerification);
    } catch (e) {
        return false;
    }
    return true;
}
