import * as Yup from 'yup';

import { ApiTelexConfig } from 'src/models/configurations';
import { CreateUpdateTelexFormValues } from './types';

export const telexFormValidationSchema =
  Yup.object({
    telexPrefix: Yup.string().max(100, 'Friendly Name must be less than 100 characters').required('Friendly Name is required'),
    incomingUsername: Yup.string().required('Incoming Username is required'),
    incomingPassword: Yup.string().required('Incoming Password is required'),
    outgoingUsername: Yup.string().required('Outgoing Username is required'),
    outgoingPassword: Yup.string().required('Outgoing Password is required'),
    telexDomain: Yup.string().required('Telex Domain is required'),
    providerEmail: Yup.string().email().required('Provider Email is required'),
  });

export const telexToTelexFormValues = (
  telex: ApiTelexConfig, accountHolderIdForHeader: string
): CreateUpdateTelexFormValues => ({
  accountHolderIdForHeader,
  telexConfigId: telex.id,
  telexPrefix: telex.telexPrefix,
  incomingUsername: telex.incomingUsername,
  incomingPassword: telex.outgoingPassword,
  outgoingUsername: telex.outgoingUsername,
  outgoingPassword: telex.outgoingPassword,
  telexDomain: telex.telexDomain,
  providerEmail: telex.providerEmail,
  enabled: telex.enabled,
});