import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { FileInputField } from 'src/components/forms/FileInputField';
import { identityAttachmentTypeList } from 'src/models/kycVerifications';
import { ApiKycIdentificationsFormValues } from './types';

export const IdentityForm: FC = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<ApiKycIdentificationsFormValues>();

  return (
    <>
      <Box sx={{ mb: '4px', mt: '30px' }}>
        <Typography variant="h6">Identification Attachment</Typography>
        <Typography variant="subtitle2"></Typography>
      </Box>

      <FormControl fullWidth margin="normal">
        <InputLabel
          error={Boolean(
            touched.identityAttachmentType && errors.identityAttachmentType,
          )}
        >
          Identification Attachment Type
        </InputLabel>
        <Select
          error={Boolean(
            touched.identityAttachmentType && errors.identityAttachmentType,
          )}
          value={values.identityAttachmentType}
          label="Identification Attachment Type"
          onBlur={handleBlur}
          onChange={handleChange}
          name="identityAttachmentType"
          variant="outlined"
        >
          {identityAttachmentTypeList.map((i) => (
            <MenuItem key={i} value={i}>
              {capitalCase(i)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {touched.identityAttachmentType && errors.identityAttachmentType}
        </FormHelperText>
      </FormControl>

      <FileInputField
        defaultFileUrl={values.identityAttachmentFileUrl}
        defaultFileName={values.identityAttachmentFileName}
        label={
          values.identityAttachmentType == 'ID_CARD'
            ? 'Front of Identity card'
            : values.identityAttachmentType == 'DRIVING_LICENSE'
            ? 'Front of Driving licence'
            : values.identityAttachmentType == 'PASSPORT'
            ? 'Passport picture'
            : 'Identification Attachment'
        }
        fileFieldName={'identityAttachment'}
        withPreview={true}
      />

      {['ID_CARD', 'DRIVING_LICENSE'].includes(
        values.identityAttachmentType,
      ) && (
        <FileInputField
          defaultFileUrl={values.additionalIdentityAttachmentFileUrl}
          defaultFileName={values.additionalIdentityAttachmentFileName}
          label={
            values.identityAttachmentType == 'ID_CARD'
              ? 'Back of Identity card'
              : values.identityAttachmentType == 'DRIVING_LICENSE'
              ? 'Back of Driving licence'
              : 'Additional Identification Attachment'
          }
          fileFieldName={'additionalIdentityAttachment'}
          withPreview={true}
        />
      )}
    </>
  );
};
