import { FilterAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  colors,
  Container,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { TransactionsExportForm } from './forms/TransactionsExportForm';
import { TransactionFilterForm, TransactionsFilterForm } from './forms/TransactionsFilterForm';
import { TransactionList } from './TransactionList';

const TransactionsScreen: FC<{
  accountHolderId: string;
  isGlobal: boolean;
}> = ({ accountHolderId, isGlobal }) => {
  const [filter, setFilter] = useState<TransactionFilterForm>();
  const [filterOpen, setFilterOpen] = useState(false);
  const navigate = useNavigate();
  const isUserActive = useSelector(isAccountHolderStatusActive);

  useEffect(() => {
    setFilterOpen(false);
  }, [filter]);


  return (
    <>
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12}>
          <Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            FINANCIALS
          </Typography>
        </Grid>

        <Box
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }),
          ]}
        >
          <Typography color="textPrimary" variant="h5">
            Transactions
          </Typography>

          <Box
            sx={[
              {
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                },
              }),
            ]}
          >
            <TransactionsExportForm
              accountHolderId={accountHolderId}
              isGlobal={isGlobal}
              size='small' />
            {isGlobal && (
              <PermissionGuard permission="PERFORM_TRANSFER">
                <Button
                  sx={[
                    {
                      whiteSpace: 'nowrap',
                    },
                    (theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        minWidth: '100%',
                      },
                      [theme.breakpoints.up('sm')]: {
                        minWidth: 'auto',
                      },
                    }),
                  ]}
                  variant="contained"
                  onClick={() => navigate(`/transfer`)}
                  disabled={!isUserActive}
                >
                  Create Transaction
                </Button>
              </PermissionGuard>
            )}
            <Box
              sx={[
                { display: 'flex', justifyContent: 'flex-end' },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    width: 'auto',
                  },
                }),
              ]}
            >
              <FilterAlt
                color="primary"
                fontSize="large"
                sx={{ marginLeft: '10px' }}
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              />
            </Box>
          </Box>
        </Box>

        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{
            marginBottom: '20px',
          }}
          xs={12}
        >
          <Grid item xs={12}>
            <TransactionsFilterForm onFilterApply={setFilter} filter={filter} size="small" hidden={['customerDetailId', 'customerEmail', 'customerFirstName', 'customerLastName', 'referenceNo', 'bankingConfigId', 'source', 'destination', 'includeSubAccountHolders', 'acquiringConfigId']} />


          </Grid>
        </Grid>

        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >
            <Grid item xs={12}>
              <TransactionsFilterForm onFilterApply={setFilter} filter={filter} size="small" />
            </Grid>
          </Grid>
        </Drawer>
        <PermissionGuard permission="GET_TRANSACTION">
          <TransactionList
            accountHolderId={accountHolderId}
            isGlobal={isGlobal}
            filter={filter}
          />
        </PermissionGuard>
      </Container>
    </>
  );
};

export default TransactionsScreen;
