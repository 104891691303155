import { Add, Close, Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { FastField, Field, FieldArray, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiTransferChannel,
  ApiTransferChannelCreate,
  ApiTransferChannelUpdate,
  UpdateTransferChannelFormValues,
  useTransferChannelProviderTypesQuery,
} from 'src/models/transferChannels';
import { FormTextField } from 'src/screens/Transfer/components/forms/FormTextField';
import {
  useAddTransferChannelMutation,
  useUpdateTransferChannelMutation,
} from '..';
import {
  transferChannelFormValidationSchema,
  transferChannelFormValuesToTransferChannelRequestBody,
  transferChannelToTransferChannelFormValues,
} from '../utils';
interface FormProps {
  onClose?: () => void;
  transferChannel?: ApiTransferChannel;
  accountHolderId: string;
}
export const TransferChannelForm: FC<FormProps> = ({
  onClose,
  transferChannel,
  accountHolderId,
}) => {
  const [addTransferChannel] = useAddTransferChannelMutation();
  const [updateTransferChannel] = useUpdateTransferChannelMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  const {
    data: transferChannelProviderTypeList,
    isFetching: isTransferChannelProviderTypeListLoading,
  } = useTransferChannelProviderTypesQuery(
    {
      accountHolderIdForHeader: accountHolderId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <Formik<UpdateTransferChannelFormValues>
      innerRef={formikRef}
      initialValues={{
        description: '',
        providerType: '',
        requiredPermission: '',
        enabled: true,
        categories: [],
        ...(transferChannel
          ? transferChannelToTransferChannelFormValues(transferChannel)
          : {}),
        accountHolderId,
      }}
      validationSchema={transferChannelFormValidationSchema}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting },
      ): Promise<void> => {
        try {
          if (transferChannel) {
            //  const {id, accountHolderId, ...rest} = values;
            //  const updateTransferChannelResponse = await updateTransferChannel({id, rest, accountHolderId}).unwrap();
            const updateValues =
              transferChannelFormValuesToTransferChannelRequestBody(values);
            const updateTransferChannelResponse = await updateTransferChannel(
              updateValues as ApiTransferChannelUpdate,
            ).unwrap();
            toast.success(`Transfer Channel updated successfully.`);
          } else {
            const createValues =
              transferChannelFormValuesToTransferChannelRequestBody(values);
            const createTransferChannelResponse = await addTransferChannel(
              createValues as ApiTransferChannelCreate,
            ).unwrap();
            toast.success(`Transfer Channel created successfully.`);
          }
          onClose();
        } catch (err) {
          if (err?.data?.errorCode == 'ACCOUNT_LIMIT_REACHED') {
            toast.error(
              `No more transferChannels with the requested currency can be created`,
            );
          } else if (err?.data?.errorCode == 'INVALID_CURRENCY') {
            toast.error(
              `Requested currency is not available for this specific bank`,
            );
          }
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {transferChannel
                  ? 'Edit Transfer Channel'
                  : 'Create Transfer Channel'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              {transferChannel && (
                <FormikTextField
                  name="id"
                  label="Id"
                  autoComplete="off"
                  disabled
                  margin='normal'
                />
              )}

              <FormikTextField
                name="description"
                label="Description"
                autoComplete="off"
                margin='normal'
              />

              {!isTransferChannelProviderTypeListLoading && (
                <FormControl fullWidth margin="normal">
                  <InputLabel
                    error={Boolean(touched.providerType && errors.providerType)}
                  >
                    Provider Type
                  </InputLabel>
                  <Select
                    error={Boolean(touched.providerType && errors.providerType)}
                    value={values.providerType}
                    label="Provider Type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="providerType"
                    variant="outlined"
                    inputProps={{ disabled: transferChannel != undefined }}
                  >
                    {transferChannelProviderTypeList?.map((i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {touched.providerType && errors.providerType}
                  </FormHelperText>
                </FormControl>
              )}

              <FormControlLabel
                control={
                  <Field
                    label="Enabled"
                    name="enabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('enabled', checked);
                    }}
                    checked={values.enabled ? true : null}
                  />
                }
                label="Enabled"
              />

              <FormikTextField
                name="requiredPermission"
                label="Required Permission"
                autoComplete="off"
              />

              <Box
                sx={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E8F0',
                  borderRadius: '4px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  paddingBottom: '16px',
                }}
              >
                <InputLabel
                  error={Boolean(touched.categories && errors.categories)}
                  sx={{ mt: '10px', mb: '10px' }}
                >
                  Categories
                </InputLabel>

                <FieldArray name="categories">
                  {({ push, remove }) => (
                    <>
                      {values.categories.length > 0 &&
                        values.categories.map((a, index) => {
                          return (
                            <div key={index}>
                              <Box
                                sx={{
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  borderColor: '#E6E8F0',
                                  borderRadius: '4px',
                                  paddingLeft: '16px',
                                  paddingRight: '16px',
                                  paddingBottom: '16px',
                                  marginBottom: '16px',
                                }}
                              >
                                {/* <Chip
                                  sx={{ mt: '10px' }}
                                  label={`#${index}`}
                                  variant="outlined"
                                /> */}
                                <FastField
                                  fullWidth
                                  required
                                  margin="normal"
                                  error={
                                    errors?.[`categories.${index}.value`] &&
                                    touched?.[`categories.${index}.value`]
                                  }
                                  variant="outlined"
                                  label={`Category#${index + 1}`}
                                  name={`categories.${index}.value`}
                                  component={FormTextField}
                                  className={
                                    'form-control' +
                                    (errors?.[`categories.${index}.value`] &&
                                      touched?.[`categories.${index}.value`]
                                      ? ' is-invalid'
                                      : '')
                                  }
                                />

                                {index > 0 && (
                                  <Tooltip title="Remove this category">
                                    <IconButton
                                      aria-label="remove"
                                      size="medium"
                                      onClick={() => remove(index)}
                                    >
                                      <Remove
                                        color="inherit"
                                        fontSize="inherit"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            </div>
                          );
                        })}

                      <Tooltip title="Add new category">
                        <IconButton
                          aria-label="add"
                          size="medium"
                          onClick={() => push({ value: '' })}
                        >
                          <Add color="inherit" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </FieldArray>
              </Box>

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {transferChannel ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
