import * as Yup from 'yup';

import { ApiKycVerification } from 'src/models/kycVerifications';

export const userSubmitFormInitialValues = (accountHolderId: string, kycVerification: ApiKycVerification): { accountHolderIdForHeader: string, kycVerificationId: string, termsOfService1: boolean, termsOfService2: boolean } => ({
    accountHolderIdForHeader: accountHolderId,
    kycVerificationId: kycVerification?.id,
    termsOfService1: false,
    termsOfService2: false,
});

export const userSubmitFormValidationSchema =
    Yup.object({
        termsOfService1: Yup.boolean().required()
            .oneOf([true], 'The above checkbox must be ticked to proceed'),
        termsOfService2: Yup.boolean().required()
            .oneOf([true], 'The above checkbox must be ticked to proceed'),
    });

export const isUserSubmitFormCompleted = (kycVerification: ApiKycVerification): boolean => {
    try {
        userSubmitFormValidationSchema.validateSync(kycVerification);
    } catch (e) {
        return false;
    }
    return true;
}