import { ArrowForward, Edit } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { ApiAudit } from 'src/models/events';
import { SortOrder } from 'src/models/types';
import { ExpandableTableRow } from '../ExpandableTableRow';

interface AuditTable {
  audits: ApiAudit[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
}

const headCells = [
  { title: '', id: 'expandable', doSort: false },
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  { title: 'Updated date', id: 'updatedDateTime', doSort: true },
  // { title: 'Id', id: 'id', doSort: false },
  { title: 'Updated By', id: 'updatedBy', doSort: false },
  { title: 'Requested By', id: 'requestedBy', doSort: false },
];

export const AuditsTable: FC<AuditTable> = ({
  audits,
  orderBy,
  sortDirection,
  onRequestSort,
}) => {
  const navigate = useNavigate();
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {audits.map((audit) => {
              return (
                <ExpandableTableRow
                  hideExpanse={audit.messages.length == 0}
                  hover
                  key={audit.id}
                  expandComponent={
                    <TableCell colSpan={5}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell>Id</TableCell> */}
                            <TableCell>Message</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {audit.messages.map((message, index) => {
                            return (
                              <TableRow key={index}>
                                {/* <TableCell>{message.id}</TableCell> */}
                                <TableCell>{message.message}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableCell>
                  }
                >
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(audit.createdDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(audit.updatedDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography noWrap variant="inherit">
                      {audit.id}
                    </Typography>
                  </TableCell> */}
                  <TableCell>{audit.updatedBy || 'N/A'}</TableCell>
                  <TableCell>{audit.requestedBy || 'N/A'}</TableCell>

                  {/* <TableCell>
                     <auditstatusLabel status={audit.accountHolderType} /> 
                  </TableCell> */}
                </ExpandableTableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
