import { NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Chip,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import {
  useAcquiringConfigurationQuery,
  useDeleteAcquiringConfigurationMutation,
} from 'src/models/configurations';
import { mapApiAcquiringConfigToCreateUpdateAcquiringFormValues } from 'src/models/configurations/mapper';
import { useCountriesQuery } from 'src/models/countryStates/api';
import { Nullable } from 'src/utils/types';
import { AcquiringForm } from '../Acquiring/forms/AcquiringForm';
import { CreateUpdateAcquiringFormValues } from '../Acquiring/forms/types-dto';
import { Attributes } from './Attributes';

interface DetailRow {
  name: string;
  value: any;
}

export const AcquiringView = ({
  id,
  accountHolderId,
  isGlobal,
}: {
  id: string;
  accountHolderId: string;
  isGlobal: boolean;
}) => {
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const [editAcquiringOpened, setEditAcquiringOpened] =
    useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteAcquiring] = useDeleteAcquiringConfigurationMutation();
  const { data: acquiring, isFetching } = useAcquiringConfigurationQuery(
    {
      acquiringConfigId: id,
      accountHolderIdForHeader: accountHolderId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: apiCountries } = useCountriesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [acquiringDto, setAcquiringDto] =
    React.useState<Nullable<CreateUpdateAcquiringFormValues>>(null);

  useEffect(() => {
    (async () => {
      if (acquiring != null && apiCountries != null) {
        const acquiringDto = await mapApiAcquiringConfigToCreateUpdateAcquiringFormValues(acquiring, apiCountries, accountHolderId);
        setAcquiringDto(acquiringDto);
      }
    })()

  }, [acquiring, apiCountries])

  const formikRef = useRef(null);
  useTotp(formikRef);

  const acquiringInfoData: DetailRow[] = useMemo(
    () =>
      acquiring
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring.id}
              </Typography>
            ),
          },
          {
            name: 'Client Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring.accountHolderId}
              </Typography>
            ),
          },
          {
            name: 'Mid',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring.mid}
              </Typography>
            ),
          },
          {
            name: 'Transfer Channel Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring.transferChannelId}
              </Typography>
            ),
          },
          {
            name: 'Transaction Descriptor',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring.transactionDescriptor}
              </Typography>
            ),
          },
          {
            name: 'API Key',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring.apiKey}
              </Typography>
            ),
          },
          {
            name: 'Country Code',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring.countryCode}
              </Typography>
            ),
          },
          {
            name: 'Require Three D',
            value: (
              <Chip
                label={acquiring.requiresThreeD ? 'Yes' : 'No'}
                color={acquiring.requiresThreeD ? 'success' : 'error'}
              />
            ),
          },
          {
            name: 'Require AVS',
            value: (
              <Chip
                label={acquiring.requiresAvs ? 'Yes' : 'No'}
                color={acquiring.requiresAvs ? 'success' : 'error'}
              />
            ),
          },
          {
            name: 'Require Non CVV',
            value: (
              <Chip
                label={acquiring.allowNonCvv ? 'Yes' : 'No'}
                color={acquiring.allowNonCvv ? 'success' : 'error'}
              />
            ),
          },
          {
            name: 'Return URL Params Enabled',
            value: (
              <Chip
                label={acquiring.returnUrlParamsEnabled ? 'Yes' : 'No'}
                color={acquiring.returnUrlParamsEnabled ? 'success' : 'error'}
              />
            ),
          },
          {
            name: 'Transaction Expiry Enabled',
            value: (
              <Chip
                label={acquiring.transactionExpiryEnabled ? 'Yes' : 'No'}
                color={
                  acquiring.transactionExpiryEnabled ? 'success' : 'error'
                }
              />
            ),
          },
          {
            name: 'Enabled',
            value: (
              <Chip
                label={acquiring.enabled ? 'Yes' : 'No'}
                color={acquiring.enabled ? 'success' : 'error'}
              />
            ),
          },
          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(acquiring.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
        ]
        : [],
    [accountHolderId, acquiring],
  );

  const breadcrumbs = (description) => [
    <Link
      key="1"
      color={blueGrey[600]}
      component={RouterLink}
      to={'/configuration/acquiring/'}
    >
      <Typography variant="overline">CONFIGURATION</Typography>
    </Link>,
    <Link key="1" color={blueGrey[500]} component={RouterLink} to={`acquiring`}>
      <Typography variant="overline">{description}</Typography>
    </Link>,
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          item
          xs={12}
        >
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {acquiring && breadcrumbs(acquiring.transactionDescriptor)}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sx={[
              {
                width: '100%',
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            {acquiring && !isFetching && (
              <PermissionGuard permission="UPDATE_ACQUIRING_CONFIGURATION">
                <Button
                  disabled={!isUserActive}
                  variant="contained"
                  onClick={() => setEditAcquiringOpened(true)}
                >
                  Edit acquiring configuration
                </Button>
              </PermissionGuard>
            )}

            {acquiring?.enabled && (
              <PermissionGuard permission="DELETE_ACQUIRING_CONFIGURATION">
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    acquiringConfigId: null,
                  }}
                  onSubmit={async (values): Promise<void> => {
                    try {
                      const result = await deleteAcquiring({
                        acquiringConfigId: values.acquiringConfigId,
                        accountHolderIdForHeader: accountHolderId,
                      }).unwrap();
                      if (result == null) {
                        toast.success(
                          'Acquiring Configuration removed successfully',
                        );
                        // navigate(`/transfer-channels`);
                      }
                    } catch (err) {
                      //
                    }
                  }}
                >
                  {({ setFieldValue }): JSX.Element => (
                    <Button
                      disabled={!isUserActive}
                      sx={{ mr: 0.5 }}
                      variant="contained"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            'Are you sure to remove this Acquiring Configuration?',
                          subTitle: 'This operation cannot be undone',
                          onConfirm: async () => {
                            setFieldValue('acquiringConfigId', acquiring.id);
                            formikRef?.current?.submitForm();
                          },
                        });
                      }}
                    >
                      Delete acquiring configuration
                    </Button>
                  )}
                </Formik>
              </PermissionGuard>
            )}
          </Grid>
        </Grid>

        {acquiring ? (
          <>
            <Grid item xs={12} sx={{ mb: '35px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      disableTypography
                      title={
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="h6">
                            Acquiring configuration
                          </Typography>
                        </Box>
                      }
                    />

                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={2}
                      sx={{ p: '16px' }}
                    >
                      {acquiringInfoData.map(({ name, value }) => (
                        <Grid item md={6} sm={12} xs={12} key={name}>
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {name}
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              {value}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>

                  <Attributes
                    accountHolderId={accountHolderId}
                    acquiringConfigId={acquiring.id}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>

      {acquiring && (
        <PermissionGuard permission="UPDATE_ACQUIRING_CONFIGURATION">
          <Drawer
            anchor="right"
            open={editAcquiringOpened == true}
            onClose={() => setEditAcquiringOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <AcquiringForm
              accountHolderId={accountHolderId}
              isGlobal={isGlobal}
              acquiring={acquiringDto}
              onClose={() => setEditAcquiringOpened(false)}
            />
          </Drawer>
        </PermissionGuard>
      )}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default AcquiringView;
