import { format } from 'date-fns';
import { getCountryCallingCode } from 'libphonenumber-js';

import { composePhoneNumber } from 'src/components/forms';
import { ClientInfo } from 'src/components/forms/specific/ClientForm';
import { DATE_OF_BIRTH_MASK } from '../types';
import { AccountHolderRegisterRequest, ApiRegisterAccountHolderRequest } from "./types-api";
import { RegisterInfo } from "./types-dto";



export const mapRegisterInfoToApiRegisterAccountHolderRequest = (registerInfo: RegisterInfo): ApiRegisterAccountHolderRequest => {
    return {
        accountHolderType: registerInfo.accountHolderType,
        descriptor: registerInfo.descriptor,
        userDetail: {
            dateOfBirth: format(registerInfo.dateOfBirth, DATE_OF_BIRTH_MASK),
            email: registerInfo.email,
            firstName: registerInfo.firstName,
            lastName: registerInfo.lastName,
            nationality: registerInfo.nationality.code,
            phoneNumber: {
                prefix: `+${getCountryCallingCode(registerInfo.phone.phoneCountryCode.code)}`,
                number: registerInfo.phone.phoneNumber,
                phoneNumber: composePhoneNumber(
                    registerInfo.phone.phoneCountryCode.code,
                    registerInfo.phone.phoneNumber,
                ),
            },
            addressLine1: registerInfo.address.addressLine1,
            addressLine2: registerInfo.address.addressLine2,
            city: registerInfo.address.city,
            countryCode: registerInfo.address.countryCode.code,
            postcode: registerInfo.address.postcode,
            state: registerInfo.address.state == '' ? null : registerInfo.address.state,
            idPassportNo: registerInfo.idPassportNo,
            password: registerInfo.password,

        },
    }
}



export const clientInfoFormValuesToRegisterRequestBody = (
    formValues: ClientInfo,
): AccountHolderRegisterRequest => ({
    accountHolderType: formValues.accountHolderType,
    descriptor: formValues.descriptor,
    userId: null,
    parentId: null,
});
