import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { accountTypes } from './constants';

export interface AccountTypeFieldProps {
  disabled: boolean;
}
export const AccountTypeField: FC<
  AccountTypeFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  field,
  form: { values, touched, errors, handleBlur, handleChange },
  ...props
}) => {
    const { t } = useTranslation();
    const { hasPermission } = usePermissions();
    return (
      <>
        <FormControl fullWidth margin="normal">
          <InputLabel
            htmlFor={`accountType`}
            error={Boolean(touched[field.name] && errors[field.name])}
          >
            {t('common.accountType')}
          </InputLabel>
          <Field
            component={Select}
            name={`accountType`}
            fullWidth
            label={t('common.accountType')}
            value={values[field.name]}
            error={Boolean(touched[field.name] && errors[field.name])}
            onBlur={handleBlur(`accountType`)}
            onChange={handleChange(`accountType`)}
            disabled={!hasPermission('ADMIN') || disabled}
          >
            {accountTypes.map((i) => {
              // If admin or disabled for update return all else return USER only
              if (
                hasPermission('ADMIN') ||
                disabled ||
                i == accountTypes[0]
              )
                return (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                );
            })}

          </Field>
          <FormHelperText error>{t(errors[field.name] as string)}</FormHelperText>
        </FormControl>
      </>
    );
  };
