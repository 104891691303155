import { Card } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import { ApiAccountCurrency } from 'src/models/accounts';
import { useAccountCurrencyRestrictionsListQuery } from 'src/models/restrictions';
import { AccountCurrenciesTable } from './AccountCurrenciesTable';


export const AccountCurrencyList = ({accountHolderId}: {accountHolderId?:string;}) => {

const { data } = useAccountCurrencyRestrictionsListQuery({
        accountHolderIdForHeader:accountHolderId,
      },
      {
        refetchOnMountOrArgChange:true,
      }
      );

  const [accountCurrencies, setAccountCurrencies] = useState<ApiAccountCurrency[]>([]);

  useEffect(() => {
    setAccountCurrencies(data || []);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <AccountCurrenciesTable
          accountHolderId={accountHolderId }
          accountCurrencies={accountCurrencies}
        />
      </Scrollbar>
    </Card>
  );
};
