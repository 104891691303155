
   
import {SafetyCheck} from '@mui/icons-material';
import { Box, FormHelperText, TextField, Theme } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectIsTotpRequired, setTotpRequired } from 'src/models/totp';
import {
    AUTHENTICATOR_CODE_LENGTH,
    INITIAL_AUTHENTICATOR_CODE,
  } from './constants';
import { CountdownTimer } from './CountdownTimer';

const useStyles = makeStyles((theme:Theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        color: theme.palette.primary.main,
    }
}))


export default function TotpDialog(props) {
    const itemsRef = useRef([]);
    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles();
    const lastFocusIndex = INITIAL_AUTHENTICATOR_CODE.length - 1;
    const [expiryDate, setExpiryDate] = useState<Date>(null);
    const dispatch = useDispatch();
    const isTotpRequired = useSelector(selectIsTotpRequired);
    useEffect(() => {
       if(isTotpRequired) {
        setExpiryDate(moment().add(2, 'm').toDate());
        itemsRef.current = itemsRef.current.slice(0, 6);
       }
      }, [isTotpRequired]);

    const onClose = () => {
        dispatch(setTotpRequired({required:false}));
        setExpiryDate(null);
        setConfirmDialog({ ...confirmDialog, isOpen: false });
    }

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                    <SafetyCheck sx={{ fontSize: '9rem' }} className={classes.titleIcon} />
            </DialogTitle>
            <Formik
                    initialValues={{
                        authenticatorCode: INITIAL_AUTHENTICATOR_CODE,
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        authenticatorCode: Yup.array().of(Yup.string().required('Code is required')),
                    })}
                    onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting },
                    ): Promise<void> => {
                           setConfirmDialog({ ...confirmDialog, isOpen: false }); 
                           confirmDialog.onConfirm(values.authenticatorCode.join('')); 
                    }}
                    >
                    {({
                        errors,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }): JSX.Element => (
            <>
                        <form noValidate onSubmit={handleSubmit}>

            <DialogContent className={classes.dialogContent}>
  
                        <>
                        <Box sx={{ typography: 'body2', mb: '32px' }}>
                            Enter authentication {AUTHENTICATOR_CODE_LENGTH}-digit code from Authenticator application.
                        </Box>
                        <Box
                           sx={[{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(6, 1fr)',
                            maxWidth: '100%',
                            py: 1,
                            },(theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                    columnGap: '0px',
                                },
                                [theme.breakpoints.up('sm')]: {
                                    columnGap: '10px',
                                },
                            })]}
                        >
                            {INITIAL_AUTHENTICATOR_CODE.map((ref, i) => (
                            <TextField
                                error={Boolean(
                                Array.isArray(touched.authenticatorCode) &&
                                    touched.authenticatorCode.length === 6 &&
                                    errors.authenticatorCode,
                                )}
                                fullWidth
                                inputRef={(el) => (itemsRef.current[i] = el)}
                                key={`codeNumber-${i}`}
                                name={`authenticatorCode[${i}]`}
                                onBlur={handleBlur}
                                onKeyDown={(event) => {
                                if (event.code === 'ENTER') {
                                    if (values.authenticatorCode[i]) {
                                    setFieldValue(`authenticatorCode[${i}]`, '');
                                    return;
                                    }

                                    if (i > 0) {
                                    setFieldValue(`authenticatorCode[${i}]`, '');
                                    itemsRef.current[i - 1].focus();
                                    return;
                                    }
                                }

                                if (Number.isInteger(parseInt(event.key, 10))) {
                                    setFieldValue(`authenticatorCode[${i}]`, event.key);

                                    if (i < lastFocusIndex) {
                                    itemsRef.current[i + 1].focus();
                                    }
                                }
                                }}
                                onPaste={(event) => {
                                const paste = event.clipboardData.getData('text');
                                const pasteArray = paste.split('');

                                if (pasteArray.length !== 6) {
                                    return;
                                }

                                let valid = true;

                                pasteArray.forEach((x) => {
                                    if (!Number.isInteger(parseInt(x, 10))) {
                                    valid = false;
                                    }
                                });

                                if (valid) {
                                    setFieldValue('authenticatorCode', pasteArray);
                                    itemsRef.current[5].focus();
                                }
                                }}
                                value={values.authenticatorCode[i]}
                                sx={{
                                display: 'inline-block',
                                textAlign: 'center',
                                '& .MuiInputBase-input': {
                                    borderRadius: '16px',
                                    textAlign: 'center',
                                },
                                }}
                                variant="outlined"
                            />
                            ))}
                        </Box>
                        </>
                        {Boolean(
                            Array.isArray(touched.authenticatorCode) &&
                            touched.authenticatorCode.length === 6 &&
                            errors.authenticatorCode,
                        ) && (
                            <FormHelperText error sx={{ mx: '14px' }}>
                            {Array.isArray(errors.authenticatorCode) &&
                                errors.authenticatorCode.find((x) => x !== undefined)}
                            </FormHelperText>
                        )}

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                    <Box sx={{
                        position:'absolute',
                        left:'0',
                        top:'0'
                    }}>
                    {expiryDate && <CountdownTimer targetDate={expiryDate} onExpiry={() => onClose()}
                           show={{days:false, hours:false, minutes:true, seconds:true}} />}
                    </Box>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => onClose()} >Cancel</Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={values.authenticatorCode.join('') == '000000'}
                    type="submit"
                   >{props.confirmText?props.confirmText:'Submit'}</Button>

            </DialogActions>
            </form>

            </>
                                )}

                                </Formik>
        </Dialog>
    )
}