import * as Yup from "yup";

import { accountHolderTypeValidationSchema } from "src/components/forms";

export const clientInfoValidationSchema = () =>
    Yup.object({
        accountHolderType: accountHolderTypeValidationSchema(),
        descriptor:
            Yup.string()
                .max(255)
                .when('accountHolderType', {
                    is: 'BUSINESS',
                    then: Yup.string().required('Descriptor is required'),
                    otherwise:
                        Yup.string()
                            .max(255)
                            .when(['accountHolderType', 'mode'], {
                                is: (accountHolderType, mode) => accountHolderType == 'INDIVIDUAL' && ['without_user', 'invite_a_user'].includes(mode),
                                then: Yup.string().required('Descriptor is required'),
                                otherwise: Yup.string().notRequired()
                            }),
                }),
    });
