import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiCorrespondentBank,
  useAddCorrespondentBankMutation,
  useUpdateCorrespondentBankMutation,
} from 'src/models/configurations';
import { CreateUpdateCorrespondentBankFormValues } from '../types';
import {
  correspondentBankFormValidationSchema,
  correspondentBankToCorrespondentBankFormValues,
} from '../utils';

interface FormProps {
  onClose?: () => void;
  correspondentBank?: ApiCorrespondentBank;
  accountHolderId: string;
  bankingConfigId?: string;
}
export const CorrespondentBankForm: FC<FormProps> = ({
  onClose,
  correspondentBank,
  accountHolderId,
  bankingConfigId,
}) => {

  const [addCorrespondentBank] = useAddCorrespondentBankMutation();
  const [updateCorrespondentBank] = useUpdateCorrespondentBankMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateUpdateCorrespondentBankFormValues>
      innerRef={formikRef}
      initialValues={{
        bankingConfigId: bankingConfigId,
        correspondentBankId: '',
        enabled: true,
        bankName: '',
        accountHolderIdForHeader: (() => {
          if (accountHolderId != undefined)
            return accountHolderId;
          else return '';
        })(),
        ...(correspondentBank
          ? correspondentBankToCorrespondentBankFormValues(
            correspondentBank,
            accountHolderId,
            bankingConfigId,
          )
          : {}),
      }}
      validationSchema={correspondentBankFormValidationSchema}
      onSubmit={async (
        values,
      ): Promise<void> => {
        try {
          if (correspondentBank) {
            const updateCorrespondentBankResponse =
              await updateCorrespondentBank(values).unwrap();
            toast.success(`Correspondent bank updated successfully.`);
          } else {
            const createCorrespondentBankResponse = await addCorrespondentBank(
              values,
            ).unwrap();
            toast.success(`Correspondent bank created successfully.`);
          }
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {correspondentBank
                  ? 'Edit Correspondent Bank'
                  : 'Create Correspondent Bank'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>

              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled
                accountHolderId={accountHolderId} />

              {correspondentBank && (
                <FormikTextField
                  name="correspondentBankId"
                  label="Id"
                  autoComplete="off"
                  disabled
                  margin='normal'
                />
              )}

              <FormikTextField
                name="bankName"
                label="Bank Name"
                autoComplete="off"
                margin='normal'
              />

              <FormControlLabel
                control={
                  <Field
                    label="Enabled"
                    name="enabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('enabled', checked);
                    }}
                    checked={values.enabled ? true : null}
                  />
                }
                label="Enabled"
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {correspondentBank ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
