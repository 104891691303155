import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FingerprintState = {
    id: string | null;
};

export const fingerprintSlice = createSlice({
    name: 'fingerprint',
    initialState: {
        id: null
    } as FingerprintState,
    //   initialState: {
    //       language: localStorage.getItem('language') != null ? localStorage.getItem('language') : "en"
    //   } as FingerprintState,
    reducers: {
        setFingerprint: (
            state,
            {
                payload: { id },
            }: PayloadAction<{
                id: string | null;
            }>,
        ) => {
            state.id = id;
        },
    },
});

export const { setFingerprint } = fingerprintSlice.actions;

