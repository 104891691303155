import { createApi } from '@reduxjs/toolkit/query/react';

import { totpbaseQuery } from 'src/services/redux/totpBaseQuery';
import { cmsBaseQuery } from '../../services/redux/api';
import { ApiAccountHolderTemplate, ApiAccountHolderTemplateUpdateRequest, ApiTransferChannelParameter, ApiTransferChannelTemplate } from './types';
import { accountHolderTemplateValuesToFormDataBody } from './utils';


export const cmsApi = createApi({
  baseQuery: totpbaseQuery(cmsBaseQuery),
  keepUnusedDataFor: 0.0001,
  tagTypes: ['AccountHolderTemplate'],
  endpoints: (builder) => ({
    getAvailableTransferChannelsParameters: builder.query<ApiTransferChannelParameter[], { transferChannelId: string }>({
      query: (payload) => ({
        method: 'GET',
        // params: {
        // },
        url: `cms/transfer-channels/${payload.transferChannelId}/templates`,
      }),
      // transformResponse: (response: ApiTransferChannelTemplate) => response.parameters.sort((a,b) => a.order - b.order)
      transformResponse: (response: ApiTransferChannelTemplate) => [...response.parameters.sort((a, b) => a.order - b.order)]
    }),
    getBankProfile: builder.query<ApiAccountHolderTemplate, { accountHolderId: string }>({
      query: (payload) => ({
        method: 'GET',
        url: `cms/account-holders/${payload.accountHolderId}/templates`,
      }),
    }),
    getBankProfileByDomain: builder.query<ApiAccountHolderTemplate, { domain: string }>({
      query: (payload) => ({
        params: {
          domain: payload.domain
        },
        method: 'GET',
        url: `cms/account-holders/templates`,
      }),
    }),


    getAccountHolderTemplate: builder.query<ApiAccountHolderTemplate, { accountHolderId: string }>({
      query: (payload) => ({
        method: 'GET',
        url: `cms/account-holders/${payload.accountHolderId}/templates`,
      }),
      providesTags: ['AccountHolderTemplate']
    }),
    addAccountHolderTemplate: builder.mutation<ApiAccountHolderTemplate, ApiAccountHolderTemplateUpdateRequest>({
      query: ({ accountHolderIdForHeader, accountHolderId, ...body }) => {
        const formData = accountHolderTemplateValuesToFormDataBody(body)
        return ({
          headers: {
            'x-account-holder-id': accountHolderIdForHeader,
          },
          method: 'POST',
          body: formData,
          url: `cms/account-holders/${accountHolderId}/templates`,
        })
      },
      invalidatesTags: ['AccountHolderTemplate']
    }),
    updateAccountHolderTemplate: builder.mutation<ApiAccountHolderTemplate, ApiAccountHolderTemplateUpdateRequest>({
      query: ({ accountHolderIdForHeader, accountHolderId, ...body }) => {
        const formData = accountHolderTemplateValuesToFormDataBody(body)
        return ({
          headers: {
            'x-account-holder-id': accountHolderIdForHeader,
          },
          method: 'PUT',
          body: formData,
          url: `cms/account-holders/${accountHolderId}/templates`,
        })
      },
      invalidatesTags: ['AccountHolderTemplate']
    }),
  }),
  reducerPath: 'cmsApi',
});

export const {
  useGetAvailableTransferChannelsParametersQuery,
  useGetBankProfileQuery,
  useGetBankProfileByDomainQuery,

  useGetAccountHolderTemplateQuery,
  useAddAccountHolderTemplateMutation,
  useUpdateAccountHolderTemplateMutation,
} = cmsApi;
