
   
import { Box,Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const PreviewImageDialog = (props) => {


    return (
        <Dialog open={props.previewImageDialog.isOpen}>
            <DialogTitle >
                 {props.previewImageDialog.title}
            </DialogTitle>
            <DialogContent>
                <Box mt={2} textAlign="center" sx={{ '& img': { objectFit:'contain', width:'80%', height:'80%'}}}>
                {props.previewImageDialog.file
                 ?<img src={props.previewImageDialog.file} alt={props.previewImageDialog.title}  />
                 :<CircularProgress />}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => { props.setPreviewImageDialog({ ...props.previewImageDialog, isOpen: false })}} >Close</Button>
            </DialogActions>
        </Dialog>
    )
}