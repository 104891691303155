import { PermissionGroupFormValues } from "./types";
import { createAccountHolderPermissionGroupFormValidationSchema } from "./validations";

export const permissionGroupInitialValues = (): PermissionGroupFormValues => ({
    permissionGroupIds: []
});


export const isAccountHolderPermissionGroupFormCompleted = () => async (permissionGroup: PermissionGroupFormValues): Promise<boolean> => {
    try {
        await createAccountHolderPermissionGroupFormValidationSchema().validate(permissionGroup);
    } catch (e) {
        return false;
    }
    return true;
}