import { lazy } from 'react';
import { Route, Routes } from 'react-router';

import { Loadable } from 'src/components/Loadable';
import LoadingScreen from '../Loading';
const LazyLoadableScreen = (screen: () => any) =>
  Loadable(lazy(screen), <LoadingScreen />);

const FingerPrintJSScreen = LazyLoadableScreen(() => import('./FingerprintJS'));

export const DiagnosticsRoutes = () => (
  <Routes>
    {/* <Route path="/" element={<PaymentSelectionScreen />} /> */}
    <Route path="fingerprint" element={<FingerPrintJSScreen />} />
  </Routes>
);
