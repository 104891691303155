import {
  Box,
  Button,
  Card,
  CardContent,
  colors,
  Divider,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import WizardTemplate from 'src/components/WizardTemplate';
import {
  selectAssociations,
  selectLoggedInUserId,
  setCurrentAccountHolderId,
} from 'src/models/auth';
import { resetBank, selectBank } from 'src/models/cms';
import { AccountHolderLogo } from './AccountHolderLogo';

const AccountHolderChoice: FC = () => {
  const bank = useSelector(selectBank);
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const loggedInUserId = useSelector(selectLoggedInUserId);
  const associations = useSelector(selectAssociations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAccountHolderIdChange = (accountHolderId) => {
    // Remove bank details coming from URL params
    dispatch(resetBank());
    // Set Current Client
    dispatch(setCurrentAccountHolderId({ accountHolderId }));
    navigate('/', { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>Select Client</title>
      </Helmet>
      <WizardTemplate>
        <Card>
          <CardContent
            sx={{
              // width:'100%',
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ typography: 'h4', mb: '16px' }}>
              Please choose one of the profile accounts below
            </Box>
            <Box
              sx={{
                // border: '1px solid blue',
                gridGap: '20px',
                gridTemplateColumns: 'repeat(auto-fill,minmax(200px, 1fr))',
                display: 'grid',
                width: '100%',
                position: 'relative',
              }}
            >
              {associations.map((association) => (
                <Box
                  key={association.id}
                  sx={[
                    {
                      position: 'relative',
                      // border: '1px solid red',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '200px',
                      padding: '10px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      transition:
                        'outline-color 0.2s ease-in-out,transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        // border: '2px solid green',
                      },
                      '&:active': {
                        transform: 'scale(1.1)',
                      },
                      '&:focus': {
                        transform: 'scale(1.1)',
                      },
                    },
                    (theme) => ({
                      border: `1px solid ${theme.palette.primary.light}`,
                      '&:hover': {
                        transform: 'scale(1.1)',
                        border: `2px solid ${theme.palette.primary.main}`,
                      },
                    }),
                  ]}
                  onClick={() =>
                    handleAccountHolderIdChange(association.id)
                  }
                >
                  <AccountHolderLogo accountHolderId={association.id} />
                  <Typography
                    sx={{
                      color: colors.blueGrey[600],
                      lineHeight: '1.5',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                    variant="overline"
                  >
                    {association.displayName}
                  </Typography>
                  <Typography
                    sx={{
                      color: colors.blueGrey[600],
                      lineHeight: '1.0',
                      padding: '5px',
                      textAlign: 'center',
                      fontSize: '0.60rem'
                    }}
                    variant="overline"
                  >
                    {association.parentAccountHolderDisplayName}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Divider sx={{ my: 3 }} />
            <Box sx={{ typography: 'body2', color: 'text.secondary' }}>
              <Button
                onClick={() => navigate('/pending-invitations')}
                variant="outlined"
              >
                View pending invitations
              </Button>
              {/* Register your own middlecc account */}
              {searchParams.get('parentAccountHolderId') != null &&
                !associations.some(
                  (x) =>
                    x.parentAccountHolderId ==
                    searchParams.get('parentAccountHolderId') &&
                    x.ownerId == loggedInUserId,
                ) && (
                  // 1. show registration button
                  // 2. 2. Call /registration/account-holders with AccountHolderDetails and userId (Step3) and parentId= parentAccountHolderIdFromUrl
                  <Button
                    onClick={() =>
                      navigate(
                        `/account-holder-register${search}&userId=${loggedInUserId}`,
                      )
                    }
                    variant="outlined"
                  >
                    Register to {bank.displayName}
                  </Button>
                )}
            </Box>
          </CardContent>
        </Card>
      </WizardTemplate>
    </>
  );
};

export default AccountHolderChoice;
