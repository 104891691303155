import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountHoldersListQuery } from 'src/models/accountHolders';
import { useAccountsQuery } from 'src/models/accounts';
import { AccountHolderIdField } from '../../simple';
import { ClientAccount, ClientAccountLabel } from './types';

export interface ClientAccountFieldProps {
  disabled: boolean;
  accountHolderId: string;
  labels: ClientAccountLabel;
}
export const ClientAccountField: FC<
  ClientAccountFieldProps & FieldProps<ClientAccount, ClientAccount>
> = ({
  labels = { clientAccountHolderIdLabel: 'Client Account Holder', clientAccountIdLabel: 'Client Account' },
  disabled = false,
  accountHolderId,
  field,
  form: {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
  },
  ...props
}) => {
    const { t } = useTranslation();

    const accountHolderIds = useAccountHoldersListQuery(
      { accountHolderId },
      { refetchOnMountOrArgChange: true },
    );

    // Auto populate clientAccountHolderId when there is only one
    useEffect(() => {
      if (accountHolderIds && accountHolderIds?.data && accountHolderIds?.data?.length === 1) {
        setFieldValue(`${field.name}.clientAccountHolderId`, accountHolderIds.data[0].id);
      }
    }, [accountHolderIds.data]);

    const accounts = useAccountsQuery(
      { accountHolderId: values[field.name].clientAccountHolderId },
      {
        skip: values[field.name].clientAccountHolderId == '',
        refetchOnMountOrArgChange: true
      },
    );

    // Auto populate clientAccountId when there is only one
    useEffect(() => {
      if (accounts && accounts?.data && accounts?.data?.length === 1) {
        setFieldValue(`${field.name}.clientAccountId`, accounts.data[0].id);
      }
    }, [accounts.data]);

    return (
      <>
        <Field
          name={`${field.name}.clientAccountHolderId`}
          component={AccountHolderIdField}
          accountHolderId={accountHolderId}
          label={t(labels.clientAccountHolderIdLabel)} />

        <FormControl
          error={
            touched?.[`${field.name}`]?.clientAccountId &&
            errors?.[`${field.name}`]?.clientAccountId?.length > 0
          }
          fullWidth
          margin="normal"
          variant="outlined"
          component="fieldset"
        >
          <InputLabel id="clientAccountId_label">{t(labels.clientAccountIdLabel)}</InputLabel>
          <Select
            labelId="clientAccountId_label"
            label={t(labels.clientAccountIdLabel)}
            name="clientAccountId"
            id="clientAccountId_select"
            defaultValue=""
            value={values[`${field.name}`]?.clientAccountId}
            onChange={(e: SelectChangeEvent<unknown>) =>
              handleChange(`${field.name}.clientAccountId`)(e as React.ChangeEvent)
            }
            onBlur={handleBlur(`${field.name}.clientAccountId`)}
            disabled={!(accounts.data?.length > 1)}
          >
            {accounts.data?.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.friendlyName} ({i.accountNumber})
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {touched[`${field.name}`]?.clientAccountId && t(errors[`${field.name}`]?.clientAccountId)}
          </FormHelperText>
        </FormControl>

      </>
    );
  };
