import {
    Box,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetPermissionGroupsQuery } from 'src/models/permission-groups';

export interface PermissionGroupsProps {
    disabled: boolean;
    accountHolderId: string;
}
export const PermissionGroupsField: FC<
    PermissionGroupsProps & FieldProps<string[], string[]>
> = ({
    disabled = false,
    accountHolderId,
    field,
    form: { values, touched, errors, handleBlur, setFieldValue },
}) => {
        const { t } = useTranslation();

        const { data: permissionGroups, isLoading } = useGetPermissionGroupsQuery(
            {
                accountHolderId,
            },
            { refetchOnMountOrArgChange: true, skip: accountHolderId == null },
        );

        return (
            <>
                {isLoading && <CircularProgress />}
                {permissionGroups && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor={`${field.name}`}
                            error={Boolean(
                                touched[field.name] && errors[field.name],
                            )}
                        >
                            {t('common.permissions')}
                        </InputLabel>
                        <Field
                            labelId={field.name}
                            error={Boolean(
                                touched[field.name] && errors[field.name],
                            )}
                            component={Select}
                            name={`${field.name}`}
                            fullWidth
                            multiple
                            value={values[field.name]}
                            onBlur={handleBlur(`${field.name}`)}
                            onChange={(e) => {
                                setFieldValue(field.name, e.target.value);
                            }}
                            input={<OutlinedInput label={t('common.permissions')} />}
                            disabled={disabled}
                            variant="outlined"
                            renderValue={(selected: string[]) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected
                                        .filter((x) =>
                                            permissionGroups
                                                .filter((x) => !x.userDefault)
                                                .some((y) => y.id == x),
                                        )
                                        .map((value) => (
                                            <Chip
                                                key={value}
                                                label={permissionGroups.find((x) => x.id == value)?.name}
                                            />
                                        ))}
                                </Box>
                            )}

                        >
                            {permissionGroups
                                .filter((x) => !x.userDefault)
                                .map((permissionGroup) => (
                                    <MenuItem key={permissionGroup.id} value={permissionGroup.id}>
                                        {permissionGroup.name}
                                    </MenuItem>
                                ))}
                        </Field>
                        <FormHelperText error>
                            {touched[field.name] && t(errors[field.name] as string)}
                        </FormHelperText>
                    </FormControl>
                )}
            </>
        );
    };
