import { Menu } from '@mui/icons-material';
import { AppBar, AppBarProps, Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import type { FC } from 'react';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { I18nSelect } from 'src/components/i18n/I18nSelect';

// import AccountPopover from './AccountPopover';
// import NotificationsPopover from './NotificationsPopover';
// import { SIDEBAR_WIDTH } from './utils/config';

interface IGamingNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const IGamingNavbarRoot = styled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          // backgroundColor: '#93B3D3',
          ...(window.location.hostname == 'voucherdemo.fliwp.com' ? { backgroundColor: '#B9D6F9' } : { backgroundColor: '#93B3D3' }),
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      // zIndex: theme.zIndex.drawer + 100,
      position: 'absolute',
      left: 0,
      right: 0,
      width: 'auto',
      borderBottom: '1px solid #E4E7EB',
      [theme.breakpoints.up('lg')]: {
        left: `${SIDEBAR_WIDTH}px`
      }
    }
  )
);

const IGamingNavbar: FC<IGamingNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  return (
    <IGamingNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64, maxHeight: 64, padding: 0 }}>
        <IconButton
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <Menu sx={
            (theme) => ({
              color: theme.palette.primary.main,
            })}
            fontSize="small" />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />

        <Box sx={{ ml: 2 }}>
          <I18nSelect languages={["en", "es", "pt", 'nl', 'de', 'fr', 'be']} />
        </Box>
      </Toolbar>
    </IGamingNavbarRoot>
  );
};

IGamingNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default IGamingNavbar;
