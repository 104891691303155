import { Container, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { PendingFilterForm } from '../../Pending/types';
import { RestrictionStatusLabel } from '../../RestrictionStatusLabel';
import { CountryPendingList } from './CountryPendingList';

export const CountryPending: FC<{
  accountHolderId?: string;
  isGlobal: boolean;
}> = ({ accountHolderId, isGlobal }) => {
  const [filter, setFilter] = useState<PendingFilterForm>(null);

  useEffect(() => {
    setFilter({
      restrictionStatus: ['PENDING'],
      restrictionType: 'COUNTRY',
    });
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ marginBottom: '25px', marginTop: '25px' }}
          xs={12}
        >
          <Grid item justifySelf="left">
            <Typography color="textPrimary" variant="h5">
              Country <RestrictionStatusLabel status="PENDING" />
            </Typography>
          </Grid>
        </Grid>

        <PermissionGuard permission="GET_RESTRICTION">
          {filter && (
            <CountryPendingList
              accountHolderId={accountHolderId}
              filter={filter}
              isGlobal={isGlobal}
            />
          )}
        </PermissionGuard>
      </Container>
    </>
  );
};
