import {
  AccountBalance,
  AssignmentInd,
  FolderShared,
  Login,
  Notifications,
  NotificationsOff,
  SupportAgent,
  VerifiedUser,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Divider,
  Popover,
  Typography,
} from '@mui/material';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { usePagination } from 'src/hooks/usePagination';
import { useToggle } from 'src/hooks/useToggle';
import { selectAccountHolderId } from 'src/models/auth';
import {
  ApiEvent,
  EventListFilter,
  EventListSort,
  resetEvents,
  selectEvents,
  selectIsConnected,
  setNotFresh,
} from 'src/models/events';
import { SortOrder } from 'src/models/types';
import { useDispatch, useSelector } from 'src/services/redux';
import { useEventsQuery } from '../../models/events';

const NotificationsPopover: FC = () => {
  const isConnected = useSelector(selectIsConnected);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, onOpen, onClose] = useToggle(false);
  const navigate = useNavigate();
  TimeAgo.addLocale(en);
  // Create formatter (English).
  const timeAgo = new TimeAgo('en-US');

  const freshEvents = useSelector(selectEvents);

  const accountHolderId = useSelector(selectAccountHolderId);
  const { page, limit, handlePageChange, resetPagination } = usePagination();
  const [sort] = useState<{
    orderBy: EventListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as EventListSort, direction: 'desc' });

  const [filter] = useState<EventListFilter>({
    id: '',
    eventType: [],
    eventCategory: [],
  });

  const { data, refetch, isFetching } = useEventsQuery(
    {
      ...filter,
      accountHolderIdForHeader: accountHolderId,
      page,
      size: limit,
      sort: [`${sort.orderBy},${sort.direction}` as EventListSort],
    },
    { refetchOnMountOrArgChange: true },
  );

  const [notifications, setNotifications] = useState<ApiEvent[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetEvents());
    refetch();
  }, [accountHolderId]);

  useEffect(() => {
    if (isOpen == true) {
      refetch();
    }
  }, [isOpen]);

  useEffect(() => {
    setNotifications([
      ...notifications,
      ...(data?.content.filter(
        (x) =>
          ![...notifications, ...freshEvents].map((e) => e.id).includes(x.id),
      ) || []),
    ]);
    setTotalElements(data?.totalElements || 0);
  }, [data]);

  useEffect(() => {
    resetPagination();
  }, [freshEvents]);

  return (
    <>
      <Box
        onClick={onOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          p: '10px',
          '&:hover': {
            backgroundColor: '#EBF4FA',
            cursor: 'pointer',
            boxShadow:
              'rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px',
          },
        }}
      >
        <Badge
          badgeContent={freshEvents.filter((e) => e.isFresh).length}
          color="primary"
        >
          {isConnected && <Notifications sx={{ color: 'green' }} />}
          {!isConnected && <NotificationsOff sx={{ color: 'red' }} />}
        </Badge>
      </Box>
      <Popover
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            height: 'calc(90vh)',
          },
        })}
        open={isOpen}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        onClose={onClose}
      >
        <Box sx={{ p: '15px' }}>
          <Typography variant="h6" sx={{ p: '2px', fontWeight: 600 }}>
            Notifications
          </Typography>
          <Divider />
          {freshEvents.length > 0 && (
            <Typography sx={{ p: '2px', fontWeight: 600 }}>New</Typography>
          )}
        </Box>
        {freshEvents.map((freshEvent) => (
          <Box
            key={freshEvent.id}
            sx={{
              p: '20px',
              display: 'flex',
              flexDirection: 'row',

              '&:hover': {
                backgroundColor: '#F2F2F2',
                ...{
                  cursor: freshEvent.parameters.find(
                    (x) => x.parameterName == 'transactionId',
                  )
                    ? 'pointer'
                    : 'auto',
                },
              },
            }}
            onClick={() => {
              if (
                freshEvent.parameters.find(
                  (x) => x.parameterName == 'transactionId',
                )
              ) {
                navigate(
                  `/transactions/${
                    freshEvent.parameters.find(
                      (x) => x.parameterName == 'transactionId',
                    ).parameterValue
                  }`,
                );
                dispatch(setNotFresh({ ids: [freshEvent.id] }));
                onClose();
              }
            }}
          >
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  color: 'white',
                  ...{
                    backgroundColor: [
                      'FAILED_LOGIN',
                      'TRANSACTION_FAILED',
                    ].includes(freshEvent.eventType)
                      ? 'red'
                      : [
                          'LOGIN',
                          'USER_ASSOCIATED',
                          'TRANSACTION_COMPLETED',
                          'ACCOUNT_HOLDER_STATUS_UPDATE',
                          'KYC_STATUS_UPDATE',
                          'USER_VERIFIED',
                        ].includes(freshEvent.eventType)
                      ? 'green'
                      : 'blue',
                  },
                },
                '& > .MuiBadge-badge': {
                  padding: '5px !important',
                  borderRadius: '500px',
                  height: 'unset',
                },
                //  margin:'5px',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              overlap="circular"
              //   badgeContent={<Check fontSize="small" sx={{ color: "green" }} />}
              badgeContent={
                <>
                  {freshEvent.eventType == 'LOGIN' && (
                    <Login fontSize="small" />
                  )}
                  {freshEvent.eventType == 'FAILED_LOGIN' && (
                    <Login fontSize="small" />
                  )}
                  {freshEvent.eventType == 'USER_ASSOCIATED' && (
                    <AssignmentInd fontSize="small" />
                  )}
                  {freshEvent.eventType == 'TRANSACTION_CREATED' && (
                    <AccountBalance fontSize="small" />
                  )}
                  {freshEvent.eventType == 'TRANSACTION_FAILED' && (
                    <AccountBalance fontSize="small" />
                  )}
                  {freshEvent.eventType == 'TRANSACTION_COMPLETED' && (
                    <AccountBalance fontSize="small" />
                  )}
                  {freshEvent.eventType == 'ACCOUNT_HOLDER_STATUS_UPDATE' && (
                    <FolderShared fontSize="small" />
                  )}
                  {freshEvent.eventType == 'KYC_STATUS_UPDATE' && (
                    <SupportAgent fontSize="small" />
                  )}
                  {freshEvent.eventType == 'USER_VERIFIED' && (
                    <VerifiedUser fontSize="small" />
                  )}
                </>
              }
            >
              <Avatar
                sx={{
                  bgcolor: 'rgb(25, 171, 186)',
                  width: '56px',
                  height: '56px',
                }}
              >
                {freshEvent.eventType == 'LOGIN' && 'LOGIN'}
                {freshEvent.eventType == 'FAILED_LOGIN' && 'LOGIN'}
                {freshEvent.eventType == 'USER_ASSOCIATED' && 'LOGIN'}
                {freshEvent.eventType == 'TRANSACTION_CREATED' && 'TX'}
                {freshEvent.eventType == 'TRANSACTION_FAILED' && 'TX'}
                {freshEvent.eventType == 'TRANSACTION_COMPLETED' && 'TX'}
                {freshEvent.eventType == 'ACCOUNT_HOLDER_STATUS_UPDATE' && 'AH'}
                {freshEvent.eventType == 'KYC_STATUS_UPDATE' && 'KYC'}
                {freshEvent.eventType == 'USER_VERIFIED' && 'USER'}
                {/* TX */}
              </Avatar>
            </Badge>
            <Box
              sx={{
                ml: '10px',
              }}
            >
              <Typography sx={{ p: '2px' }}>
                {freshEvent.eventType == 'LOGIN' &&
                  `Login initiated from ${
                    freshEvent.parameters.find(
                      (n) => n.parameterName == 'ipAddress',
                    )?.parameterValue
                  }`}
                {freshEvent.eventType == 'FAILED_LOGIN' &&
                  `Failed login attempt from ip ${
                    freshEvent.parameters.find(
                      (n) => n.parameterName == 'ipAddress',
                    )?.parameterValue
                  }`}
                {freshEvent.eventType == 'USER_ASSOCIATED' &&
                  `User has been associated`}
                {freshEvent.eventType == 'TRANSACTION_CREATED' &&
                  `Transaction created`}
                {freshEvent.eventType == 'TRANSACTION_FAILED' &&
                  `Transaction failed`}
                {freshEvent.eventType == 'TRANSACTION_COMPLETED' &&
                  `${
                    freshEvent.parameters.find(
                      (n) => n.parameterName == 'description',
                    )?.parameterValue
                  } successfully processed`}
                {freshEvent.eventType == 'ACCOUNT_HOLDER_STATUS_UPDATE' &&
                  `Client status has been updated`}
                {freshEvent.eventType == 'KYC_STATUS_UPDATE' &&
                  `KYC status has been updated`}
                {freshEvent.eventType == 'USER_VERIFIED' && `User is verified`}
              </Typography>
              <Typography
                sx={[
                  {
                    p: '2px',
                    fontweight: '600',
                    lineHeight: '1.2308',
                    fontSize: '.8125rem',
                    textAlign: 'left',
                    userSelect: 'none',
                  },
                  (theme) => ({
                    color: theme.palette.primary.main,
                  }),
                ]}
              >
                {timeAgo.format(new Date(freshEvent.createdDateTime))}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& span': {
                  height: '12px',
                  width: '12px',
                  borderRadius: '50%',
                  backgroundColor: '#1876F2',
                  display: 'inline-flex',
                },
              }}
            >
              <span></span>
            </Box>
          </Box>
        ))}

        {freshEvents.length > 0 && (
          <Box sx={{ p: '15px' }}>
            <Typography sx={{ p: '2px', fontWeight: 600 }}>Earlier</Typography>
          </Box>
        )}
        {notifications.map((notification) => (
          <Box
            key={notification.id}
            sx={{
              p: '20px',
              display: 'flex',
              flexDirection: 'row',

              '&:hover': {
                backgroundColor: '#F2F2F2',
                ...{
                  cursor: notification.parameters.find(
                    (x) => x.parameterName == 'transactionId',
                  )
                    ? 'pointer'
                    : 'auto',
                },
              },
            }}
            onClick={() => {
              if (
                notification.parameters.find(
                  (x) => x.parameterName == 'transactionId',
                )
              ) {
                navigate(
                  `/transactions/${
                    notification.parameters.find(
                      (x) => x.parameterName == 'transactionId',
                    ).parameterValue
                  }`,
                );
                onClose();
              }
            }}
          >
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  color: 'white',
                  ...{
                    backgroundColor: [
                      'FAILED_LOGIN',
                      'TRANSACTION_FAILED',
                    ].includes(notification.eventType)
                      ? 'red'
                      : [
                          'LOGIN',
                          'USER_ASSOCIATED',
                          'TRANSACTION_COMPLETED',
                          'ACCOUNT_HOLDER_STATUS_UPDATE',
                          'KYC_STATUS_UPDATE',
                          'USER_VERIFIED',
                        ].includes(notification.eventType)
                      ? 'green'
                      : 'blue',
                  },
                },
                '& > .MuiBadge-badge': {
                  padding: '5px !important',
                  borderRadius: '500px',
                  height: 'unset',
                },
                //  margin:'5px',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              overlap="circular"
              //   badgeContent={<Check fontSize="small" sx={{ color: "green" }} />}
              badgeContent={
                <>
                  {notification.eventType == 'LOGIN' && (
                    <Login fontSize="small" />
                  )}
                  {notification.eventType == 'FAILED_LOGIN' && (
                    <Login fontSize="small" />
                  )}
                  {notification.eventType == 'USER_ASSOCIATED' && (
                    <AssignmentInd fontSize="small" />
                  )}
                  {notification.eventType == 'TRANSACTION_CREATED' && (
                    <AccountBalance fontSize="small" />
                  )}
                  {notification.eventType == 'TRANSACTION_FAILED' && (
                    <AccountBalance fontSize="small" />
                  )}
                  {notification.eventType == 'TRANSACTION_COMPLETED' && (
                    <AccountBalance fontSize="small" />
                  )}
                  {notification.eventType == 'ACCOUNT_HOLDER_STATUS_UPDATE' && (
                    <FolderShared fontSize="small" />
                  )}
                  {notification.eventType == 'KYC_STATUS_UPDATE' && (
                    <SupportAgent fontSize="small" />
                  )}
                  {notification.eventType == 'USER_VERIFIED' && (
                    <VerifiedUser fontSize="small" />
                  )}
                </>
              }
            >
              <Avatar
                sx={[
                  { width: '56px', height: '56px' },
                  (theme) => ({
                    bgcolor: theme.palette.primary.main,
                  }),
                ]}
              >
                {notification.eventType == 'LOGIN' && 'LOGIN'}
                {notification.eventType == 'FAILED_LOGIN' && 'LOGIN'}
                {notification.eventType == 'USER_ASSOCIATED' && 'LOGIN'}
                {notification.eventType == 'TRANSACTION_CREATED' && 'TX'}
                {notification.eventType == 'TRANSACTION_FAILED' && 'TX'}
                {notification.eventType == 'TRANSACTION_COMPLETED' && 'TX'}
                {notification.eventType == 'ACCOUNT_HOLDER_STATUS_UPDATE' &&
                  'AH'}
                {notification.eventType == 'KYC_STATUS_UPDATE' && 'KYC'}
                {notification.eventType == 'USER_VERIFIED' && 'USER'}
                {/* TX */}
              </Avatar>
            </Badge>
            <Box
              sx={{
                ml: '10px',
              }}
            >
              <Typography sx={{ p: '2px' }}>
                {notification.eventType == 'LOGIN' &&
                  `Login initiated from ${
                    notification.parameters.find(
                      (n) => n.parameterName == 'ipAddress',
                    )?.parameterValue
                  }`}
                {notification.eventType == 'FAILED_LOGIN' &&
                  `Failed login attempt from ip ${
                    notification.parameters.find(
                      (n) => n.parameterName == 'ipAddress',
                    )?.parameterValue
                  }`}
                {notification.eventType == 'USER_ASSOCIATED' &&
                  `User has been associated`}
                {notification.eventType == 'TRANSACTION_CREATED' &&
                  `Transaction created`}
                {notification.eventType == 'TRANSACTION_FAILED' &&
                  `Transaction failed`}
                {notification.eventType == 'TRANSACTION_COMPLETED' &&
                  `${
                    notification.parameters.find(
                      (n) => n.parameterName == 'description',
                    )?.parameterValue
                  } successfully processed`}
                {notification.eventType == 'ACCOUNT_HOLDER_STATUS_UPDATE' &&
                  `Client status has been updated`}
                {notification.eventType == 'KYC_STATUS_UPDATE' &&
                  `KYC status has been updated`}
                {notification.eventType == 'USER_VERIFIED' &&
                  `User is verified`}
              </Typography>
              <Typography
                sx={{
                  p: '2px',
                  color: '#1876F2',
                  fontweight: '600',
                  lineHeight: '1.2308',
                  fontSize: '.8125rem',
                  textAlign: 'left',
                  userSelect: 'none',
                }}
              >
                {timeAgo.format(new Date(notification.createdDateTime))}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& span': {
                  height: '12px',
                  width: '12px',
                  borderRadius: '50%',
                  backgroundColor: '#1876F2',
                  display: 'inline-flex',
                },
              }}
            >
              {/* <span></span> */}
            </Box>
          </Box>
        ))}

        {Math.ceil(totalElements / limit) > page && !isFetching && (
          <Waypoint
            onEnter={() => {
              // console.log("WAYPOINT REACHED");
              handlePageChange(null, page + 1);
            }}
          />
        )}

        {isFetching && <CircularProgress />}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
