import { clientAccountInitialValues } from "src/components/forms";
import { ApiAccount } from "src/models/accounts";
import { CreateUpdateFeeFormValues } from "src/screens/Configuration/Fees/forms/types-dto";
import { Nullable } from "src/utils/types";
import { ApiFeeConfig, ApiFeeConfigCreateUpdate } from "../types";



export const mapCreateUpdateFeeFormValuesToApiFeeConfigCreate = (feeDto: CreateUpdateFeeFormValues): ApiFeeConfigCreateUpdate => ({
    accountHolderId: feeDto.accountHolderId,
    percentage: feeDto.percentage,
    transactionType: feeDto.transactionType,
    feeContributor: feeDto.feeContributor,
    feeAccountId: feeDto.feeAccount.clientAccountId,
    contributorAccountHolderId: feeDto.contributorAccount.clientAccountHolderId,
    contributorAccountId: feeDto.contributorAccount.clientAccountId,
    description: feeDto.description,
    enabled: feeDto.enabled,
    assignable: feeDto.assignable,
    accountHolderIdForHeader: feeDto.accountHolderIdForHeader,
    transactionContext: feeDto.transactionContext,
    feeConfigId: feeDto.feeConfigId
})




export const mapApiFeeConfigToCreateUpdateFeeFormValues = (
    fee: ApiFeeConfig, accountHolderIdForHeader: string, contributorAccount: Nullable<ApiAccount>, feeAccount: ApiAccount
): CreateUpdateFeeFormValues => {
    // alert(JSON.stringify(feeAccount))
    return {
        accountHolderIdForHeader,
        feeConfigId: fee.id,
        accountHolderId: fee.accountHolderId || '',
        percentage: fee.percentage,
        transactionType: fee.transactionType,
        feeContributor: fee.feeContributor,
        ...(feeAccount ?
            {
                feeAccount: {
                    clientAccountId: feeAccount?.id,
                    clientAccountHolderId: feeAccount?.accountHolderId
                }
            } : {
                feeAccount: clientAccountInitialValues()
            }),
        transactionContext: fee.transactionContext,
        ...(contributorAccount ?
            {
                contributorAccount: {
                    clientAccountId: contributorAccount?.id,
                    clientAccountHolderId: contributorAccount?.accountHolderId
                }
            } : {
                contributorAccount: clientAccountInitialValues()
            }),
        description: fee.description,
        enabled: fee.enabled,
        assignable: fee.assignable
    }
};