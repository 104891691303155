import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { xor } from 'lodash';

import {  ApiEvent } from './types';



export type EventState = {
   events:ApiEvent[];
   isConnected:boolean;
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState: { 
    events:[]
  } as EventState,
  reducers: {

    addEvent: (
      state,
      {
        payload: { event },
      }: PayloadAction<{
        event: ApiEvent;
      }>,
    ) => {
      state.events = [event,...state.events];
    },
    resetEvents: (state) => {
        state.events = [];
      },
    setConnected: (
        state,
        {
          payload: { status },
        }: PayloadAction<{
          status: boolean;
        }>,
      ) => {
        state.isConnected = status;
      },
      setNotFresh: (
        state,
        {
          payload: { ids },
        }: PayloadAction<{
          ids:string[];
        }>,
      ) => {
        state.events = [...state.events.filter(e => !ids.includes(e.id)),
                        ...state.events.filter(e => ids.includes(e.id))
                                       .map(n => ({...n, isFresh: false}) )]
      },
 
  },
});

export const { 
    addEvent,
    resetEvents, 
    setConnected,
    setNotFresh
} = eventsSlice.actions;

