import { styled } from '@mui/system';
import { FC, ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { SocketHandler } from '../socket/SocketHandler';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import KYCProcess from './KYCProcess';
import { SIDEBAR_WIDTH } from './utils/config';
interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = styled('div')(() => ({
  backgroundColor: '#ffffff',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: `${SIDEBAR_WIDTH}px`,
  },
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  minHeight: '100%',
  padding: '80px 0 16px',
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  return (
    <>
      <DashboardLayoutRoot>
        <DashboardSidebar
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardNavbar
            onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
          />
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <SocketHandler>
                <Outlet />
                <KYCProcess />
              </SocketHandler>
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardLayout;
