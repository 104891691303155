import React, { FC } from 'react';

import { AccountHolderPermission } from 'src/models/accountHolder/types';
import { usePermissions } from '../hooks/usePermissions';

interface Props {
  permissions: { permission: AccountHolderPermission, shouldHave: boolean }[];
  placeholder?: React.ReactNode;
}

export const PermissionsGuard: FC<Props> = ({
  children,
  permissions,
  placeholder,
}) => {
  const { hasPermission } = usePermissions();

  return <>{(permissions.every(p => hasPermission(p.permission) === p.shouldHave) || permissions.length == 0) ? children : placeholder || null}</>;
};
