import { ExpandMore, FilterAlt } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { AuditListFilter, EntityType } from 'src/models/events';
import { AuditFilterForm } from './AuditFilterForm';
import { AuditList } from './AuditList';

const Audits: FC<{
  accountHolderId: string;
  entityType: EntityType;
  entityId: string;
}> = ({ accountHolderId, entityType, entityId }) => {
  const [filter, setFilter] = useState<AuditListFilter>();
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    setFilter({
      id: '',
      entityType: entityType,
      entityReference: '',
      entityId: entityId,
    });
  }, []);

  return (
    <>
      <PermissionGuard permission="GET_AUDIT">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">Audits</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '0',
            }}
          >
            {/* <Box
              sx={[
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '25px',
                  marginTop: '25px',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                  },
                  [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                  },
                }),
              ]}
            >
              <Typography color="textPrimary" variant="h5"></Typography>

              <Box
                sx={[
                  {
                    display: 'flex',
                    gap: 1,
                    marginBottom: '10px',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      flexDirection: 'column',
                      gap: '10px',
                    },
                    [theme.breakpoints.up('sm')]: {
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    },
                  }),
                ]}
              >
                <Box
                  sx={[
                    { display: 'flex', justifyContent: 'flex-end' },
                    (theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('sm')]: {
                        width: 'auto',
                      },
                    }),
                  ]}
                >
                  <FilterAlt
                    color="primary"
                    fontSize="large"
                    sx={{ marginLeft: '10px' }}
                    onClick={() => {
                      setFilterOpen(!filterOpen);
                    }}
                  />
                </Box>
              </Box>
            </Box> */}

            <AuditList filter={filter} accountHolderId={accountHolderId} />
          </AccordionDetails>
        </Accordion>

        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >
            <Grid item xs={12}>
              <AuditFilterForm
                onFilterApply={setFilter}
                entityType={entityType}
                entityId={entityId}
              />
            </Grid>
          </Grid>
        </Drawer>
      </PermissionGuard>
    </>
  );
};

export default Audits;
