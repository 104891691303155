import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'src/services/redux/store';
import { AccountHolderPermission } from '../accountHolder/types';

export const selectAccountId = (state: RootState) => state.auth.userId;

export const selectIsAuthorized = (state: RootState) =>
  Boolean(state.auth?.accessToken);

export const selectToken = (state: RootState) =>
  state.auth?.accessToken;

export const selectExpiresOn = (state: RootState) =>
  state.auth?.expires_on;

export const selectLoggedInUserId = (state: RootState) =>
  state.auth?.userId;

export const selectAssociations = (state: RootState) =>
  state.auth?.associations || [];


export const selectUserStatus = (state: RootState) =>
  state.auth?.userStatus;

export const selectAccountHolderId = (state: RootState) =>
  state.auth?.currentAccountHolderId


export const isAccountHolderStatusActive = createSelector(
  selectAssociations,
  selectAccountHolderId,
  (associations, currentAccountHolderId) =>
  (associations
    .find(association => association.id == currentAccountHolderId)
    ?.status === 'ACTIVE')
);

export const isAccountHolderStatusKycRequired = createSelector(
  selectAssociations,
  selectAccountHolderId,
  (associations, currentAccountHolderId) =>
  (associations
    .find(association => association.id == currentAccountHolderId)
    ?.status === 'KYC_REQUIRED')
);

export const isAccountHolderStatusKycPending = createSelector(
  selectAssociations,
  selectAccountHolderId,
  (associations, currentAccountHolderId) =>
  (associations
    .find(association => association.id == currentAccountHolderId)
    ?.status === 'PENDING_KYC')
);


export const selectPermissionsMap = createSelector(
  selectAssociations,
  selectAccountHolderId,
  (associations, currentAccountHolderId) =>
  (associations
    .find(association => association.id == currentAccountHolderId)
    ?.permissions as AccountHolderPermission[])
);


export const selectAccountHolderType = createSelector(
  selectAssociations,
  selectAccountHolderId,
  (associations, currentAccountHolderId) =>
  (associations
    .find(association => association.id == currentAccountHolderId)
    ?.accountHolderType)
);

export const selectAccountHolderDisplayName = createSelector(
  selectAssociations,
  selectAccountHolderId,
  (associations, currentAccountHolderId) =>
  (associations
    .find(association => association.id == currentAccountHolderId)
    ?.displayName)
);
