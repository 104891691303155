import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiTelexConfig,
  useAddTelexConfigurationMutation,
  useUpdateTelexConfigurationMutation,
} from 'src/models/configurations';
import { CreateUpdateTelexFormValues } from '../types';
import { telexFormValidationSchema, telexToTelexFormValues } from '../utils';
interface FormProps {
  onClose?: () => void;
  telex?: ApiTelexConfig;
  accountHolderId: string;
  isGlobal: boolean;
}
export const TelexForm: FC<FormProps> = ({
  onClose,
  telex,
  accountHolderId,
  isGlobal,
}) => {
  const [addTelex] = useAddTelexConfigurationMutation();
  const [updateTelex] = useUpdateTelexConfigurationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateUpdateTelexFormValues>
      innerRef={formikRef}
      initialValues={{
        telexConfigId: '',
        telexPrefix: '',
        incomingUsername: '',
        incomingPassword: '',
        outgoingUsername: '',
        outgoingPassword: '',
        telexDomain: '',
        providerEmail: '',
        enabled: true,
        accountHolderIdForHeader: !isGlobal ? accountHolderId : null,
        ...(telex ? telexToTelexFormValues(telex, accountHolderId) : {}),
      }}
      validationSchema={telexFormValidationSchema}
      onSubmit={async (
        values,
      ): Promise<void> => {
        try {
          if (telex) {
            //  const {telexConfigId, accountHolderIdForHeader,...rest} = values;
            const updateTelexResponse = await updateTelex(values).unwrap();
            toast.success(`Telex updated successfully.`);
          } else {
            const createTelexResponse = await addTelex(values).unwrap();
            toast.success(`Telex created successfully.`);
          }
          onClose();
        } catch (err) {
          // if (err?.data?.errorCode == 'ACCOUNT_LIMIT_REACHED'){
          //   toast.error(`No more accounts with the requested currency can be created` )
          // }
          // else if (err?.data?.errorCode == 'INVALID_CURRENCY'){
          //   toast.error(`Requested currency is not available for this specific bank` )
          // }
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {telex ? 'Edit Telex' : 'Create Telex'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>


              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal || telex}
                accountHolderId={accountHolderId} />

              {telex && (
                <FormikTextField
                  name="telexConfigId"
                  label="Id"
                  autoComplete="off"
                  disabled
                  margin='normal'
                />
              )}

              <FormikTextField
                name="telexPrefix"
                label="Telex Prefix"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="incomingUsername"
                label="Incoming Username"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="incomingPassword"
                label="Incoming Password"
                autoComplete="off"
                margin='normal'
              />

              <FormikTextField
                name="outgoingUsername"
                label="Outgoing Username"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="outgoingPassword"
                label="Outgoing Password"
                autoComplete="off"
                margin='normal'
              />

              <FormikTextField
                name="telexDomain"
                label="Telex Domain"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="providerEmail"
                label="Provider Email"
                autoComplete="off"
                margin='normal'
              />

              <FormControlLabel
                control={
                  <Field
                    label="Enabled"
                    name="enabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('enabled', checked);
                    }}
                    checked={values.enabled ? true : null}
                  />
                }
                label="Enabled"
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {telex ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
