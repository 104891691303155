import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { CountryCode } from 'libphonenumber-js';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { countries, Country } from '../../../../../utils/countries';
import { Nullable } from '../../../../../utils/types';
import { CountryFlag } from '../../../../CountryFlag';
import { Phone } from './types';

export interface PhoneFieldProps {
  disabled?: boolean;
}
export const PhoneField: FC<PhoneFieldProps & FieldProps<Phone, Phone>> = ({
  disabled = false,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={[
          (theme) => ({
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: '160px 1fr',
            [theme.breakpoints.down('sm')]: {
              gridTemplateColumns: '1fr',
            },
          }),
        ]}
      >
        <FormControl fullWidth margin="normal">
          <Field
            component={Autocomplete}
            name={`${field.name}.phoneCountryCode`}
            fullWidth
            value={field.value.phoneCountryCode}
            onBlur={handleBlur(`${field.name}.phoneCountryCode`)}
            onChange={(e: any, value: Country) => {
              setFieldValue(
                `${field.name}.phoneCountryCode`,
                value !== null ? value : null,
              );
            }}
            disabled={disabled}
            options={countries}
            autoHighlight
            variant="outlined"
            filterOptions={(
              options: Array<Country>,
              state: { inputValue: string },
            ): Array<Country> => {
              const value = state.inputValue;
              return options.filter(
                (x) =>
                  x.name
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()) ||
                  x.code
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()) ||
                  x.callingCode
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()) ||
                  x.callingCode
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase().replace('+', '')),
              );
            }}
            isOptionEqualToValue={(option: Country, value: Nullable<Country>) =>
              option.code === value?.code
            }
            getOptionLabel={(option: Country) => `+${option.callingCode}` || ''}
            renderOption={(
              props: any,
              option: { callingCode: string; code: CountryCode; name: string },
            ) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
                key={option.code}
              >
                <CountryFlag countryCode={option.code} />+{option.callingCode}
              </Box>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t('common.phonePrefix')}
                  error={Boolean(
                    getIn(touched, `${field.name}.phoneCountryCode`) &&
                    !!getIn(errors, `${field.name}.phoneCountryCode`)
                  )}
                  helperText={
                    getIn(touched, `${field.name}.phoneCountryCode`) &&
                    t(getIn(errors, `${field.name}.phoneCountryCode`) as string)
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    ...(getIn(values, `${field.name}.phoneCountryCode`)?.code
                      ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <>
                              <Box sx={{ ml: '8px' }}>
                                <CountryFlag
                                  countryCode={
                                    getIn(values, `${field.name}.phoneCountryCode`).code
                                  }
                                />
                              </Box>
                            </>
                          </InputAdornment>
                        ),
                      }
                      : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
        <Field
          component={TextField}
          name={`${field.name}.phoneNumber`}
          fullWidth
          label={t('common.phoneNumber')}
          value={field.value.phoneNumber}
          margin="normal"
          error={Boolean(
            getIn(touched, `${field.name}.phoneNumber`) &&
            !!getIn(errors, `${field.name}.phoneNumber`)
          )}
          helperText={
            getIn(touched, `${field.name}.phoneNumber`) &&
            t(getIn(errors, `${field.name}.phoneNumber`) as string)
          }
          onBlur={handleBlur(`${field.name}.phoneNumber`)}
          onChange={handleChange(`${field.name}.phoneNumber`)}
          variant="outlined"
          disabled={disabled}
        />
      </Box>
    </>
  );
};
