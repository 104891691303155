import { AttachFile, Preview } from '@mui/icons-material';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PreviewImageDialog } from 'src/components/PreviewImageDialog';

export interface ImageFieldProps {
  disabled: boolean;
  withPreview: boolean;
  defaultFileUrl: string;
  defaultFileName: string;
  label: string;
}

export const ImageField: FC<ImageFieldProps & FieldProps<File, File>> = ({
  disabled = false,
  withPreview = false,
  label,
  defaultFileUrl,
  defaultFileName,
  field,
  form: { values, touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const { t } = useTranslation();

  const [previewImageDialog, setPreviewImageDialog] = useState({
    isOpen: false,
    title: '',
    file: null,
    onConfirm: () => void 0,
  });

  const scriptInputRef = React.useRef<HTMLInputElement>(null);
  const fileSelect = (
    e: ChangeEvent<HTMLInputElement>,
    fileFieldName: string,
  ) => {
    setFieldValue(fileFieldName, e.currentTarget.files.item(0));
    if (scriptInputRef.current) {
      scriptInputRef.current.focus();
    }
  };

  return (
    <>
      {(values[field.name] || defaultFileUrl) && withPreview && (
        <Box mt={2} textAlign="center">
          <div>{t('common.imagePreview')}:</div>
          <img
            src={
              values[field.name]
                ? URL.createObjectURL(values[field.name])
                : defaultFileUrl
            }
            alt={values[field.name] ? values[field.name].name : defaultFileName}
            height="100px"
          />
        </Box>
      )}
      <Field
        component={TextField}
        margin="normal"
        error={Boolean(touched[field.name] && errors[field.name])}
        helperText={touched[field.name] && t(errors[field.name])}
        fullWidth
        id="outlined-basic"
        variant="outlined"
        label={t(label)}
        inputRef={scriptInputRef} // To focus on the field after clicking icon
        value={values[field.name]?.name || ''}
        onBlur={() => setFieldTouched(field.name, true)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              {(values[field.name] || defaultFileUrl) && (
                <Tooltip title={`Preview ${t(label)}`} arrow>
                  <IconButton
                    aria-label="upload"
                    component="label"
                    onClick={() => {
                      setPreviewImageDialog({
                        isOpen: true,
                        title: `Preview ${values[field.name]
                          ? values[field.name]?.name
                          : defaultFileName
                          }`,
                        file: values[field.name]
                          ? URL.createObjectURL(values[field.name])
                          : defaultFileUrl,
                        onConfirm: () => void 0,
                      });
                    }}
                  >
                    <Preview
                      sx={(theme) => ({
                        ...(touched[field.name] && errors[field.name]
                          ? { color: theme.palette.error.main }
                          : {}),
                      })}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={`Upload ${t(label)}`} arrow>
                <IconButton aria-label="upload" component="label">
                  <AttachFile
                    sx={(theme) => ({
                      ...(touched[field.name] && errors[field.name]
                        ? { color: theme.palette.error.main }
                        : {}),
                    })}
                  />
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    onChange={(e) => fileSelect(e, field.name)}
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
        }}
      />

      {
        <PreviewImageDialog
          previewImageDialog={previewImageDialog}
          setPreviewImageDialog={setPreviewImageDialog}
        />
      }
    </>
  );
};
