import { ContentCopy, NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import Audits from 'src/components/audits';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import InputDialog from 'src/components/InputDialog';
import Scrollbar from 'src/components/Scrollbar';
import { useCopyText } from 'src/hooks/useCopyText';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { useResetPasswordMutation } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { useCountriesQuery } from 'src/models/countryStates/api';
import { mapApiUserResponseToUserInfo, useDisableUser2FAMutation, useUserQuery } from 'src/models/users';
import { UserStatusLabel } from 'src/models/users/components/UserStatusLabel';
import { Nullable } from 'src/utils/types';
import { UserInfo } from '../Users/forms/types-dto';
import { UserForm } from '../Users/forms/UserForm';
import { AssociationsTable } from './AssociationsTable';
import { ResetPasswordForm } from './ResetPasswordForm';

interface DetailRow {
  name: string;
  value: any;
}

export const UserView = ({
  id,
  accountHolderId,
  isGlobal,
}: {
  id: string;
  accountHolderId: string;
  isGlobal: boolean;
}) => {
  const copyText = useCopyText();
  const [editUserOpened, setEditUserOpened] = useState<boolean>(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [resetPassword] = useResetPasswordMutation();
  const [disable2FA] = useDisableUser2FAMutation();
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const [newPassword, setNewPassword] = useState<string>(null);
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const [inputDialog, setInputDialog] = React.useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const { data: user } = useUserQuery(
    {
      accountHolderIdForHeader: accountHolderId,
      id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: apiCountries } = useCountriesQuery(
    { accountHolderId },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [userInfo, setUserInfo] =
    React.useState<Nullable<UserInfo>>(null);

  useEffect(() => {
    (async () => {
      if (user != null && apiCountries != null) {
        const onboardingInfo = await mapApiUserResponseToUserInfo(user, apiCountries, accountHolderId);
        setUserInfo(onboardingInfo);
      }
    })()

  }, [user, apiCountries, accountHolderId])

  const userInfoData: DetailRow[] = useMemo(
    () =>
      user
        ? [
          {
            name: 'Status',
            value: <UserStatusLabel status={user.userStatus} />,
          },
          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(user.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.id}
              </Typography>
            ),
          },
          {
            name: 'Email',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.email}
              </Typography>
            ),
          },
          {
            name: 'Phone Prefix',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.phoneNumber.prefix}
              </Typography>
            ),
          },
          {
            name: 'Phone Number',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.phoneNumber.number}
              </Typography>
            ),
          },
          {
            name: 'Full Phone Number',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.phoneNumber.phoneNumber}
              </Typography>
            ),
          },
          {
            name: 'First Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.firstName}
              </Typography>
            ),
          },
          {
            name: 'Last Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.lastName}
              </Typography>
            ),
          },
          {
            name: 'Date of Birth',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {moment.utc(user.dateOfBirth).format('DD/MM/yyyy')}
              </Typography>
            ),
          },
          {
            name: 'Nationality',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.nationality}
              </Typography>
            ),
          },
          {
            name: 'Passport/Identity Number',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.idPassportNo}
              </Typography>
            ),
          },
          {
            name: 'Address Line 1',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.addressLine1}
              </Typography>
            ),
          },
          {
            name: 'Address Line 2',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.addressLine2}
              </Typography>
            ),
          },
          {
            name: 'Zip/Post Code',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.postcode}
              </Typography>
            ),
          },
          {
            name: 'City',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.city}
              </Typography>
            ),
          },
          {
            name: 'State',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.state}
              </Typography>
            ),
          },
          {
            name: 'Country',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {user.countryCode}
              </Typography>
            ),
          },
          {
            name: '2FA Enabled',
            value: (
              <Chip
                label={
                  user.twoFactorSecurity.enabled ? 'Enabled' : 'Disabled'
                }
                color={user.twoFactorSecurity.enabled ? 'success' : 'error'}
              />
            ),
          },
          // {
          //   name: 'Editable',
          //   value: (
          //     <Chip
          //       label={user.editable ? 'Yes' : 'No'}
          //       color={user.editable ? 'success' : 'error'}
          //     />
          //   ),
          // },
        ]
        : [],
    [accountHolderId, user],
  );

  const breadcrumbs = (email) => [
    <Link key="1" color={blueGrey[600]} component={RouterLink} to={'./..'}>
      <Typography variant="overline">USER MANAGEMENT</Typography>
    </Link>,
    <Link
      key="1"
      color={blueGrey[500]}
      component={RouterLink}
      to={`./../${id}`}
    >
      <Typography variant="overline">{email}</Typography>
    </Link>,
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          item
          xs={12}
        >
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {user && breadcrumbs(user.email)}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sx={[
              {
                width: '100%',
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            {hasPermission('UPDATE_USER') && user?.editable && (
              <Button
                variant="contained"
                onClick={() => setEditUserOpened(true)}
                disabled={!isUserActive}
              >
                Edit User
              </Button>
            )}

            {hasPermission('RESET_PASSWORD') && user?.editable && (
              <Button
                variant="contained"
                disabled={!isUserActive}
                onClick={() => {
                  setInputDialog({
                    isOpen: true,
                    title: 'Reset password',
                    subTitle: `Reset password for user ${user.email}`,
                  });
                }}
              >
                Reset Password
              </Button>
            )}
            {user?.twoFactorSecurity?.enabled && (
              <PermissionGuard permission="ADMIN">
                <Button
                  variant="contained"
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: 'Are you sure to disable 2FA for this user?',
                      subTitle: 'This operation cannot be undone',
                      onConfirm: async () => {
                        const result = await disable2FA({
                          userId: user.id,
                          accountHolderIdForHeader: accountHolderId,
                        }).unwrap();
                      },
                    });
                  }}
                >
                  Disable 2FA
                </Button>
              </PermissionGuard>
            )}
          </Grid>
        </Grid>

        {user ? (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6">User info</Typography>
                      </Box>
                    }
                  />

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: '16px' }}
                  >
                    {userInfoData.map(({ name, value }) => (
                      <Grid item md={6} sm={12} xs={12} key={name}>
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {name}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Audits
                    accountHolderId={accountHolderId}
                    entityType={'USER'}
                    entityId={user.id}
                  />
                </Card>

                <Grid
                  alignItems="center"
                  container
                  item
                  justifyContent="space-between"
                  sx={{ marginBottom: '25px', marginTop: '25px' }}
                  xs={12}
                >
                  <Grid item justifySelf="left">
                    <Typography color="textPrimary" variant="h5">
                      Associations
                    </Typography>
                  </Grid>
                </Grid>
                <Card>
                  <Scrollbar>
                    <AssociationsTable
                      user={user}
                      accountHolderId={accountHolderId}
                    />
                  </Scrollbar>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {user && (
        <>
          {hasPermission('UPDATE_USER') && user?.editable && (
            <Drawer
              anchor="right"
              open={editUserOpened == true}
              onClose={() => setEditUserOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <UserForm
                accountHolderId={accountHolderId}
                isGlobal={isGlobal}
                user={userInfo}
                onClose={() => setEditUserOpened(false)}
              />
            </Drawer>
          )}
        </>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {user && (
        <>
          <InputDialog
            inputDialog={inputDialog}
            setInputDialog={setInputDialog}
            initialValues={{
              sendByEmail: false,
              submit: null,
            }}
            validationSchema={Yup.object({
              sendByEmail: Yup.boolean().required(),
            })}
            onSubmit={async (values) => {
              const result = await resetPassword({
                userId: user.id,
                accountHolderId,
                sendByEmail: values.sendByEmail,
              }).unwrap();
              setNewPassword(result.newPassword);

              toast.success(`Password reset successfully.`);
              setInputDialog({
                isOpen: false,
                title: '',
                subTitle: '',
              });
            }}
          >
            <ResetPasswordForm
              onClose={() =>
                setInputDialog({
                  isOpen: false,
                  title: '',
                  subTitle: '',
                })
              }
            />
          </InputDialog>

          <Dialog
            open={newPassword != null}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="transaction-dialog-title"
          >
            <DialogTitle id="transaction-dialog-title">
              New password for {user?.email}
            </DialogTitle>
            <DialogContent dividers>
              {newPassword}{' '}
              <IconButton size="small" onClick={() => copyText(newPassword)}>
                <ContentCopy fontSize="small" />
              </IconButton>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => setNewPassword(null)}
                color="primary"
              >
                {t('common.close')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default UserView;
