import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import {
  ApiKeyListSort,
  ApiKeySummary,
  useApiKeysListQuery,
} from 'src/models/apiKeys';
import { SortOrder } from 'src/models/types';
import { usePagination } from '../../hooks/usePagination';
import { ApiKeysTable } from './ApiKeysTable';
import { ApiKeyFilterForm } from './types';

export const ApiKeyList = ({
  filter,
  accountHolderId
}: {
  filter: ApiKeyFilterForm;
  accountHolderId:string;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: ApiKeyListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as ApiKeyListSort, direction: 'asc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  const [skipFetch, setSkipFetch] = useState<boolean>(true);

  useEffect(() => {
    setSkipFetch(true);
    resetPagination();
    setSkipFetch(false);
  }, [filter]);

  const { data } = useApiKeysListQuery({
    ...filter,
    accountHolderIdForHeader:accountHolderId,
    page,
    size: limit,
    sort: [`${sort.orderBy},${sort.direction}` as ApiKeyListSort],
  },
  {
    refetchOnMountOrArgChange:true,
    skip:skipFetch
  });

  const [apiKeys, setApiKeys] = useState<ApiKeySummary[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    setApiKeys(data?.content || []);
    setTotalElements(data?.totalElements || 0);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <ApiKeysTable
          apiKeys={apiKeys}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
          accountHolderId={accountHolderId}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
