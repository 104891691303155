import { ChangeEvent, MouseEvent, useState } from 'react';

export const usePagination = ({
  rowsPerPage = 15,
}: { rowsPerPage?: number } = {}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(rowsPerPage);

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const resetPagination = () => {
    setPage(0);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  return { limit, handleLimitChange, page, handlePageChange, resetPagination };
};
