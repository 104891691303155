
   
import {ErrorOutline} from '@mui/icons-material';
import type { Theme } from '@mui/material';
import { Button, colors,Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'



const useStyles = makeStyles((theme:Theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        // top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        // backgroundColor: colors.orange[50],
        color: colors.orange[400],
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    }
}))


export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles()

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <ErrorOutline />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
                {props.children}
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} >Cancel</Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => { setConfirmDialog({ ...confirmDialog, isOpen: false }); confirmDialog.onConfirm(e); }} >{props.confirmText?props.confirmText:'Yes'}</Button>
            </DialogActions>
        </Dialog>
    )
}