import { CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
  isAccountHolderStatusKycPending,
  isAccountHolderStatusKycRequired,
  selectAccountHolderId,
  selectAssociations,
} from 'src/models/auth/selectors';
import { selectBank, setBank, useGetBankProfileQuery } from 'src/models/cms';
import { useDispatch, useSelector } from 'src/services/redux/store';

export const AccountHolderSelectedGuard: FC = ({ children }) => {
  const accountHolderIdSelected = useSelector(selectAccountHolderId);
  const bank = useSelector(selectBank);

  const associations = useSelector(selectAssociations);

  const isKycRequired = useSelector(isAccountHolderStatusKycRequired);
  const isKycPending = useSelector(isAccountHolderStatusKycPending);

  const [parentAccountHolderId, setParentAccountHolderId] =
    useState<string>(null);
  const parentBankData = useGetBankProfileQuery(
    { accountHolderId: parentAccountHolderId },
    { skip: parentAccountHolderId == null, refetchOnMountOrArgChange: true },
  );
  const bankData = useGetBankProfileQuery(
    { accountHolderId: accountHolderIdSelected },
    { skip: accountHolderIdSelected == null, refetchOnMountOrArgChange: true },
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!parentBankData.isFetching && parentBankData.data !== undefined)
      dispatch(setBank({ bank: parentBankData.data }));
  }, [parentBankData]);

  useEffect(() => {
    if (
      !parentBankData.isFetching &&
      parentBankData.data == undefined &&
      !bankData.isFetching &&
      bankData.data !== undefined
    )
      dispatch(setBank({ bank: bankData.data }));
  }, [bankData, parentBankData]);

  useEffect(() => {
    setParentAccountHolderId(
      associations.find((x) => x.id == accountHolderIdSelected)
        ?.parentAccountHolderId,
    );
  }, []);

  if (accountHolderIdSelected == null) {
    return <Navigate to="/account-holder-choice" />;
  }

  if (bank?.kycCheckEnabled == true && isKycRequired) {
    return <Navigate to="/kyc" />;
  }
  if (bank?.kycCheckEnabled == true && isKycPending) {
    return <Navigate to="/kyc-pending" />;
  }

  if (
    bank == null &&
    (parentBankData.isFetching || bankData.isFetching) &&
    (parentBankData.isLoading || bankData.isLoading) &&
    (isKycRequired || isKycPending)
  )
    return <CircularProgress />;

  return <>{children}</>;
};
