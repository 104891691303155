import * as Yup from 'yup';

export const createAccountHolderInviteUserFormValidationSchema = (userExists: any) =>
    Yup.object().shape({
        userEmail: Yup.string()
            .email()
            .required('validations.emailIsRequired')
            .when("checkEmail", {
                is: true,
                then: Yup.string()
                    .test({
                        message: () => "validations.anAccountWiththisEmailIsAlreadyRegistered",
                        test: async (email) => {
                            if (email) {
                                try {
                                    const response = await userExists[0]({ email }).unwrap();

                                    if (userExists[1].isLoading || response?.id?.length > 0) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                } catch (error) {
                                    // console.log(error);
                                    if (error?.['data'].errorCode == 'USERNAME_NOT_FOUND') {
                                        // alert(1);
                                        return true;
                                    }
                                }
                            }
                        },
                    }),

            }),
    })