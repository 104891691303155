import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC, useEffect } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { useTranslation } from 'react-i18next';

import { useCurrenciesQuery } from 'src/models/countryStates/api';
import { ApiCurrencyLookup } from 'src/models/countryStates/types';

export interface CurrenciesFieldProps {
  disabled: boolean;
  accountHolderId?: string;
}
export const CurrenciesField: FC<
  CurrenciesFieldProps & FieldProps<ApiCurrencyLookup[], ApiCurrencyLookup[]>
> = ({
  disabled = false,
  accountHolderId,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
    const { t } = useTranslation();

    const currencies = useCurrenciesQuery(
      { accountHolderId },
      {
        refetchOnMountOrArgChange: true,
      },
    );

    // Auto populate currencies when there is only one
    useEffect(() => {
      if (currencies && currencies?.data && currencies?.data?.length === 1) {
        setFieldValue(`${field.name}`, currencies.data[0]);
      }
    }, [currencies.data]);

    return (
      <>
        {/* <pre>{JSON.stringify(touched, null, 2)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre> */}

        {currencies && currencies?.data && currencies?.data?.length > 0 && (
          <FormControl fullWidth margin="normal">
            <InputLabel
              htmlFor={`${field.name}`}
              error={Boolean(touched[field.name] && errors[field.name])}
            >
              {t('common.currencies')}
            </InputLabel>
            <Field
              labelId={field.name}
              error={Boolean(touched[field.name] && errors[field.name])}
              component={Select}
              name={`${field.name}`}
              fullWidth
              multiple
              value={values[field.name]}
              onBlur={handleBlur(`${field.name}`)}
              onChange={(e: any, value: any) => {
                setFieldValue(`${field.name}`, e.target.value);
              }}
              input={<OutlinedInput label={t('common.currencies')} />}
              disabled={disabled || currencies?.data?.length === 1}
              variant="outlined"
              renderValue={(selected: ApiCurrencyLookup[]) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value: ApiCurrencyLookup) => (
                    <Chip
                      sx={{ pl: '5px' }}
                      key={value.code}
                      label={`${value.name} (${value.code})`}
                      icon={<CurrencyFlag currency={value.code.toLocaleLowerCase()} size="sm" />}
                    />
                  ))}
                </Box>
              )}
            >
              {currencies.data?.map((option) => (
                <MenuItem key={option.code} value={option as any}>
                  <Checkbox checked={values[field.name].indexOf(option) > -1} />
                  <CurrencyFlag currency={option.code.toLocaleLowerCase()} size="md" />
                  <ListItemText sx={{ ml: 1 }} primary={`${option.name} (${option.code})`} />
                </MenuItem>
              ))}
            </Field>
            <FormHelperText error>{touched[field.name] && t(errors[field.name] as string)}</FormHelperText>
          </FormControl>
        )}
      </>
    );
  };
