import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiTelexConfig,
  useDeleteTelexConfigurationMutation,
} from 'src/models/configurations';
import { SortOrder } from 'src/models/types';
import { TelexForm } from './forms/TelexForm';
interface TelexesTable {
  telexes: ApiTelexConfig[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
  isGlobal: boolean;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  // { title: 'Id', id: 'id', doSort: true },
  { title: 'Telex Prefix', id: 'telexPrefix', doSort: true },
  { title: 'Incoming Username', id: 'incomingUsername', doSort: true },
  { title: 'Incoming Password', id: 'incomingPassword', doSort: true },
  { title: 'Outgoing Username', id: 'outgoingUsername', doSort: true },
  { title: 'Outgoing Password', id: 'outgoingPassword', doSort: true },
  { title: 'Telex Domain', id: 'telexDomain', doSort: true },
  { title: 'Provider Email', id: 'providerEmail', doSort: true },
  { title: 'Enabled', id: 'enabled', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const TelexesTable: FC<TelexesTable> = ({
  telexes,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId,
  isGlobal,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };
  const { hasPermission } = usePermissions();
  const [editTelexOpened, setEditTelexOpened] = useState<ApiTelexConfig | null>(
    null,
  );
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteTelex] = useDeleteTelexConfigurationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            telexConfigId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              await deleteTelex({
                telexConfigId: values.telexConfigId,
                accountHolderIdForHeader: accountHolderId,
              }).unwrap();
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* Action is update only so we remove Actions if update is not possible */}
                  {headCells
                    .filter(
                      (x) =>
                        x.title != 'Actions' ||
                        hasPermission('UPDATE_TELEX_CONFIGURATION') ||
                        hasPermission('DELETE_TELEX_CONFIGURATION'),
                    )
                    .map(({ title, id, doSort }) => (
                      <TableCell
                        key={id}
                        sortDirection={
                          sortEnabled && doSort && orderBy === id
                            ? sortDirection
                            : false
                        }
                      >
                        {sortEnabled && doSort ? (
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? sortDirection : 'asc'}
                            onClick={createSortHandler(id)}
                          >
                            {title}
                          </TableSortLabel>
                        ) : (
                          title
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {telexes.map((telex) => {
                  return (
                    <TableRow
                      hover
                      key={telex.id}
                    // onClick={() => navigate(`${telex.id}`)}
                    >
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {format(
                            new Date(telex.createdDateTime),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {telex.id}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {telex.telexPrefix}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {telex.incomingUsername}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {telex.incomingPassword}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {telex.outgoingUsername}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {telex.outgoingPassword}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {telex.telexDomain}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {telex.providerEmail}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={telex.enabled ? 'Yes' : 'No'}
                          color={telex.enabled ? 'success' : 'error'}
                        />
                      </TableCell>

                      {hasPermission('UPDATE_TELEX_CONFIGURATION') && (
                        <TableCell align="right">
                          <Box sx={{ display: 'flex' }}>
                            <PermissionGuard permission="UPDATE_TELEX_CONFIGURATION">
                              <IconButton
                                disabled={!isUserActive}
                                onClick={() => setEditTelexOpened(telex)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </PermissionGuard>
                            <PermissionGuard permission="DELETE_TELEX_CONFIGURATION">
                              <IconButton
                                disabled={!isUserActive}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      'Are you sure to remove this telex configuration?',
                                    subTitle: 'This operation cannot be undone',
                                    onConfirm: () => {
                                      setFieldValue('telexConfigId', telex.id);
                                      formikRef?.current?.submitForm();
                                    },
                                  });
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </PermissionGuard>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>

      <PermissionGuard permission="UPDATE_TELEX_CONFIGURATION">
        <Drawer
          anchor="right"
          open={editTelexOpened != null}
          onClose={() => setEditTelexOpened(null)}
          PaperProps={{
            sx: {
              backgroundColor: '#F3F7FE',
              width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            },
          }}
        >
          <TelexForm
            telex={editTelexOpened}
            accountHolderId={accountHolderId}
            isGlobal={isGlobal}
            onClose={() => setEditTelexOpened(null)}
          />
        </Drawer>
      </PermissionGuard>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
