import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { transactionsApi } from '../transactions';
import { authApi } from './api';
import { TokenAccountHolderDetails } from './types';

export type AuthState = {
  accessToken: string;
  associations: TokenAccountHolderDetails[];
  expiresIn: number;
  userId: string;
  username: string;
  userStatus: string;
  currentAccountHolderId?: string | null;
  expires_on: number;
};

const initialState: AuthState = {
  accessToken: '',
  associations: [],
  expiresIn: 0,
  userId: '',
  username: '',
  userStatus: '',
  currentAccountHolderId: null,
  expires_on: 0
};

const resetCreds = () => initialState;

const NOT_AUTHORIZED_ERROR_CODES = [401, 403];

export const authSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (_, action) => {
        const { access_token, expires_in, ...payload } = action.payload;
        return {
          ...payload,
          accessToken: access_token,
          expiresIn: expires_in,
        };
      })
      .addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (_, action) => {
        const { access_token, expires_in, ...payload } = action.payload;
        return {
          ...payload,
          accessToken: access_token,
          expiresIn: expires_in,
          currentAccountHolderId: _.currentAccountHolderId
        };
      })
      .addMatcher(authApi.endpoints.logout.matchPending, resetCreds)
      .addMatcher(
        transactionsApi.endpoints.transactionsList.matchRejected,
        (state, action) => {
          if (
            NOT_AUTHORIZED_ERROR_CODES.includes(
              action.payload?.status as number,
            )
          ) {
            return resetCreds();
          }
          return state;
        },
      )
      .addMatcher(authApi.endpoints.impersonate.matchFulfilled, (_, action) => {
        const { access_token, expires_in, ...payload } = action.payload;
        return {
          ...payload,
          accessToken: access_token,
          expiresIn: expires_in,
        };
      }),
  name: 'auth',
  initialState,
  reducers: {
    resetCredentials: resetCreds,
    setCurrentAccountHolderId: (
      state,
      {
        payload: { accountHolderId },
      }: PayloadAction<{
        accountHolderId: string | null;
      }>,
    ) => {
      state.currentAccountHolderId = accountHolderId;
    },
  },
});

export const { resetCredentials, setCurrentAccountHolderId } = authSlice.actions;
