import { Redo } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import {
  ApiInvitation,
  useResendInvitationMutation,
} from 'src/models/invitations';
import { InvitationStatusLabel } from './InvitationStatusLabel';

interface InvitationTable {
  invitations: ApiInvitation[];
  accountHolderId: string;
}

const headCells = [
  { title: 'Created Date', id: 'createdDateTime', doSort: true },
  //   { title: 'Id', id: 'id', doSort: true },
  { title: 'Email', id: 'email', doSort: true },
  { title: 'Status', id: 'status', doSort: true },
  { title: 'Display Name', id: 'displayName', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const InvitationsTable: FC<InvitationTable> = ({
  invitations,
  accountHolderId,
}) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [resendInvitation] = useResendInvitationMutation();

  const formikRef = useRef(null);
  useTotp(formikRef);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            invitationId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              await resendInvitation({
                invitationId: values.invitationId,
                accountHolderId: accountHolderId,
              }).unwrap();
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* Action is update and delete only so we remove Actions if update and delete is not possible */}
                  {headCells.map(({ title, id, doSort }) => (
                    <TableCell key={id}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invitations.map((invitation) => {
                  return (
                    <TableRow
                      hover
                      key={invitation.id}
                    // onClick={() => navigate(`${invitation.id}`)}
                    >
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {format(
                            new Date(invitation.createdDateTime),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                        </Typography>
                      </TableCell>

                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {invitation.id}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {invitation.email}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <InvitationStatusLabel status={invitation.status} />
                      </TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {invitation.displayName}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <PermissionGuard permission="CREATE_USER_INVITATION">
                          <Tooltip title="Resend invitation">
                            <IconButton
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    'Are you sure to resend this invitation?',
                                  subTitle: 'This operation cannot be undone',
                                  onConfirm: () => {
                                    setFieldValue(
                                      'invitationId',
                                      invitation.id,
                                    );
                                    formikRef?.current?.submitForm();
                                  },
                                });
                              }}
                            >
                              <Redo fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </PermissionGuard>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
