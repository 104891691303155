import * as Yup from 'yup';

import { ApiKycSelfiesRequest, ApiKycVerification } from 'src/models/kycVerifications';
import { localConfig } from 'src/services/config';
import { ApiKycSelfiesFormValues } from './types';

export const userSelfiesFormInitialValues = (accountHolderId: string, kycVerification: ApiKycVerification): ApiKycSelfiesFormValues => ({
    accountHolderIdForHeader: accountHolderId,
    faceAttachment: null,
    kycVerificationId: kycVerification?.id,

    faceAttachmentFileId: kycVerification?.faceAttachmentFileId,
    faceAttachmentFileName: kycVerification?.faceAttachmentFileName,
    faceAttachmentFileUrl: kycVerification?.faceAttachmentFileUrl
});

export const userSelfiesFormValuesToUserDetailsRequestBody = (
    formValues: ApiKycSelfiesFormValues,
): ApiKycSelfiesRequest => ({
    accountHolderIdForHeader: formValues.accountHolderIdForHeader,
    kycVerificationId: formValues.kycVerificationId,
    faceAttachment: formValues.faceAttachment,
});

export const updateUserSelfiesFormValidationSchema =
    Yup.object().shape({

        faceAttachment: Yup
            .mixed()
            .when(['faceAttachmentFileId', 'faceAttachmentFileName'], {
                is: (faceAttachmentFileId, faceAttachmentFileName) => faceAttachmentFileId != null && faceAttachmentFileName != null,
                then: Yup.mixed().notRequired(),
                otherwise: Yup
                    .mixed()
                    .required("Face file is required")
                    .test("fileSize", "The file is too large", (value) => {
                        return value && value.size <= localConfig.UPLOAD_MAX_FILE_SIZE;
                    })
                    .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf", (value) => {
                        return value && (
                            value.type === "image/jpeg" ||
                            value.type === "image/bmp" ||
                            value.type === "image/png" ||
                            value.type === "application/pdf"
                        );
                    }),
            }),
        faceAttachmentFileId: Yup
            .string().when('faceAttachment', {
                is: (faceAttachment) => faceAttachment == null,
                then: Yup.string().required('Field is required'),
                otherwise: Yup.string().nullable(),
            }),
        faceAttachmentFileName: Yup
            .string().when('faceAttachment', {
                is: (faceAttachment) => faceAttachment == null,
                then: Yup.string().required('Field is required'),
                otherwise: Yup.string().nullable(),
            })
    }, [['faceAttachment', 'faceAttachmentFileId'], ['faceAttachment', 'faceAttachmentFileName'], ['faceAttachmentFileId', 'faceAttachmentFileName']]);

export const isUserSelfieFormCompleted = (kycVerification: ApiKycVerification): boolean => {
    try {
        updateUserSelfiesFormValidationSchema.validateSync(kycVerification);
    } catch (e) {
        return false;
    }
    return true;
}