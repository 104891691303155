import { AccountHolderType } from "src/models/accountHolders";
import { AccountHolderDetailsFormValues } from "./types";
import { createAccountHolderFormValidationSchema } from "./validations";

export const accountHolderDetailsInitialValues = (hasAdminPermission: boolean, accountHolderIdOverride: string, accountHolderType: AccountHolderType): AccountHolderDetailsFormValues => ({
    hasAdminPermission: hasAdminPermission,
    parentId: accountHolderIdOverride,
    accountHolderType: accountHolderType,
    descriptor: '',
    userId: '',
    mode: 'create_new_user'
});

export const isCreateAccountHolderFormCompleted = () => async (accountHolderDetails: AccountHolderDetailsFormValues): Promise<boolean> => {
    try {
        await createAccountHolderFormValidationSchema().validate(accountHolderDetails);
    } catch (e) {
        return false;
    }
    return true;
}