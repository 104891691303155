import { Card, CardContent } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { KYCWizard } from 'src/components/dashboard/KYCProcess/KYCWizard';
import WizardTemplate from 'src/components/WizardTemplate';

const KYCScreen: FC<{ accountHolderId: string }> = ({ accountHolderId }) => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>KYC</title>
      </Helmet>
      <WizardTemplate>
        <Card>
          <CardContent
            sx={{
              // width:'100%',
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <KYCWizard
              accountHolderIdForHeader={accountHolderId}
              hideClose={true}
              onClose={() => navigate('/', { replace: true })}
            />
          </CardContent>
        </Card>
      </WizardTemplate>
    </>
  );
};

export default KYCScreen;
