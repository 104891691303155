import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';

import { FormikTextField } from 'src/components/forms/TextField';
import { TelexFilterForm } from './types';

const initialValues: TelexFilterForm = {
  id: '',
  createdDateTime: '',
  telexPrefix: '',
  incomingUsername: '',
  incomingPassword: '',
  outgoingUsername: '',
  outgoingPassword: '',
  telexDomain: '',
  providerEmail: '',
  enabled: false,
};

interface FormProps {
  onFilterApply: (values: TelexFilterForm) => void;
}
export const TelexesFilterForm: FC<FormProps> = ({ onFilterApply }) => {
  const onSubmit = (values: TelexFilterForm) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField label="Id" name="id" type="text" size='small' />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Telex Prefix"
                  name="telexPrefix"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Incoming Username"
                  name="incomingUsername"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Outgoing Username"
                  name="outgoingUsername"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Telex Domain"
                  name="telexDomain"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Provider Email"
                  name="providerEmail"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={<Field name="enabled" component={Switch} />}
                  label="Enabled"
                  name="enabled"
                  checked={values.enabled}
                  onChange={(ev, checked) => {
                    setFieldValue('enabled', checked);
                  }}
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
