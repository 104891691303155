import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { CountryCode } from 'libphonenumber-js';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryFlag } from 'src/components/CountryFlag';
import { countries, Country } from 'src/utils/countries';
import { Nullable } from 'src/utils/types';

export interface NationalityFieldProps {
  disabled: boolean;
}
export const NationalityField: FC<
  NationalityFieldProps & FieldProps<Country, Country>
> = ({
  disabled = false,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
    const { t } = useTranslation();

    return (
      <>
        <FormControl fullWidth margin="normal">
          <Field
            component={Autocomplete}
            name={`${field.name}`}
            fullWidth
            value={values[field.name]}
            onBlur={handleBlur(`${field.name}`)}
            onChange={(e: any, value: Country) => {
              setFieldValue(`${field.name}`, value !== null ? value : null);
            }}
            disabled={disabled}
            options={countries}
            autoHighlight
            variant="outlined"
            filterOptions={(
              options: Array<Country>,
              state: { inputValue: string },
            ): Array<Country> => {
              // const value = state.inputValue;
              return options.filter(
                (x) =>
                  x.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  x.code.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  x.callingCode
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()) ||
                  x.callingCode
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase().replace('+', '')),
              );
            }}
            isOptionEqualToValue={(option: Country, value: Nullable<Country>) =>
              option.code === value?.code
            }
            getOptionLabel={(option: Country) => option.name || ''}
            renderOption={(
              props: any,
              option: { callingCode: string; code: CountryCode; name: string },
            ) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <CountryFlag countryCode={option.code} />
                {/* <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              /> */}
                {option.name} ({option.code}){/* +{option.callingCode} */}
              </Box>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <>
                <TextField
                  {...params}
                  label={t('common.nationality')}
                  error={Boolean(touched[field.name] && errors[field.name])}
                  helperText={
                    touched[field.name] && t(errors[field.name] as string)
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    ...(values[field.name]
                      ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <>
                              <Box sx={{ ml: '8px' }}>
                                <CountryFlag
                                  countryCode={values[field.name].code}
                                />
                              </Box>
                            </>
                          </InputAdornment>
                        ),
                      }
                      : {}),
                  }}
                />
              </>
            )}
          ></Field>
        </FormControl>
      </>
    );
  };
