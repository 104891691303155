import { emailInitialValue } from "../../elements";
import { EmailInfo, emailInfoValidationSchema } from ".";

export const emailFormInitialValues = (emailInfo?: EmailInfo): EmailInfo => {
    return {
        email: emailInitialValue(),
        password: '',
        ...emailInfo
    }
}


export const isEmailFormCompleted = (userExists: any) => async (emailInfo: EmailInfo): Promise<boolean> => {
    try {
        await emailInfoValidationSchema(userExists).validate(emailInfo);
    } catch (e) {
        return false;
    }
    return true;
}