import { format, parseISO } from "date-fns"

import { mapApiPhoneNumberToPhone, mapPhoneToApiPhoneNumber } from "src/components/forms"
import { UserInfo } from "src/screens/Users/forms/types-dto"
import { countries } from "src/utils/countries"
import { ApiCountryLookup } from "../countryStates/types"
import { DATE_OF_BIRTH_MASK } from "../types"
import { ApiCreateUserRequest, ApiUserResponse } from "./types-api"


// DTO to API 

export const mapUserInfoToApiCreateUserRequest = (userInfo: UserInfo): ApiCreateUserRequest => {
    return {
        ...(userInfo.id ? { id: userInfo.id } : {}),
        ...(userInfo.userStatus ? { userStatus: userInfo.userStatus } : {}),
        email: userInfo.email,
        dateOfBirth: format(userInfo.dateOfBirth, DATE_OF_BIRTH_MASK),
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        nationality: userInfo.nationality.code,
        phoneNumber: mapPhoneToApiPhoneNumber(userInfo.phone),
        idPassportNo: userInfo.idPassportNo,
        addressLine1: userInfo.address.addressLine1,
        addressLine2: userInfo.address.addressLine2,
        postcode: userInfo.address.postcode,
        countryCode: userInfo.address.countryCode.code,
        city: userInfo.address.city,
        state: userInfo.address.state.length > 0 ? userInfo.address.state : null,
        permissionGroupIds: userInfo.permissionGroupIds,
        accountHolderIdForHeader: userInfo.accountHolderIdForHeader
    }
}



// API to DTO

export const mapApiUserResponseToUserInfo = async (user: ApiUserResponse, apiCountries: ApiCountryLookup[], accountHolderIdForHeader: string): Promise<UserInfo> => {
    return {
        id: user.id,
        permissionGroupIds: user.associations.flatMap(a => a.permissionGroups.map(p => p.id)),
        email: user.email,
        dateOfBirth: parseISO(user.dateOfBirth),
        firstName: user.firstName,
        lastName: user.lastName,
        nationality: countries.find(x => x.code === user.nationality),
        phone: mapApiPhoneNumberToPhone(user.phoneNumber),
        idPassportNo: user.idPassportNo,
        address: {
            addressLine1: user.addressLine1,
            addressLine2: user.addressLine2,
            postcode: user.postcode,
            countryCode: apiCountries.find(x => x.code === user.countryCode),
            city: user.city,
            state: user.state?.length > 0 ? user.state : '',
        },
        userStatus: user.userStatus,
        accountHolderIdForHeader: accountHolderIdForHeader
    }
}


