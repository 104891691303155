import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';

import { FormikTextField } from 'src/components/forms/TextField';
import { kycStatusList } from 'src/models/kycVerifications';
import { KycVerificationFilterForm } from './types';



interface FormProps {
  onFilterApply: (values: KycVerificationFilterForm) => void;
  isGlobal: boolean;
  accountHolderId: string;
}
export const KycVerificationsFilterForm: FC<FormProps> = ({ onFilterApply, isGlobal, accountHolderId }) => {

  const initialValues: KycVerificationFilterForm = {
    kycStatus: [],
    idPassportNo: '',
    id: '',
    accountHolderId: (!isGlobal ? accountHolderId : ''),
    includeSubAccountHolders: false
  };

  const onSubmit = (values: KycVerificationFilterForm) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size='small' >
                  <InputLabel id="select-kyc-status-label">
                    KYC Status
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    labelId="select-kyc-status-label"
                    label="KYC Status"
                    id="select-kyc-status"
                    value={values.kycStatus}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('type', e.target.value);
                    }}
                  >
                    {kycStatusList.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="ID/Passport Number"
                  name="idPassportNo"
                  type="text"
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Id"
                  name="id"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}
                sx={{
                  display: 'flex'
                }}>

                <FormControlLabel
                  control={<Field name="includeSubAccountHolders" component={Switch} />}
                  label="Include Sub-AccountHolder Holders"
                  name="includeSubAccountHolders"
                  checked={values.includeSubAccountHolders}
                  onChange={(ev, checked) => { setFieldValue('includeSubAccountHolders', checked); }}
                />


              </Grid>


            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
