import * as Yup from 'yup';

import { ApiAccountHolderUpdate } from "src/models/accountHolders";
import { ApiAccountHolderTemplate, ApiAccountHolderTemplateUpdateRequest } from "src/models/cms";
import { localConfig } from "src/services/config";
import { EditAccountHolderDetailsFormValues } from "./types";

// Used by edit account holder
export const accountHolderFormValuesToAccountHolderRequestBody
  = (
    formValues: EditAccountHolderDetailsFormValues
  ): ApiAccountHolderUpdate => ({
    accountHolderIdForHeader: formValues.accountHolderIdForHeader,
    accountHolderId: formValues.accountHolderId,
    domain: formValues.domain == '' ? null : formValues.domain,
    accountHolderType: formValues.accountHolderType,
    accountHolderStatus: formValues.accountHolderStatus,
    descriptor: formValues.descriptor,
    senderEmail: formValues.senderEmail,
    allowRegistration: formValues.allowRegistration,
    displayCurrencyCode: formValues.displayCurrencyCode.length > 0 ? formValues.displayCurrencyCode : null
  });

// account holder template
export const accountHolderTemplateInitialValues = (accountHolderId: string, accountHolderIdForHeader: string): ApiAccountHolderTemplateUpdateRequest => ({
  logo: null,
  themeColourHex: '',
  displayName: '',
  checkoutBackgroundColourHex: '',
  checkoutLogo: null,
  checkoutForegroundColourHex: '',
  checkoutButtonColourHex: '',
  checkoutTextColourHex: '',
  checkoutBannerSource: '',
  checkoutBackgroundSource: '',
  checkoutFontReference: '',
  domain: '',
  status: '',
  kycCheckEnabled: false,
  accountHolderId,
  accountHolderIdForHeader
});

export const accountHolderTemplateFormValidationSchema =
  Yup.object().shape({
    logo: Yup
      .mixed()
      .when(['logoUrl'], {
        is: (logoUrl) => logoUrl != null,
        then: Yup.mixed().notRequired(),
        otherwise: Yup
          .mixed()
          .required("Identity file is required")
          .test("fileSize", "The file is too large", (value) => {
            return value && value.size <= localConfig.UPLOAD_MAX_FILE_SIZE;
          })
          .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf", (value) => {
            return value && (
              value.type === "image/jpeg" ||
              value.type === "image/bmp" ||
              value.type === "image/png" ||
              value.type === "application/pdf"
            );
          }),
      }),
    logoUrl: Yup
      .string().when('logo', {
        is: (logo) => logo == null,
        then: Yup.string().required('Field is required'),
        otherwise: Yup.string().nullable(),
      }),

    displayName: Yup.string().required('Display name is required'),
  }, [['logo', 'logoUrl'], ['logoUrl', 'logo']]);



export const accountHolderTemplateToAccountHolderTemplateFormValues = (
  accountHolderTemplate: ApiAccountHolderTemplate,
): ApiAccountHolderTemplate => ({
  id: accountHolderTemplate.id,
  accountHolderId: accountHolderTemplate.accountHolderId,
  logoUrl: accountHolderTemplate.logoUrl,
  themeColourHex: accountHolderTemplate.themeColourHex || '',
  displayName: accountHolderTemplate.displayName || '',
  checkoutBackgroundColourHex: accountHolderTemplate.checkoutBackgroundColourHex || '',
  checkoutLogoUrl: accountHolderTemplate.checkoutLogoUrl,
  checkoutForegroundColourHex: accountHolderTemplate.checkoutForegroundColourHex || '',
  checkoutButtonColourHex: accountHolderTemplate.checkoutButtonColourHex || '',
  checkoutTextColourHex: accountHolderTemplate.checkoutTextColourHex || '',
  checkoutBannerSource: accountHolderTemplate.checkoutBannerSource || '',
  checkoutBackgroundSource: accountHolderTemplate.checkoutBackgroundSource || '',
  checkoutFontReference: accountHolderTemplate.checkoutFontReference || '',
  allowRegistration: accountHolderTemplate.allowRegistration,
  domain: accountHolderTemplate.domain,
  status: accountHolderTemplate.status,
  kycCheckEnabled: accountHolderTemplate.kycCheckEnabled
});