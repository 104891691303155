import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { LazyLoadableScreen } from 'src/navigation/LazyLoadableScreen';

const TelexesScreen = LazyLoadableScreen(() => import('./Telexes'));

export const TelexRoutes: FC<{
  accountHolderId: string;
}> = ({ accountHolderId }) => {
  return (
    <Routes>
      <Route
        path=""
        element={<TelexesScreen accountHolderId={accountHolderId} />}
      />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};
