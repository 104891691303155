import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { ApiKeyListRequest, ApiKeyRequest, ApiKeyResponse, PageApiKeySummary } from './types-api';

export const apiKeysApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        apiKeysList: builder.query<PageApiKeySummary, ApiKeyListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'auth/api-keys',
                }
            },
            providesTags: ['ApiKey']
        }),
        addApiKey: builder.mutation<ApiKeyResponse, ApiKeyRequest>({
            query: ({ accountHolderIdForHeader, ...body }) => ({
                body,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                url: 'auth/api-keys',
            }),
            invalidatesTags: ['ApiKey']
        }),
        deleteApiKey: builder.mutation<void, { id: string, accountHolderId: string }>({
            query: (payload) => ({
                headers: {
                    'x-account-holder-id': `${payload.accountHolderId}`,
                },
                method: 'DELETE',
                url: `auth/api-keys/${payload.id}`,
            }),
            invalidatesTags: ['ApiKey']
        })
    })
})

export const {
    useApiKeysListQuery,
    useAddApiKeyMutation,
    useDeleteApiKeyMutation
} = apiKeysApi;