import { CreateUpdateBeneficiaryFormValues } from "./types-dto"

export const createBeneficiaryFormValuesInitialValue = (accountHolderIdForHeader: string): CreateUpdateBeneficiaryFormValues => {
    return {
        id: null,
        firstName: '',
        lastName: '',
        companyName: '',
        accountNumber: '',
        iban: '',
        enabled: true,
        accountHolderIdForHeader
    }
}