

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectIsTotpRequired, selectTotpCode, setTotpRequired } from "src/models/totp";

export const useTotp = (formikRef?:React.MutableRefObject<any>) => {
    const dispatch = useDispatch();
    const totpCode = useSelector(selectTotpCode);
    const isTotpRequired = useSelector(selectIsTotpRequired);

    useEffect(() => {
      if(totpCode){
        if(isTotpRequired) {
          formikRef?.current?.submitForm();
          dispatch(setTotpRequired({required:false}));
        }
      }
    },[totpCode]);
    
  }