
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import {
    AccountEntryListRequest, AccountEntryListResponse, AccountListRequest, AccountListResponse, ApiAccount, ApiAccountCreate, ApiAccountCurrency, ApiAccountUpdate, GetAccountBalancesResponse,
} from './types-api';

export const accountsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        accountsList: builder.query<AccountListResponse, AccountListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/accounts',
                }
            },
            providesTags: ['Account']
        }),
        account: builder.query<ApiAccount, { id: string, accountHolderIdForHeader: string }>({
            query: ({ id, accountHolderIdForHeader }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/accounts/${id}`,

            }),
            // transformResponse:(response:ApiAccountResponse, meta, arg) => {
            //     const {associations, ...rest} = response;
            //     return ({...rest,  permissionGroupIds: associations.find(a => a.accountHolderId == arg.accountHolderId).permissionGroups.map(pg => pg.id)});
            //   },
            providesTags: ['Account']
        }),
        accountEntriesList: builder.query<AccountEntryListResponse, AccountEntryListRequest>({
            query: ({ id, accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: `gateway/accounts/${id}/account-entries`,
                }
            },
            providesTags: ['Account']
        }),
        addAccount: builder.mutation<ApiAccount, ApiAccountCreate>({
            query: ({ accountHolderIdForHeader, ...account }) => ({
                url: 'gateway/accounts',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: account
            }),
            invalidatesTags: ['Account']
        }),
        updateAccount: builder.mutation<ApiAccount, ApiAccountUpdate>({
            query: ({ id, accountHolderIdForHeader, ...rest }) => ({
                url: `gateway/accounts/${id}`,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest }
            }),
            invalidatesTags: ['Account']
        }),
        deleteAccount: builder.mutation<void, { id: string, accountHolderId: string }>({
            query: ({ id, accountHolderId }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                url: `gateway/accounts/${id}`,
            }),
            invalidatesTags: ['Account']
        }),
        accountCurrencies: builder.query<ApiAccountCurrency[], { accountHolderId: string }>({
            query: ({ accountHolderId }) => ({
                method: 'GET',
                headers: {
                    'x-account-holder-id': accountHolderId,
                },
                url: `gateway/accounts/currencies`,

            }),
            providesTags: ['Account']
        }),

        accounts: builder.query<ApiAccount[], { accountHolderId: string }>({
            query: ({ accountHolderId }) => {
                const parameters = removeEmpty({
                    accountStatus: ['ACTIVE'],
                    accountHolderId
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderId,
                    },
                    method: 'GET',
                    params: parameters,
                    url: `gateway/accounts/list`,
                }
            },
            transformResponse: (response: ApiAccount[]) => {
                return response
                    .sort((a, b) => a.currencyCode > b.currencyCode && 1 || -1)
            },
            providesTags: ['Account']
        }),

        accountBalances: builder.query<GetAccountBalancesResponse, { accountHolderId: string }>({
            query: ({ accountHolderId }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderId,
                    },
                    method: 'GET',
                    url: `gateway/accounts/balances`,
                }
            },
            providesTags: ['Account']
        }),
    })
})

export const {
    useAccountsListQuery,
    useAccountQuery,
    useAccountEntriesListQuery,
    useAddAccountMutation,
    useUpdateAccountMutation,
    useDeleteAccountMutation,
    useAccountCurrenciesQuery,
    useAccountsQuery,
    useAccountBalancesQuery
} = accountsApi;