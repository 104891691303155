import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryCodeField } from 'src/components/forms/CountryCodeField';
import { FormikTextField } from 'src/components/forms/TextField';
import { AcquiringListFilter } from 'src/models/configurations';
import { useCountriesQuery } from 'src/models/countryStates/api';

const initialValues: AcquiringListFilter = {
  id: '',
  mid: '',
  requiresThreeD: false,
  requiresAvs: false,
  allowNonCvv: false,
  transactionDescriptor: '',
  returnUrlParamsEnabled: false,
  transactionExpiryEnabled: false,
  enabled: false,
  countryCode: null,
};

interface FormProps {
  onFilterApply: (values: AcquiringListFilter) => void;
}
export const AcquiringsFilterForm: FC<FormProps> = ({ onFilterApply }) => {
  const onSubmit = (values: AcquiringListFilter) => {
    onFilterApply(values);
    return Promise.resolve();
  };


  const countries = useCountriesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { t } = useTranslation();
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        setFieldValue,
        values,
        handleBlur,
        errors,
        touched
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField label="Id" name="id" type="text" size='small' />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField label="Mid" name="mid" type="text" size='small' />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Transaction Descriptor"
                  name="transactionDescriptor"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                {/* {countries && countries?.data && countries?.data?.length > 0 && (
                  <FormControl fullWidth margin="normal">
                    <Field
                      component={Autocomplete}
                      name={`countryCode`}
                      fullWidth
                      value={values.countryCode}
                      onBlur={handleBlur(`countryCode`)}
                      onChange={(e: any, value: ApiCountryLookup) => {
                        setFieldValue(
                          `countryCode`,
                          value !== null ? value : null,
                        ); // Set Country
                      }}
                      disabled={countries?.data?.length === 1}
                      options={countries.data}
                      autoHighlight
                      variant="outlined"
                      filterOptions={(
                        options: Array<ApiCountryLookup>,
                        state: { inputValue: string },
                      ): Array<ApiCountryLookup> => {
                        // const value = state.inputValue;
                        return options.filter(
                          (x) =>
                            x.name
                              ?.toLowerCase()
                              ?.includes(state.inputValue.toLowerCase()) ||
                            x.code
                              ?.toLowerCase()
                              ?.includes(state.inputValue.toLowerCase()) ||
                            x.alpha3
                              ?.toLowerCase()
                              ?.includes(state.inputValue.toLowerCase()),
                        );
                      }}
                      isOptionEqualToValue={(
                        option: ApiCountryLookup,
                        value: Nullable<ApiCountryLookup>,
                      ) => option.code === value?.code}
                      getOptionLabel={(option: ApiCountryLookup) =>
                        `${option.name} (${option.code})` || ''
                      }
                      renderOption={(props: any, option: ApiCountryLookup) => (
                        <Box
                          key={option}
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <CountryFlag countryCode={option.code} />
                          {option.name} ({option.code})
                        </Box>
                      )}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <>
                          <TextField
                            {...params}
                            label={t('common.country')}
                            error={Boolean(
                              touched.countryCode &&
                              errors.countryCode,
                            )}
                            helperText={
                              touched.countryCode &&
                              t(typeof errors.countryCode === 'object' ? errors?.countryCode?.alpha3 : errors.countryCode)
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            InputProps={{
                              ...params.InputProps,
                              ...(values.countryCode?.code
                                ? {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <>
                                        <Box sx={{ ml: '8px' }}>
                                          <CountryFlag
                                            countryCode={
                                              values.countryCode?.code
                                            }
                                          />
                                        </Box>
                                      </>
                                    </InputAdornment>
                                  ),
                                }
                                : {}),
                            }}
                          />
                        </>
                      )}
                    ></Field>
                  </FormControl>
                )} */}
                <CountryCodeField
                  formControlProps={{
                    fullWidth: true,
                    margin: 'normal',
                    size: 'small'

                  }}
                  label="Country Code"
                  name="countryCode"
                  type="nationality"
                  selectProps={{
                    variant: 'outlined',
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={<Field name="requiresThreeD" component={Switch} />}
                  label="Requires Three D"
                  name="requiresThreeD"
                  checked={values.requiresThreeD}
                  onChange={(ev, checked) => {
                    setFieldValue('requiresThreeD', checked);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={<Field name="requiresAvs" component={Switch} />}
                  label="Requires AVS"
                  name="requiresAvs"
                  checked={values.requiresAvs}
                  onChange={(ev, checked) => {
                    setFieldValue('requiresAvs', checked);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={<Field name="allowNonCvv" component={Switch} />}
                  label="Allow Non CVV"
                  name="allowNonCvv"
                  checked={values.allowNonCvv}
                  onChange={(ev, checked) => {
                    setFieldValue('allowNonCvv', checked);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={
                    <Field name="returnUrlParamsEnabled" component={Switch} />
                  }
                  label="Return URL Params"
                  name="returnUrlParamsEnabled"
                  checked={values.returnUrlParamsEnabled}
                  onChange={(ev, checked) => {
                    setFieldValue('returnUrlParamsEnabled', checked);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={
                    <Field name="transactionExpiryEnabled" component={Switch} />
                  }
                  label="Transaction Expiry"
                  name="transactionExpiryEnabled"
                  checked={values.transactionExpiryEnabled}
                  onChange={(ev, checked) => {
                    setFieldValue('transactionExpiryEnabled', checked);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={<Field name="enabled" component={Switch} />}
                  label="Enabled"
                  name="enabled"
                  checked={values.enabled}
                  onChange={(ev, checked) => {
                    setFieldValue('enabled', checked);
                  }}
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
