
import { apiWithTag } from '../emptySplitApi';
import { ApiPaymentResponse, ApiRefundRequest } from '.';

export const paymentsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    refundPayment: builder.mutation<
      ApiPaymentResponse,
      ApiRefundRequest
    >({
      query: ({ accountHolderId, ...body }) => ({
        body,
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'POST',
        url: 'gateway/payments/refund',
      }),
    }),
  }),
});

export const { useRefundPaymentMutation } = paymentsApi;
