import { FilterAlt } from '@mui/icons-material';
import { colors, Container, Drawer, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { PendingList } from './PendingList';
import { PendingsFilterForm } from './PendingsFilterForm';
import { PendingFilterForm } from './types';

export const Pending: FC<{ accountHolderId?: string; isGlobal: boolean }> = ({
  accountHolderId,
  isGlobal,
}) => {
  const [filter, setFilter] = useState<PendingFilterForm>(null);
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    setFilterOpen(false);
  }, [filter]);

  useEffect(() => {
    setFilter({
      restrictionStatus: ['PENDING'],
      restrictionType: 'CURRENCY',
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Pending</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12} sx={{ mt: '30px' }}>
          {isGlobal && (<Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            RESTRICTIONS
          </Typography>)}
        </Grid>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ marginBottom: '25px' }}
          xs={12}
        >
          <Grid item justifySelf="left">
            <Typography color="textPrimary" variant="h5">
              Pending
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
            }}
          >
            <FilterAlt
              color="primary"
              fontSize="large"
              sx={{ marginLeft: '10px' }}
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            />
          </Grid>
        </Grid>
        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >
            <Grid item xs={12}>
              <PendingsFilterForm onFilterApply={setFilter} />
            </Grid>
          </Grid>
        </Drawer>

        <PermissionGuard permission="GET_RESTRICTION">
          {filter && (
            <PendingList
              accountHolderId={accountHolderId}
              filter={filter}
              isGlobal={isGlobal}
            />
          )}
        </PermissionGuard>
      </Container>
    </>
  );
};
