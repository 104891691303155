import { Chip, colors } from '@mui/material';

import { AddressAttachmentType, IdentityAttachmentType, KycStatus } from 'src/models/kycVerifications';
const statusColors = {
  INITIAL: colors.orange,
  PENDING: colors.orange,
  IN_REVIEW: colors.orange,
  VERIFIED: colors.green,
  FAILED: colors.red,
  DECLINED: colors.red,

  ID_CARD: colors.teal, 
  PASSPORT: colors.teal,
  DRIVING_LICENSE: colors.teal,
  UTILITY_BILL: colors.teal,
  BANK_STATEMENT: colors.teal,
  RENT_AGREEMENT: colors.teal,
  EMPLOYER_LETTER: colors.teal,
  TAX_BILL: colors.teal,
};


export const KycVerificationStatusLabel = ({
  status,
}: {
  status: KycStatus | IdentityAttachmentType | AddressAttachmentType;
}) => {
  // alert(status);
  const backgroundColor = statusColors[status] && `${statusColors[status][50]} !important`;
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
