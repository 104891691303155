import { FileDownload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { DateRangeForm } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { selectToken } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { config } from 'src/services/config';
import {
  transactionStatusList,
  transactionTypeList,
} from '../../../../models/transactions/constants';
import { removeEmpty } from '../../../../utils/removeEmpty';
import { ApiTransactionExportForm, mapTransactionExportFormToApiTransactionExportForm, TransactionExportForm, transactionExportFormFormInitialValues, transactionsExportFormValidationSchema } from '.';


interface FormProps {
  size?: "small" | "medium";
  accountHolderId: string;
  isGlobal: boolean;
}
export const TransactionsExportForm: FC<FormProps> = ({ accountHolderId, isGlobal, size }) => {
  const token = useSelector(selectToken);
  const [exportOpen, setExportOpen] = useState(false);



  const onTransactions = () => {
    setExportOpen(!exportOpen);
  };

  const triggerDownload = (params: TransactionExportForm) => {
    const request: ApiTransactionExportForm = mapTransactionExportFormToApiTransactionExportForm(params);
    const parameters = removeEmpty({
      ...request
    });
    const filename = `${new Date().toJSON().slice(0, 10).replaceAll('-', '_')}_${new Date().toJSON().slice(11, 19).replaceAll(':', '_')}.csv`;
    const accountHolderIdParam = !isGlobal ? `&accountHolderId=${accountHolderId}` : '';
    const queryString = new URLSearchParams(parameters).toString();
    fetch(`${config.apiUrl}/gateway/reports/transactions?${queryString}${accountHolderIdParam}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + token,
        'x-account-holder-id': accountHolderId
      }
    })
      .then(response => {
        return response.blob()
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
        setExportOpen(false);
      }).catch((error) => {
        setExportOpen(false);
      });
  }

  return (
    <PermissionGuard permission="GET_TRANSACTION_REPORT">
      <Tooltip title={`Export Transactions`}>
        <Button
          variant="contained" onClick={onTransactions}>
          <FileDownload />
        </Button>
      </Tooltip>

      <Drawer
        anchor="top"
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ padding: '20px' }}
          xs={12}
        >
          <Grid item xs={12}>
            <Formik<TransactionExportForm>
              initialStatus={{ error: '' }}
              initialValues={transactionExportFormFormInitialValues()}
              validationSchema={transactionsExportFormValidationSchema()}
              onSubmit={(values, { resetForm }) => {
                triggerDownload(values);
                resetForm();
              }}
            >
              {({
                handleSubmit,
                isSubmitting,
                status,
                resetForm,
                values,
                setFieldValue,
              }): JSX.Element => (
                <>
                  <form noValidate>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth size={size}>
                          <InputLabel id="select-transaction-type-label">
                            Transaction type
                          </InputLabel>
                          <Select
                            multiple
                            fullWidth
                            labelId="select-transaction-type-label"
                            label="Transaction type"
                            id="select-transaction-type"
                            value={values.transactionType}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            onChange={(e) => {
                              setFieldValue('transactionType', e.target.value);
                            }}
                          >
                            {transactionTypeList.map((trType) => (
                              <MenuItem key={trType} value={trType}>
                                {trType}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth size={size}>
                          <InputLabel id="select-transaction-status-label">
                            Transaction Status
                          </InputLabel>
                          <Select
                            multiple
                            fullWidth
                            labelId="select-transaction-status-label"
                            label="Transaction Status"
                            id="select-transaction-status"
                            value={values.transactionStatus}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            onChange={(e) => {
                              setFieldValue('transactionStatus', e.target.value);
                            }}
                          >
                            {transactionStatusList.map((trStatus) => (
                              <MenuItem key={trStatus} value={trStatus}>
                                {trStatus}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <FormikTextField
                            label="Customer Detail Id"
                            name="customerDetailId"
                            type="text"
                            size={size}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Customer Email"
                          name="customerEmail"
                          type="text"
                          size={size}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Customer First Name"
                          name="customerFirstName"
                          type="text"
                          size={size}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Customer Last Name"
                          name="customerLastName"
                          type="text"
                          size={size}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Reference Number"
                          name="referenceNo"
                          type="text"
                          size={size}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Banking Config Id"
                          name="bankingConfigId"
                          type="text"
                          size={size}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Client Id"
                          name="accountHolderId"
                          type="text"
                          size={size}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField label="Id" name="id" type="text" size={size} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Transaction Number"
                          name="transactionNumber"
                          type="text"
                          size={size}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField label="Source" name="source" type="text" size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Destination"
                          name="destination"
                          type="text"
                          size={size}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormikTextField
                          label="Acquiring config Id"
                          name="acquiringConfigId"
                          type="text"
                          size={size}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Field name="includeSubAccountHolders" component={Switch} />
                          }
                          label="Include Sub-Clients"
                          name="includeSubAccountHolders"
                          checked={values.includeSubAccountHolders}
                          onChange={(ev, checked) => {
                            setFieldValue('includeSubAccountHolders', checked);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <DateRangeForm size={size} />
                      </Grid>
                    </Grid>

                    {!isSubmitting && status.error && (
                      <Box sx={{ mt: 1 }}>
                        <FormHelperText error>{status.error}</FormHelperText>
                      </Box>
                    )}
                  </form>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'right',
                      padding: '24px',
                    }}
                  >
                    <Button
                      color="error"
                      onClick={() => {
                        resetForm();
                        setExportOpen(false);
                      }}
                      variant="contained"
                      sx={{ marginRight: '20px' }}
                    >
                      Clear X
                    </Button>
                    <LoadingButton
                      color="primary"
                      loading={isSubmitting}
                      onClick={() => handleSubmit()}
                      variant="contained"
                    >
                      Export
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Drawer>
    </PermissionGuard >
  );
};
