import { TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InviteUserFormValues } from './types';

export const InviteUserForm: FC = () => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange
  } = useFormikContext<InviteUserFormValues>();
  const { t } = useTranslation();
  return (
    <>
      <Field
        component={TextField}
        name="userEmail"
        fullWidth
        label={t('common.emailWillBeUsedForLogin')}
        value={values.userEmail}
        margin="normal"
        error={Boolean(touched?.userEmail && !!errors?.userEmail)}
        helperText={touched?.userEmail && t(errors?.userEmail as string)}
        onBlur={(e) => {
          setFieldValue('checkEmail', true);
          handleBlur(`userEmail`)(e);
        }}
        onChange={handleChange(`userEmail`)}
        variant="outlined"
      />
    </>
  );
};
