import { NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  colors,
  Container,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';

import { AccountHolderPermission } from 'src/models/accountHolder';
import { useAccountHolderQuery } from 'src/models/accountHolders';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import AccountDetails from '../AccountDetails';
import Accounts from '../Accounts';
import ApiKeys from '../ApiKeys';
import AssignedFees from '../AssignedFees';
import { Attributes } from '../Attributes';
import Beneficiaries from '../Beneficiaries';
import BeneficiaryDetails from '../BeneficiaryDetails';
import { ConfigurationRoutes } from '../Configuration';
import KycManagement from '../KycManagement';
import KycManagementDetails from '../KycManagementDetails';
import { RestrictionRoutes } from '../Restriction';
import { TelexRoutes } from '../Telex';
import TransactionDetails from '../TransactionDetails';
import Transactions from '../Transactions';
import UserDetails from '../UserDetails';
import Users from '../Users';
import AccountHolderView from './AccountHolderView';



export const AccountHolderTabs: FC<{ accountHolderId: string }> = ({
  accountHolderId,
}) => {
  const location = useLocation();
  const { hasPermission } = usePermissions();
  const { id } = useParams<{ id: string }>();

  const { data: accountHolder } = useAccountHolderQuery(
    {
      accountHolderIdForHeader: accountHolderId,
      accountHolderId: id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const breadcrumbs = [
    <Link
      key="1"
      color={blueGrey[600]}
      component={RouterLink}
      to={'/account-holders'}
    >
      <Typography variant="overline">USER MANAGEMENT</Typography>
    </Link>,
    <Link
      key="1"
      color={blueGrey[500]}
      component={RouterLink}
      to={'/account-holders'}
    >
      <Typography variant="overline">Clients</Typography>
    </Link>,
  ];

  const routes = [
    'details',
    'configuration',
    'transactions',
    'telex',
    'restrictions',
    'users',
    'api-keys',
    'accounts',
    'kyc-management',
    'attributes',
    'assigned-fees',
    'beneficiaries'
  ];
  const configuration_routes = ['banking', 'acquiring', 'telex', 'fees'];
  const restriction_routes = [
    'currency',
    'country',
    'card-bin',
    'account-currency',
    'pending',
  ];
  return (
    <>
      <Helmet>
        <title>Client</title>
      </Helmet>
      <Box>
        <Container maxWidth={false}>
          <PermissionGuard permission="GET_ACCOUNT_HOLDER">
            <Grid container spacing={3}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                item
                xs={12}
              >
                <Grid item>
                  <Breadcrumbs
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>
                  <Typography color="textPrimary" variant="h5">
                    {accountHolder?.displayName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={
                  location.pathname !== `/account-holders/${id}` &&
                    location.pathname !== `/account-holders/${id}/`
                    ? `${location.pathname.split('/')[3]}`
                    : routes[0]
                }
                aria-label="client"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  style={{ minWidth: 50 }}
                  label="Details"
                  value={`${routes[0]}`}
                  component={RouterLink}
                  to={routes[0]}
                />
                {[
                  'GET_BANKING_CONFIGURATION',
                  'GET_ACQUIRING_CONFIGURATION',
                  'GET_TELEX_CONFIGURATION',
                  'GET_FEE_CONFIGURATION',
                ].some((p: AccountHolderPermission) => hasPermission(p)) && (
                    <Tab
                      style={{ minWidth: 50 }}
                      label="Configurations"
                      value={`${routes[1]}`}
                      component={RouterLink}
                      to={routes[1]}
                    />
                  )}
                {hasPermission('GET_TRANSACTION') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Transactions"
                    value={`${routes[2]}`}
                    component={RouterLink}
                    to={routes[2]}
                  />
                )}
                {hasPermission('GET_TELEX') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Telexes"
                    value={`${routes[3]}`}
                    component={RouterLink}
                    to={routes[3]}
                  />
                )}
                {[
                  'GET_COUNTRY_RESTRICTION',
                  'GET_CURRENCY_RESTRICTION',
                  'GET_CARDBIN_RESTRICTION',
                  'GET_ACCOUNT_CURRENCY_RESTRICTION',
                  'GET_RESTRICTION',
                ].some((p: AccountHolderPermission) => hasPermission(p)) && (
                    <Tab
                      style={{ minWidth: 50 }}
                      label="Restrictions"
                      value={`${routes[4]}`}
                      component={RouterLink}
                      to={routes[4]}
                    />
                  )}

                {hasPermission('GET_USER') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Users"
                    value={`${routes[5]}`}
                    component={RouterLink}
                    to={routes[5]}
                  />
                )}
                {hasPermission('GET_API_KEY') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Api Keys"
                    value={`${routes[6]}`}
                    component={RouterLink}
                    to={routes[6]}
                  />
                )}
                {hasPermission('GET_ACCOUNT') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Accounts"
                    value={`${routes[7]}`}
                    component={RouterLink}
                    to={routes[7]}
                  />
                )}
                {hasPermission('GET_KYC_VERIFICATION') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="KYC Management"
                    value={`${routes[8]}`}
                    component={RouterLink}
                    to={routes[8]}
                  />
                )}
                {hasPermission('CREATE_ACCOUNT_HOLDER_CONFIGURATION') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Attributes"
                    value={`${routes[9]}`}
                    component={RouterLink}
                    to={routes[9]}
                  />
                )}
                {accountHolder?.parentId && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Assigned Fees"
                    value={`${routes[10]}`}
                    component={RouterLink}
                    to={routes[10]}
                  />
                )}
                {hasPermission('GET_BENEFICIARY') && (
                  <Tab
                    style={{ minWidth: 50 }}
                    label="Beneficiaries"
                    value={`${routes[11]}`}
                    component={RouterLink}
                    to={routes[11]}
                  />
                )}
              </Tabs>
            </Box>
            <Routes>
              <Route
                path="details"
                element={
                  <AccountHolderView
                    accountHolderId={id}
                    accountHolderIdForHeader={accountHolderId}
                  />
                }
              />
              <Route
                path="transactions"
                element={<Transactions accountHolderId={id} isGlobal={false} />}
              />

              <Route
                path="transactions/:id"
                element={<TransactionDetails accountHolderId={id} />}
              />
              <Route
                path="telex/*"
                element={<TelexRoutes accountHolderId={id} />}
              />
              <Route
                path="users"
                element={<Users accountHolderId={id} isGlobal={false} />}
              />
              <Route
                path="users/:id"
                element={<UserDetails accountHolderId={id} isGlobal={false} />}
              />

              <Route
                path="api-keys"
                element={<ApiKeys accountHolderId={id} isGlobal={false} />}
              />

              <Route
                path="accounts"
                element={<Accounts accountHolderId={id} isGlobal={false} />}
              />
              <Route
                path="accounts/:id"
                element={
                  <AccountDetails accountHolderId={id} isGlobal={false} />
                }
              />

              <Route
                path="beneficiaries"
                element={<Beneficiaries accountHolderId={id} isGlobal={false} />}
              />
              <Route
                path="beneficiaries/:id"
                element={
                  <BeneficiaryDetails accountHolderId={id} isGlobal={false} />
                }
              />

              <Route
                path="kyc-management"
                element={
                  <KycManagement accountHolderId={id} isGlobal={false} />
                }
              />
              <Route
                path="kyc-management/:id"
                element={
                  <KycManagementDetails accountHolderId={id} isGlobal={false} />
                }
              />
              <Route
                path="attributes"
                element={
                  <Attributes
                    accountHolderId={id}
                  // isGlobal={false}
                  />
                }
              />
              <Route
                path="assigned-fees"
                element={
                  <AssignedFees
                    parentAccountHolderId={accountHolder?.parentId}
                    accountHolderId={id}
                  />
                }
              />
              {[
                'GET_BANKING_CONFIGURATION',
                'GET_ACQUIRING_CONFIGURATION',
                'GET_TELEX_CONFIGURATION',
                'GET_FEE_CONFIGURATION',
              ].some((p: AccountHolderPermission) => hasPermission(p)) && (
                  <Route
                    path="configuration/*"
                    element={
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Grid
                            alignItems="center"
                            container
                            item
                            justifyContent="space-between"
                            sx={{ marginBottom: '5px', marginTop: '20px' }}
                            xs={12}
                          >
                            <Grid item>
                              <Typography
                                sx={{ color: colors.blueGrey[600] }}
                                variant="overline"
                              >
                                CONFIGURATIONS
                              </Typography>
                            </Grid>
                          </Grid>
                          <Tabs
                            value={
                              location.pathname.split('/')?.[3] ==
                              'configuration' &&
                              `${location.pathname.split('/')?.[4]?.length > 0
                                ? location.pathname.split('/')[4]
                                : configuration_routes[0]
                              }`
                            }
                            aria-label="client"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            {hasPermission('GET_BANKING_CONFIGURATION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Banking"
                                value={`${configuration_routes[0]}`}
                                component={RouterLink}
                                to={configuration_routes[0]}
                              />
                            )}
                            {hasPermission('GET_ACQUIRING_CONFIGURATION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Acquiring"
                                value={`${configuration_routes[1]}`}
                                component={RouterLink}
                                to={configuration_routes[1]}
                              />
                            )}
                            {hasPermission('GET_TELEX_CONFIGURATION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Telex"
                                value={`${configuration_routes[2]}`}
                                component={RouterLink}
                                to={configuration_routes[2]}
                              />
                            )}
                            {hasPermission('GET_FEE_CONFIGURATION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Fees"
                                value={`${configuration_routes[3]}`}
                                component={RouterLink}
                                to={configuration_routes[3]}
                              />
                            )}
                          </Tabs>
                        </Box>
                        <ConfigurationRoutes
                          accountHolderId={id}
                          isGlobal={false}
                        />
                      </>
                    }
                  />
                )}

              {[
                'GET_COUNTRY_RESTRICTION',
                'GET_CURRENCY_RESTRICTION',
                'GET_CARDBIN_RESTRICTION',
                'GET_ACCOUNT_CURRENCY_RESTRICTION',
                'GET_RESTRICTION',
              ].some((p: AccountHolderPermission) => hasPermission(p)) && (
                  <Route
                    path="restrictions/*"
                    element={
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Grid
                            alignItems="center"
                            container
                            item
                            justifyContent="space-between"
                            sx={{ marginBottom: '5px', marginTop: '20px' }}
                            xs={12}
                          >
                            <Grid item>
                              <Typography
                                sx={{ color: colors.blueGrey[600] }}
                                variant="overline"
                              >
                                RESTRICTIONS
                              </Typography>
                            </Grid>
                          </Grid>

                          <Tabs
                            value={
                              location.pathname.split('/')?.[3] == 'restrictions'
                                ? `${location.pathname.split('/')?.[4]?.length > 0
                                  ? location.pathname.split('/')[4]
                                  : restriction_routes[0]
                                }`
                                : ''
                            }
                            aria-label="client"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            {hasPermission('GET_CURRENCY_RESTRICTION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Currency"
                                value={`${restriction_routes[0]}`}
                                component={RouterLink}
                                to={restriction_routes[0]}
                              />
                            )}
                            {hasPermission('GET_COUNTRY_RESTRICTION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Country"
                                value={`${restriction_routes[1]}`}
                                component={RouterLink}
                                to={restriction_routes[1]}
                              />
                            )}
                            {hasPermission('GET_CARDBIN_RESTRICTION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Card Bin"
                                value={`${restriction_routes[2]}`}
                                component={RouterLink}
                                to={restriction_routes[2]}
                              />
                            )}
                            {hasPermission(
                              'GET_ACCOUNT_CURRENCY_RESTRICTION',
                            ) && (
                                <Tab
                                  style={{ minWidth: 50 }}
                                  label="Account Currency"
                                  value={`${restriction_routes[3]}`}
                                  component={RouterLink}
                                  to={restriction_routes[3]}
                                />
                              )}
                            {hasPermission('GET_RESTRICTION') && (
                              <Tab
                                style={{ minWidth: 50 }}
                                label="Pending"
                                value={`${restriction_routes[4]}`}
                                component={RouterLink}
                                to={restriction_routes[4]}
                              />
                            )}
                          </Tabs>
                        </Box>
                        <RestrictionRoutes
                          accountHolderId={id}
                          isGlobal={false}
                        />
                      </>
                    }
                  />
                )}

              <Route path="*" element={<Navigate to="details" replace />} />
            </Routes>
          </PermissionGuard>
        </Container>
      </Box>
    </>
  );
};
