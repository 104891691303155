

export const transferChannelTemplateParameterTypeList = [
  'STRING',
  'TEXT_AREA',
  'DROP_DOWN',
  'CHECKBOX',
  'RADIO',
  'DATE',
  'DATE_TIME',
  'CUSTOM',
  'STATIC'
  // 'TIME'
] as const;



