import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC } from 'react';

import { FormikTextField } from 'src/components/forms/TextField';
import {
  accountHolderStatusList,
  accountHolderTypeList,
} from '../../../models/accountHolders/constants';
import { AccountHolderFilterForm } from '../forms/AccountHolderFilterForm';

const initialValues: AccountHolderFilterForm = {
  type: [],
  status: [],
  descriptor: '',
  id: '',
  ownerEmail: '',
  includeSubAccountHolders: false,
  parentsOnly: false,
};

interface FormProps {
  onFilterApply: (values: AccountHolderFilterForm) => void;
}
export const SubAccountHoldersFilterForm: FC<FormProps> = ({
  onFilterApply,
}) => {
  const onSubmit = (values: AccountHolderFilterForm) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size='small' >
                  <InputLabel id="select-accountHolder-type-label">
                    Client Type
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    labelId="select-accountHolder-type-label"
                    label="Client Type"
                    id="select-accountHolder-type"
                    value={values.type}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('type', e.target.value);
                    }}
                  >
                    {accountHolderTypeList.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size='small' >
                  <InputLabel id="select-status-label">
                    Client Status
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    labelId="select-status-label"
                    label="Client Status"
                    id="select-accountHolder-status"
                    value={values.status}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('status', e.target.value);
                    }}
                  >
                    {accountHolderStatusList.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Descriptor"
                  name="descriptor"
                  type="text"
                  size='small'
                />
              </Grid>
              {/* <Grid item xs={2}>
                <FormikTextField
                  label="Id"
                  name="id"
                  type="text"
                  disabled
                />
              </Grid> */}

              <Grid item xs={12} sm={6} md={4} sx={{ marginTop: '7px' }}>
                <FormikTextField
                  label="Owner Email"
                  name="ownerEmail"
                  type="text"
                  size='small'
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
