import { ContentCopy, FilterAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  colors,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useCopyText } from 'src/hooks/useCopyText';
import { ApiKeyResponse } from 'src/models/apiKeys';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { ApiKeyList } from './ApiKeyList';
import { ApiKeysFilterForm } from './ApiKeysFilterForm';
import { ApiKeyForm } from './forms/ApiKeyForm';
import { ApiKeyFilterForm } from './types';

const ApiKeysScreen: FC<{ accountHolderId: string; isGlobal: boolean }> = ({
  accountHolderId,
  isGlobal,
}) => {
  const copyText = useCopyText();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ApiKeyFilterForm>();
  const [filterOpen, setFilterOpen] = useState(false);
  const [addApiKeyOpened, setAddApiKeyOpened] = useState<boolean>(false);
  const [newApiKey, setNewApiKey] = useState<ApiKeyResponse | null>(null);
  const isUserActive = useSelector(isAccountHolderStatusActive);

  useEffect(() => {
    setFilterOpen(false);
  }, [filter]);

  useEffect(() => {
    setFilter({
      description: '',
      status: ['ACTIVE'],
      id: '',
      accountHolderId: !isGlobal ? accountHolderId : '',
      includeSubAccountHolders: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Api Keys</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12}>
          <Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            USER MANAGEMENT
          </Typography>
        </Grid>
        <Box
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }),
          ]}
        >
          <Typography color="textPrimary" variant="h5">
            Api Keys
          </Typography>

          <Box
            sx={[
              {
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            <PermissionGuard permission="CREATE_API_KEY">
              <Button
                disabled={!isUserActive}
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setAddApiKeyOpened(true)}
              >
                Create Api Key
              </Button>
            </PermissionGuard>

            <Box
              sx={[
                { display: 'flex', justifyContent: 'flex-end' },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    width: 'auto',
                  },
                }),
              ]}
            >
              <FilterAlt
                color="primary"
                fontSize="large"
                sx={{ marginLeft: '10px' }}
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              />
            </Box>
          </Box>
        </Box>

        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >
            <Grid item xs={12}>
              <ApiKeysFilterForm onFilterApply={setFilter} />
            </Grid>
          </Grid>
        </Drawer>
        <PermissionGuard permission="CREATE_API_KEY">
          <Drawer
            anchor="right"
            open={addApiKeyOpened}
            onClose={() => setAddApiKeyOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: '#F3F7FE',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            <ApiKeyForm
              isGlobal={isGlobal}
              accountHolderId={accountHolderId}
              onClose={(newApiKey) => {
                setNewApiKey(newApiKey);
                setAddApiKeyOpened(false);
              }}
            />
          </Drawer>

          {newApiKey && (
            <Dialog
              open={newApiKey != null}
              fullWidth={true}
              maxWidth={'md'}
              aria-labelledby="transaction-dialog-title"
            >
              <DialogTitle id="transaction-dialog-title">
                New Api Key {newApiKey.id}{' '}
                <IconButton size="small" onClick={() => copyText(newApiKey.id)}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <p>
                  Password: {newApiKey.password}{' '}
                  <IconButton
                    size="small"
                    onClick={() => copyText(newApiKey.password)}
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </p>
                <p>
                  Encoded Key: {newApiKey.encodedKey}{' '}
                  <IconButton
                    size="small"
                    onClick={() => copyText(newApiKey.encodedKey)}
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </p>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => setNewApiKey(null)}
                  color="primary"
                >
                  {t('common.close')}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </PermissionGuard>
        <PermissionGuard permission="GET_API_KEY">
          <ApiKeyList accountHolderId={accountHolderId} filter={filter} />
        </PermissionGuard>
      </Container>
    </>
  );
};

export default ApiKeysScreen;
