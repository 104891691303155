import { Chip, colors } from '@mui/material';
import React from 'react';

import { TransferChannelTemplateParameterType } from 'src/models/transferChannelTemplates';

const statusColors = {
  STRING: colors.blueGrey,
  TEXT_AREA: colors.blueGrey,
  DROP_DOWN: colors.blueGrey,
  CHECKBOX: colors.blueGrey,
  RADIO: colors.blueGrey,
  DATE: colors.blueGrey,
  DATE_TIME: colors.blueGrey,
  CUSTOM: colors.blueGrey,
  STATIC: colors.blueGrey,
};

export const TransferChannelParameterTypeLabel = ({
  status,
}: {
  status: TransferChannelTemplateParameterType;
}) => {
  const backgroundColor =
    statusColors[status] && `${statusColors[status][50]} !important`;
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
