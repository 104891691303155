import { useCallback, useEffect, useState } from 'react';

type Cb = () => void;

export const useBackGroundShadowLogo = (): string => {
    const [bgLogo, setBgLogo] = useState<string>('/static/bg-logo.svg?v=4');

    useEffect(() => {
        if (window.location.hostname == 'dev-secure.hipercapitalfinance.com' || window.location.hostname == 'secure.hipercapitalfinance.com') {
            setBgLogo(
                'https://storage.googleapis.com/dev-content-management-service/81a2d3d1-7e39-480d-8554-47d4ba3d79fe/bg-logo.svg',
            );
        }
        if (window.location.hostname == 'dev-my.sekurebank.vu' || window.location.hostname == 'my.sekurebank.vu') {
            setBgLogo(
                'https://storage.googleapis.com/dev-content-management-service/manifest/sekurebank/bg-logo.svg',
            );
        }
        if (window.location.hostname == 'dev-secure.onlinestatedigitalbank.com' || window.location.hostname == 'secure.onlinestatedigitalbank.com') {
            setBgLogo(
                'https://storage.googleapis.com/dev-content-management-service/manifest/onlinestatedigitalbank/bg-logo.svg?v=1',
            );
        }
        if (window.location.hostname == 'dev-secure.swissalpinetrust.is' || window.location.hostname == 'secure.swissalpinetrust.is') {
            setBgLogo(
                'https://storage.googleapis.com/dev-content-management-service/manifest/swissalpinetrust/bg-logo.svg',
            );
        }
    }, []);
    return bgLogo;
};
