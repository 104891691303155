import { Theme } from '@emotion/react';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect } from 'react';

import { useCountdownTimer } from 'src/hooks/useCountDownTimer';


const useStyles = makeStyles((theme:Theme) => ({
      showCounter : {
        // border: '1px solid red',
        // padding: '0.5rem',
        transform: 'scale(0.7)',
        '& .countdown-link': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '600',
            // fontSize: '1.25rem',
            lineHeight: '1.75rem',
            // padding: '0.5rem',
            // border: '1px solid #ebebeb',
            // borderRadius: '0.25rem',
            textDecoration: 'none',
            // color: '#000'
            color: '#7B8B9A',
          },


        '& .countdown': {
            // lineHeight: '1.25rem',
            padding: '0 0.75rem 0 0.75rem',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          },
        '& .countdown.danger' : {
            color: '#ff0000'
          },
        '& .countdown > p' : {
            margin: '0'
          },
        '& .countdown > span' : {
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            lineHeight: '1rem'
        }
    }
}))



const DateTimeDisplay = ({ value, type, isDanger }) => {

    return (
      <div className={isDanger ? 'countdown danger' : 'countdown'}>
        <p>{value}</p>
        <span>{type}</span>
      </div>
    );
  };

export const ShowCounter:FC<{
   days:number; 
   hours:number; 
   minutes:number; 
   seconds:number;
   show:{
     days:boolean;
     hours:boolean;
     minutes:boolean;
     seconds:boolean;
   }}> = ({ show, days, hours, minutes, seconds  }) => {

    const classes = useStyles();
    return (
      <div className={classes.showCounter}>
          <div className="countdown-link">
          {show.days && <DateTimeDisplay value={days} type={'Days'} isDanger={false} />}
          {show.days && show.hours && <p>:</p>}
          {show.hours && <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />}
          {show.hours && show.minutes && <p>:</p> }
          {show.minutes && <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />}
          {show.minutes && show.seconds && <p>:</p>}
          {show.seconds && <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={days==0 && hours==0 && minutes==0 && seconds <= 10 } />}
          </div>
      </div>
    );
  };

  export const CountdownTimer:FC<{
            targetDate:Date;
             onExpiry:(() => void);
             show:{
               days:boolean;
               hours:boolean;
               minutes:boolean;
               seconds:boolean;
             }}> = ({ targetDate, onExpiry, show }) => {
    const [days, hours, minutes, seconds] = useCountdownTimer(targetDate);

    useEffect(()=>{
      // alert(targetDate)
      if (days + hours + minutes + seconds <= 0) {
        // alert('haq');
        onExpiry();
      }
    },[days, hours, minutes, seconds]);

      return (
        <ShowCounter
          show={show}
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
  };