import { Card } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import { ApiInvitation, useInvitationsQuery } from 'src/models/invitations';
import { InvitationsTable } from './InvitationsTable';
import { InvitationFilterForm } from './types';
export const InvitationList = ({
  accountHolderId,
  filter,
}: {
  accountHolderId?:string;
  filter: InvitationFilterForm;
}) => {


  const [invitations, setInvitations] = useState<ApiInvitation[]>([]);


  
  const { data } = useInvitationsQuery({
    ...filter,
    accountHolderId,
  },
  {
    refetchOnMountOrArgChange:true,
  }
  );

  useEffect(() => {
    setInvitations(data || []);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <InvitationsTable
          accountHolderId={accountHolderId}
          invitations={invitations}
        />
      </Scrollbar>
    </Card>
  );
};
