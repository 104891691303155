import { TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountHolderTypeField } from 'src/components/forms';
import { ClientInfo } from './types';

export const ClientForm: FC = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<ClientInfo>();
  const { t } = useTranslation();
  return (
    <>
      <Field name={`accountHolderType`} component={AccountHolderTypeField} />

      {values['accountHolderType'] == 'BUSINESS' && (
        <Field
          component={TextField}
          name={`descriptor`}
          fullWidth
          label={t('common.descriptor')}
          value={values.descriptor}
          margin="normal"
          error={Boolean(touched?.descriptor && !!errors?.descriptor)}
          helperText={touched?.descriptor && t(errors?.descriptor as string)}
          onBlur={handleBlur(`descriptor`)}
          onChange={handleChange(`descriptor`)}
          variant="outlined"
        />
      )}
    </>
  );
};
