import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiFeeConfig,
  useDeleteFeeConfigurationMutation,
} from 'src/models/configurations';
import { SortOrder } from 'src/models/types';
import { FeeStatusLabel } from './FeeStatusLabel';
import { FeeForm } from './forms/FeeForm';
interface FeesTable {
  fees: ApiFeeConfig[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
  isGlobal: boolean;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  //   { title: 'Id', id: 'id', doSort: true },
  { title: 'Description', id: 'description', doSort: true },
  { title: 'Client', id: 'accountHolder', doSort: true },
  { title: 'Percentage', id: 'percentage', doSort: true },
  { title: 'Transaction Type', id: 'transactionType', doSort: true },
  { title: 'Fee Contributor', id: 'feeContributor', doSort: true },
  { title: 'Fee Transaction Context', id: 'transactionContext', doSort: true },
  // { title: 'Fee Account Id', id: 'feeAccountId', doSort: true },
  { title: 'Fee Account No', id: 'feeAccountNumber', doSort: true },
  {
    title: 'Contributor Account No',
    id: 'contributorAccountNumber',
    doSort: true,
  },

  { title: 'Enabled', id: 'enabled', doSort: true },
  { title: 'Assignable', id: 'assignable', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const FeesTable: FC<FeesTable> = ({
  fees,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId,
  isGlobal,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };
  const { hasPermission } = usePermissions();
  const [editFeeOpened, setEditFeeOpened] = useState<ApiFeeConfig | null>(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteFee] = useDeleteFeeConfigurationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            feeConfigId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              await deleteFee({
                feeConfigId: values.feeConfigId,
                accountHolderIdForHeader: accountHolderId,
              }).unwrap();
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* Action is update and delete only so we remove Actions if update and delete is not possible */}
                  {headCells
                    .filter(
                      (x) =>
                        x.title != 'Actions' ||
                        hasPermission('UPDATE_FEE_CONFIGURATION') ||
                        hasPermission('DELETE_FEE_CONFIGURATION'),
                    )
                    .map(({ title, id, doSort }) => (
                      <TableCell
                        key={id}
                        sortDirection={
                          sortEnabled && doSort && orderBy === id
                            ? sortDirection
                            : false
                        }
                      >
                        {sortEnabled && doSort ? (
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? sortDirection : 'asc'}
                            onClick={createSortHandler(id)}
                          >
                            {title}
                          </TableSortLabel>
                        ) : (
                          title
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fees.map((fee) => {
                  return (
                    <TableRow
                      hover
                      key={fee.id}
                    // onClick={() => navigate(`${fee.id}`)}
                    >
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {format(
                            new Date(fee.createdDateTime),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {fee.id}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {fee.description}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {fee.accountHolderId}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {fee.percentage}%
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <FeeStatusLabel status={fee.transactionType} />
                      </TableCell>

                      <TableCell>
                        <FeeStatusLabel status={fee.feeContributor} />
                      </TableCell>
                      <TableCell>
                        {fee.transactionContext && (
                          <FeeStatusLabel status={fee.transactionContext} />
                        )}
                      </TableCell>
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {fee.feeAccountId}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {fee.feeAccountNumber}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {fee.contributorAccountNumber}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={fee.enabled ? 'Yes' : 'No'}
                          color={fee.enabled ? 'success' : 'error'}
                        />
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={fee.assignable ? 'Yes' : 'No'}
                          color={fee.assignable ? 'success' : 'error'}
                        />
                      </TableCell>

                      {(hasPermission('UPDATE_FEE_CONFIGURATION') ||
                        hasPermission('DELETE_FEE_CONFIGURATION')) && (
                          <TableCell align="right">
                            <Box sx={{ display: 'flex' }}>
                              <PermissionGuard permission="UPDATE_FEE_CONFIGURATION">
                                <IconButton
                                  disabled={!isUserActive}
                                  onClick={() => setEditFeeOpened(fee)}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </PermissionGuard>
                              <PermissionGuard permission="DELETE_FEE_CONFIGURATION">
                                <IconButton
                                  disabled={!isUserActive}
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        'Are you sure to remove this fee configuration?',
                                      subTitle: 'This operation cannot be undone',
                                      onConfirm: () => {
                                        setFieldValue('feeConfigId', fee.id);
                                        formikRef?.current?.submitForm();
                                      },
                                    });
                                  }}
                                >
                                  <Delete fontSize="small" />
                                </IconButton>
                              </PermissionGuard>
                            </Box>
                          </TableCell>
                        )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>

      <PermissionGuard permission="UPDATE_FEE_CONFIGURATION">
        <Drawer
          anchor="right"
          open={editFeeOpened != null}
          onClose={() => setEditFeeOpened(null)}
          PaperProps={{
            sx: [
              {
                backgroundColor: '#F3F7FE',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: `100%`,
                },
                [theme.breakpoints.up('md')]: {
                  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                },
              }),
            ],
          }}
        >
          <FeeForm
            fee={editFeeOpened}
            accountHolderId={accountHolderId}
            isGlobal={isGlobal}
            onClose={() => setEditFeeOpened(null)}
          />
        </Drawer>
      </PermissionGuard>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
