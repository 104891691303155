import { Close, HelpCenter } from '@mui/icons-material';
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { LogoText } from 'src/components/logo/LogoText';
import { selectBank, selectBankLogo } from 'src/models/cms';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const Signature: FC = () => {
    const bank = useSelector(selectBank);

    return (
        <>
            {bank?.displayName && (
                <Box
                    fontSize="small"
                    color="primary"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        zIndex: '999',
                        position: 'fixed',
                        bottom: '0px',
                        backgroundColor: 'white'
                    }}
                >
                    Online Banking Platform run and operated by {bank?.displayName}

                </Box>)}
        </>
    );
};
