import { TransactionFilterForm } from "../TransactionsFilterForm"


export const transactionsFilterFormValuesInitialValue = (filter?: TransactionFilterForm): TransactionFilterForm => {
    return {
        id: '',
        transactionStatus: [],
        acquiringConfigId: '',
        customerDetailId: '',
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        referenceNo: '',
        transactionType: [],
        bankingConfigId: '',
        accountHolderId: '',
        includeSubAccountHolders: false,
        createdDateTime: '',
        transactionNumber: '',
        source: '',
        destination: '',
        startDateTime: null,
        endDateTime: null,
        ...filter
    }
}