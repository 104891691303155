import { Delete } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiCountry,
  useDeleteCountryRestrictionMutation,
  useDeleteCountryRestrictionRequestMutation,
} from 'src/models/restrictions';
interface CountriesTable {
  countries: ApiCountry[];
  accountHolderId: string;
}

const headCells = [
  //   { title: 'Id', id: 'id', doSort: true },
  { title: 'Country Code', id: 'countryCode', doSort: true },
  { title: 'Display Name Override', id: 'displayNameOverride', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const CountriesTable: FC<CountriesTable> = ({
  countries,
  accountHolderId,
}) => {
  const { hasPermission } = usePermissions();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });

  const [deleteCountryRestriction] = useDeleteCountryRestrictionMutation();
  const [deleteCountryRestrictionRequest] =
    useDeleteCountryRestrictionRequestMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            countryId: null,
            permission: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              if (values.permission == 'DELETE_COUNTRY_RESTRICTION') {
                await deleteCountryRestriction({
                  countryId: values.countryId,
                  accountHolderIdForHeader: accountHolderId,
                }).unwrap();
              }
              if (values.permission == 'REQUEST_COUNTRY_RESTRICTION') {
                await deleteCountryRestrictionRequest({
                  countryId: values.countryId,
                  accountHolderIdForHeader: accountHolderId,
                }).unwrap();
              }
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id }) => (
                    <TableCell key={id}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {countries.map((country) => {
                  return (
                    <TableRow
                      hover
                      key={country.id}
                    // onClick={() => navigate(`${country.id}`)}
                    >
                      {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {country.id}
                  </Typography>
                </TableCell> */}

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {country.countryCode}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {country.displayNameOverride}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <PermissionGuard permission="DELETE_COUNTRY_RESTRICTION">
                          <IconButton
                            disabled={!isUserActive}
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title:
                                  'Are you sure to remove this country restriction?',
                                subTitle: 'This operation cannot be undone',
                                onConfirm: () => {
                                  setFieldValue('countryId', country.id);
                                  setFieldValue(
                                    'permission',
                                    'DELETE_COUNTRY_RESTRICTION',
                                  );
                                  formikRef?.current?.submitForm();
                                },
                              });
                            }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </PermissionGuard>
                        {!hasPermission('ADMIN') && (
                          <PermissionGuard permission="REQUEST_COUNTRY_RESTRICTION">
                            <IconButton
                              disabled={!isUserActive}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    'Are you sure to remove this country restriction request?',
                                  subTitle: 'This operation cannot be undone',
                                  onConfirm: () => {
                                    setFieldValue('countryId', country.id);
                                    setFieldValue(
                                      'permission',
                                      'REQUEST_COUNTRY_RESTRICTION',
                                    );
                                    formikRef?.current?.submitForm();
                                  },
                                });
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </PermissionGuard>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
