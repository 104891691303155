export const accountHolderStatusList = [
  'PENDING',
  'ACTIVE',
  'SUSPENDED',
  'PENDING_KYC',
  'KYC_REQUIRED',
  'KYC_COMPLETED'
] as const;

export const accountHolderTypeList = [
  'INDIVIDUAL',
  'BUSINESS',
  'INTERNAL',
  'EXTERNAL'
] as const;

