import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField, ApiKeyTypeField, PermissionGroupsField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiKeyRequest,
  ApiKeyResponse,
  mapCreateApiKeyFormValuesToApiKeyRequest,
  useAddApiKeyMutation,
} from 'src/models/apiKeys';
import { CreateApiKeyFormValues } from './types-dto';
import { createApiKeyFormValuesInitialValue } from './utils';
import { apiKeyFormValidationSchema } from './validations';
interface FormProps {
  isGlobal: boolean;
  accountHolderId: string;
  onClose?: (value: ApiKeyResponse | null) => void;
}
export const ApiKeyForm: FC<FormProps> = ({
  onClose,
  accountHolderId,
  isGlobal,
}) => {

  const [addApiKey] = useAddApiKeyMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateApiKeyFormValues>
      innerRef={formikRef}
      initialValues={{ ...createApiKeyFormValuesInitialValue(!isGlobal ? accountHolderId : '') }}
      validationSchema={apiKeyFormValidationSchema()}
      onSubmit={async (
        values,
      ): Promise<void> => {
        try {
          const apiKeyRequest: ApiKeyRequest = await mapCreateApiKeyFormValuesToApiKeyRequest(values);
          const addApiKeyResponse: ApiKeyResponse = await addApiKey(
            apiKeyRequest,
          ).unwrap();

          if (addApiKeyResponse.id) {
            toast.success(`Api Key created successfully.`);
            onClose(addApiKeyResponse);
          }
        } catch (err) {
          // toast.error('An error has occured');
          //  console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose(null)}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Add Api Key
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal}
                accountHolderId={accountHolderId} />

              <FormikTextField
                name="description"
                label="Description"
                autoComplete="off"
                margin='normal'
              />

              <Field name={`apiKeyType`}
                component={ApiKeyTypeField} />

              <Field
                name={"permissionGroupIds"}
                component={PermissionGroupsField}
                accountHolderId={accountHolderId}
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
