import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';

import { accountTypes } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import {
  accountStatusList,
} from '../../models/accounts/constants';
import { BeneficiaryFilterForm } from './types';

const initialValues: BeneficiaryFilterForm = {
  firstName: '',
  lastName: '',
  companyName: '',
  accountNumber: '',
  iban: '',
  enabled: false,
  id: '',
};

interface FormProps {
  onFilterApply: (values: BeneficiaryFilterForm) => void;
}
export const BeneficiariesFilterForm: FC<FormProps> = ({ onFilterApply }) => {
  const onSubmit = (values: BeneficiaryFilterForm) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Id"
                  name="id"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="First Name"
                  name="firstName"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Last Name"
                  name="lastName"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Company Name"
                  name="companyName"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Account Number"
                  name="accountNumber"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="IBAN Number"
                  name="iban"
                  type="text"
                />
              </Grid>



              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={
                    <Field name="enabled" component={Switch} />
                  }
                  label="Enabled"
                  name="enabled"
                  checked={values.enabled}
                  onChange={(ev, checked) => {
                    setFieldValue('enabled', checked);
                  }}
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
