import { Chip, colors } from '@mui/material';

import { RestrictionAction, RestrictionStatus, RestrictionType } from 'src/models/restrictions';



const statusColors = {
    CREATE: colors.blueGrey,
    DELETE: colors.blueGrey,
    PENDING: colors.blueGrey,
    COMPLETED: colors.blueGrey,
    CANCELED: colors.blueGrey,
    COUNTRY: colors.blueGrey,
    CURRENCY: colors.blueGrey,
    CARD_BIN: colors.blueGrey,
    ACCOUNT_CURRENCY: colors.blueGrey,
    ACTIVE: colors.blueGrey,
};


export const RestrictionStatusLabel = ({
  status,
}: {
  status: RestrictionStatus | RestrictionType | RestrictionAction | 'ACTIVE';
}) => {
  const backgroundColor = statusColors[status] && `${statusColors[status][50]} !important`;
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
