import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    FormControl,
    TextField,
} from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiAccountHolderResponse, useAccountHoldersListQuery } from 'src/models/accountHolders';
import { AccountHolderStatusLabel } from 'src/screens/AccountHolders/AccountHolderStatusLabel';
import { Nullable } from 'src/utils/types';

export interface AccountHolderIdFieldProps {
    disabled: boolean;
    accountHolderId?: string;
    label?: string;
    onChange: (value: string) => void;
}
export const AccountHolderIdField: FC<
    AccountHolderIdFieldProps & FieldProps<ApiAccountHolderResponse[], ApiAccountHolderResponse[]>
> = ({
    disabled = false,
    accountHolderId,
    label = 'common.client',
    onChange = () => null,
    field,
    form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
    ...props
}) => {
        const { t } = useTranslation();

        const accountHolderIds = useAccountHoldersListQuery(
            { accountHolderId },
            { refetchOnMountOrArgChange: true },
        );

        return (
            <>
                {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                {accountHolderIds && accountHolderIds?.data && accountHolderIds?.data?.length > 0 && (
                    <FormControl fullWidth margin="normal">
                        <Field
                            component={Autocomplete}
                            name={`${field.name}`}
                            fullWidth
                            value={accountHolderIds.data.find(x => x.id === getIn(values, field.name)) || null}
                            onBlur={handleBlur(`${field.name}`)}
                            onChange={(e: any, value: ApiAccountHolderResponse) => {
                                setFieldValue(
                                    `${field.name}`,
                                    value !== null ? value.id : '',
                                );
                                onChange(value !== null ? value.id : '')
                            }}
                            disabled={disabled || accountHolderIds?.data?.length === 1}
                            options={accountHolderIds.data}
                            autoHighlight
                            variant="outlined"
                            filterOptions={(
                                options: Array<ApiAccountHolderResponse>,
                                state: { inputValue: string },
                            ): Array<ApiAccountHolderResponse> => {
                                return options.filter(
                                    (x) =>
                                        x.displayName
                                            ?.toLowerCase()
                                            ?.includes(state.inputValue.toLowerCase()) ||
                                        x.id
                                            ?.toLowerCase()
                                            ?.includes(state.inputValue.toLowerCase()) ||
                                        x.ownerEmail
                                            ?.toLowerCase()
                                            ?.includes(state.inputValue.toLowerCase()),
                                );
                            }}
                            isOptionEqualToValue={(
                                option: ApiAccountHolderResponse,
                                value: Nullable<ApiAccountHolderResponse>,
                            ) => option.id === value?.id}
                            getOptionLabel={(option: ApiAccountHolderResponse) =>
                                `${option?.displayName} (${option?.id})`
                            }
                            renderOption={(props: any, option: ApiAccountHolderResponse) => (
                                <Box
                                    key={option}
                                    component="li"
                                    {...props}
                                >
                                    {option.displayName} ({option.id}) &nbsp;&nbsp;
                                    <AccountHolderStatusLabel status={option.status} />
                                </Box>
                            )}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <>
                                    <TextField
                                        {...params}
                                        label={t(label)}
                                        error={Boolean(
                                            getIn(touched, field.name) &&
                                            getIn(errors, field.name),
                                        )}
                                        helperText={
                                            getIn(touched, field.name) &&
                                            t(getIn(errors, field.name))
                                        }
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            // ...(values[field.name]?.id
                                            //     ? {
                                            //         startAdornment: (
                                            //             <InputAdornment position="end">
                                            //                 <>
                                            //                     <Box sx={{ ml: '8px' }}>
                                            //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                                            //                         <AccountHolderStatusLabel status={values[field.name]?.status} />
                                            //                     </Box>
                                            //                 </>
                                            //             </InputAdornment>
                                            //         ),
                                            //     }
                                            //     : {}),
                                        }}
                                    />
                                </>
                            )}
                        ></Field>
                    </FormControl>
                )
                }
            </>
        );
    };
