import { Card } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import { ApiCurrency, useCurrencyRestrictionsListQuery } from 'src/models/restrictions';
import { CurrenciesTable } from './CurrenciesTable';


export const CurrencyList = ({accountHolderId}: {accountHolderId?:string;}) => {

const { data } = useCurrencyRestrictionsListQuery({
        accountHolderIdForHeader:accountHolderId,
      },
      {
        refetchOnMountOrArgChange:true,
      }
      );

  const [currencies, setCurrencies] = useState<ApiCurrency[]>([]);

  useEffect(() => {
    setCurrencies(data || []);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <CurrenciesTable
          accountHolderId={accountHolderId }
          currencies={currencies}
        />
      </Scrollbar>
    </Card>
  );
};
