import { Card } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import { ApiCountry, useCountryRestrictionsListQuery } from 'src/models/restrictions';
import { CountriesTable } from './CountriesTable';


export const CountryList = ({accountHolderId}: {accountHolderId?:string;}) => {

const { data } = useCountryRestrictionsListQuery({
        accountHolderIdForHeader:accountHolderId,
      },
      {
        refetchOnMountOrArgChange:true,
      }
      );

  const [countries, setCountries] = useState<ApiCountry[]>([]);

  useEffect(() => {
    setCountries(data || []);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <CountriesTable
          accountHolderId={accountHolderId }
          countries={countries}
        />
      </Scrollbar>
    </Card>
  );
};
