import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { LazyLoadableScreen } from 'src/navigation/LazyLoadableScreen';

const OnboardingScreen = LazyLoadableScreen(
    () => import('./Onboarding'),
);

const OnboardingDetailsScreen = LazyLoadableScreen(
    () => import('./OnboardingDetails'),
);

export const OnboardingRoutes: FC<{
    accountHolderId?: string;
}> = ({ accountHolderId }) => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <OnboardingScreen accountHolderId={accountHolderId} />
                }
            />
            <Route
                path=":id"
                element={
                    <OnboardingDetailsScreen
                        accountHolderId={accountHolderId}
                    />
                }
            />
            <Route path="*" element={<Navigate to="" replace />} />
        </Routes>
    );
};
