export const apiKeyStatusList = [
  "PENDING_INVITATION",
  "PENDING_SMS",
  "PENDING_EMAIL",
  "PENDING",
  "ACTIVE",
  "EXPIRED",
  "DORMANT",
  "LOCKED",
  "SUSPENDED"
] as const;
