import { Delete } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { ApiKeySummary, useDeleteApiKeyMutation } from 'src/models/apiKeys';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { SortOrder } from 'src/models/types';
import { ApiKeyStatusLabel } from './ApiKeyStatusLabel';
interface ApiKeyTable {
  apiKeys: ApiKeySummary[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  { title: 'Id', id: 'email', doSort: true },
  { title: 'Description', id: 'apiKeyStatus', doSort: false },
  { title: 'Status', id: 'firstName', doSort: false },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const ApiKeysTable: FC<ApiKeyTable> = ({
  apiKeys,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteApiKey] = useDeleteApiKeyMutation();
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            id: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              await deleteApiKey({ id: values.id, accountHolderId }).unwrap();
              toast.success(`Api Key deleted successfully.`);
            } catch (err) {
              // toast.error('An error has occured');
              console.log(err);
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id, doSort }) => (
                    <TableCell
                      key={id}
                      sortDirection={
                        sortEnabled && doSort && orderBy === id
                          ? sortDirection
                          : false
                      }
                    >
                      {sortEnabled && doSort ? (
                        <TableSortLabel
                          active={orderBy === id}
                          direction={orderBy === id ? sortDirection : 'asc'}
                          onClick={createSortHandler(id)}
                        >
                          {title}
                        </TableSortLabel>
                      ) : (
                        title
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {apiKeys.map((apiKey) => {
                  return (
                    <TableRow
                      hover
                      key={apiKey.id}
                    // onClick={() => navigate(`/apiKeys/${apiKey.id}`)}
                    >
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {format(
                            new Date(apiKey.createdDateTime),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {apiKey.id}
                        </Typography>
                      </TableCell>
                      <TableCell>{apiKey.description}</TableCell>
                      <TableCell>
                        <ApiKeyStatusLabel status={apiKey.status} />
                      </TableCell>

                      <TableCell align="right">
                        <PermissionGuard permission="DELETE_API_KEY">
                          <IconButton
                            disabled={!isUserActive}
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to remove this API Key?',
                                subTitle: 'This operation cannot be undone',
                                onConfirm: () => {
                                  setFieldValue('id', apiKey.id);
                                  formikRef?.current?.submitForm();
                                },
                              });
                            }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </PermissionGuard>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
