import { colors, Container, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import BankingView from './BankingView';

export const Banking: FC<{ accountHolderId: string; isGlobal: boolean }> = ({
  accountHolderId,
  isGlobal,
}) => {
  return (
    <>
      <Helmet>
        <title>Banking</title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid alignItems="center" container item xs={12} sx={{ mt: '30px' }}>
          {isGlobal && (<Typography sx={{ color: colors.blueGrey[600] }} variant="overline">
            CONFIGURATION
          </Typography>)}
        </Grid>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ marginBottom: '25px' }}
          xs={12}
        >
          <Grid item justifySelf="left">
            <Typography color="textPrimary" variant="h5">
              Banking
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
            }}
          ></Grid>
        </Grid>

        <PermissionGuard permission="GET_BANKING_CONFIGURATION">
          <BankingView accountHolderId={accountHolderId} isGlobal={isGlobal} />
        </PermissionGuard>
      </Container>
    </>
  );
};
