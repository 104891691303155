import * as Yup from 'yup';

import { ApiConfigAttribute } from 'src/models/configurations';
import { CreateUpdateBankingConfigAttributeFormValues } from './types';


export const attributeFormValidationSchema =
  Yup.object({
    attributeName: Yup.string().required('Attribute Name is required'),
    attributeValue: Yup.string().required('Attribute Value is required'),
  });

export const attributeToAttributeFormValues = (
  attribute: ApiConfigAttribute, accountHolderIdForHeader: string, bankingConfigId: string
): CreateUpdateBankingConfigAttributeFormValues => ({
  accountHolderIdForHeader,
  bankingConfigId: bankingConfigId,
  id: attribute.id,
  attributeName: attribute.attributeName,
  attributeValue: attribute.attributeValue,
})