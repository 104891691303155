import * as Yup from 'yup';

import {
  ApiTransferChannel, CreateUpdateTransferChannel, UpdateTransferChannelFormValues,
} from '.';

export const transferChannelFormValidationSchema =
  Yup.object({

    description: Yup.string().max(100, 'Description must be less than 100 characters').required('Description is required'),
    providerType: Yup.string().required('Provider Type is required'),
    requiredPermission: Yup.string().max(100, 'Required Permission must be less than 100 characters').required('Required Permission is required'),
    enabled: Yup.bool().required('enabled is required'),
    categories: Yup.array().min(1, 'Categories must have at least 1 item').of(
      Yup.object().shape({
        value: Yup.string().required("Category is required"),
      })),
  });

export const transferChannelToTransferChannelFormValues = (
  transferChannel: ApiTransferChannel,
): UpdateTransferChannelFormValues => ({
  id: transferChannel?.id,
  description: transferChannel.description,
  providerType: transferChannel.providerType,
  requiredPermission: transferChannel.requiredPermission,
  enabled: transferChannel.enabled,
  categories: transferChannel.categories.map(x => ({ value: x })),
  accountHolderId: null
});


export const transferChannelFormValuesToTransferChannelRequestBody = (
  formValues: UpdateTransferChannelFormValues,
): CreateUpdateTransferChannel => ({
  ...(formValues?.id ? { id: formValues.id } : {}),
  description: formValues.description,
  providerType: formValues.providerType,
  requiredPermission: formValues.requiredPermission,
  enabled: formValues.enabled,
  categories: formValues.categories.map(x => x.value),
  accountHolderId: formValues.accountHolderId
});