import {
  BaseQueryFn,
  FetchArgs,
  // fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'

import { setTotpCode } from 'src/models/totp'

//   const baseQuery = fetchBaseQuery({ baseUrl: '/' })
export const totpbaseQuery = (baseQuery): BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> => async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  // skip validate reset as it will never have 2FA
  api.dispatch(setTotpCode({ code: null }))
  return result;
}