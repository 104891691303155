import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import {
  ApiKycVerification,
  KycVerificationListSort,
  useKycVerificationsQuery,
} from 'src/models/kycVerifications';
import { SortOrder } from 'src/models/types';
import { usePagination } from '../../hooks/usePagination';
import { KycVerificationsTable } from './KycVerificationsTable';
import { KycVerificationFilterForm } from './types';

export const KycVerificationList = ({
  filter,
  accountHolderId,
}: {
  filter: KycVerificationFilterForm;
  accountHolderId:string;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: KycVerificationListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as KycVerificationListSort, direction: 'desc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  const [skipFetch, setSkipFetch] = useState<boolean>(true);

  useEffect(() => {
    setSkipFetch(true);
    resetPagination();
    setSkipFetch(false);
  }, [filter]);
  
  const { data } = useKycVerificationsQuery({
    ...filter,
    accountHolderIdForHeader:accountHolderId,
    page,
    size: limit,
    sort: [`${sort.orderBy},${sort.direction}` as KycVerificationListSort],
  },
  {
    refetchOnMountOrArgChange:true,
    skip:skipFetch
  });


  const [kycVerifications, setKycVerifications] = useState<ApiKycVerification[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    setKycVerifications(data?.content || []);
    setTotalElements(data?.totalElements || 0);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <KycVerificationsTable
          accountHolderId={accountHolderId}
          kycVerifications={kycVerifications}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
