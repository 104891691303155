import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { AccountHolderIdField, ClientAccountField, clientAccountInitialValues } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import { useAccountQuery } from 'src/models/accounts';
import { selectAccountHolderId } from 'src/models/auth';
import {
  ApiFeeConfig,
  feeContributorList,
  feeTransactionContextList,
  mapApiFeeConfigToCreateUpdateFeeFormValues,
  mapCreateUpdateFeeFormValuesToApiFeeConfigCreate,
  useAddFeeConfigurationMutation,
  useFeeConfigurationsTransactionTypesQuery,
  useUpdateFeeConfigurationMutation,
} from 'src/models/configurations';
import { CreateUpdateFeeFormValues } from './types-dto';
import { feeFormValidationSchema } from './validations';
interface FormProps {
  onClose?: () => void;
  fee?: ApiFeeConfig;
  accountHolderId: string;
  isGlobal: boolean;
}
export const FeeForm: FC<FormProps> = ({
  onClose,
  fee,
  accountHolderId,
  isGlobal,
}) => {

  const [addFee] = useAddFeeConfigurationMutation();
  const [updateFee] = useUpdateFeeConfigurationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const currentAccountHolderId = useSelector(selectAccountHolderId);
  const { data: transactionTypeList, isFetching: transactionTypeListLoading } =
    useFeeConfigurationsTransactionTypesQuery(
      {
        accountHolderIdForHeader: accountHolderId,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: contributorAccount } = useAccountQuery(
    {
      accountHolderIdForHeader: accountHolderId,
      id: fee?.contributorAccountId
    },
    { refetchOnMountOrArgChange: true, skip: !fee?.contributorAccountId },
  );

  const { data: feeAccount } = useAccountQuery(
    {
      accountHolderIdForHeader: accountHolderId,
      id: fee?.feeAccountId
    },
    { refetchOnMountOrArgChange: true, skip: !fee?.feeAccountId },
  );

  const initialValues = useMemo(
    (): CreateUpdateFeeFormValues => ({
      feeConfigId: '',
      accountHolderId: !isGlobal ? accountHolderId : '',
      percentage: 0,
      transactionType: '',
      feeContributor: feeContributorList[0],
      transactionContext: feeTransactionContextList[0],
      feeAccount: clientAccountInitialValues(),
      contributorAccount: clientAccountInitialValues(),
      description: '',
      enabled: true,
      assignable: false,
      accountHolderIdForHeader: accountHolderId,
      ...(fee
        ? mapApiFeeConfigToCreateUpdateFeeFormValues(
          fee,
          accountHolderId,
          contributorAccount,
          feeAccount
        ) : {}
      )
    }), [fee, contributorAccount, feeAccount, accountHolderId]);

  return (
    <Formik<CreateUpdateFeeFormValues>
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={feeFormValidationSchema}
      onSubmit={async (values): Promise<void> => {
        try {
          const request = mapCreateUpdateFeeFormValuesToApiFeeConfigCreate(values);
          if (fee) {
            await updateFee(request).unwrap();
            toast.success(`Fee updated successfully.`);
          } else {
            await addFee(request).unwrap();
            toast.success(`Fee created successfully.`);
          }
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(fee, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {fee ? 'Edit Fee' : 'Create Fee'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              {fee && (
                <FormikTextField
                  name="feeConfigId"
                  label="Id"
                  autoComplete="off"
                  disabled
                />
              )}

              <Field
                name={`accountHolderId`}
                component={AccountHolderIdField}
                accountHolderId={accountHolderId}
              />

              <FormikTextField
                name="description"
                label="Description"
                autoComplete="off"
                margin='normal'
              />

              <FormikTextField
                name="percentage"
                label="Percentage"
                autoComplete="off"
                margin='normal'
              />
              {transactionTypeListLoading && <CircularProgress />}
              {!transactionTypeListLoading && (
                <FormControl fullWidth margin="normal">
                  <InputLabel
                    error={Boolean(
                      touched.transactionType && errors.transactionType,
                    )}
                  >
                    Transaction Type
                  </InputLabel>
                  <Select
                    error={Boolean(
                      touched.transactionType && errors.transactionType,
                    )}
                    value={values.transactionType}
                    label="Transaction Type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="transactionType"
                    variant="outlined"
                  >
                    {transactionTypeList.map((i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {touched.transactionType && errors.transactionType}
                  </FormHelperText>
                </FormControl>
              )}

              <Field name="feeAccount"
                component={ClientAccountField}
                accountHolderId={currentAccountHolderId}
                labels={{ clientAccountHolderIdLabel: 'common.feeClient', clientAccountIdLabel: 'common.feeAccount' }} />

              <FormControl fullWidth margin="normal">
                <InputLabel
                  error={Boolean(
                    touched.feeContributor && errors.feeContributor,
                  )}
                >
                  Fee Contributor
                </InputLabel>
                <Select
                  error={Boolean(
                    touched.feeContributor && errors.feeContributor,
                  )}
                  value={values.feeContributor}
                  label="Fee Contributor"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="feeContributor"
                  variant="outlined"
                >
                  {feeContributorList.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {touched.feeContributor && errors.feeContributor}
                </FormHelperText>
              </FormControl>

              {values.feeContributor == 'OTHER' &&
                (
                  <Field name="contributorAccount"
                    component={ClientAccountField}
                    accountHolderId={accountHolderId}
                    labels={{ clientAccountHolderIdLabel: 'common.contributorClient', clientAccountIdLabel: 'common.contributorAccount' }} />
                )}

              <FormControl fullWidth margin="normal">
                <InputLabel
                  error={Boolean(
                    touched.transactionContext && errors.transactionContext,
                  )}
                >
                  Fee Transaction Context
                </InputLabel>
                <Select
                  error={Boolean(
                    touched.transactionContext && errors.transactionContext,
                  )}
                  value={values.transactionContext}
                  label="Fee Transaction Context"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="transactionContext"
                  variant="outlined"
                >
                  {feeTransactionContextList.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {touched.transactionContext && errors.transactionContext}
                </FormHelperText>
              </FormControl>

              <FormControlLabel
                control={
                  <Field
                    label="Enabled"
                    name="enabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('enabled', checked);
                    }}
                    checked={values.enabled ? true : false}
                  />
                }
                label="Enabled"
              />

              <FormControlLabel
                control={
                  <Field
                    label="Assignable"
                    name="assignable"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('assignable', checked);
                    }}
                    checked={values.assignable ? true : false}
                  />
                }
                label="Assignable"
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {fee ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
