import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { SelectChangeEvent } from "@mui/material";
import { useFormikContext } from 'formik';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { useCallback } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { countries, Country } from 'src/utils/countries';

interface Props {
  formControlProps?: FormControlProps;
  label: string;
  name: string;
  selectProps?: SelectProps;
  type?: 'country' | 'nationality' | 'phone';
}

const CountryFlag = (props: { countryCode: CountryCode }) => (
  <ReactCountryFlag
    countryCode={props.countryCode}
    style={{
      height: '1.25em',
      marginTop: '-2px',
      width: '1.25em',
    }}
    svg
  />
);

export const CountryCodeField = ({
  formControlProps,
  label,
  name,
  selectProps,
  type = 'country',
}: Props) => {
  const { errors, handleBlur, touched, setFieldValue, values } =
    useFormikContext();

  const onChangeCountryCode = useCallback(
    (e: SelectChangeEvent<{ value: CountryCode }>, child) => {
      setFieldValue(name, e.target.value);
      typeof selectProps?.onChange === 'function' &&  selectProps?.onChange(e, child);
    },
    [],
  );

  const renderCountryItem = useCallback(
    (country: Country) => `${country.name} - ${country.code}` || '',
    [],
  );

  const renderNationalityItem = useCallback(
    (country: Country) => country.name || '',
    [],
  );

  const renderPhoneItem = useCallback(
    (country: Country) => (
      <>
        <CountryFlag countryCode={country.code} />
        &nbsp; {country.name}
        &nbsp; (+{country.callingCode})
      </>
    ),
    [],
  );

  const renderPhoneValue = useCallback(
    (countryCode: CountryCode) => (
      <>
        <CountryFlag countryCode={countryCode} />
        &nbsp; (+{getCountryCallingCode(countryCode)})
      </>
    ),
    [],
  );

  const renderContryCode = useCallback((countryCode) => countryCode, []);

  return (
    <FormControl {...formControlProps}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...selectProps}
        error={Boolean(touched[name] && errors[name])}
        label={label}
        name={name}
        onBlur={handleBlur}
        onChange={onChangeCountryCode}
        renderValue={
          type === 'country'
            ? renderContryCode
            : type === 'phone'
            ? renderPhoneValue
            : undefined
        }
        value={values[name] || ''}
      >
        {countries.map((country) => (
          <MenuItem
            key={country.code}
            style={{ textAlign: 'center' }}
            value={country.code}
          >
            {type === 'phone'
              ? renderPhoneItem(country)
              : type === 'nationality'
              ? renderNationalityItem(country)
              : renderCountryItem(country)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{touched[name] && errors[name]}</FormHelperText>
    </FormControl>
  );
};
