import { apiWithTag } from '../emptySplitApi';
import { ApiTotp, ApiTotpRequest, MeRequest, MeResponse } from './types';

export const userApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<MeResponse, MeRequest>({
      query: ({ accountHolderId }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'GET',
        url: 'auth/me',
      }),
      providesTags: ['Me']
    }),
    qrCode: builder.mutation<ApiTotp, { accountHolderId: string }>({
      query: ({ accountHolderId }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'POST',
        url: 'auth/me/totp',
      }),
      invalidatesTags: ['Me']
    }),
    enable2FA: builder.mutation<ApiTotp, ApiTotpRequest>({
      query: ({ accountHolderIdForHeader, totpCode }) => ({
        body: { totpCode },
        headers: {
          'x-account-holder-id': accountHolderIdForHeader,
        },
        method: 'PUT',
        url: 'auth/me/totp',
      }),
      invalidatesTags: ['Me']
    }),
    disable2FA: builder.mutation<void, { accountHolderId: string }>({
      query: (payload) => ({
        headers: {
          'x-account-holder-id': `${payload.accountHolderId}`,
        },
        method: 'DELETE',
        url: `auth/me/totp`,
      }),
      invalidatesTags: ['Me']
    }),
    changePassword: builder.mutation<void, { accountHolderId: string, oldPassword: string, newPassword: string }>({
      query: ({ accountHolderId, oldPassword, newPassword }) => ({
        body: {
          oldPassword,
          newPassword
        },
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'PUT',
        url: 'auth/me/password',
      }),
    }),
  }),
});

export const { useMeQuery, useLazyMeQuery, useQrCodeMutation, useEnable2FAMutation, useDisable2FAMutation, useChangePasswordMutation } = userApi;
