import { createApi } from '@reduxjs/toolkit/query/react';
import { CountryCode } from 'libphonenumber-js';

import { totpbaseQuery } from 'src/services/redux/totpBaseQuery';
import { removeEmpty } from 'src/utils/removeEmpty';
import { gatewayBaseQuery } from '../../services/redux/api';
import { ApiCountryLookup, ApiCurrencyLookup, CountryState } from './types';


export const countryStatesApi = createApi({
  baseQuery: totpbaseQuery(gatewayBaseQuery),
  keepUnusedDataFor: 0.0001,
  endpoints: (builder) => ({
    countries: builder.query<ApiCountryLookup[], { accountHolderId?: string }>({
      query: (params) => {
        const parameters = removeEmpty({
          ...params,
        });
        return {
          method: 'GET',
          url: `gateway/countries`,
          params: parameters,
        }
      },
      transformResponse: (response: ApiCountryLookup[], meta, arg) => {
        return response.sort((a, b) => a.name.localeCompare(b.name));
      },
    }),
    countryStates: builder.query<CountryState[], { countryCode: CountryCode }>({
      query: ({ countryCode }) => ({
        method: 'GET',
        url: `gateway/country-states/${countryCode}`,
      }),
    }),
    currencies: builder.query<ApiCurrencyLookup[], { accountHolderId?: string }>({
      query: (params) => {
        const parameters = removeEmpty({
          ...params,
        });
        return {
          method: 'GET',
          url: `gateway/currencies`,
          params: parameters,
        }
      },
      transformResponse: (response: ApiCurrencyLookup[], meta, arg) => {
        return response.sort((a, b) => a.name.localeCompare(b.name));
      },
    }),
  }),
  reducerPath: 'countryStatesApi',
});

export const {
  useCountriesQuery,
  useCountryStatesQuery,
  useCurrenciesQuery,
} = countryStatesApi;
