import * as Yup from 'yup';

import { accountHolderIdValidationSchema } from 'src/components/forms';


export const cardBinFormValidationSchema = () =>
    Yup.object().shape({
        rangeFrom: Yup.number().positive().integer()
            .min(0, 'Range From must be larger than 0')
            .max(999999, 'Range From must be less than 999999')
            .when('rangeTo', (rangeTo) => {
                if (rangeTo) {
                    return Yup.number()
                        .max(rangeTo, 'Range From must be smaller than Range To')
                        .typeError('Range From is required')
                }
            }),
        rangeTo: Yup.number().positive().integer()
            .min(0, 'Range To must be larger than 0')
            .max(999999, 'Range To must be less than 999999')
            .when('rangeFrom', (rangeFrom) => {
                if (rangeFrom) {
                    return Yup.number()
                        .min(rangeFrom, 'Range To must be larger than Range From')
                        .typeError('Range To is required')
                }
            }),
        accountHolderIdForHeader: accountHolderIdValidationSchema()
    }, [['rangeFrom', 'rangeTo']]);

