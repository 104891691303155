import { Alert, Button, Drawer, Snackbar } from '@mui/material';
import { FC, SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  isAccountHolderStatusKycPending,
  isAccountHolderStatusKycRequired,
  selectAccountHolderId,
} from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { SIDEBAR_WIDTH } from '../utils/config';
import { KYCWizard } from './KYCWizard';
const KYCProcess: FC = () => {
  const isKycRequired = useSelector(isAccountHolderStatusKycRequired);
  const isKycPending = useSelector(isAccountHolderStatusKycPending);

  const [verifyYourAccountOpened, setVerifyYourAccountOpened] =
    useState<boolean>(false);
  const onVerifyYourAccount = () => {
    setVerifyYourAccountOpened(true);
  };
  const accountHolderId = useSelector(selectAccountHolderId);

  const [kycPendingVerificationOpened, setKycPendingVerificationOpened] =
    useState<boolean>(true);

  const handleClose = (event: SyntheticEvent<Element, Event>) => {
    setKycPendingVerificationOpened(false);
  };

  return (
    <>
      <Snackbar
        open={isKycPending && kycPendingVerificationOpened}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          sx={{
            width: '100%',
          }}
        >
          KYC Verification is still pending
        </Alert>
      </Snackbar>
      <PermissionGuard permission="CREATE_KYC_VERIFICATION">
        <Snackbar
          open={isKycRequired && verifyYourAccountOpened == false}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert
            severity="warning"
            sx={{
              width: '100%',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => onVerifyYourAccount()}
          >
            Click here to verify your account
          </Alert>
        </Snackbar>
        <Snackbar
          open={isKycRequired && verifyYourAccountOpened == false}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert
            severity="warning"
            sx={{
              width: '100%',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => onVerifyYourAccount()}
          >
            Click here to verify your account
          </Alert>
        </Snackbar>
        <Drawer
          anchor="right"
          open={verifyYourAccountOpened == true}
          onClose={() => setVerifyYourAccountOpened(false)}
          PaperProps={{
            sx: [
              {
                backgroundColor: '#F3F7FE',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: `100%`,
                },
                [theme.breakpoints.up('md')]: {
                  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                },
              }),
            ],
          }}
        >
          <KYCWizard
            accountHolderIdForHeader={accountHolderId}
            hideClose={false}
            onClose={() => setVerifyYourAccountOpened(false)}
          />
        </Drawer>
      </PermissionGuard>
    </>
  );
};

export default KYCProcess;
