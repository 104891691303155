import type { RootState } from 'src/services/redux';

export const selectEvents = (state: RootState) =>
  state.events?.events;


export const selectEventsCount = (state: RootState) =>
  state.events?.events?.filter(x => x.isFresh).length || 0;



  export const selectIsConnected = (state: RootState) =>
  state.events?.isConnected || false;

