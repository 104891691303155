import * as Yup from "yup";


export const clientAccountValidationSchema = () =>
  Yup.object({
    clientAccountHolderId: Yup.string()
      .required('validations.clientIsRequired'),
    clientAccountId: Yup.string()
      .required('validations.accountIsRequired')
  });
