import { ExpandMore, NavigateNext } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link as RouterLink } from 'react-router-dom';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { useTransactionQuery } from 'src/models/transactions';
import { TransactionStatusLabel } from 'src/models/transactions/components/TransactionStatusLabel';
import { ProcessingLogs } from 'src/screens/TransactionDetails/ProcessingLogs';
import { createAddressString } from 'src/utils/createAddressString';
import { AccountEntriesList } from './AccountEntriesList';
import AcquiringProcessingView from './AcquiringProcessingView';
import { CardTokenDetails } from './CardTokenDetails';
import { RefundForm } from './RefundForm';

interface DetailRow {
  name: string;
  value: any;
}

export const TransactionView = ({
  id,
  accountHolderId,
}: {
  id: string;
  accountHolderId: string;
}) => {
  // const { hasPermission } = usePermissions();
  const [cardVaultOpened, setCardVaultOpened] = useState<boolean>(false);
  const [refundOpened, setRefundOpened] = useState<boolean>(false);
  const [processingLogsOpened, setProcessingLogsOpened] =
    useState<boolean>(false);

  const { data: transaction } = useTransactionQuery(
    {
      accountHolderId,
      id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const transactionInfoData: DetailRow[] = useMemo(
    () =>
      transaction
        ? [
          {
            name: 'Transaction Id',
            value: (
              <Typography
                variant="body2"
                sx={{ wordWrap: 'break-word' }}
              >{`${transaction.id}`}</Typography>
            ),
          },
          ...(transaction.transactionNumber
            ? [
              {
                name: 'Transaction Number',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >{`${transaction.transactionNumber}`}</Typography>
                ),
              },
            ]
            : []),
          {
            name: 'Status',
            value: (
              <TransactionStatusLabel
                status={transaction.transactionStatus}
              />
            ),
          },
          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(transaction.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
          {
            name: 'Transaction Type Description',
            value:
              transaction.transactionTypeDescription ||
              transaction.transactionType,
          },
          {
            name: 'Transaction Type',
            value: (
              <TransactionStatusLabel status={transaction.transactionType} />
            ),
          },
          {
            name: 'Amount',
            value: (
              <Typography
                variant="body2"
                sx={{ wordWrap: 'break-word' }}
              >{`${transaction.amount.displayCurrencyCode} ${transaction.amount.displayText}`}</Typography>
            ),
          },
          {
            name: 'Actual Amount',
            value: (
              <Typography
                variant="body2"
                sx={{ wordWrap: 'break-word' }}
              >{`${transaction.actualAmount.displayCurrencyCode} ${transaction.actualAmount.displayText}`}</Typography>
            ),
          },
          ...(transaction.chargedAmount
            ? [
              {
                name: 'Charged Amount',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >{`${transaction.chargedAmount.displayCurrencyCode} ${transaction.chargedAmount.displayText}`}</Typography>
                ),
              },
            ]
            : []),
          ...(transaction.feeAmount
            ? [
              {
                name: 'Fee Amount',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >{`${transaction.feeAmount.displayCurrencyCode} ${transaction.feeAmount.displayText}`}</Typography>
                ),
              },
            ]
            : []),
          {
            name: 'Description',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {transaction.description}
              </Typography>
            ),
          },
          {
            name: 'Summary',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {transaction.descriptionSummary}
              </Typography>
            ),
          },
          {
            name: 'Source',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {transaction.source}
              </Typography>
            ),
          },
          {
            name: 'Destination',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {transaction.destination}
              </Typography>
            ),
          },
          {
            name: 'Username',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {transaction.username}
              </Typography>
            ),
          },
          ...(transaction.transferChannel
            ? [
              {
                name: 'Transfer Channel',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >
                    {transaction.transferChannel}
                  </Typography>
                ),
              },
            ]
            : []),
          ...(transaction.originalTransactionId
            ? [
              {
                name: 'Original Transaction Id',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >
                    {transaction.originalTransactionId}
                  </Typography>
                ),
              },
            ]
            : []),
          {
            name: 'Client Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {transaction.accountHolderId}
              </Typography>
            ),
          },
          ...(transaction.referenceNo
            ? [
              {
                name: 'Reference',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >
                    {transaction.referenceNo}
                  </Typography>
                ),
              },
            ]
            : []),
          {
            name: 'IP Address',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {transaction.ipAddress}
              </Typography>
            ),
          },
          ...(transaction.errorMessage
            ? [
              {
                name: 'Error message',
                value: (
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >{`${transaction.errorMessage}`}</Typography>
                ),
              },
            ]
            : []),
        ]
        : [],
    [accountHolderId, transaction],
  );

  const acquiring = useMemo(() => transaction?.acquiringData, [transaction]);

  const acquiringData: DetailRow[] = useMemo(
    () =>
      acquiring
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.id}
              </Typography>
            ),
          },
          {
            name: 'MCC',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.mcc}
              </Typography>
            ),
          },
          {
            name: 'Transaction Descriptor',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.transactionDescriptor}
              </Typography>
            ),
          },
          {
            name: 'Return URL',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.returnUrl}
              </Typography>
            ),
          },
          {
            name: 'Failed Return URL',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.failedReturnUrl}
              </Typography>
            ),
          },
          {
            name: 'Card Token Id',
            value: (
              <PermissionGuard
                permission="GET_CARD_TOKEN"
                placeholder={acquiring.cardTokenId}
              >
                <Button
                  onClick={() => {
                    setCardVaultOpened(true);
                  }}
                >
                  {acquiring.cardTokenId}
                </Button>
              </PermissionGuard>
            ),
          },
          {
            name: 'Title',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.title}
              </Typography>
            ),
          },
          {
            name: 'First Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.firstName}
              </Typography>
            ),
          },
          {
            name: 'last Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.lastName}
              </Typography>
            ),
          },
          {
            name: 'Email',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.email}
              </Typography>
            ),
          },
          {
            name: 'Phone',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.phoneNumber?.phoneNumber}
              </Typography>
            ),
          },
          {
            name: 'Mobile',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.mobile?.phoneNumber}
              </Typography>
            ),
          },
          {
            name: 'Date of Birth',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {moment
                  .utc(acquiring?.customerDetail?.dob)
                  .format('DD/MM/yyyy')}
              </Typography>
            ),
          },
          {
            name: 'External Ref',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.externalId}
              </Typography>
            ),
          },
          {
            name: 'Billing Address',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.billingAddress &&
                  createAddressString(
                    acquiring?.customerDetail.billingAddress,
                  )}
              </Typography>
            ),
          },
          {
            name: 'Shipping Address',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {acquiring?.customerDetail?.deliveryAddress &&
                  createAddressString(
                    acquiring?.customerDetail.deliveryAddress,
                  )}
              </Typography>
            ),
          },
        ]
        : [],
    [acquiring],
  );

  const banking = useMemo(() => transaction?.bankingData, [transaction]);

  const bankingData: DetailRow[] = useMemo(
    () =>
      banking
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {banking?.id}
              </Typography>
            ),
          },
          {
            name: 'Banking Config Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {banking?.bankingConfigId}
              </Typography>
            ),
          },
          // {
          //   name: 'Account Number',
          //   value: (
          //     <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          //       {banking?.accountNumber}
          //     </Typography>
          //   ),
          // },
          // {
          //   name: 'Account Id',
          //   value: (
          //     <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          //       {banking?.accountId}
          //     </Typography>
          //   ),
          // },
          // {
          //   name: 'Destination Account Number',
          //   value: (
          //     <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          //       {banking?.destinationAccountNumber}
          //     </Typography>
          //   ),
          // },
        ]
        : [],
    [banking],
  );

  // const processingInfo: DetailRow[] = useMemo(
  //   () => [
  //     {
  //       name: 'Processor Ref',
  //       value: transaction?.processorReference ?? '-',
  //     },
  //   ],
  //   [transaction],
  // );

  // const processingInfo3D: DetailRow[] = useMemo(
  //   () => [
  //     {
  //       name: '3DS Used',
  //       value: (
  //         <FormControlLabel
  //           control={<Switch checked={transaction?.requiresThreeD} />}
  //           label={transaction?.requiresThreeD ? 'On' : 'Off'}
  //         />
  //       ),
  //     },
  //   ],
  //   [transaction],
  // );

  const breadcrumbs = [
    <Link key="1" color={blueGrey[600]} component={RouterLink} to={'/'}>
      <Typography variant="overline">FINANCIALS</Typography>
    </Link>,
    <Link key="1" color={blueGrey[500]} component={RouterLink} to={'./..'}>
      <Typography variant="overline">TRANSACTIONS</Typography>
    </Link>,
  ];

  return (
    <>
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
            marginBottom: '25px',
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }),
        ]}
      >
        <Box>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          {transaction && (
            <Typography color="textPrimary" variant="h5">
              {transaction.transactionNumber}
            </Typography>
          )}
        </Box>
        <Box
          sx={[
            {
              display: 'flex',
              gap: 1,
              marginBottom: '10px',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '10px',
              },
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            }),
          ]}
        >
          {transaction &&
            ['AUTH', 'CHECKOUT_PAYMENT', 'PAYMENT', 'CAPTURE'].includes(
              transaction.transactionType,
            ) &&
            ['COMPLETED'].includes(transaction.transactionStatus) ? (
            <PermissionGuard permission="PERFORM_REFUND">
              <Button
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setRefundOpened(true)}
              >
                Refund
              </Button>
            </PermissionGuard>
          ) : null}

          <PermissionGuard permission="GET_TRANSACTION_LOG">
            <Button
              sx={[
                {
                  whiteSpace: 'nowrap',
                },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    minWidth: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    minWidth: 'auto',
                  },
                }),
              ]}
              variant="contained"
              onClick={() => setProcessingLogsOpened(!processingLogsOpened)}
            >
              Processing Logs
            </Button>
          </PermissionGuard>
        </Box>
      </Box>

      {transaction ? (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  disableTypography
                  title={
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h6">Transaction info</Typography>
                    </Box>
                  }
                />

                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={2}
                  sx={{ p: '16px', mb: '20px' }}
                >
                  {transactionInfoData.map(({ name, value }) => (
                    <Grid item md={6} sm={12} xs={12} key={name}>
                      <Grid container>
                        <Grid item sm={6} xs={12}>
                          <Typography variant="body2" color="textSecondary">
                            {name}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          {value}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {transaction?.parameters && transaction?.parameters.length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Parameters</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: '0',
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={2}
                        sx={{ p: '16px', mb: '20px' }}
                      >
                        {transaction?.parameters.map(
                          ({ parameterName, parameterValue }) => (
                            <Grid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              key={parameterName}
                            >
                              <Grid container>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {parameterName}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  {(() => {
                                    let result = null;
                                    try {
                                      result = JSON.parse(parameterValue);
                                    } catch (e) {
                                      // do nothing
                                    }

                                    if (
                                      typeof result === 'object' &&
                                      result !== null
                                    ) {
                                      return (
                                        <ReactJson
                                          src={result}
                                          style={{ marginBottom: 40 }}
                                        />
                                      );
                                    } else {
                                      return (
                                        <Typography
                                          variant="body2"
                                          sx={{ wordWrap: 'break-word' }}
                                        >
                                          {parameterValue}
                                        </Typography>
                                      );
                                    }
                                  })()}
                                </Grid>
                              </Grid>
                            </Grid>
                          ),
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Card>
            </Grid>

            <Grid item xs={12}>
              {transaction?.acquiringData && (
                <Card>
                  <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6">Acquiring Data</Typography>
                      </Box>
                    }
                  />

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    sx={{ p: '16px', mb: '20px' }}
                  >
                    {acquiringData.map(({ name, value }) => (
                      <Grid item md={6} sm={12} xs={12} key={name}>
                        <Grid container>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {name}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>

                  <PermissionGuard permission="GET_ACQUIRING_PROCESSING">
                    <AcquiringProcessingView
                      id={id}
                      accountHolderId={accountHolderId}
                    />
                  </PermissionGuard>
                </Card>
              )}

              {transaction?.bankingData && (
                <Card>
                  <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6">Banking Data</Typography>
                      </Box>
                    }
                  />

                  {/* {hasPermission('ADMIN') && (
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={2}
                      sx={{ p: '16px', mb: '20px' }}
                    >
                      {bankingData.map(({ name, value }) => (
                        <Grid item md={6} sm={12} xs={12} key={name}>
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {name}
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              {value}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>)} */}

                  <AccountEntriesList
                    accountHolderId={accountHolderId}
                    transactionId={id}
                  />
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {transaction &&
        ['AUTH', 'CHECKOUT_PAYMENT', 'PAYMENT', 'CAPTURE'].includes(
          transaction.transactionType,
        ) &&
        ['COMPLETED'].includes(transaction.transactionStatus) && (
          <>
            <PermissionGuard permission="PERFORM_REFUND">
              <Drawer
                anchor="right"
                onClose={() => setRefundOpened(false)}
                open={refundOpened}
              >
                <RefundForm
                  onClose={() => setRefundOpened(false)}
                  onSuccess={() => setRefundOpened(false)}
                  transaction={transaction}
                />
              </Drawer>
            </PermissionGuard>
          </>
        )}
      {transaction && (
        <>
          <PermissionGuard permission="GET_CARD_TOKEN">
            <Drawer
              anchor="right"
              onClose={() => setCardVaultOpened(false)}
              open={cardVaultOpened}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <CardTokenDetails
                accountHolderId={accountHolderId}
                cardTokenId={transaction?.acquiringData?.cardTokenId}
                onClose={() => setCardVaultOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
          <PermissionGuard permission="GET_TRANSACTION_LOG">
            <Drawer
              anchor="right"
              onClose={() => setProcessingLogsOpened(false)}
              open={processingLogsOpened}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <ProcessingLogs
                accountHolderId={accountHolderId}
                onClose={() => setProcessingLogsOpened(false)}
                transactionId={id}
              />
            </Drawer>
          </PermissionGuard>
        </>
      )}
    </>
  );
};

export default TransactionView;
