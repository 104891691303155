import * as Yup from "yup";

export const accountReportExportFormValidationSchema = () =>
    Yup.object({
        startDateTime: Yup.date()
            .required('Start date is required')
            .nullable(),
        endDateTime: Yup.date()
            .required('End date is required')
            .nullable()
    })
    ;


