import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { CountryCode } from 'libphonenumber-js';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectAccountHolderId } from 'src/models/auth';
import {
  useCountriesQuery,
  useCountryStatesQuery,
} from 'src/models/countryStates/api';
import { ApiCountryLookup } from 'src/models/countryStates/types';
import { Nullable } from '../../../../../utils/types';
import { CountryFlag } from '../../../../CountryFlag';
import { Address } from './types';

export interface AddressFieldProps {
  disabled: boolean;
  accountHolderId?: string;
}
export const AddressField: FC<
  AddressFieldProps & FieldProps<Address, Address>
> = ({
  disabled = false,
  accountHolderId,
  field,
  form: {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
  },
  ...props
}) => {
    const { t } = useTranslation();

    const countries = useCountriesQuery(
      { accountHolderId },
      {
        refetchOnMountOrArgChange: true,
      },
    );

    const countryStates = useCountryStatesQuery(
      { countryCode: values[field.name].countryCode?.code as CountryCode },
      {
        skip: values[field.name].countryCode?.code == undefined,
        refetchOnMountOrArgChange: true,
      },
    );

    // Auto populate country when there is only one
    useEffect(() => {
      if (countries && countries?.data && countries?.data?.length === 1) {
        setFieldValue(`${field.name}.countryCode`, countries.data[0]);
      }
    }, [countries.data]);

    return (
      <>
        <Field
          component={TextField}
          name={`${field.name}.addressLine1`}
          fullWidth
          label={t('common.addressLine1')}
          value={values[field.name]?.addressLine1}
          margin="normal"
          error={Boolean(
            touched[field.name]?.addressLine1 &&
            !!errors[field.name]?.addressLine1,
          )}
          helperText={
            touched[field.name]?.addressLine1 &&
            t(errors[field.name]?.addressLine1 as string)
          }
          onBlur={handleBlur(`${field.name}.addressLine1`)}
          onChange={handleChange(`${field.name}.addressLine1`)}
          variant="outlined"
          disabled={disabled}
        />
        <Field
          component={TextField}
          name={`${field.name}.addressLine2`}
          fullWidth
          label={t('common.addressLine2')}
          value={values[field.name]?.addressLine2}
          margin="normal"
          error={Boolean(
            touched[field.name]?.addressLine2 &&
            !!errors[field.name]?.addressLine2,
          )}
          helperText={
            touched[field.name]?.addressLine2 &&
            t(errors[field.name]?.addressLine2 as string)
          }
          onBlur={handleBlur(`${field.name}.addressLine2`)}
          onChange={handleChange(`${field.name}.addressLine2`)}
          variant="outlined"
          disabled={disabled}
        />
        <Field
          component={TextField}
          name={`${field.name}.postcode`}
          fullWidth
          label={t('common.zipPostCode')}
          value={values[field.name]?.postcode}
          margin="normal"
          error={Boolean(
            touched[field.name]?.postcode && !!errors[field.name]?.postcode,
          )}
          helperText={
            touched[field.name]?.postcode &&
            t(errors[field.name]?.postcode as string)
          }
          onBlur={handleBlur(`${field.name}.postcode`)}
          onChange={handleChange(`${field.name}.postcode`)}
          variant="outlined"
          disabled={disabled}
        />
        <Field
          component={TextField}
          name={`${field.name}.city`}
          fullWidth
          label={t('common.city')}
          value={values[field.name]?.city}
          margin="normal"
          error={Boolean(touched[field.name]?.city && !!errors[field.name]?.city)}
          helperText={
            touched[field.name]?.city && t(errors[field.name]?.city as string)
          }
          onBlur={handleBlur(`${field.name}.city`)}
          onChange={handleChange(`${field.name}.city`)}
          variant="outlined"
          disabled={disabled}
        />

        {countries && countries?.data && countries?.data?.length > 0 && (
          <FormControl fullWidth margin="normal">
            <Field
              component={Autocomplete}
              name={`${field.name}.countryCode`}
              fullWidth
              value={values[field.name].countryCode}
              onBlur={handleBlur(`${field.name}.countryCode`)}
              onChange={(e: any, value: ApiCountryLookup) => {
                setFieldValue(`${field.name}.state`, ''); // Clear State
                setFieldValue(
                  `${field.name}.countryCode`,
                  value !== null ? value : null,
                ); // Set Country
                // if (value !== null) {
                setTimeout(() => setFieldTouched(`${field.name}.state`, true));
                countryStates.refetch(); // refetch
                // }
              }}
              disabled={disabled || countries?.data?.length === 1}
              options={countries.data}
              autoHighlight
              variant="outlined"
              filterOptions={(
                options: Array<ApiCountryLookup>,
                state: { inputValue: string },
              ): Array<ApiCountryLookup> => {
                // const value = state.inputValue;
                return options.filter(
                  (x) =>
                    x.name
                      ?.toLowerCase()
                      ?.includes(state.inputValue.toLowerCase()) ||
                    x.code
                      ?.toLowerCase()
                      ?.includes(state.inputValue.toLowerCase()) ||
                    x.alpha3
                      ?.toLowerCase()
                      ?.includes(state.inputValue.toLowerCase()),
                );
              }}
              isOptionEqualToValue={(
                option: ApiCountryLookup,
                value: Nullable<ApiCountryLookup>,
              ) => option.code === value?.code}
              getOptionLabel={(option: ApiCountryLookup) =>
                `${option.name} (${option.code})` || ''
              }
              renderOption={(props: any, option: ApiCountryLookup) => (
                <Box
                  key={option}
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <CountryFlag countryCode={option.code} />
                  {option.name} ({option.code})
                </Box>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <>
                  <TextField
                    {...params}
                    label={t('common.country')}
                    error={Boolean(
                      touched[field.name]?.countryCode &&
                      errors[field.name]?.countryCode,
                    )}
                    helperText={
                      touched[field.name]?.countryCode &&
                      t(typeof errors[field.name]?.countryCode === 'object' ? errors[field.name]?.countryCode.alpha3 : errors[field.name]?.countryCode)
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    InputProps={{
                      ...params.InputProps,
                      ...(values[field.name]?.countryCode?.code
                        ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <>
                                <Box sx={{ ml: '8px' }}>
                                  <CountryFlag
                                    countryCode={
                                      values[field.name]?.countryCode?.code
                                    }
                                  />
                                </Box>
                                {/* {values[field.name]?.countryCode?.name}  ({values[field.name]?.countryCode?.code}) */}
                              </>
                            </InputAdornment>
                          ),
                        }
                        : {}),
                    }}
                  />
                </>
              )}
            ></Field>
          </FormControl>
        )}

        {countryStates && countryStates?.data && countryStates?.data?.length > 0 && (
          <FormControl fullWidth margin="normal">
            <InputLabel
              htmlFor={`${field.name}.state`}
              error={Boolean(
                touched[field.name]?.state && errors[field.name]?.state,
              )}
            >
              {t('common.state')}
            </InputLabel>
            <Field
              component={Select}
              name={`${field.name}.state`}
              fullWidth
              label={t('common.state')}
              error={Boolean(
                touched[field.name]?.state && errors[field.name]?.state,
              )}
              onBlur={handleBlur(`${field.name}.state`)}
              value={values.state}
              onChange={handleChange(`${field.name}.state`)}
              disabled={disabled}
            >
              {countryStates.data?.map((i) => (
                <MenuItem key={i.code} value={i.code}>
                  {i.name}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText error>
              {touched[field.name]?.state &&
                t(errors[field.name]?.state as string)}
            </FormHelperText>
          </FormControl>
        )}
      </>
    );
  };
