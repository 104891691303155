export const restrictionStatusList = [
    'PENDING',
    'COMPLETED',
    'CANCELLED'
] as const;

export const restrictionTypeList = [
    'COUNTRY',
    'CURRENCY',
    'CARD_BIN',
    'ACCOUNT_CURRENCY'
] as const;

export const restrictionActionList = [
    'CREATE',
    'DELETE '
] as const;