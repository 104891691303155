import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ApiBeneficiary } from 'src/models/beneficiaries';
import { SortOrder } from 'src/models/types';

interface BeneficiaryTable {
  beneficiaries: ApiBeneficiary[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  // { title: 'Id', id: 'id', doSort: true },
  { title: 'First Name', id: 'firstName', doSort: true },
  { title: 'Last Name', id: 'lastName', doSort: false },
  { title: 'Company Name', id: 'companyName', doSort: false },
  { title: 'Account Number', id: 'accountNumber', doSort: false },
  { title: 'Iban', id: 'iban', doSort: false },
  { title: 'Enabled', id: 'enabled', doSort: false },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const BeneficiariesTable: FC<BeneficiaryTable> = ({
  beneficiaries,
  orderBy,
  sortDirection,
  onRequestSort,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  // const [editBeneficiaryOpened, setEditBeneficiaryOpened] = useState<ApiBeneficiary>(null);
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {beneficiaries.map((beneficiary) => {
              return (
                <TableRow
                  hover
                  key={beneficiary.id}
                // onClick={() => navigate(`${beneficiary.id}`)}
                >
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(beneficiary.createdDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {beneficiary.id}
                  </Typography>
                </TableCell> */}
                  <TableCell>{beneficiary.firstName}</TableCell>
                  <TableCell>{beneficiary.lastName}</TableCell>
                  <TableCell>{beneficiary.companyName}</TableCell>
                  <TableCell>{beneficiary.accountNumber}</TableCell>
                  <TableCell>{beneficiary.iban}</TableCell>
                  <TableCell>
                    <Chip
                      label={beneficiary.enabled ? 'Yes' : 'No'}
                      color={beneficiary.enabled ? 'success' : 'error'}
                    />
                  </TableCell>

                  <TableCell align="right">
                    <IconButton component={RouterLink} to={`${beneficiary.id}`}>
                      <ArrowForward fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
