import { Theme } from '@mui/material';
import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    justifyContent: 'center',
  },
  titleIcon: {
    color: colors.orange[400],
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'default',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '8rem',
    },
  },
}));

export default function InputDialog(props) {
  const {
    inputDialog,
    setInputDialog,
    initialValues,
    validationSchema,
    onSubmit,
    children,
  } = props;
  const classes = useStyles();

  return (
    <Dialog open={inputDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}></DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          errors,
        }): JSX.Element => (
          <>
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">{inputDialog.title}</Typography>
                <Typography variant="subtitle2">
                  {inputDialog.subTitle}
                </Typography>
                {children}
              </DialogContent>
              <DialogActions className={classes.dialogAction}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    setInputDialog({ ...inputDialog, isOpen: false })
                  }
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  {props.confirmText ? props.confirmText : 'Submit'}
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
