import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiBankingConfig,
  useAddBankingConfigurationMutation,
  useUpdateBankingConfigurationMutation,
} from 'src/models/configurations';
import { CreateUpdateBankingFormValues } from '../types';
import {
  bankingFormValidationSchema,
  bankingToBankingFormValues,
} from '../utils';
interface FormProps {
  onClose?: () => void;
  banking?: ApiBankingConfig;
  accountHolderId: string;
  isGlobal: boolean;
}
export const BankingForm: FC<FormProps> = ({
  onClose,
  banking,
  accountHolderId,
  isGlobal,
}) => {

  const [addBanking] = useAddBankingConfigurationMutation();
  const [updateBanking] = useUpdateBankingConfigurationMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateUpdateBankingFormValues>
      innerRef={formikRef}
      initialValues={{
        bankingConfigId: '',
        enabled: true,
        bankName: '',
        bankAddress: '',
        bankAdmin: '',
        accountHolderIdForHeader: !isGlobal ? accountHolderId : null,
        ...(banking
          ? bankingToBankingFormValues(banking, accountHolderId)
          : {}),
      }}
      validationSchema={bankingFormValidationSchema}
      onSubmit={async (
        values,
      ): Promise<void> => {
        try {
          //   console.log(JSON.stringify(values));

          if (banking) {
            const updateBankingResponse = await updateBanking(values).unwrap();
            toast.success(`Banking updated successfully.`);
          } else {
            const createBankingResponse = await addBanking(values).unwrap();
            toast.success(`Banking created successfully.`);
          }
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                {banking ? 'Edit Banking' : 'Create Banking'}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>

              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal || banking}
                accountHolderId={accountHolderId} />

              {banking && (
                <FormikTextField
                  name="bankingConfigId"
                  label="Id"
                  autoComplete="off"
                  disabled
                  margin='normal'
                />
              )}

              <FormikTextField
                name="bankName"
                label="Bank Name"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="bankAddress"
                label="Bank Address"
                autoComplete="off"
                margin='normal'
              />
              <FormikTextField
                name="bankAdmin"
                label="Bank Admin"
                autoComplete="off"
                margin='normal'
              />

              <FormControlLabel
                control={
                  <Field
                    label="Enabled"
                    name="enabled"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('enabled', checked);
                    }}
                    checked={values.enabled ? true : null}
                  />
                }
                label="Enabled"
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {banking ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
