export interface AgentInfo {
  customerAgent: string | null;
  customerIp: string | null;
}

export interface ApiMoney {
  currency: string;
  value: number;
  displayText: string;
  displayCurrencyCode: string;
}

export interface WithAccountHolderId {
  accountHolderId: string;
}

export interface WithAccountHolderIdForHeader {
  accountHolderIdForHeader: string;
}

export type SortOrder = 'desc' | 'asc';

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}


export const countryCodeDefault = 'US';


export interface ApiPhoneNumber {
  prefix: string;
  number: string;
  phoneNumber: string;
}


export interface Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sort;
  unpaged: boolean;
};



export interface PagedObject {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

export const DATE_OF_BIRTH_MASK = 'yyyy-MM-dd';