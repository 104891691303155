import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';

import { accountTypes } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import {
  accountStatusList,
} from '../../models/accounts/constants';
import { AccountFilterForm } from './types';

const initialValues: AccountFilterForm = {
  type: [],
  accountStatus: ['ACTIVE'],
  friendlyName: '',
  accountNumber: '',
  includeSubAccountHolders: false,
  accountHolderId: '',
};

interface FormProps {
  onFilterApply: (values: AccountFilterForm) => void;
}
export const AccountsFilterForm: FC<FormProps> = ({ onFilterApply }) => {
  const onSubmit = (values: AccountFilterForm) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="select-account-type-label">
                    Account Type
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    labelId="select-account-type-label"
                    label="Account Type"
                    id="select-account-type"
                    value={values.type}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('type', e.target.value);
                    }}
                  >
                    {accountTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="select-account-status-label">
                    Account Status
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    labelId="select-account-status-label"
                    label="Account Status"
                    id="select-account-status"
                    value={values.accountStatus}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('accountStatus', e.target.value);
                    }}
                  >
                    {accountStatusList.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Account Number"
                  name="accountNumber"
                  type="text"
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Friendly Name"
                  name="friendlyName"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Client Id"
                  name="accountHolderId"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={
                    <Field name="includeSubAccountHolders" component={Switch} />
                  }
                  label="Include Sub-Clients"
                  name="includeSubAccountHolders"
                  checked={values.includeSubAccountHolders}
                  onChange={(ev, checked) => {
                    setFieldValue('includeSubAccountHolders', checked);
                  }}
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
