import { CommentsDisabledOutlined } from "@mui/icons-material";
import { instanceOf } from "prop-types";
import * as Yup from "yup";

const d = new Date();
d.setFullYear(d.getFullYear() - 100);

export const dateOfBirthValidationSchema = () => Yup.date()
    .max(new Date(), 'validations.dateOfBirthCannotBeInTheFuture')
    .min(d, `validations.dateOfBirthIsInvalid`)
    .required('validations.dateOfBirthIsRequired')
    .nullable();

