export const accountStatusList = [
  'ACTIVE',
  'CLOSED',
  'DELETED'
] as const;

export const accountEntryStatusList = [
  'COMPLETED',
  'CREATED',
  'PENDING',
  'REVERTED'
] as const;

