import * as Yup from "yup";

import { monthDiff } from "src/utils/date";

export const accountEntryExportFormValidationSchema = () =>
    Yup.object().shape({
        startDateTime: Yup.date()
            .required('Start date is required')
            .nullable(),
        endDateTime: Yup.date()
            .required('End date is required')
            .nullable()
            .when('startDateTime', (startDateTime: Date, schema: any) =>
                startDateTime
                    ? schema.test(
                        'test-3-month',
                        'Start and End date must be within a 3 month window',
                        (endDateTime: Date) => startDateTime == null || endDateTime === null || monthDiff(startDateTime, endDateTime) <= 3,
                    )
                    : schema,
            ),
    }, [['startDateTime', 'endDateTime']]);



