import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';

import { FormikTextField } from 'src/components/forms/TextField';
import {
  userStatusList,
  //   userTypeList,
} from '../../models/users/constants';
import { UserFilterForm } from './types';

interface FormProps {
  onFilterApply: (values: UserFilterForm) => void;
  isGlobal: boolean;
  accountHolderId: string;
}
export const UsersFilterForm: FC<FormProps> = ({
  onFilterApply,
  isGlobal,
  accountHolderId,
}) => {
  const initialValues: UserFilterForm = {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    nationality: '',
    userStatus: [],
    query: '',
    email: '',
    accountHolderId: !isGlobal ? accountHolderId : '',
    id: '',
    includeSubAccountHolders: false,
  };

  const onSubmit = (values: UserFilterForm) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size='small' >
                  <InputLabel id="select-user-status-label">
                    User Status
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    labelId="select-user-status-label"
                    label="User Status"
                    id="select-user-status"
                    value={values.userStatus}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('userStatus', e.target.value);
                    }}
                  >
                    {userStatusList.map((trStatus) => (
                      <MenuItem key={trStatus} value={trStatus}>
                        {trStatus}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField label="Email" name="email" type="text" size='small' />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="First Name"
                  name="firstName"
                  type="text"
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Last Name"
                  name="lastName"
                  type="text"
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Nationality"
                  name="nationality"
                  type="text"
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Phone Number"
                  name="phoneNumber"
                  type="text"
                  size='small'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField label="Id" name="id" type="text" size='small' />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Client Id"
                  name="accountHolderId"
                  type="text"
                  size='small'
                  disabled={!isGlobal}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField label="Query" name="query" type="text" size='small' />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                {/* <FormControlLabel
                control={<Field name="includeSubAccountHolders" component={Checkbox} />}
                label="Include Sub-Clients"
                name="includeSubAccountHolders"
                onChange={(ev, checked) => { setFieldValue('includeSubAccountHolders', checked); }}
                /> */}

                <FormControlLabel
                  control={
                    <Field name="includeSubAccountHolders" component={Switch} />
                  }
                  label="Include Sub-Clients"
                  name="includeSubAccountHolders"
                  checked={values.includeSubAccountHolders}
                  onChange={(ev, checked) => {
                    setFieldValue('includeSubAccountHolders', checked);
                  }}
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
