import { Address } from "./types"

export const addressInitialValues = (address?: Address): Address => {
    return {
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        city: '',
        state: '',
        countryCode: null,
        ...address
    }
}