import {
  AnyAction,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  Store,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

import { authApi } from 'src/models/auth';
// import { setSessionExpired } from 'src/models/authBanking';
import { setTotpRequired } from 'src/models/totp';
/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (
        action?.payload?.data?.errorCode == 'INVALID_SIGNATURE' ||
        action?.payload?.data?.errorCode == 'EXPIRED_TOKEN'
      ) {
        // Might cause issues with banking
        // api.dispatch(setSessionExpired({ sessionExpired: true }));
        (api.dispatch as ThunkDispatch<Store, void, AnyAction>)(
          authApi.endpoints.logout.initiate(),
        );
      }
      //   else if (action?.payload?.data?.errorCode == 'TRANSFER_CHANNEL_NOT_FOUND') {
      //     toast.error( i18n.t('common.accessDenied')+'' );
      //  }
      else if (action?.payload?.data?.errorCode == 'TOTP_CODE_REQUIRED') {
        api.dispatch(setTotpRequired({ required: true }));
      } else if (action?.payload?.data?.errorCode == 'INVALID_TOTP_CODE') {
        toast.error('Invalid 2FA code');
        api.dispatch(setTotpRequired({ required: true }));
      } else if (action?.payload?.data?.errorCode == 'USERNAME_NOT_FOUND') {
        // User already migrated from cyclos
      } else if (
        action?.payload?.data?.errorCode == 'BANKING_PROFILE_NOT_FOUND'
      ) {
        // Bank is new and is not in cyclos
      } else if (action?.payload?.data?.errorCode == 'VERIFICATION_EXPIRED') {
        // User verification expired
      } else if (
        action?.payload?.data?.errorCode == 'VERIFICATION_RETRIES_EXCEEDED'
      ) {
        // User verification exceeded
      } else if (action?.payload?.data?.errorCode == 'VERIFICATION_FAILED') {
        // User verification failed
      } else if (
        action?.payload?.data?.errorCode == 'INVITATION_ALREADY_PENDING'
      ) {
        toast.error('An invitation is already pending');
      } else if (action?.payload?.data?.errorCode == 'BAD_CREDENTIALS') {
        toast.error('Wrong email or password');
      } else if (action?.payload?.data?.errorCode == 'ACCOUNT_LOCKED') {
        toast.error('Account is locked');
      } else if (action?.payload?.data?.errorCode == 'ACCOUNT_DISABLED') {
        toast.error(
          'Your account has been suspended. Please contact an administrator',
        );
      } else if (action?.payload?.data?.errorCode == 'PASSWORD_NOT_MATCHED') {
        toast.error('Incorrect Old Password');
      } else if (action?.payload?.data?.errorCode == 'PASSWORD_ALREADY_USED') {
        toast.error('Cannot set use old or same password');
      } else if (
        action?.payload?.data?.errorCode == 'INVALID_PERMISSION_GROUP'
      ) {
        toast.error('Invalid permission group');
      } else if (
        action?.payload?.data?.errorCode == 'FIELD_VALIDATION_FAILED'
      ) {
        const errorMsg = action?.payload?.data?.fieldErrors
          .map((fieldError) => fieldError.errorMessage)
          .join(', ');
        toast.error(`Field Error:${errorMsg}`);
      } else if (
        action?.payload?.data?.errorCode == 'ACCOUNT_HOLDER_NOT_FOUND'
      ) {
        toast.error(`Client not found`);
      } else if (action?.payload?.data?.errorCode == 'KYC_NOT_REQUIRED') {
        toast.error(`KYC not required`);
      } else if (
        action?.payload?.data?.errorCode == 'ATTRIBUTE_ALREADY_EXISTS'
      ) {
        toast.error(`Attribute already exists`);
      } else if (
        action?.payload?.data?.errorCode == 'KYC_IDENTITY_DOCUMENT_MISSING'
      ) {
        //
      } else if (
        action?.payload?.data?.errorCode == 'KYC_ADDRESS_DOCUMENT_MISSING'
      ) {
        //
      } else if (
        action?.payload?.data?.errorCode == 'KYC_FACE_DOCUMENT_MISSING'
      ) {
        //
      } else if (action?.payload?.data?.errorCode == 'KYC_DOC_UPLOAD_FAILED') {
        //
      } else if (
        action?.payload?.data?.errorCode == 'KYC_VERIFICATION_NOT_FOUND'
      ) {
        //
      }
      else if (
        action?.payload?.data?.errorCode == 'BENEFICIARY_NOT_FOUND'
      ) {
        //
      }
      else if (
        action?.payload?.data?.errorCode == 'ACCOUNT_HOLDER_UPDATE_NOT_ALLOWED'
      ) {
        toast.error(`Updates for this client are not allowed`);
      } else if (action?.payload?.data?.errorCode == 'KYC_ALREADY_PENDING') {
        toast.error(`KYC is already pending`);
      } else if (action?.payload?.data?.errorCode == 'ACCOUNT_LIMIT_REACHED') {
        // toast.error(`No more accounts with the requested currency can be created` )
      } else if (action?.payload?.data?.errorCode == 'INVALID_CURRENCY') {
        // toast.error(`Requested currency is not available for this specific bank` )
      } else if (action?.payload?.data?.errorCode == 'IP_BLACKLISTED') {
        toast.error(`IP is blacklisted`);
      } else if (
        action?.payload?.data?.errorCode == 'OWNER_CANNOT_BE_UNLINKED'
      ) {
        // toast.error(`Owner cannot be unlinked` )
      } else if (
        action?.payload?.data?.errorCode == 'SYSTEM_UNDER_MAINTENANCE'
      ) {
        // system under maintanience
      } else if (
        action?.payload?.data?.errorCode == 'TRANSFER_CHANNEL_NOT_FOUND'
      ) {
        // 99% it't a transfer channel without template for params yet
      } else if (action?.payload?.data?.errorCode == 'INVALID_INVITATION_ID') {
        // invitation id invalid
      } else if (
        action?.payload?.data?.errorCode == 'INVALID_DESTINATION_ACCOUNT'
      ) {
        // simulation error
      } else if (
        action?.payload?.data?.errorCode == 'ACCOUNT_NOT_IN_SAME_BANK'
      ) {
        // simulation error
      } else if (action?.payload?.data?.errorCode == 'ACCOUNT_OWNER_MISMATCH') {
        // simulation error
      } else if (action?.payload?.data?.errorCode == 'INSUFFICIENT_FUNDS') {
        // simulation error
      } else if (action?.payload?.data?.errorCode == 'INVALID_AMOUNT') {
        // simulation error
      } else if (
        action?.payload?.data?.errorCode == 'ACCOUNT_HOLDER_LIMIT_REACHED'
      ) {
        // account holder creation
      } else if (
        action?.payload?.data?.errorCode == 'ACCOUNT_HOLDER_ACCESS_DENIED'
      ) {
        toast.error(`Access denied`);
      } else if (
        action?.payload?.data?.errorCode == 'ACCOUNT_HOLDER_TEMPLATE_NOT_FOUND'
      ) {
        // account holder template not found
      } else if (
        action?.payload?.data?.errorCode == 'FEE_CANNOT_BE_UNLINKED'
      ) {
        // account holder template not found
      } else {
        console.warn('We got a rejected action!');
        console.warn(JSON.stringify(action));
        toast.error(action.error.message);
      }
    }

    return next(action);
  };
