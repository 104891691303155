import { Box, FormControl, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectLanguage } from 'src/models/i18n/selectors';
import { setLanguage } from 'src/models/i18n/slice';

interface I18nProps {
  languages: string[];
}

const useStyles = makeStyles({
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .MuiSelect-select": {
      display: "inline-flex"
    }
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: '25px'
  },
  languageSpan: {
    marginLeft: '5px'
  },
  iconRoot: {
    textAlign: 'center'
  }
});

export const I18nSelect: FC<I18nProps> = ({ languages }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language.toLocaleLowerCase());
  }, [language]);

  const handleChange = (event) => {
    dispatch(setLanguage({ language: event.target.value }));
    localStorage.setItem('language', event.target.value);
    i18n.changeLanguage('en')
  };




  return (
    <Box sx={{
      position: 'absolute',
      right: '0',
      top: '0',
    }}>
      <FormControl
        variant="outlined"
        margin="none"
        classes={{
          root: classes.formControl
        }}>
        {/* // fullWidth */}
        {/* > */}
        {/* <InputLabel id="i18n-label">Age</InputLabel> */}
        <Select
          // labelId="i18n-label"
          id="i18n"
          value={language}
          label="i18n"
          onChange={handleChange}
        >
          {languages.map(value =>
            <MenuItem key={value} value={value}><img className={classes.imageIcon} src={`/static/svg-country-flags/svg/${value == 'en' ? 'us' : value}.svg`} /><span className={classes.languageSpan}>{value.toLocaleUpperCase()}</span></MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
