import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import {
    ApiAssignFeeRequest,
    ApiFeeConfig,
    FeeConfigListRequest,
    PageApiFeeConfig,
} from './types-api';

export const assignedFeesApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        assignableFeesList: builder.query<PageApiFeeConfig, FeeConfigListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/assignable-fees',
                }
            },
            providesTags: ['AssignedFees']
        }),
        assignedFeesList: builder.query<PageApiFeeConfig, FeeConfigListRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/assigned-fees',
                }
            },
            providesTags: ['AssignedFees']
        }),
        addAssignedFee: builder.mutation<ApiFeeConfig, ApiAssignFeeRequest>({
            query: ({ accountHolderIdForHeader, ...assignFeeRequest }) => ({
                url: 'gateway/assigned-fees',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: assignFeeRequest
            }),
            invalidatesTags: ['AssignedFees']
        }),
        deleteAssignedFee: builder.mutation<void, ApiAssignFeeRequest>({
            query: ({ accountHolderIdForHeader, ...assignFeeRequest }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/assigned-fees`,
                body: assignFeeRequest
            }),
            invalidatesTags: ['AssignedFees']
        }),
    })
})

export const {
    useAssignableFeesListQuery,
    useAssignedFeesListQuery,
    useAddAssignedFeeMutation,
    useDeleteAssignedFeeMutation,
} = assignedFeesApi;