import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiUserResponse,
  useDeleteUserPermissionsMutation,
} from 'src/models/users';
import { UserPermissionsForm } from 'src/models/users/components/UserPermissionsForm';

interface AssociationsTable {
  user: ApiUserResponse;
  accountHolderId: string;
}

export const AssociationsTable: FC<AssociationsTable> = ({
  user,
  accountHolderId,
}) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const navigate = useNavigate();
  const [deleteUserPermissions] = useDeleteUserPermissionsMutation();
  const { hasPermission } = usePermissions();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const [editUserPermissionsOpened, setEditUserPermissionsOpened] =
    useState<string>(null);

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            accountHolderId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              const result = await deleteUserPermissions({
                userId: user.id,
                accountHolderId: values.accountHolderId,
                accountHolderIdForHeader: accountHolderId,
              }).unwrap();
              if (result == null) {
                toast.success('Permission removed successfully');
                navigate(`/users/${user.id}`);
              }
            } catch (err) {
              if (err?.data?.errorCode == 'OWNER_CANNOT_BE_UNLINKED') {
                toast.error(`Owner cannot be unlinked`);
              }
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Permission Groups</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.associations.map((association) => (
                  <Fragment key={association.accountHolderId}>
                    <TableRow>
                      <TableCell>
                        <Tooltip title={association.accountHolderId}>
                          {hasPermission('MANAGE_ACCOUNT_HOLDER') ? (
                            <Link
                              color="primary"
                              component={RouterLink}
                              to={`/account-holders/${association.accountHolderId}`}
                              variant="body1"
                            >
                              {association.accountHolderDisplayName}
                            </Link>
                          ) : (
                            //  ? <span>{association.accountHolderDisplayName}</span>
                            <span>{association.accountHolderDisplayName}</span>
                          )}
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {association.permissionGroups.map(
                            (permissionGroup) => (
                              <Tooltip
                                key={permissionGroup.id}
                                title={permissionGroup.id}
                              >
                                <Chip
                                  sx={{ m: 1 }}
                                  color="primary"
                                  variant="outlined"
                                  label={permissionGroup.name}
                                />
                              </Tooltip>
                            ),
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <PermissionGuard permission="UPDATE_LINKED_USER">
                          <IconButton
                            disabled={!isUserActive}
                            onClick={() =>
                              setEditUserPermissionsOpened(
                                association.accountHolderId,
                              )
                            }
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </PermissionGuard>
                        <PermissionGuard permission="UNLINK_USER">
                          <IconButton
                            disabled={!isUserActive}
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title:
                                  'Are you sure to remove this Permission?',
                                subTitle: 'This operation cannot be undone',
                                onConfirm: async () => {
                                  setFieldValue(
                                    'accountHolderId',
                                    association.accountHolderId,
                                  );
                                  formikRef?.current?.submitForm();
                                },
                              });
                            }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </PermissionGuard>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <PermissionGuard permission="UPDATE_LINKED_USER">
        <Drawer
          anchor="right"
          open={editUserPermissionsOpened != null}
          onClose={() => setEditUserPermissionsOpened(null)}
          PaperProps={{
            sx: [
              {
                backgroundColor: '#F3F7FE',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: `100%`,
                },
                [theme.breakpoints.up('md')]: {
                  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                },
              }),
            ],
          }}
        >
          <UserPermissionsForm
            accountHolderId={editUserPermissionsOpened}
            accountHolderIdForHeader={accountHolderId}
            user={user}
            onClose={() => setEditUserPermissionsOpened(null)}
          />
        </Drawer>
      </PermissionGuard>
    </>
  );
};
