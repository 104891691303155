import React, { FC } from 'react';

import { AccountHolderPermission } from 'src/models/accountHolder/types';
import { usePermissions } from '../hooks/usePermissions';

interface Props {
  permission: AccountHolderPermission;
  placeholder?: React.ReactNode;
}

export const PermissionGuard: FC<Props> = ({
  children,
  permission,
  placeholder,
}) => {
  const { hasPermission } = usePermissions();

  return <>{(hasPermission(permission) || permission == null) ? children : placeholder || null}</>;
};
