import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    FormControl,
    TextField,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiAccountCurrency, useAccountCurrenciesQuery } from 'src/models/accounts';
import { Nullable } from 'src/utils/types';

export interface AccountCurrencyFieldProps {
    disabled: boolean;
    accountHolderId?: string;
    label?: string;
}
export const AccountCurrencyField: FC<
    AccountCurrencyFieldProps & FieldProps<ApiAccountCurrency[], ApiAccountCurrency[]>
> = ({
    disabled = false,
    accountHolderId,
    label = 'common.accountCurrency',
    field,
    form: { values, touched, errors, handleBlur, setFieldValue },
    ...props
}) => {
        const { t } = useTranslation();

        const accountCurrencies = useAccountCurrenciesQuery(
            { accountHolderId },
            { refetchOnMountOrArgChange: true },
        );

        // Auto populate accountCurrency when there is only one
        useEffect(() => {
            if (accountCurrencies && accountCurrencies?.data && accountCurrencies?.data?.length === 1) {
                setFieldValue(`${field.name}`, accountCurrencies.data[0].currencyCode);
            }
        }, [accountCurrencies.data]);

        return (
            <>
                {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                {accountCurrencies && accountCurrencies?.data && accountCurrencies?.data?.length > 0 && (
                    <FormControl fullWidth margin="normal">
                        <Field
                            component={Autocomplete}
                            name={`${field.name}`}
                            fullWidth
                            value={accountCurrencies.data.find(x => x.currencyCode === values[field.name]) || null}
                            onBlur={handleBlur(`${field.name}`)}
                            onChange={(e: any, value: ApiAccountCurrency) => {
                                setFieldValue(
                                    `${field.name}`,
                                    value !== null ? value.currencyCode : '',
                                );
                            }}
                            disabled={disabled || accountCurrencies?.data?.length === 1}
                            options={accountCurrencies.data}
                            autoHighlight
                            variant="outlined"
                            filterOptions={(
                                options: Array<ApiAccountCurrency>,
                                state: { inputValue: string },
                            ): Array<ApiAccountCurrency> => {
                                return options.filter(
                                    (x) =>
                                        x.currencyCode
                                            ?.toLowerCase()
                                            ?.includes(state.inputValue.toLowerCase()) ||
                                        x.id
                                            ?.toLowerCase()
                                            ?.includes(state.inputValue.toLowerCase())
                                );
                            }}
                            isOptionEqualToValue={(
                                option: ApiAccountCurrency,
                                value: Nullable<ApiAccountCurrency>,
                            ) => option.id === value?.id}
                            getOptionLabel={(option: ApiAccountCurrency) =>
                                `${option?.currencyCode}`
                            }
                            renderOption={(props: any, option: ApiAccountCurrency) => (
                                <Box
                                    key={option}
                                    component="li"
                                    {...props}
                                >
                                    {option.currencyCode}
                                </Box>
                            )}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <>
                                    <TextField
                                        {...params}
                                        label={t(label)}
                                        error={Boolean(
                                            touched[field.name] &&
                                            errors[field.name],
                                        )}
                                        helperText={
                                            touched[field.name] &&
                                            t(errors[field.name])
                                        }
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            // ...(values[field.name]?.id
                                            //     ? {
                                            //         startAdornment: (
                                            //             <InputAdornment position="end">
                                            //                 <>
                                            //                     <Box sx={{ ml: '8px' }}>
                                            //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                                            //                         <AccountHolderStatusLabel status={values[field.name]?.status} />
                                            //                     </Box>
                                            //                 </>
                                            //             </InputAdornment>
                                            //         ),
                                            //     }
                                            //     : {}),
                                        }}
                                    />
                                </>
                            )}
                        ></Field>
                    </FormControl>
                )
                }
            </>
        );
    };
