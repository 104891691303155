import { Box, Button, Card, Drawer, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import Scrollbar from 'src/components/Scrollbar';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { useCorrespondentBanksQuery } from 'src/models/configurations';
import { CorrespondentBanksTable } from './CorrespondentBanksTable';
import { CorrespondentBankForm } from './forms/CorrespondentBankForm';
export const CorrespondentBanks: FC<{
  bankingConfigId: string;
  accountHolderId: string;
}> = ({ bankingConfigId, accountHolderId }) => {
  const [addCorrespondentBankOpened, setAddCorrespondentBankOpened] =
    useState<boolean>(false);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const { data } = useCorrespondentBanksQuery(
    {
      accountHolderIdForHeader: accountHolderId,
      bankingConfigId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const correspondentBanks = data || [];

  return (
    <>
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
            marginBottom: '25px',
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }),
        ]}
      >
        <Typography color="textPrimary" variant="h5">
          Correspondent banks
        </Typography>

        <Box
          sx={[
            {
              display: 'flex',
              gap: 1,
              marginBottom: '10px',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '10px',
              },
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            }),
          ]}
        >
          <PermissionGuard permission="CREATE_CORRESPONDENT_BANK">
            <Button
              disabled={!isUserActive}
              variant="contained"
              onClick={() => setAddCorrespondentBankOpened(true)}
            >
              Create correspondent bank
            </Button>
          </PermissionGuard>
        </Box>
      </Box>

      {/* <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ marginBottom: '25px' , marginTop: '25px'}}
          xs={12}
        >
          <Grid item  justifySelf="left">
            <Typography color="textPrimary" variant="h5">
              Correspondent banks
            </Typography>
          </Grid>
          <Grid item 
                sx={{
                    display: 'flex'
                }}
            >
            <PermissionGuard permission="CREATE_CORRESPONDENT_BANK">
                    <Button
                      variant="contained"
                      onClick={() => setAddCorrespondentBankOpened(true)}
                    >
                      Create correspondent bank
                    </Button>
              
                </PermissionGuard>
          </Grid>
        </Grid> */}

      <PermissionGuard permission="CREATE_CORRESPONDENT_BANK">
        <Drawer
          anchor="right"
          open={addCorrespondentBankOpened}
          onClose={() => setAddCorrespondentBankOpened(false)}
          PaperProps={{
            sx: {
              backgroundColor: '#F3F7FE',
              width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            },
          }}
        >
          <CorrespondentBankForm
            bankingConfigId={bankingConfigId}
            accountHolderId={accountHolderId}
            onClose={() => setAddCorrespondentBankOpened(false)}
          />
        </Drawer>
      </PermissionGuard>
      <PermissionGuard permission="GET_CORRESPONDENT_BANK">
        <Card>
          <Scrollbar>
            <CorrespondentBanksTable
              correspondentBanks={correspondentBanks}
              accountHolderId={accountHolderId}
            />
          </Scrollbar>
        </Card>
      </PermissionGuard>
    </>
  );
};
