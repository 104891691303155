import * as Yup from "yup";

export const emailValidationSchema = (userExists: any) => Yup.string()
    .email('Email format invalid')
    .required('Email is required')
    .when("checkEmail", {
        is: true,
        then: Yup.string()
            .test({
                message: () => "An account with this e-mail is already registered",
                test: async (email) => {
                    if (email) {
                        try {
                            const response = await userExists[0]({ email }).unwrap();
                            if (userExists[1].isLoading || response?.id?.length > 0) {
                                return false;
                            } else {
                                return true;
                            }
                        } catch (error) {
                            if (error?.['data'].errorCode == 'USERNAME_NOT_FOUND') {
                                return true;
                            }
                        }
                    }
                },
            }),
    })

