import { Close } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  colors,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { FC } from 'react';
import ReactJson from 'react-json-view';

import { useKycVerificationLogsQuery } from 'src/models/kycVerifications';
import Scrollbar from '../../components/Scrollbar';
import { KycVerificationStatusLabel } from '../KycManagement/KycVerificationStatusLabel';

interface Props {
  accountHolderId: string;
  kycVerificationId: string;
  onClose: () => void;
}

export const KycVerificationLogs: FC<Props> = ({
  accountHolderId,
  kycVerificationId,
  onClose,
}) => {
  const { data: logs } = useKycVerificationLogsQuery({
    accountHolderIdForHeader:accountHolderId,
    kycVerificationId
  },{refetchOnMountOrArgChange:true,});

  if (!logs) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 38px 24px 32px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{ mr: 2 }}
            aria-label="close"
            size="medium"
            onClick={() => onClose()}
          >
            <Close color="inherit" fontSize="inherit" />
          </IconButton>
          <Typography color={'textPrimary'} variant="h5">
            KYC Verification Logs
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Card
        sx={{
          padding: '20px',
          margin: '10px 20px',
        }}
      >
        <Scrollbar>
          {logs.length === 0 ? <Typography>No Logs found</Typography> : null}
          {logs.map((l) => (
            <Accordion
              key={l.id}
              sx={{
                border: 'none',
                boxShadow: 'none',
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                sx={{
                  cursor: 'pointer',
                  padding: '0 15px',
                  ':hover': {
                    backgroundColor: colors.grey[100],
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography>{l.id}</Typography>
                  <KycVerificationStatusLabel status={JSON.parse(l.content).userStatus} />
                  <Typography>
                    {format(new Date(l.createdDateTime), 'dd MMM, HH:mm')}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Scrollbar>
                  <ReactJson src={JSON.parse(l.content)} style={{ marginBottom:40}} />
                  {/* <Typography sx={{ maxHeight: '60vh', overflow: 'auto' }}>
                    {l.content}
                  </Typography> */}
                </Scrollbar>
              </AccordionDetails>
            </Accordion>
          ))}
        </Scrollbar>
      </Card>
    </>
  );
};
