import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import {
  AccountEntrySort,
  TransactionListSort,
  useAccountEntriesQuery,
} from 'src/models/transactions';
import { SortOrder } from 'src/models/types';
import { usePagination } from '../../hooks/usePagination';
import { AccountEntriesTable } from './AccountEntriesTable';

export const AccountEntriesList = ({
  accountHolderId,
  transactionId
}: {
  accountHolderId:string;
  transactionId:string;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: AccountEntrySort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as AccountEntrySort, direction: 'desc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  const { data ,refetch} = useAccountEntriesQuery({
    transactionId,
    accountHolderId,
    page,
    size: limit,
    sort: [`${sort.orderBy},${sort.direction}` as TransactionListSort],
  },{refetchOnMountOrArgChange:true,});

  const accountEntries = data?.content || [];
  const totalElements = data?.totalElements || 0;

  useEffect(() => {
    refetch();
  },[])

  return (
    <Card>
      <Scrollbar>
        <AccountEntriesTable
          accountEntries={accountEntries}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
