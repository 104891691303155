import { NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Chip,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { mapApiBeneficiaryToCreateUpdateBeneficiaryFormValues, useBeneficiaryQuery } from 'src/models/beneficiaries';
import { Nullable } from 'src/utils/types';
import { BeneficiaryForm } from '../Beneficiaries/forms/BeneficiaryForm';
import { CreateUpdateBeneficiaryFormValues } from '../Beneficiaries/forms/types-dto';
interface DetailRow {
  name: string;
  value: any;
}

interface BeneficiaryViewProps {
  id: string;
  accountHolderId?: string;
  isGlobal: boolean;
}

export const BeneficiaryView: FC<BeneficiaryViewProps> = ({
  id,
  accountHolderId,
  isGlobal,
}) => {
  const [editBeneficiaryOpened, setEditBeneficiaryOpened] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const { t } = useTranslation();

  const { data: apiBeneficiary } = useBeneficiaryQuery(
    {
      id,
      accountHolderIdForHeader: accountHolderId,
    },
    { refetchOnMountOrArgChange: true },
  );
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const formikRef = useRef(null);
  useTotp(formikRef);


  const [beneficiaryInfo, setBeneficiaryInfo] =
    React.useState<Nullable<CreateUpdateBeneficiaryFormValues>>(null);

  useEffect(() => {
    (async () => {
      if (apiBeneficiary != null) {
        const onboardingInfo = await mapApiBeneficiaryToCreateUpdateBeneficiaryFormValues(apiBeneficiary, accountHolderId);
        setBeneficiaryInfo(onboardingInfo);
      }
    })()

  }, [apiBeneficiary, accountHolderId])


  const beneficiaryInfoData: DetailRow[] = useMemo(
    () =>
      apiBeneficiary
        ? [
          {
            name: 'Id',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiBeneficiary.id}
              </Typography>
            ),
          },
          {
            name: 'First Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiBeneficiary.firstName}
              </Typography>
            ),
          },
          {
            name: 'Last Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiBeneficiary.lastName}
              </Typography>
            ),
          },
          {
            name: 'Company Name',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiBeneficiary.companyName}
              </Typography>
            ),
          },
          {
            name: 'Account Number',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiBeneficiary.accountNumber}
              </Typography>
            ),
          },
          {
            name: 'IBAN',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {apiBeneficiary.iban}
              </Typography>
            ),
          },

          {
            name: 'Enabled',
            value: (
              <Chip
                label={apiBeneficiary.enabled ? 'Yes' : 'No'}
                color={apiBeneficiary.enabled ? 'success' : 'error'}
              />
            ),
          },

          {
            name: 'Created Date Time',
            value: (
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {format(
                  new Date(apiBeneficiary.createdDateTime),
                  'dd/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            ),
          },
        ]
        : [],
    [accountHolderId, apiBeneficiary],
  );

  const breadcrumbs = [
    <Link key="1" color={blueGrey[600]} component={RouterLink} to={'./..'}>
      <Typography variant="overline">BENEFICIARIES MANAGEMENT</Typography>
    </Link>,
    <Link
      key="1"
      color={blueGrey[500]}
      component={RouterLink}
      to={`./../${id}`}
    >
      <Typography variant="overline">{id}</Typography>
    </Link>,
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }),
          ]}
        >
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sx={[
              {
                width: '100%',
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >

            <PermissionGuard permission="UPDATE_BENEFICIARY">
              <Button
                sx={[
                  {
                    whiteSpace: 'nowrap',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '100%',
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 'auto',
                    },
                  }),
                ]}
                variant="contained"
                onClick={() => setEditBeneficiaryOpened(true)}
                disabled={!isUserActive}
              >
                Edit Beneficiary
              </Button>
            </PermissionGuard>


          </Grid>
        </Grid>

        {beneficiaryInfoData && apiBeneficiary ? (
          <>
            <Grid item xs={12} sx={{ mb: '35px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      disableTypography
                      title={
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="h6">Beneficiary info</Typography>
                        </Box>
                      }
                    />
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={2}
                      sx={{ p: '16px' }}
                    >
                      {beneficiaryInfoData.map(({ name, value }) => (
                        <Grid item md={6} sm={12} xs={12} key={name}>
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {name}
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              {value}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>



                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      {apiBeneficiary && (
        <>
          <PermissionGuard permission="UPDATE_BENEFICIARY">
            <Drawer
              anchor="right"
              open={editBeneficiaryOpened == true}
              onClose={() => setEditBeneficiaryOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <BeneficiaryForm
                isGlobal={isGlobal}
                accountHolderId={accountHolderId}
                beneficiary={beneficiaryInfo}
                onClose={() => setEditBeneficiaryOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
        </>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default BeneficiaryView;
