import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import {
  setTransactions,
  TransactionListSort,
  useTransactionsListQuery,
} from 'src/models/transactions';
import { TransactionsTable } from 'src/models/transactions/components/TransactionsTable';
import { SortOrder } from 'src/models/types';
import { useDispatch } from 'src/services/redux/store';
import { usePagination } from '../../hooks/usePagination';
import { TransactionFilterForm } from './forms/TransactionsFilterForm';

export const TransactionList = ({
  accountHolderId,
  isGlobal,
  filter,
}: {
  accountHolderId: string;
  isGlobal: boolean;
  filter: TransactionFilterForm;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: TransactionListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as TransactionListSort, direction: 'desc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  // todo this triggers 2 requests - one with old page number and one with new
  useEffect(() => {
    resetPagination();
  }, [filter]);
  const dispatch = useDispatch();
  const { data } = useTransactionsListQuery(
    {
      ...filter,
      accountHolderId: !isGlobal ? accountHolderId : null,
      accountHolderIdForHeader: accountHolderId,
      page,
      size: limit,
      sort: [`${sort.orderBy},${sort.direction}` as TransactionListSort],
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (data?.content !== undefined) {
      dispatch(setTransactions({ transactions: data }));
    }
  }, [data?.content]);

  const transactions = data?.content || [];
  const totalElements = data?.totalElements || 0;

  return (
    <Card>
      <Scrollbar>
        <TransactionsTable
          transactions={transactions}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
