import { emailInitialValue, userDetailsInitialValues } from 'src/components/forms';
import { UserDetailsFormValues } from "./types";
import { createAccountHolderUserFormValidationSchema } from "./validations";

export const accountHolderUserDetailsInitialValues = (): UserDetailsFormValues => ({
    email: emailInitialValue(),
    ...userDetailsInitialValues(),
    checkEmail: false
});



export const isUserDetailsFormCompleted = (userExists: any) => async (userDetails: UserDetailsFormValues): Promise<boolean> => {
    try {
        await createAccountHolderUserFormValidationSchema(userExists).validate(userDetails);
    } catch (e) {
        return false;
    }
    return true;
}