import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import { SortOrder } from 'src/models/types';
import {
  ApiUser,
  UserListSort,
  useUsersListQuery,
} from 'src/models/users';
import { usePagination } from '../../hooks/usePagination';
import { UserFilterForm } from './types';
import { UsersTable } from './UsersTable';

export const UserList = ({
  accountHolderId,
  filter,
}: {
  accountHolderId:string;
  filter: UserFilterForm;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: UserListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as UserListSort, direction: 'asc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  const [skipFetch, setSkipFetch] = useState<boolean>(true);

  useEffect(() => {
    setSkipFetch(true);
    resetPagination();
    setSkipFetch(false);
  }, [filter]);
  
  const { data } = useUsersListQuery({
    ...filter,
    accountHolderIdForHeader: accountHolderId,
    page,
    size: limit,
    sort: [`${sort.orderBy},${sort.direction}` as UserListSort],
  },
  {
    refetchOnMountOrArgChange:true,
    skip:skipFetch
  });

  const [users, setUsers] = useState<ApiUser[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    setUsers(data?.content || []);
    setTotalElements(data?.totalElements || 0);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <UsersTable
          users={users}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
