import { Delete } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import {
  ApiRestrictionRequest,
  useDeleteRestrictionMutation,
} from 'src/models/restrictions';
import { SortOrder } from 'src/models/types';
import { RestrictionStatusLabel } from '../../RestrictionStatusLabel';

interface CardBinsTable {
  cardBins: ApiRestrictionRequest[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
}

const headCells = [
  { title: 'Range From', id: 'rangeFrom', doSort: true },
  { title: 'Range To', id: 'rangeTo', doSort: true },
  { title: 'Restriction Action', id: 'restrictionAction', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const CardBinsPendingTable: FC<CardBinsTable> = ({
  cardBins,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });

  const [deleteRestrictionRequest] = useDeleteRestrictionMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            restrictionId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              deleteRestrictionRequest({
                restrictionId: values.restrictionId,
                accountHolderIdForHeader: accountHolderId,
              }).unwrap();
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* Action is update and delete only so we remove Actions if update and delete is not possible */}
                  {headCells.map(({ title, id, doSort }) => (
                    <TableCell
                      key={id}
                      sortDirection={
                        sortEnabled && doSort && orderBy === id
                          ? sortDirection
                          : false
                      }
                    >
                      {sortEnabled && doSort ? (
                        <TableSortLabel
                          active={orderBy === id}
                          direction={orderBy === id ? sortDirection : 'asc'}
                          onClick={createSortHandler(id)}
                        >
                          {title}
                        </TableSortLabel>
                      ) : (
                        title
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cardBins.map((cardBin) => {
                  return (
                    <TableRow
                      hover
                      key={cardBin.id}
                    // onClick={() => navigate(`${cardBin.id}`)}
                    >
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {cardBin.rangeFrom}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {cardBin.rangeTo}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <RestrictionStatusLabel
                          status={cardBin.restrictionAction}
                        />
                      </TableCell>

                      <TableCell>
                        <PermissionGuard permission="REQUEST_CARDBIN_RESTRICTION">
                          <Tooltip title="Cancel request">
                            <IconButton
                              disabled={!isUserActive}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    'Are you sure to remove this pending card bin restriction request?',
                                  subTitle: 'This operation cannot be undone',
                                  onConfirm: () => {
                                    setFieldValue('restrictionId', cardBin.id);
                                    formikRef?.current?.submitForm();
                                  },
                                });
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </PermissionGuard>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
