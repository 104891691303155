import { ApiAccountHolderTemplateUpdateRequestBody } from "./types";


export const accountHolderTemplateValuesToFormDataBody = (
    formValues: ApiAccountHolderTemplateUpdateRequestBody,
): FormData => {
    const formData = new FormData();
    /* append input field values to formData */
    for (const value in formValues) {
        if (formValues[value] != null) {
            formData.append(value, formValues[value]);
        }
    }

    return formData;

};