import * as Yup from 'yup';

import { countryCodeAPISchema } from 'src/components/forms';

export const acquiringFormValidationSchema = () =>
    Yup.object({
        accountHolderIdForHeader: Yup.string().required('Client is required'),
        mid: Yup.string().required('Mid is required'),
        transferChannelId: Yup.string().required('Transfer Channel Id is required'),
        requiresThreeD: Yup.bool().required('Requires Three D is required'),
        requiresAvs: Yup.bool().required('Requires AVS is required'),
        allowNonCvv: Yup.bool().required('Allow non CVV is required'),
        transactionDescriptor: Yup.string().required('Transaction type is required'),
        returnUrlParamsEnabled: Yup.bool().required('Return URL Params is required'),
        transactionExpiryEnabled: Yup.bool().required('Transaction Expiry is required'),
        apiKey: Yup.string().required('API Key is required'),
        countryCode: countryCodeAPISchema.required('validations.countryIsRequired'),
    });
