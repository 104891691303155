import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountHolderIdField, accountHolderTypes } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useUsersQuery } from 'src/models/users';

interface AccountHolderDetailsFormPropType {
  accountHolderId: string;
  accountHolderIdOverride?: string;
  onValueChange: (value: string) => void;
}

export const AccountHolderDetailsForm: FC<AccountHolderDetailsFormPropType> = ({
  accountHolderIdOverride,
  accountHolderId,
  onValueChange,
}) => {
  const { t } = useTranslation();

  const users = useUsersQuery(
    { accountHolderIdForHeader: accountHolderId },
    { refetchOnMountOrArgChange: true },
  );
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext();
  return (
    <>
      <Box sx={{ typography: 'subtitle2', mb: '4px' }}>Hierarchy:</Box>

      <Field
        name={`parentId`}
        label='common.parentClient'
        component={AccountHolderIdField}
        disabled={accountHolderIdOverride != undefined}
        accountHolderId={accountHolderId} />

      <FormControl fullWidth margin="normal">
        <InputLabel
          error={Boolean(
            touched['accountHolderType'] && errors['accountHolderType'],
          )}
        >
          Account Type
        </InputLabel>
        <Select
          error={Boolean(
            touched['accountHolderType'] && errors['accountHolderType'],
          )}
          value={values['accountHolderType']}
          label="Account Type"
          onBlur={handleBlur}
          onChange={handleChange}
          name="accountHolderType"
          variant="outlined"
        >
          {accountHolderTypes.map((i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {touched['accountHolderType'] && errors['accountHolderType']}
        </FormHelperText>
      </FormControl>

      {(values['accountHolderType'] == 'BUSINESS' ||
        (values['accountHolderType'] == 'INDIVIDUAL' &&
          ['without_user', 'invite_a_user'].includes(values['mode']))) && (
          <>
            {values['accountHolderType'] == 'BUSINESS' && (
              <Box sx={{ typography: 'subtitle2', mb: '4px' }}>Company info</Box>
            )}
            <FormikTextField name="descriptor" label="Descriptor" />
          </>
        )}

      <Box sx={{ typography: 'subtitle2', mb: '4px' }}>User</Box>
      {users.data?.length > 0 && (
        <FormControl
          error={touched?.['userId'] && errors?.['userId']?.length > 0}
          fullWidth
          margin="normal"
          variant="outlined"
          component="fieldset"
        >
          <InputLabel shrink id="userId_label">
            User
          </InputLabel>
          <Select
            labelId="userId_label"
            label="User"
            name="mode"
            id="userId_select"
            // defaultValue=""
            value={values['mode']}
            displayEmpty
            input={<OutlinedInput notched label="User" />}
            onChange={(e: SelectChangeEvent<unknown>) => {
              if (
                !['create_new_user', 'invite_a_user', 'without_user'].includes(
                  e.target.value as string,
                )
              )
                setFieldValue('userId', e.target.value);
              onValueChange(e.target.value as string);
              return handleChange('mode')(e as React.ChangeEvent);
            }}
            onBlur={handleBlur('mode')}
          >
            <MenuItem key={'create_new_user'} value="create_new_user">
              {t('common.createNewUser')}
            </MenuItem>
            <MenuItem key={'invite_a_user'} value="invite_a_user">
              {t('common.inviteAUser')}
            </MenuItem>
            <MenuItem key={'without_user'} value="without_user">
              {t('common.withoutUser')}
            </MenuItem>
            {users.data?.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.email} ({i.firstName} {i.lastName}) ({i.id})
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {touched['userId'] && errors['userId']}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};
