import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormHelperText,
  IconButton,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useCallback, useRef } from 'react';
import * as Yup from 'yup';

import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import { useRefundPaymentMutation } from 'src/models/payments';
import { Transaction } from 'src/models/transactions';
import { maybeCall } from 'src/utils/common';

interface FormValues {
  amount: string;
  description: string;
}

const initialValues: FormValues = {
  amount: '',
  description: '',
};

const validationSchema = Yup.object().shape({
  amount: Yup.string().required('Amount is required'),
  description: Yup.string().required('Description is required'),
});

interface FormProps {
  onClose?: () => void;
  onError?: () => void;
  onSuccess?: () => void;
  transaction: Transaction;
}
export const RefundForm: FC<FormProps> = ({
  onClose,
  onError,
  onSuccess,
  transaction,
}) => {
  const [refundPayment] = useRefundPaymentMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  const onSubmit = useCallback(
    async (
      values: FormValues,
      { setStatus, setSubmitting }: FormikHelpers<FormValues>,
    ) => {
      try {
        setStatus({ error: '' });

        await refundPayment({
          accountHolderId: transaction.accountHolderId,
          agentInfo: { customerAgent:navigator.userAgent, customerIp:''}, 
          amount: { ...transaction.amount, value: Number(values.amount) },
          deviceInfo: { id: '' },
          originalTransactionId: transaction.id,
          referenceNo: transaction.referenceNo,
          acquiringConfigId: transaction.acquiringData.acquiringConfigId,
          description:values.description
        }).unwrap();

        maybeCall(onSuccess);
      } catch (err) {
        console.error(err);

        setStatus({ error: err.data?.message || err.message });

        maybeCall(onError);
      } finally {
        setSubmitting(false);
      }
    },
    [transaction],
  );

  return (
    <Formik
      innerRef={formikRef}
      initialStatus={{ error: '' }}
      initialValues={{
        ...initialValues,
        amount: `${transaction.amount.value}`,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, status }): JSX.Element => (
        <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label="close"
                onClick={() => onClose()}
                size="medium"
                sx={{ mr: 2 }}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Refund
              </Typography>
            </Box>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Refund
            </LoadingButton>
          </Box>
          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate>
              <FormikTextField label="Amount" name="amount" type="text" />
              <FormikTextField
                label="Description"
                name="description"
                type="text"
              />
              {!isSubmitting && status.error && (
                <Box sx={{ mt: 1 }}>
                  <FormHelperText error>{status.error}</FormHelperText>
                </Box>
              )}
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
