import { colors } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsTotpRequired, setTotpCode, setTotpRequired } from '../../models/totp';
import TotpDialog from './TotpDialog';

const { blueGrey } = colors;

const Totp: FC = () => {

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' , onConfirm: (code:string) => void 0 })
const dispatch = useDispatch();
const isTotpRequired = useSelector(selectIsTotpRequired);

useEffect(() => {
        setConfirmDialog({isOpen:isTotpRequired, title:'2FA', subTitle:'2FA sub', onConfirm:(code:string) => dispatch(setTotpCode({code:code}))})
  }, [isTotpRequired]);

  return (
    <>
                 <TotpDialog
                          confirmDialog={confirmDialog}
                          setConfirmDialog={setConfirmDialog}
                        />
    </>
  );
};

export default Totp;
