import { Delete, DragIndicator, Edit } from '@mui/icons-material';
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { ExpandableTableRow } from 'src/components/ExpandableTableRow';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { useUpdateTransferChannelTemplateMutation } from 'src/models/transferChannelTemplates';
import { TransferChannelTemplateForm } from 'src/models/transferChannelTemplates/components/TransferChannelTemplateForm';
import {
  ApiTransferChannelParameter,
  ApiTransferChannelTemplate,
} from 'src/models/transferChannelTemplates/types';
import { TransferChannelParameterTypeLabel } from './TransferChannelParameterTypeLabel';
const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent =
  (onDragEnd: (result, provided) => void) => (props) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'1'} direction="vertical">
          {(provided) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

interface TransferChannelTemplateTable {
  transferChannelTemplate: ApiTransferChannelTemplate;
  accountHolderId: string;
  // orderBy?: string;
  // onRequestSort?: (property: string) => void;
  // sortDirection?: SortOrder;
}

const headCells = [
  { title: '', id: 'expandable', doSort: false },
  { title: '', id: 'draggable', doSort: false },
  { title: 'Name', id: 'parameterName', doSort: true },
  { title: 'Description', id: 'parameterDescription', doSort: true },
  { title: 'Friendly Name', id: 'friendlyName', doSort: true },
  { title: 'Required', id: 'required', doSort: false },
  { title: 'Order', id: 'order', doSort: true },
  { title: 'Parameter Type', id: 'channelParameterType', doSort: false },
  { title: 'Allowed Values Count', id: 'alowedValues', doSort: false },
  { title: 'Custom Script', id: 'customScript', doSort: false },
  { title: 'Static Value', id: 'staticValue', doSort: false },
  { title: 'Actions', id: 'actions', doSort: false },
];

const reorder = (
  list: ApiTransferChannelParameter[],
  startIndex: number,
  endIndex: number,
): ApiTransferChannelParameter[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

export const TransferChannelTemplatesTable: FC<
  TransferChannelTemplateTable
> = ({ transferChannelTemplate, accountHolderId }) => {
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const [editParameterOpened, setEditParameterOpened] = useState<number | null>(
    null,
  );
  const [parameters, setParameters] = useState<ApiTransferChannelParameter[]>(
    [],
  );
  const [updateTransferChannelTemplate] =
    useUpdateTransferChannelTemplateMutation();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  useEffect(() => {
    setParameters(transferChannelTemplate.parameters);
  }, [transferChannelTemplate]);

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.index == result.destination.index) {
      return;
    }
    const firstOrder =
      transferChannelTemplate.parameters[result.source.index].order;
    const secondOrder =
      transferChannelTemplate.parameters[result.destination.index].order;

    const orderUpdatedParameters = [
      ...parameters.filter(
        (x, i) => ![result.source.index, result.destination.index].includes(i),
      ),
      { ...parameters[result.source.index], order: secondOrder },
      { ...parameters[result.destination.index], order: firstOrder },
    ];

    const updatedParameters = reorder(
      orderUpdatedParameters,
      result.source.index,
      result.destination.index,
    );

    const updatedtransferChannelTemplate = {
      ...transferChannelTemplate,
      parameters: updatedParameters,
    };
    try {
      await updateTransferChannelTemplate({
        accountHolderId,
        ...updatedtransferChannelTemplate,
      }).unwrap();
      toast.success(`Transfer channel parameter order updated successfully.`);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteParameter = async (index) => {
    try {
      const updatedtransferChannelTemplate = {
        ...transferChannelTemplate,
        parameters: transferChannelTemplate.parameters.filter(
          (x, i) => i != index,
        ),
      };
      await updateTransferChannelTemplate({
        accountHolderId,
        ...updatedtransferChannelTemplate,
      }).unwrap();
      toast.success(`Transfer channel parameter deleted successfully.`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headCells.map(({ title, id }) => (
                <TableCell key={id}>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody component={DroppableComponent(onDragEnd)}>
            {parameters.map((parameter, index) => {
              return (
                <ExpandableTableRow
                  hideExpanse={parameter.channelParameterType != 'DROP_DOWN'}
                  component={DraggableComponent(parameter.parameterName, index)}
                  hover
                  key={parameter.parameterName}
                  expandComponent={
                    <TableCell colSpan={5}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Value</TableCell>
                            <TableCell>Description</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {parameter.allowedValues.map(
                            (allowedValue, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{allowedValue.value}</TableCell>
                                  <TableCell>
                                    {allowedValue.description}
                                  </TableCell>
                                </TableRow>
                              );
                            },
                          )}
                        </TableBody>
                      </Table>
                    </TableCell>
                  }
                >
                  <TableCell>
                    <PermissionGuard permission="UPDATE_TRANSFER_CHANNEL_TEMPLATE">
                      <IconButton
                        disabled={!isUserActive}
                        onClick={() => setEditParameterOpened(index)}
                      >
                        <DragIndicator fontSize="small" />
                      </IconButton>
                    </PermissionGuard>
                  </TableCell>
                  <TableCell>{parameter.parameterName}</TableCell>
                  <TableCell>{parameter.parameterDescription}</TableCell>
                  <TableCell>{parameter.friendlyName}</TableCell>
                  <TableCell>
                    <Chip
                      label={parameter.required ? 'Yes' : 'No'}
                      color={parameter.required ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>{parameter.order}</TableCell>
                  <TableCell>
                    <TransferChannelParameterTypeLabel
                      status={parameter.channelParameterType}
                    />
                  </TableCell>
                  <TableCell>
                    {parameter.channelParameterType != 'DROP_DOWN'
                      ? 'N/A'
                      : parameter.allowedValues.length}
                  </TableCell>
                  {/* <TableCell>{JSON.stringify(parameter.allowedValues)}</TableCell> */}
                  <TableCell>
                    {parameter.channelParameterType != 'CUSTOM'
                      ? 'N/A'
                      : parameter.customScript}
                  </TableCell>
                  <TableCell>
                    {parameter.channelParameterType != 'STATIC'
                      ? 'N/A'
                      : parameter.staticValue}
                  </TableCell>
                  <TableCell align="right">
                    <PermissionGuard permission="UPDATE_TRANSFER_CHANNEL_TEMPLATE">
                      <IconButton
                        disabled={!isUserActive}
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              'Are you sure to remove this transfer channel template parameter?',
                            subTitle: 'This operation cannot be undone',
                            onConfirm: () => {
                              deleteParameter(index);
                            },
                          });
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </PermissionGuard>
                    <PermissionGuard permission="UPDATE_TRANSFER_CHANNEL_TEMPLATE">
                      <IconButton
                        disabled={!isUserActive}
                        // variant="contained"
                        onClick={() => setEditParameterOpened(index)}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </PermissionGuard>
                  </TableCell>
                </ExpandableTableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {transferChannelTemplate && (
        <>
          <PermissionGuard permission="UPDATE_TRANSFER_CHANNEL_TEMPLATE">
            <Drawer
              anchor="right"
              open={editParameterOpened != null}
              onClose={() => setEditParameterOpened(null)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <TransferChannelTemplateForm
                transferChannelId={transferChannelTemplate.transferChannelId}
                transferChannelTemplate={transferChannelTemplate}
                index={editParameterOpened}
                accountHolderId={accountHolderId}
                onClose={() => setEditParameterOpened(null)}
              />
            </Drawer>
          </PermissionGuard>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </>
      )}
    </>
  );
};
