export const PWAPromptStyles = {
    "noScroll":{
       "overflow":"hidden"
    },
    "pwaPromptOverlay":{
       "backgroundColor":"rgba(0, 0, 0, 0.8)",
       "left":"0",
       "minHeight":"-webkit-fill-available",
       "opacity":"0",
       "position":"fixed",
       "top":"0",
       "transition":"opacity 0.2s ease-in",
       "width":"100vw",
       "zIndex":"999999"
    },
    "pwaPromptOverlay_visible":{
       "opacity":"1",
       "display":"block"
    },
    "pwaPromptOverlay_hidden":{
       "pointerEvents":"none",
       "touchAction":"none"
    },
    "@media (prefers-color-scheme: dark)":{
       "__expression__":"(prefers-color-scheme: dark)",
       "pwaPromptOverlay_modern":{
          "background":"rgba(0, 0, 0, 0.6)",
          "color":"rgba(235, 235, 245, 0.6)"
       },
       "pwaPrompt_modern":{
          "background":"rgba(120, 120, 128, 0.32)"
       },
       "modern__pwaPromptHeader":{
          "borderColor":"rgba(84, 84, 88, 0.6)"
       },
       "modern__pwaPromptHeader__pwaPromptTitle":{
          "color":"rgba(255, 255, 255, 1)"
       },
       "modern__pwaPromptHeader__pwaPromptCancel":{
          "color":"rgba(9, 132, 255, 1)"
       },
       "modern__pwaPromptBody__pwaPromptDescription":{
          "borderColor":"rgba(84, 84, 88, 0.6)"
       },
       "modern__pwaPromptCopy":{
          "borderColor":"rgba(235, 235, 245, 0.6)",
          "color":"rgba(235, 235, 245, 0.6)"
       },
       "modern__pwaPromptInstruction__pwaPromptHomeIcon":{
          "color":"white",
          "fill":"white"
       },
       "modern__pwaPromptInstruction__pwaPromptShareIcon":{
          "color":"rgba(9, 132, 255, 1)",
          "fill":"rgba(0, 122, 255, 1)"
       }
    },
    "pwaPrompt":{
       "WebkitBackdropFilter":"blur(10px)",
       "backdropFilter":"blur(10px)",
       "backgroundColor":"rgba(250, 250, 250, 0.8)",
       "borderRadius":"10px",
       "bottom":"0",
       "color":"black",
       "filter":"brightness(1.1)",
       "left":"0",
       "margin":"0 8px 10px",
       "overflow":"hidden",
       "position":"fixed",
       "transform":"translateY(calc(100% + 10px))",
       "transition":"transform 0.4s cubic-bezier(0.4, 0.24, 0.3, 1)",
       "width":"calc(100vw - 16px)",
       "zIndex":"999999"
    },
    "pwaPrompt_visible":{
       "transform":"translateY(0)",
       "display":"block"
    },
    "pwaPrompt_hidden":{
       "pointerEvents":"none",
       "touchAction":"none"
    },
    "pwaPrompt_modern":{
       "background":"rgba(250, 250, 250, 0.8)"
    },
    "pwaPromptHeader":{
       "alignItems":"center",
       "borderBottom":"1px solid rgba(0, 0, 0, 0.1)",
       "borderTop":"0px",
       "borderLeft":"0px",
       "borderRight":"0px",
       "borderWidth":"0.5px",
       "display":"flex",
       "flexFlow":"row nowrap",
       "justifyContent":"space-between",
       "padding":"13px 16px"
    },
    "modern__pwaPromptHeader":{
       "borderColor":"rgba(60, 60, 67, 0.29)"
    },
    "pwaPromptHeader__pwaPromptTitle":{
       "color":"#333",
       "fontFamily":"-apple-system, system-ui, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif",
       "fontSize":"18px",
       "fontWeight":"500",
       "lineHeight":"1.125",
       "margin":"0",
       "padding":"0"
    },
    "modern__pwaPromptHeader__pwaPromptTitle":{
       "color":"rgba(0, 0, 0, 1)"
    },
    "pwaPromptHeader__pwaPromptCancel":{
       "color":"#2d7cf6",
       "fontSize":"16px",
       "padding":"0",
       "margin":"0",
       "border":"0",
       "background":"transparent"
    },
    "modern__pwaPromptHeader__pwaPromptCancel":{
       "color":"rgba(0, 122, 255, 1)"
    },
    "pwaPromptBody":{
       "display":"flex",
       "width":"100%"
    },
    "pwaPromptBody__pwaPromptDescription":{
       "borderBottom":"1px solid rgba(0, 0, 0, 0.1)",
       "borderTop":"0px",
       "borderLeft":"0px",
       "borderRight":"0px",
       "borderWidth":"0.5px",
       "color":"inherit",
       "margin":"0 16px",
       "padding":"16px",
       "width":"100%"
    },
    "modern__pwaPromptBody__pwaPromptDescription":{
       "borderColor":"rgba(60, 60, 67, 0.29)"
    },
    "pwaPromptCopy":{
       "color":"#7b7b7a",
       "fontFamily":"-apple-system, system-ui, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif",
       "fontSize":"13px",
       "lineHeight":"17px",
       "margin":"0",
       "padding":"0"
    },
    "pwaPromptCopy_bold":{
       "fontWeight":"600"
    },
    "modern__pwaPromptCopy":{
       "color":"rgba(60, 60, 67, 0.6)"
    },
    "pwaPromptInstruction":{
       "color":"inherit",
       "margin":"0 16px",
       "padding":"16px"
    },
    "pwaPromptInstruction__pwaPromptInstructionStep":{
       "alignItems":"center",
       "display":"flex",
       "flexFlow":"row nowrap",
       "justifyContent":"flex-start",
       "textAlign":"left",
       "marginBottom":"16px"
    },
    "pwaPromptInstruction__pwaPromptInstructionStep_last_of_type":{
       "marginBottom":"0"
    },
    "pwaPromptInstruction__pwaPromptShareIcon":{
       "color":"#2d7cf6",
       "fill":"#2d7cf6"
    },
    "pwaPromptInstruction__pwaPromptHomeIcon":{
       "color":"#2d7cf6"
    },
    "modern__pwaPromptInstruction__pwaPromptHomeIcon":{
       "color":"black",
       "fill":"black"
    },
    "modern__pwaPromptInstruction__pwaPromptShareIcon":{
       "color":"rgba(0, 122, 255, 1)",
       "fill":"rgba(0, 122, 255, 1)"
    }
 };