import * as ibantools from "ibantools";
import * as Yup from 'yup';

import { accountHolderIdValidationSchema } from 'src/components/forms';

export const beneficiaryFormValidationSchema = () =>
    Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        companyName: Yup.string().required('Company name is required'),
        accountNumber: Yup.string().required('Account number is required'),
        iban: Yup.string().required('IBAN is required')
            .test({
                message: () => "Invalid IBAN",
                test: (iban) => {
                    return ibantools.isValidIBAN(iban)
                },
            }),

        accountHolderIdForHeader: accountHolderIdValidationSchema(),
    });