import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';

import { ExpandableTableRow } from 'src/components/ExpandableTableRow';
import { ApiAccountEntry } from 'src/models/accounts';
import { TransactionStatusLabel } from 'src/models/transactions/components/TransactionStatusLabel';
import { SortOrder } from 'src/models/types';
import { AccountStatusLabel } from '../Accounts/AccountStatusLabel';

interface AccountEntryTable {
  entries: ApiAccountEntry[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
}

const headCells = [
  { title: '', id: '', doSort: false },

  // { title: 'Created date', id: 'createdDateTime', doSort: true },
  // { title: 'Updated date', id: 'updatedDateTime', doSort: true },
  { title: 'Date', id: 'updatedDateTime', doSort: true },
  // { title: 'Id', id: 'id', doSort: true },
  // { title: 'Account Id', id: 'friendlyName', doSort: true },
  { title: 'Account Number', id: 'accountType', doSort: false },
  // { title: 'Transaction Id', id: 'transactionId', doSort: false },
  { title: 'Transaction Number', id: 'transactionNumber', doSort: false },
  { title: 'Entry Type', id: 'entryType', doSort: true },
  { title: 'Status', id: 'status', doSort: true },
  // { title: 'Actual Balance Before', id: 'actualBalanceBefore', doSort: false },
  // { title: 'Available Balance Before', id: 'availableBalanceBefore', doSort: false },
  // { title: 'Actual Balance After', id: 'actualBalanceAfter', doSort: false },
  // { title: 'Available Balance After', id: 'availableBalanceAfter', doSort: false },
  { title: 'Amount', id: 'amount', doSort: false },
  // { title: 'Actions', id: 'actions', doSort: false },
];

export const AccountEntriesTable: FC<AccountEntryTable> = ({
  entries,
  orderBy,
  sortDirection,
  onRequestSort,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => {
              return (
                <ExpandableTableRow
                  hideExpanse={false}
                  hover
                  key={entry.id}
                  expandComponent={
                    <TableCell colSpan={5}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ border: 'none' }}>
                              <Typography
                                noWrap
                                variant="body2"
                                color="textSecondary"
                              >
                                Created date
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              <Typography noWrap variant="inherit">
                                {format(
                                  new Date(entry.createdDateTime),
                                  'dd/MM/yyyy HH:mm:ss',
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={{ border: 'none' }}>
                              <Typography
                                noWrap
                                variant="body2"
                                color="textSecondary"
                              >
                                Actual Balance Before
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {entry?.actualBalanceBefore?.displayText || 'N/A'}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell width={'185px'} sx={{ border: 'none' }}>
                              <Typography
                                noWrap
                                variant="body2"
                                color="textSecondary"
                              >
                                Available Balance Before
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {entry?.availableBalanceBefore?.displayText ||
                                'N/A'}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={{ border: 'none' }}>
                              <Typography
                                noWrap
                                variant="body2"
                                color="textSecondary"
                              >
                                Actual Balance After
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {entry?.actualBalanceAfter?.displayText || 'N/A'}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={{ border: 'none' }}>
                              <Typography
                                noWrap
                                variant="body2"
                                color="textSecondary"
                              >
                                Available Balance After
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {entry?.availableBalanceAfter?.displayText ||
                                'N/A'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ border: 'none' }}>
                              <Typography
                                noWrap
                                variant="body2"
                                color="textSecondary"
                              >
                                Transaction Id
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {entry?.transactionId}
                            </TableCell>
                          </TableRow>
                        </TableBody>

                      </Table>
                    </TableCell>
                  }
                // onClick={() => navigate(`/accounts/${entry.id}`)}
                >
                  {/* <TableCell>
                <Typography noWrap variant="inherit">
                {format(
                    new Date(entry.createdDateTime),
                    'dd/MM/yyyy HH:mm:ss',
                  )}
                </Typography>  
                </TableCell> */}

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(entry.updatedDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>{entry.id}</TableCell> */}
                  {/* <TableCell>{entry.accountId}</TableCell> */}
                  <TableCell>{entry.accountNumber}</TableCell>
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {entry.transactionNumber}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <TransactionStatusLabel status={entry.accountEntryType} />
                  </TableCell>
                  <TableCell>
                    <AccountStatusLabel status={entry.status} />
                  </TableCell>
                  {/* <TableCell>{entry.actualBalanceBefore.displayText}</TableCell>
                <TableCell>{entry.availableBalanceBefore.displayText}</TableCell>
                <TableCell>{entry.actualBalanceAfter.displayText}</TableCell>
                <TableCell>{entry.availableBalanceAfter.displayText}</TableCell> */}
                  <TableCell>
                    {entry.amount.currency} {entry.amount?.displayText || 'N/A'}
                  </TableCell>
                  {/* <TableCell align="right">
                  <IconButton
                    component={RouterLink}
                    to={`/entries/${entry.id}`}
                  >
                    <ArrowForward fontSize="small" />
                  </IconButton>
                </TableCell> */}
                </ExpandableTableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
