import { CreateUpdateAcquiringFormValues } from "./types-dto"

export const createUpdateAcquiringFormValuesInitialValue = (accountHolderIdForHeader: string): CreateUpdateAcquiringFormValues => {
    return {
        acquiringConfigId: '',
        mid: '',
        transferChannelId: '',
        requiresThreeD: false,
        requiresAvs: false,
        allowNonCvv: false,
        transactionDescriptor: '',
        returnUrlParamsEnabled: false,
        transactionExpiryEnabled: false,
        apiKey: '',
        countryCode: null,
        accountHolderIdForHeader
    }
}