import * as Yup from 'yup';

import { accountHolderIdValidationSchema } from 'src/components/forms';

export const accountCurrencyFormValidationSchema = () =>
    Yup.object({
        currencyCode: Yup.string().required('Currency code is required'),
        createOnRegistration: Yup.bool().required('Create on registration is required'),
        accountHolderIdForHeader: accountHolderIdValidationSchema()
        // currencyCode: Yup.string().required('validations.currencyCodeIsRequired'),
        // createOnRegistration: Yup.bool().required('validations.createOnRegistrationIsRequired'),
    });

