import { accountTypesInitialValue } from "src/components/forms"
import { CreateUpdateAccountFormValues } from "./types-dto"

export const createAccountFormValuesInitialValue = (accountHolderIdForHeader: string): CreateUpdateAccountFormValues => {
    return {
        id: null,
        friendlyName: '',
        accountType: accountTypesInitialValue(),
        currencyCode: '',
        accountHolderIdForHeader
    }
}