import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  accountHolderStatusList,
  accountHolderTypeList,
  ApiAccountHolderResponse,
  ApiAccountHolderUpdate,
  useUpdateAccountHolderMutation,
} from 'src/models/accountHolders';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { EditAccountHolderDetailsFormValues } from '../types';
import { accountHolderFormValuesToAccountHolderRequestBody } from '../utils';
interface FormProps {
  onClose?: () => void;
  accountHolder?: ApiAccountHolderResponse;
  accountHolderIdForHeader: string;
}
export const EditAccountHolderForm: FC<FormProps> = ({
  onClose,
  accountHolder,
  accountHolderIdForHeader,
}) => {
  const [updateAccountHolder] = useUpdateAccountHolderMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<EditAccountHolderDetailsFormValues>
      innerRef={formikRef}
      initialValues={{
        accountHolderIdForHeader: accountHolderIdForHeader,
        accountHolderId: accountHolder.id,
        senderEmail: accountHolder.senderEmail || '',
        domain: accountHolder.domain || '',
        allowRegistration: accountHolder.allowRegistration || false,
        displayCurrencyCode: accountHolder.displayCurrencyCode || '',
        ...(() => {
          const { accountHolderType, status, descriptor } = accountHolder;
          return {
            accountHolderType,
            accountHolderStatus: status,
            descriptor,
          };
        })(),
      }}
      validationSchema={Yup.object().shape({
        accountHolderStatus: Yup.mixed().oneOf(accountHolderStatusList as any),
        accountHolderType: Yup.mixed().oneOf(accountHolderTypeList as any),
        descriptor: Yup.string()
          .max(255)
          .when('accountHolderType', {
            is: 'BUSINESS',
            then: Yup.string().required('Descriptor is required'),
          }),
        domain: Yup.string().notRequired(),
        displayCurrencyCode: Yup.string().notRequired(),
        senderEmail: Yup.string().email().nullable().notRequired(),
        allowRegistration: Yup.boolean(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting },
      ): Promise<void> => {
        try {
          const updatedAccountHolder =
            accountHolderFormValuesToAccountHolderRequestBody(values);
          const updateAccountHolderResponse = await updateAccountHolder(
            updatedAccountHolder,
          ).unwrap();

          if (updateAccountHolderResponse?.status) {
            toast.success(`Client updated successfully.`);
            onClose();
          }
        } catch (err) {
          // toast.error('An error has occured');
          console.log(err);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleBlur,
        handleChange,
        values,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Edit Client
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <FormControl fullWidth margin="normal">
                <InputLabel
                  error={Boolean(
                    touched.accountHolderType && errors.accountHolderType,
                  )}
                >
                  Client Type
                </InputLabel>
                <Select
                  error={Boolean(
                    touched.accountHolderType && errors.accountHolderType,
                  )}
                  value={values.accountHolderType}
                  label="Client Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="accountHolderType"
                  variant="outlined"
                  disabled={true}
                >
                  {accountHolderTypeList.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {touched.accountHolderType && errors.accountHolderType}
                </FormHelperText>
              </FormControl>

              {(values.accountHolderType == 'BUSINESS' ||
                (values.accountHolderType == 'INDIVIDUAL' &&
                  values.descriptor.length > 0)) && (
                  <>
                    {values.accountHolderType == 'BUSINESS' && (
                      <Box sx={{ typography: 'subtitle2', mb: '4px' }}>
                        Company info
                      </Box>
                    )}
                    <FormikTextField name="descriptor" label="Descriptor" margin='normal' />
                  </>
                )}

              <FormControl fullWidth margin="normal">
                <InputLabel
                  error={Boolean(
                    touched.accountHolderStatus && errors.accountHolderStatus,
                  )}
                >
                  Client Status
                </InputLabel>
                <Select
                  error={Boolean(
                    touched.accountHolderStatus && errors.accountHolderStatus,
                  )}
                  value={values.accountHolderStatus}
                  label="Client Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="accountHolderStatus"
                  variant="outlined"
                >
                  {accountHolderStatusList.map((i) => (
                    <MenuItem disabled={i == 'KYC_REQUIRED'} key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {touched.accountHolderStatus && errors.accountHolderStatus}
                </FormHelperText>
              </FormControl>

              <PermissionGuard permission="ADMIN">
                <FormikTextField label="Domain" name="domain" margin='normal' />
              </PermissionGuard>
              <PermissionGuard permission="ADMIN">
                <FormikTextField
                  name="senderEmail"
                  label="Sender Email"
                  type="email"
                  autoComplete="off"
                  margin='normal'
                />
              </PermissionGuard>

              <PermissionGuard permission="ADMIN">
                <FormikTextField label="Display Currency Code" name="displayCurrencyCode" margin='normal' />
              </PermissionGuard>

              <PermissionGuard permission="ADMIN">
                <FormControlLabel
                  control={
                    <Field
                      label="Allow Registration"
                      name="allowRegistration"
                      component={Switch}
                      onChange={(ev, checked) => {
                        setFieldValue('allowRegistration', checked);
                      }}
                      checked={values.allowRegistration ? true : false}
                    />
                  }
                  label="Allow Registration"
                />
              </PermissionGuard>

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  // loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
