import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useTotp } from 'src/hooks/useTotp';
import { useRefreshTokenMutation } from 'src/models/auth';
import {
  ApiKycVerification,
  useKycVerificationPendingQuery,
  useLazyKycVerificationImageQuery,
  useSubmitUserKycVerificationMutation,
  useUpdateUserAddressesKycVerificationMutation,
  useUpdateUserDetailsKycVerificationMutation,
  useUpdateUserIdentificationsKycVerificationMutation,
  useUpdateUserSelfiesKycVerificationMutation,
} from 'src/models/kycVerifications';
import { AddressForm } from './AddressForm';
import {
  isUserAddressesFormCompleted,
  updateUserAddressesFormValidationSchema,
  userAddressesFormInitialValues,
  userAddressesFormValuesToUserDetailsRequestBody,
} from './AddressForm/utils';
import { IdentityForm } from './IdentityForm';
import {
  isUserIdentificationsFormCompleted,
  updateUserIdentificationsFormValidationSchema,
  userIdentificationsFormInitialValues,
  userIdentificationsFormValuesToUserDetailsRequestBody,
} from './IdentityForm/utils';
import { SelfieForm } from './SelfieForm';
import {
  isUserSelfieFormCompleted,
  updateUserSelfiesFormValidationSchema,
  userSelfiesFormInitialValues,
  userSelfiesFormValuesToUserDetailsRequestBody,
} from './SelfieForm/utils';
import { SubmitForm } from './SubmitForm';
import {
  isUserSubmitFormCompleted,
  userSubmitFormInitialValues,
  userSubmitFormValidationSchema,
} from './SubmitForm/utils';
import { UserDetailsForm } from './UserDetailsForm';
import {
  isUserDetailsFormCompleted,
  updateUserDetailsFormValidationSchema,
  userDetailsFormInitialValues,
  userDetailsFormValuesToUserDetailsRequestBody,
} from './UserDetailsForm/utils';

interface WizardProps {
  onClose?: () => void;
  hideClose: boolean;
  accountHolderIdForHeader: string;
}

export const KYCWizard: FC<WizardProps> = ({
  accountHolderIdForHeader,
  hideClose,
  onClose,
  ...props
}) => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  useTotp(formikRef);
  const [userDetails, setUserDetails] =
    React.useState<ApiKycVerification>(null);
  const [updateUserDetailsKycVerification] =
    useUpdateUserDetailsKycVerificationMutation();
  const [updateUserIdentificationsKycVerification] =
    useUpdateUserIdentificationsKycVerificationMutation();
  const [updateUserAddressesKycVerification] =
    useUpdateUserAddressesKycVerificationMutation();
  const [updateUserSelfiesKycVerification] =
    useUpdateUserSelfiesKycVerificationMutation();
  const [submitUserKycVerification] = useSubmitUserKycVerificationMutation();
  const [getImage] = useLazyKycVerificationImageQuery();

  const userKycDetails = useKycVerificationPendingQuery(
    { accountHolderIdForHeader },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    setUserDetails(userKycDetails.data);
    const fetchData = async () => {
      const userDetailsWithImages = await getFiles(userKycDetails.data);
      setUserDetails(userDetailsWithImages);
    };
    fetchData().catch(console.error);
  }, [userKycDetails.data]);

  const getFiles = async (
    userKycDetails: ApiKycVerification,
  ): Promise<ApiKycVerification> => {
    let identityAttachmentFileUrl = null;
    if (userKycDetails?.identityAttachmentFileId) {
      identityAttachmentFileUrl = await getImage({
        kycVerificationId: userKycDetails.id,
        accountHolderIdForHeader,
        fileId: userKycDetails.identityAttachmentFileId,
      }).unwrap();
    }
    let additionalIdentityAttachmentFileUrl = null;
    if (userKycDetails?.additionalIdentityAttachmentFileId) {
      additionalIdentityAttachmentFileUrl = await getImage({
        kycVerificationId: userKycDetails.id,
        accountHolderIdForHeader,
        fileId: userKycDetails.additionalIdentityAttachmentFileId,
      }).unwrap();
    }
    let addressAttachmentFileUrl = null;
    if (userKycDetails?.addressAttachmentFileId) {
      addressAttachmentFileUrl = await getImage({
        kycVerificationId: userKycDetails.id,
        accountHolderIdForHeader,
        fileId: userKycDetails.addressAttachmentFileId,
      }).unwrap();
    }
    let faceAttachmentFileUrl = null;
    if (userKycDetails?.faceAttachmentFileId) {
      faceAttachmentFileUrl = await getImage({
        kycVerificationId: userKycDetails.id,
        accountHolderIdForHeader,
        fileId: userKycDetails.faceAttachmentFileId,
      }).unwrap();
    }
    return {
      ...userKycDetails,
      identityAttachmentFileUrl,
      additionalIdentityAttachmentFileUrl,
      addressAttachmentFileUrl,
      faceAttachmentFileUrl,
    };
  };
  const [step, setStep] = React.useState<number>(0);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [refreshToken] = useRefreshTokenMutation();

  const steps = React.useMemo(
    () => [
      {
        name: 'User Details',
        initialValues: userDetailsFormInitialValues(
          accountHolderIdForHeader,
          userDetails,
        ),
        validationSchema: updateUserDetailsFormValidationSchema,
        component: <UserDetailsForm />,
        submit: async (values: any) => {
          const body = userDetailsFormValuesToUserDetailsRequestBody(values);
          return await updateUserDetailsKycVerification(body).unwrap();
        },
        completed: isUserDetailsFormCompleted(userDetails),
      },
      {
        name: 'Identity Uploads',
        initialValues: userIdentificationsFormInitialValues(
          accountHolderIdForHeader,
          userDetails,
        ),
        validationSchema: updateUserIdentificationsFormValidationSchema,
        component: <IdentityForm />,
        submit: async (values: any) => {
          if (values.identityAttachment) {
            const body =
              userIdentificationsFormValuesToUserDetailsRequestBody(values);
            return await updateUserIdentificationsKycVerification(
              body,
            ).unwrap();
          } else {
            return userDetails;
          }
        },
        completed: isUserIdentificationsFormCompleted(userDetails),
      },
      ...(userDetails?.addressVerificationRequired
        ? [
            {
              name: 'Proof of address',
              initialValues: userAddressesFormInitialValues(
                accountHolderIdForHeader,
                userDetails,
              ),
              validationSchema: updateUserAddressesFormValidationSchema,
              component: <AddressForm />,
              submit: async (values: any) => {
                if (values.addressAttachment) {
                  const body =
                    userAddressesFormValuesToUserDetailsRequestBody(values);
                  return await updateUserAddressesKycVerification(
                    body,
                  ).unwrap();
                } else {
                  return userDetails;
                }
              },
              completed: isUserAddressesFormCompleted(userDetails),
            },
          ]
        : []),
      ...(userDetails?.selfieVerificationRequired
        ? [
            {
              name: 'Confirm your identity',
              initialValues: userSelfiesFormInitialValues(
                accountHolderIdForHeader,
                userDetails,
              ),
              validationSchema: updateUserSelfiesFormValidationSchema,
              component: <SelfieForm />,
              submit: async (values: any) => {
                if (values.faceAttachment) {
                  const body =
                    userSelfiesFormValuesToUserDetailsRequestBody(values);
                  return await updateUserSelfiesKycVerification(body).unwrap();
                } else {
                  return userDetails;
                }
              },
              completed: isUserSelfieFormCompleted(userDetails),
            },
          ]
        : []),
      {
        name: 'Terms and conditions',
        initialValues: userSubmitFormInitialValues(
          accountHolderIdForHeader,
          userDetails,
        ),
        validationSchema: userSubmitFormValidationSchema,
        component: <SubmitForm />,
        submit: async (values: any) => {
          const result = await submitUserKycVerification({
            accountHolderIdForHeader: values.accountHolderIdForHeader,
            kycVerificationId: values.kycVerificationId,
          }).unwrap();
          // await refreshToken().unwrap();
          return result;
        },
        completed: isUserSubmitFormCompleted(userDetails),
      },
    ],
    [userDetails, step],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 38px 24px 32px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!hideClose && (
            <IconButton
              sx={{ mr: 2 }}
              aria-label="close"
              size="medium"
              onClick={() => onClose()}
            >
              <Close color="inherit" fontSize="inherit" />
            </IconButton>
          )}
          <Typography color={'textPrimary'} variant="h5">
            Account Verification
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box sx={{ padding: '32px' }}>
        <Stepper nonLinear activeStep={step} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.name} completed={steps[index].completed}>
              <StepButton onClick={() => setStep(index)}>
                {step.name}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Formik
          key={step}
          enableReinitialize
          innerRef={formikRef}
          initialValues={steps[step].initialValues}
          validationSchema={steps[step].validationSchema}
          onSubmit={async (values, { resetForm }): Promise<void> => {
            setSubmitting(true);
            if (step < steps.length) {
              try {
                const result = await steps[step].submit(values);
                await refreshToken().unwrap();
                const userDetailsWithImages = await getFiles(result);
                setUserDetails(userDetailsWithImages);
                if (step < steps.length - 1) {
                  setStep(step + 1);
                } else {
                  toast.success(`KYC verification submitted successfully.`);
                  if (hideClose) {
                    navigate('/', { replace: true });
                  } else {
                    onClose();
                  }
                }
              } catch (err) {
                if (err?.data.errorCode == 'KYC_IDENTITY_DOCUMENT_MISSING') {
                  toast.error(`Identity document missing.`);
                  setStep(1);
                }
                if (err?.data.errorCode == 'KYC_ADDRESS_DOCUMENT_MISSING') {
                  toast.error(`Address document missing`);
                  setStep(2);
                }
                if (err?.data.errorCode == 'KYC_FACE_DOCUMENT_MISSING') {
                  toast.error(`Face documenty missing`);
                  setStep(3);
                }
                if (err?.data.errorCode == 'KYC_DOC_UPLOAD_FAILED') {
                  toast.error(`Document upload failed`);
                }
                if (err?.data.errorCode == 'KYC_VERIFICATION_NOT_FOUND') {
                  toast.error(`KYC verification not found`);
                  setStep(3);
                }

                console.log(err);
              }
            } else {
              resetForm(); // resets touched/ errors etc
            }
            if (step < steps.length - 1 || !hideClose) {
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit }): JSX.Element => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              {!isSubmitting &&
                steps[step].initialValues &&
                steps[step].component}
              {(isSubmitting || !userDetails) && (
                <Box
                  sx={{
                    typography: 'body1',
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '10px',
                    fontSize: '1.5rem',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}

              {!isSubmitting && (
                <Box sx={{ mt: '24px' }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {step == steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};
