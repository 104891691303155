import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { Field, Formik } from 'formik';
import { FC } from 'react';

import { FormikTextField } from 'src/components/forms/TextField';
import { FeeConfigListFilter } from 'src/models/assignedFees';
import { feeContributorList, feeTransactionContextList, useFeeConfigurationsTransactionTypesQuery } from 'src/models/configurations';

interface FormProps {
  onFilterApply: (values: FeeConfigListFilter) => void;
  accountHolderId: string;
}
export const AssignedFeesFilterForm: FC<FormProps> = ({ onFilterApply, accountHolderId }) => {

  const { data: transactionTypeList, isFetching: transactionTypeListLoading } =
    useFeeConfigurationsTransactionTypesQuery(
      {
        accountHolderIdForHeader: accountHolderId,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );


  const initialValues: FeeConfigListFilter = {
    id: '',
    transactionType: [],
    feeContributor: [],
    feeAccountNumber: '',
    feeAccountId: '',
    accountHolderId: '',
    enabled: true,
    transactionContext: null,
    contributorAccountNumber: '',
    description: '',
    assignable: false,
    relatedFeeId: '',
  };

  const onSubmit = (values: FeeConfigListFilter) => {
    onFilterApply(values);
    return Promise.resolve();
  };
  return (
    <Formik
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        status,
        resetForm,
        values,
        setFieldValue,
      }): JSX.Element => (
        <>
          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField label="Id" name="id" type="text" />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <>
                  {transactionTypeListLoading && <CircularProgress />}
                  {!transactionTypeListLoading && (
                    <FormControl fullWidth >
                      <InputLabel id="select-fee-transaction-type-label">
                        Transaction Type
                      </InputLabel>
                      <Select
                        multiple
                        fullWidth
                        labelId="select-fee-transaction-type-label"
                        label="Transaction Type"
                        id="select-fee-transaction-type"
                        value={values.transactionType}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        onChange={(e) => {
                          setFieldValue('transactionType', e.target.value);
                        }}
                      >
                        {transactionTypeList.map((transactionType) => (
                          <MenuItem
                            key={transactionType}
                            value={transactionType}
                          >
                            {transactionType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth >
                  <InputLabel id="select-fee-contributor-label">
                    Fee Contributor
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    labelId="select-fee-contributor-label"
                    label="Fee Contributor"
                    id="select-fee-contributor"
                    value={values.feeContributor}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('feeContributor', e.target.value);
                    }}
                  >
                    {feeContributorList.map((contributor) => (
                      <MenuItem key={contributor} value={contributor}>
                        {contributor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth >
                  <InputLabel id="select-fee-transaction-context-label">
                    Fee Transaction Context
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="select-fee-transaction-context-label"
                    label="Fee Transaction Context"
                    id="select-fee-transaction-context"
                    value={values.transactionContext}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={selected} label={selected} />
                      </Box>
                    )}
                    onChange={(e) => {
                      setFieldValue('transactionContext', e.target.value);
                    }}
                  >
                    {feeTransactionContextList.map((contributor) => (
                      <MenuItem key={contributor} value={contributor}>
                        {contributor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>



              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Fee Account Number"
                  name="feeAccountNumber"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Fee Account Id"
                  name="feeAccountId"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Client Id"
                  name="accountHolderId"
                  type="text"
                // disabled={!isGlobal}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Contributor Account Number"
                  name="contributorAccountNumber"
                  type="text"
                // disabled={!isGlobal}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                <FormikTextField
                  label="Description"
                  name="description"
                  type="text"
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={<Field name="enabled" component={Switch} />}
                  label="Enabled"
                  name="enabled"
                  checked={values.enabled}
                  onChange={(ev, checked) => {
                    setFieldValue('enabled', checked);
                  }}
                />
              </Grid>
            </Grid>
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
            {!isSubmitting && status.error && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{status.error}</FormHelperText>
              </Box>
            )}
          </form>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'right',
              padding: '24px',
            }}
          >
            <Button
              color="error"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
              variant="contained"
              sx={{ marginRight: '20px' }}
            >
              Clear X
            </Button>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Box>
        </>
      )}
    </Formik>
  );
};
