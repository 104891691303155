import * as Yup from 'yup';

import { ApiKycIdentificationsRequest, ApiKycVerification, identityAttachmentTypeList } from 'src/models/kycVerifications';
import { localConfig } from 'src/services/config';
import { ApiKycIdentificationsFormValues } from './types';

export const userIdentificationsFormInitialValues = (accountHolderId: string, kycVerification: ApiKycVerification): ApiKycIdentificationsFormValues => {
    return {
        accountHolderIdForHeader: accountHolderId,
        kycVerificationId: kycVerification?.id,
        identityAttachmentType: kycVerification?.identityAttachmentType || '',
        identityAttachment: null,
        additionalIdentityAttachmentType: kycVerification?.additionalIdentityAttachmentType || '',
        additionalIdentityAttachment: null,

        identityAttachmentFileId: kycVerification?.identityAttachmentFileId,
        identityAttachmentFileName: kycVerification?.identityAttachmentFileName,
        identityAttachmentFileUrl: kycVerification?.identityAttachmentFileUrl,

        additionalIdentityAttachmentFileId: kycVerification?.additionalIdentityAttachmentFileId,
        additionalIdentityAttachmentFileName: kycVerification?.additionalIdentityAttachmentFileName,
        additionalIdentityAttachmentFileUrl: kycVerification?.additionalIdentityAttachmentFileUrl,
    }
};

export const userIdentificationsFormValuesToUserDetailsRequestBody = (
    formValues: ApiKycIdentificationsFormValues,
): ApiKycIdentificationsRequest => ({
    accountHolderIdForHeader: formValues.accountHolderIdForHeader,
    kycVerificationId: formValues.kycVerificationId,
    identityAttachmentType: formValues.identityAttachmentType,
    identityAttachment: formValues.identityAttachment,
    // additionalIdentityAttachmentType: formValues.additionalIdentityAttachmentType,
    additionalIdentityAttachmentType: ['ID_CARD', 'DRIVING_LICENSE'].includes(formValues.identityAttachmentType) ? formValues.identityAttachmentType : null,
    additionalIdentityAttachment: formValues.additionalIdentityAttachment
});

export const updateUserIdentificationsFormValidationSchema =
    // Yup.object({
    Yup.object().shape({
        identityAttachment: Yup
            .mixed()
            .when(['identityAttachmentFileId', 'identityAttachmentFileName'], {
                is: (identityAttachmentFileId, identityAttachmentFileName) => identityAttachmentFileId != null && identityAttachmentFileName != null,
                then: Yup.mixed().notRequired(),
                otherwise: Yup
                    .mixed()
                    .required("Identity file is required")
                    .test("fileSize", "The file is too large", (value) => {
                        return value && value.size <= localConfig.UPLOAD_MAX_FILE_SIZE;
                    })
                    .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf", (value) => {
                        return value && (
                            value.type === "image/jpeg" ||
                            value.type === "image/bmp" ||
                            value.type === "image/png" ||
                            value.type === "application/pdf"
                        );
                    }),
            }),
        identityAttachmentType: Yup.mixed().required("Identity attachment type is required").oneOf(identityAttachmentTypeList as any),
        identityAttachmentFileId: Yup
            .string().when('identityAttachment', {
                is: (addressAttachment) => addressAttachment == null,
                then: Yup.string().required('Field is required'),
                otherwise: Yup.string().nullable(),
            }),
        identityAttachmentFileName: Yup
            .string().when('identityAttachment', {
                is: (addressAttachment) => addressAttachment == null,
                then: Yup.string().required('Field is required'),
                otherwise: Yup.string().nullable(),
            }),
        additionalIdentityAttachment: Yup
            .mixed()
            .when(['additionalIdentityAttachmentFileId', 'additionalIdentityAttachmentFileName'], {
                is: (additionalIdentityAttachmentFileId, additionalIdentityAttachmentFileName) => additionalIdentityAttachmentFileId != null && additionalIdentityAttachmentFileName != null,
                then: Yup.mixed().notRequired(),
                otherwise: Yup
                    .mixed().when('identityAttachmentType', {
                        is: (identityAttachmentType) => !['ID_CARD', 'DRIVING_LICENSE'].includes(identityAttachmentType),
                        then: Yup.mixed().notRequired(),
                        otherwise: Yup
                            .mixed()
                            .required(`Picture of back of the card is required`)
                            .test("fileSize", "The file is too large", (value) => {
                                return value && value.size <= localConfig.UPLOAD_MAX_FILE_SIZE;
                            })
                            .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf", (value) => {
                                return value && (
                                    value.type === "image/jpeg" ||
                                    value.type === "image/bmp" ||
                                    value.type === "image/png" ||
                                    value.type === "application/pdf"
                                );
                            }),

                    }),

            }),
    }, [['identityAttachment', 'identityAttachmentFileId'], ['identityAttachment', 'identityAttachmentFileName'], ['identityAttachmentFileId', 'identityAttachmentFileName']]);

export const isUserIdentificationsFormCompleted = (kycVerification: ApiKycVerification): boolean => {
    try {
        updateUserIdentificationsFormValidationSchema.validateSync(kycVerification);
    } catch (e) {
        return false;
    }
    return true;
}