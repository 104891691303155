import * as Yup from 'yup';

import { accountHolderIdValidationSchema, apiKeyTypeValidationSchema, permissionGroupsValidationSchema } from 'src/components/forms';

export const apiKeyFormValidationSchema = () =>
    Yup.object().shape({
        description: Yup.string().required('Description is required'),
        apiKeyType: apiKeyTypeValidationSchema(),
        permissionGroupIds: permissionGroupsValidationSchema(),
        accountHolderIdForHeader: accountHolderIdValidationSchema(),
    })

