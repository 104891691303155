import { apiKeyTypesInitialValue } from "src/components/forms"
import { CreateApiKeyFormValues } from "./types-dto"

export const createApiKeyFormValuesInitialValue = (accountHolderIdForHeader: string): CreateApiKeyFormValues => {
    return {
        description: '',
        apiKeyType: apiKeyTypesInitialValue(),
        permissionGroupIds: [],
        accountHolderIdForHeader
    }
}