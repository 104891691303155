import PromptApple from './apple';
import { PwaUpdate } from './update/PwaUpdate';

export const Pwa: React.FC = () => {
  return (
    <>
      {/* <PwaUpdate /> */}
      <PromptApple />
    </>
  );
};
