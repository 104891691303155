// import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  //   IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';

// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ApiAccountEntry } from 'src/models/transactions';
import { TransactionStatusLabel } from 'src/models/transactions/components/TransactionStatusLabel';
import { SortOrder } from 'src/models/types';
import { AccountStatusLabel } from '../Accounts/AccountStatusLabel';

interface AccountEntryTable {
  accountEntries: ApiAccountEntry[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
}

const headCells = [
  // { title: 'ID', id: 'id', doSort: true },
  // { title: 'Created date', id: 'createdDateTime', doSort: true },
  // { title: 'Updated date', id: 'updatedDateTime', doSort: true },
  { title: 'Account No', id: 'accountNumber', doSort: false },
  { title: 'Account Entry Type', id: 'accountEntryType', doSort: false },
  { title: 'Amount', id: 'amount', doSort: false },
  // { title: 'Status', id: 'status', doSort: true },
  // { title: 'Account Id', id: 'accountId', doSort: false },
  // { title: 'Transaction Id', id: 'transactionId', doSort: false },
  // { title: 'Actual Balance Before', id: 'actualBalanceBefore', doSort: false },
  // { title: 'Actual Balance After', id: 'actualBalanceAfter', doSort: false },
  // { title: 'Available Balance Before', id: 'availableBalanceBefore', doSort: false },
  // { title: 'Available Balance After', id: 'availableBalanceAfter', doSort: false },
  //   { title: 'Actions', id: 'actions', doSort: false },
];

export const AccountEntriesTable: FC<AccountEntryTable> = ({
  accountEntries,
  orderBy,
  sortDirection,
  onRequestSort,
}) => {
  //   const navigate = useNavigate();
  const sortEnabled = orderBy && sortDirection && onRequestSort;

  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <Box sx={{ minWidth: 700 }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {headCells.map(({ title, id, doSort }) => (
              <TableCell
                key={id}
                sortDirection={
                  sortEnabled && doSort && orderBy === id
                    ? sortDirection
                    : false
                }
              >
                {sortEnabled && doSort ? (
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? sortDirection : 'asc'}
                    onClick={createSortHandler(id)}
                  >
                    {title}
                  </TableSortLabel>
                ) : (
                  title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {accountEntries?.map((accountEntry) => {
            return (
              <TableRow hover key={accountEntry.id}>
                {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {accountEntry.id}
                  </Typography>
                </TableCell> */}
                {/* <TableCell>
                <Typography noWrap variant="inherit">
                {format(
                    new Date(accountEntry.createdDateTime),
                    'dd/MM/yyyy HH:mm:ss',
                  )}
                </Typography>
                </TableCell> */}
                {/* <TableCell>
                <Typography noWrap variant="inherit">
                {format(
                    new Date(accountEntry.updatedDateTime),
                    'dd/MM/yyyy HH:mm:ss',
                  )}
                </Typography>
                </TableCell> */}
                <TableCell>{accountEntry.accountNumber}</TableCell>
                <TableCell>
                  <TransactionStatusLabel
                    status={accountEntry.accountEntryType}
                  />
                </TableCell>
                <TableCell>
                  {accountEntry.amount.displayCurrencyCode}{' '}
                  {accountEntry.amount.displayText}
                </TableCell>

                {/* <TableCell>
                  <AccountStatusLabel
                    status={accountEntry.status}
                  />
                </TableCell> */}

                {/* <TableCell>{accountEntry.accountId}</TableCell> */}
                {/* <TableCell>{accountEntry.transactionId}</TableCell> */}
                {/* <TableCell>{accountEntry.actualBalanceBefore.displayText}</TableCell> */}
                {/* <TableCell>{accountEntry.actualBalanceAfter.displayText}</TableCell> */}
                {/* <TableCell>{accountEntry.availableBalanceBefore.displayText}</TableCell> */}
                {/* <TableCell>{accountEntry.availableBalanceAfter.displayText}</TableCell>  */}
                {/* <TableCell align="right">
                  <IconButton
                    component={RouterLink}
                    to={`/transactions/${transaction.id}`}
                  >
                    <ArrowForward fontSize="small" />
                  </IconButton>
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
