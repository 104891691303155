import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { Loadable } from 'src/components/Loadable';
import LoadingScreen from '../Loading';
import IGamingLayout from './Layout/IGamingLayout';
const LazyLoadableScreen = (screen: () => any) =>
  Loadable(lazy(screen), <LoadingScreen />);

const LoginScreen = LazyLoadableScreen(
  () => import('./Login'),
);

const TransactionsScreen = LazyLoadableScreen(
  () => import('./Transactions'),
);

const WalletScreen = LazyLoadableScreen(
  () => import('./Wallet'),
);

const DepositScreen = LazyLoadableScreen(
  () => import('./Wallet/Deposit'),
);

const VoucherScreen = LazyLoadableScreen(
  () => import('./Wallet/Voucher'),
);


export const IGamingDemoRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <IGamingLayout />
      }
    >
      <Route path='/wallet' element={<WalletScreen />} />
      <Route path='/wallet/deposit' element={<DepositScreen />} />
      <Route path='/wallet/voucher' element={<VoucherScreen />} />
      <Route path='/transactions' element={<TransactionsScreen />} />
      <Route
        path=""
        element={<Navigate to="login" replace />} />
      <Route path="*" element={<Navigate to="login" replace />} />
    </Route>
    <Route path='/login' element={<LoginScreen />} />
    <Route path="*" element={<Navigate to="login" replace />} />
  </Routes>
);

