import { format } from 'date-fns';
import * as Yup from 'yup';

import {
  ApiTransferChannelTemplate, transferChannelTemplateParameterTypeList, UpdateTransferChannelTemplateFormValues,
} from '.';

export const transferChannelTemplateFormValidationSchema =
  Yup.object({
    parameterName: Yup.string().required('Parameter name is required'),
    parameterDescription: Yup.string().required('Parameter description is required'),
    friendlyName: Yup.string().required('Friendly name is required'),
    channelParameterType: Yup.mixed().oneOf(transferChannelTemplateParameterTypeList as any),
    allowedValues: Yup.array()
      .when('channelParameterType', {
        is: 'DROP_DOWN',
        then: Yup.array().min(1, 'Allowed values must have at least 1 item').of(
          Yup.object().shape({
            value: Yup.string().required("Value is required"),
            description: Yup.string()
              .required("Description is required")
          })),
        otherwise: Yup.array().max(0)
      }),
    customScript: Yup.string()
      .when('channelParameterType', {
        is: 'CUSTOM',
        then: Yup.string().required('Custom script is required'),
        otherwise: Yup.string().notRequired()
      }),
    staticValue: Yup.string()
      .when('channelParameterType', {
        is: 'STATIC',
        then: Yup.string().required('Static value is required'),
        otherwise: Yup.string().nullable().notRequired()
      }),
  });

export const transferChannelTemplateToTransferChannelTemplateFormValues = (
  transferChannelTemplate: ApiTransferChannelTemplate,
): UpdateTransferChannelTemplateFormValues => ({
  id: transferChannelTemplate.id,
  transferChannelId: transferChannelTemplate.transferChannelId,
  parameters: transferChannelTemplate.parameters,
  accountHolderId: null
});