import { Card, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import {
  AccountHolderListSort,
  ApiAccountHolderResponse,
  useSubAccountHoldersQuery
} from 'src/models/accountHolders';
import { SortOrder } from 'src/models/types';
import { usePagination } from '../../../hooks/usePagination';
import { AccountHolderFilterForm } from '../forms/AccountHolderFilterForm';
import { SubAccountHoldersTable } from './SubAccountHoldersTable';

export const SubAccountHolderList = ({
  filter,
  accountHolderId
}: {
  filter: AccountHolderFilterForm;
  accountHolderId: string;
}) => {
  const { page, limit, handlePageChange, handleLimitChange, resetPagination } =
    usePagination();
  const [sort, setSort] = useState<{
    orderBy: AccountHolderListSort;
    direction: SortOrder;
  }>({ orderBy: 'createdDateTime' as AccountHolderListSort, direction: 'desc' });

  const onRequestSort = (sortProperty) => {
    if (sort.orderBy === sortProperty) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        orderBy: sortProperty,
        direction: 'asc',
      });
    }
  };

  const [skipFetch, setSkipFetch] = useState<boolean>(true);

  useEffect(() => {
    setSkipFetch(true);
    resetPagination();
    setSkipFetch(false);
  }, [filter]);

  const { data } = useSubAccountHoldersQuery({
    ...filter,
    id: accountHolderId,
    accountHolderIdForHeader: accountHolderId,
    page,
    size: limit,
    sort: [`${sort.orderBy},${sort.direction}` as AccountHolderListSort],
  },
    {
      refetchOnMountOrArgChange: true,
      skip: skipFetch
    });

  const [accountHolders, setAccountHolders] = useState<ApiAccountHolderResponse[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    setAccountHolders(data?.content || []);
    setTotalElements(data?.totalElements || 0);
  }, [data])

  return (
    <Card>
      <Scrollbar>
        <SubAccountHoldersTable
          accountHolderId={accountHolderId}
          accountHolders={accountHolders}
          orderBy={sort.orderBy}
          sortDirection={sort.direction}
          onRequestSort={onRequestSort}
        />
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalElements}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 15, 25]}
      />
    </Card>
  );
};
