import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { fliwpLogo, goMagicPayLogo, merchantLogo } from './logos';

export const LogoText = () => {

    window.location.hostname


    const [logo, setLogo] =
        useState<string>(merchantLogo);


    useEffect(() => {
        // if (window.location.hostname == 'voucherdemo.fliwp.com') {
        //     setLogo(fliwpLogo);
        // }
    }, []);

    return (
        <Box
            sx={{ maxWidth: 225, height: 'auto' }}
            component="img"
            src={logo}
        />
    )
}

