export const userStatusList = [
    'ACTIVE',
    'DORMANT',
    'EXPIRED',
    'LOCKED',
    'PENDING',
    'PENDING_EMAIL',
    'PENDING_INVITATION',
    'PENDING_SMS',
    'SUSPENDED',
  ] as const;
  