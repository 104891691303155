import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiCurrencyCreate,
  mapCreateCurrencyFormValuesToApiCurrencyCreate,
  useAddCurrencyRestrictionMutation,
  useAddCurrencyRestrictionRequestMutation,
} from 'src/models/restrictions';
import { CreateCurrencyFormValues } from './types-dto';
import { createCurrencyFormValuesInitialValue } from './utils';
import { currencyFormValidationSchema } from './validations';
interface FormProps {
  onClose?: () => void;
  accountHolderId: string;
  isGlobal: boolean;
  isRequest: boolean;
}
export const CurrencyForm: FC<FormProps> = ({
  onClose,
  accountHolderId,
  isGlobal,
  isRequest,
}) => {

  const [addCurrency] = useAddCurrencyRestrictionMutation();
  const [addCurrencyRequest] = useAddCurrencyRestrictionRequestMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateCurrencyFormValues>
      enableReinitialize
      innerRef={formikRef}
      initialValues={createCurrencyFormValuesInitialValue(!isGlobal ? accountHolderId : '')}
      validationSchema={currencyFormValidationSchema()}
      onSubmit={async (values): Promise<void> => {
        try {
          const currencyRestrictionRequest: ApiCurrencyCreate = await mapCreateCurrencyFormValuesToApiCurrencyCreate(values);
          if (isRequest == true) {
            const createCurrencyResponse = await addCurrencyRequest(
              currencyRestrictionRequest
            ).unwrap();
            toast.success(`Currency restriction request created successfully.`);
          } else {
            const createCurrencyResponse = await addCurrency(currencyRestrictionRequest).unwrap();
            toast.success(`Currency restriction created successfully.`);
          }
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Create Currency {isRequest ? 'Request' : ''}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal}
                accountHolderId={accountHolderId} />

              <FormikTextField
                name="currencyCode"
                label="Currency Code"
                autoComplete="off"
                margin='normal'
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
