import { Card } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from 'src/components/Scrollbar';
import { ApiCardBin, useCardBinRestrictionsListQuery } from 'src/models/restrictions';
import { CardBinsTable } from './CardBinsTable';


export const CardBinList = ({accountHolderId}: {accountHolderId?:string;}) => {

const { data } = useCardBinRestrictionsListQuery({
        accountHolderIdForHeader:accountHolderId,
      },
      {
        refetchOnMountOrArgChange:true,
      }
      );

  const [cardBins, setCardBins] = useState<ApiCardBin[]>([]);

  useEffect(() => {
    setCardBins(data || []);
  },[data])

  return (
    <Card>
      <Scrollbar>
        <CardBinsTable
          accountHolderId={accountHolderId }
          cardBins={cardBins}
        />
      </Scrollbar>
    </Card>
  );
};
