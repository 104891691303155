import { Field, useFormikContext } from 'formik';
import { FC } from 'react';

import { UserDetailsField, UserDetailsInfo } from 'src/components/forms';

export const UserDetailsForm: FC<{ accountHolderId: string }> = ({ accountHolderId }) => {
  const { values, errors, touched } = useFormikContext<UserDetailsInfo>();
  return (
    <>
      <Field
        errors={errors}
        touched={touched}
        values={values}
        component={UserDetailsField}
        accountHolderId={accountHolderId}
      />
    </>
  );
};
