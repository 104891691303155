import { Box, Card, CardContent } from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import AuthTemplate from 'src/components/AuthTemplate';

const KYCPendingScreen: FC = () => {
  return (
    <>
      <Helmet>
        <title>KYC Pending</title>
      </Helmet>
      <AuthTemplate>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ typography: 'h4', mb: '8px' }}>KYC Pending</Box>
            <Box sx={{ typography: 'body2', mb: '16px' }}>
              KYC Verification is still pending
            </Box>
          </CardContent>
        </Card>
      </AuthTemplate>
    </>
  );
};

export default KYCPendingScreen;
