import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ApiAccountHolderResponse } from 'src/models/accountHolders/types-api';
import { SortOrder } from 'src/models/types';
import { AccountHolderStatusLabel } from '../AccountHolderStatusLabel';

interface SubAccountHolderTable {
  accountHolders: ApiAccountHolderResponse[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  { title: 'Id', id: 'id', doSort: true },
  { title: 'Name', id: 'displayName', doSort: true },
  { title: 'Status', id: 'status', doSort: false },
  { title: 'Type', id: 'accountHolderType', doSort: false },
  // { title: 'ParentId', id: 'parentId', doSort: false },
  // { title: 'AccountHolder Holder Id', id: 'accountHolderId', doSort: false },
  // { title: 'Old AccountHolder Number', id: 'oldAccountHolderNumber', doSort: false },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const SubAccountHoldersTable: FC<SubAccountHolderTable> = ({
  accountHolders,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accountHolders.filter(accountHolder => accountHolder.id != accountHolderId).map((accountHolder) => {
              return (
                <TableRow
                  hover
                  key={accountHolder.id}
                // onClick={() => navigate(`/account-holders/${accountHolder.id}`)}
                >
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(accountHolder.createdDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {accountHolder.id}
                    </Typography>
                  </TableCell>
                  <TableCell>{accountHolder.displayName}</TableCell>
                  <TableCell>
                    <AccountHolderStatusLabel
                      status={accountHolder.status}
                    />
                  </TableCell>
                  <TableCell>
                    <AccountHolderStatusLabel
                      status={accountHolder.accountHolderType}
                    />
                  </TableCell>

                  {/* <TableCell>{accountHolder.parentId}</TableCell> */}
                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={`/account-holders/${accountHolder.id}`}
                    >
                      <ArrowForward fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

    </>
  );
};
