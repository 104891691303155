import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { selectLoggedInUserId, useRefreshTokenMutation } from 'src/models/auth';
import { ApiInvitation, useAcceptUserInvitationMutation, useDeclineUserInvitationMutation } from 'src/models/invitations';
import { InvitationStatusLabel } from 'src/models/invitations/components/InvitationStatusLabel';


interface PendingInvitationTable {
  invitations: ApiInvitation[];
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime' },
  { title: 'Invitation ID', id: 'id' },
  { title: 'Name', id: 'displayName' },
  { title: 'Status', id: 'status' },
  { title: '', id: 'actions' },
];

export const PendingInvitationsTable: FC<PendingInvitationTable> = ({
  invitations,
}) => {
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', onConfirm: () => void 0 })
  const [declineInvitation] = useDeclineUserInvitationMutation();
  const [acceptInvitation] = useAcceptUserInvitationMutation();
  const [refreshToken] = useRefreshTokenMutation();
  const userId = useSelector(selectLoggedInUserId);
  return (
    <Box sx={{ minWidth: 700 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map(({ title, id }) => (
              <TableCell
                key={id}
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {invitations.map((invitation) => {
            return (
              <TableRow
                hover
                key={invitation.id}
              >
                <TableCell>
                  <Typography noWrap variant="inherit">
                    {format(
                      new Date(invitation.createdDateTime),
                      'dd/MM/yyyy HH:mm:ss',
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap variant="inherit">
                    {invitation.id}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <TransactionStatusLabel
                    status={transaction.status}
                  />
                </TableCell> */}
                {/* <TableCell>{transaction.amount.value}</TableCell>
                <TableCell>{transaction.amount.currency}</TableCell> */}
                <TableCell sx={{ maxWidth: '150px' }}>
                  <Typography noWrap variant="inherit">
                    {invitation.displayName}
                  </Typography>
                </TableCell>

                <TableCell>
                  <InvitationStatusLabel
                    status={invitation.status}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button color="primary" sx={{ ml: 2 }} variant="outlined"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to accept this invitation?',
                        subTitle: "This operation cannot be undone",
                        onConfirm: async () => {
                          await acceptInvitation({ invitationId: invitation.id, acceptInvitationRequest: { userId } }).unwrap();
                          await refreshToken().unwrap();
                        }
                      })
                    }}>
                    Accept
                  </Button>

                  <Button color="error" sx={{ ml: 2 }} variant="outlined"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to decline this invitation?',
                        subTitle: "This operation cannot be undone",
                        onConfirm: () => { declineInvitation({ invitationId: invitation.id }); }
                      })
                    }}>
                    Decline
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Box>
  );
};
