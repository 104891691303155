import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    FormControl,
    TextField,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiFeeConfig, FeeConfigListFilter, FeeConfigListSort, useAssignableFeesListQuery } from 'src/models/assignedFees';
import { Nullable } from 'src/utils/types';

export interface AssignableFeeFieldProps {
    disabled: boolean;
    accountHolderId?: string;
    label?: string;
}
export const AssignableFeeField: FC<
    AssignableFeeFieldProps & FieldProps<ApiFeeConfig, ApiFeeConfig>
> = ({
    disabled = false,
    accountHolderId,
    label = 'common.fee',
    field,
    form: { values, touched, errors, handleBlur, setFieldValue },
}) => {
        const { t } = useTranslation();

        const filter: FeeConfigListFilter = {
            id: '',
            transactionType: [],
            feeContributor: [],
            feeAccountNumber: '',
            feeAccountId: '',
            accountHolderId: '',
            enabled: false,
            transactionContext: null,
            contributorAccountNumber: '',
            description: '',
            assignable: true,
            relatedFeeId: '',
        };

        const assignableFees = useAssignableFeesListQuery(
            {
                ...filter,
                accountHolderIdForHeader: accountHolderId,
                page: 0,
                size: 1000,
                sort: [`description,asc` as FeeConfigListSort],

            },
            { refetchOnMountOrArgChange: true },
        );


        // Auto populate assignablefees when there is only one
        useEffect(() => {
            if (assignableFees && assignableFees?.data && assignableFees?.data?.content?.length === 1) {
                setFieldValue(`${field.name}`, assignableFees?.data?.content[0]);
            }
        }, [assignableFees.data]);

        return (
            <>
                {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                {assignableFees?.data?.content?.length === 0 &&
                    <Box sx={{ typography: 'body2', mb: '32px' }}>
                        No available fees to assign
                    </Box>}
                {assignableFees && assignableFees?.data && assignableFees?.data?.content && assignableFees?.data?.content?.length > 0 && (
                    <FormControl fullWidth margin="normal">
                        <Field
                            component={Autocomplete}
                            name={`${field.name}`}
                            fullWidth
                            value={values[field.name]?.id ? assignableFees.data.content.find(x => x?.id === values[field.name]?.id) : null}
                            onBlur={handleBlur(`${field.name}`)}
                            onChange={(e: any, value: ApiFeeConfig) => {
                                setFieldValue(`${field.name}`, value);
                            }}
                            disabled={disabled || assignableFees?.data?.content?.length === 1}
                            options={assignableFees.data.content}
                            autoHighlight
                            variant="outlined"
                            filterOptions={(
                                options: Array<ApiFeeConfig>,
                                state: { inputValue: string },
                            ): Array<ApiFeeConfig> => {
                                return options.filter(
                                    (x) =>
                                        x.description
                                            ?.toLowerCase()
                                            ?.includes(state.inputValue.toLowerCase()) ||
                                        x.id
                                            ?.toLowerCase()
                                            ?.includes(state.inputValue.toLowerCase())
                                );
                            }}
                            isOptionEqualToValue={(
                                option: ApiFeeConfig,
                                value: Nullable<ApiFeeConfig>,
                            ) => option.id === value?.id}
                            getOptionLabel={(option: ApiFeeConfig) =>
                                `${option?.description}  (${option.percentage}%) ${option.transactionType} ${option.transactionContext}`
                            }
                            renderOption={(props: any, option: ApiFeeConfig) => (
                                <Box
                                    key={option}
                                    component="li"
                                    {...props}
                                >
                                    {`${option?.description}  (${option.percentage}%) ${option.transactionType} ${option.transactionContext}`}
                                </Box>
                            )}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <>
                                    <TextField
                                        {...params}
                                        label={t(label)}
                                        error={Boolean(
                                            touched[field.name] &&
                                            errors[field.name],
                                        )}
                                        helperText={
                                            touched[field.name] &&
                                            t(errors[field.name])
                                        }
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            // ...(values[field.name]?.id
                                            //     ? {
                                            //         startAdornment: (
                                            //             <InputAdornment position="end">
                                            //                 <>
                                            //                     <Box sx={{ ml: '8px' }}>
                                            //                         {/* {values[field.name]?.displayName} ({values[field.name]?.id}) &nbsp;&nbsp; */}
                                            //                         <AccountHolderStatusLabel status={values[field.name]?.status} />
                                            //                     </Box>
                                            //                 </>
                                            //             </InputAdornment>
                                            //         ),
                                            //     }
                                            //     : {}),
                                        }}
                                    />
                                </>
                            )}
                        ></Field>
                    </FormControl>
                )
                }
            </>
        );
    };
