export const config = {
  apiUrl: process.env.REACT_APP_API_BASE_ROOT,
  checkoutUrl: process.env.REACT_APP_CHECKOUT_URL,
  authToken: process.env.REACT_APP_AUTH_TOKEN,
  enableReduxDevTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',

  // Demo Payment configurations
  demoAccountHolderId: process.env.REACT_APP_DEMO_ACCOUNT_HOLDER_ID,
  demoAcquiringConfigId: process.env.REACT_APP_DEMO_ACQUIRING_CONFIG_ID,
  demoApiKey: process.env.REACT_APP_DEMO_API_DEMO_KEY,
};


export const localConfig = {
  countryCodeDefault: 'US',
  PASSWORD_MIN_LENGTH: 6,
  UPLOAD_MAX_FILE_SIZE: (2 * 1024 * 1024) // 2 Mega bytes
}

// console.log("INJECTED ENV");
// console.log((JSON.stringify(process.env)));