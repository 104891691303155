import * as Yup from 'yup';

import { ApiKycVerification, ApiKycVerificationUpdate, kycStatusList } from 'src/models/kycVerifications';

export const kycVerificationFormValidationSchema =
  Yup.object({
    kycStatus: Yup.mixed().oneOf(kycStatusList as any),
    kycVerificationId: Yup.string().required('KYC verivication Id is required'),
    accountHolderIdForHeader: Yup.string().required('Client is required'),
  });

export const kycVerificationToKycVerificationFormValues = (
  kycVerification: ApiKycVerification, accountHolderIdForHeader: string
): ApiKycVerificationUpdate => ({
  kycVerificationId: kycVerification.id,
  kycStatus: kycVerification.kycStatus,
  accountHolderIdForHeader
});