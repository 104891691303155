import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiConfigAttribute,
  useDeleteAttributeMutation,
} from 'src/models/configurations';
import { AttributeForm } from './forms/AttributeForm';

interface AttributeTable {
  attributes: ApiConfigAttribute[];
  accountHolderId: string;
}

const headCells = [
  { title: 'Id', id: 'id', doSort: true },
  { title: 'Attribute Name', id: 'attributeName', doSort: true },
  { title: 'Attribute Value', id: 'attributeValue', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const AttributesTable: FC<AttributeTable> = ({
  attributes,
  accountHolderId,
}) => {
  const { hasPermission } = usePermissions();
  const [deleteAttribute] = useDeleteAttributeMutation();
  const [editAttributeOpened, setEditAttributeOpened] =
    useState<ApiConfigAttribute>(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            attributeId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              await deleteAttribute({
                attributeId: values.attributeId,
                accountHolderIdForHeader: accountHolderId,
              }).unwrap();
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size='small'>
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id, doSort }) => (
                    <TableCell key={id}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {attributes.map((attribute) => {
                  return (
                    <TableRow hover key={attribute.id}>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {attribute.id}
                        </Typography>
                      </TableCell>
                      <TableCell>{attribute.attributeName}</TableCell>
                      <TableCell>{attribute.attributeValue}</TableCell>

                      {(hasPermission('UPDATE_ACQUIRING_CONFIGURATION') ||
                        hasPermission('DELETE_ACQUIRING_CONFIGURATION')) && (
                          <TableCell align="right">
                            <PermissionGuard permission="UPDATE_CORRESPONDENT_BANK">
                              <IconButton
                                onClick={() => setEditAttributeOpened(attribute)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </PermissionGuard>
                            <PermissionGuard permission="DELETE_ACQUIRING_CONFIGURATION">
                              <IconButton
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      'Are you sure to remove this attribute?',
                                    subTitle: 'This operation cannot be undone',
                                    onConfirm: () => {
                                      setFieldValue('attributeId', attribute.id);
                                      formikRef?.current?.submitForm();
                                    },
                                  });
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </PermissionGuard>
                          </TableCell>
                        )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>

      <PermissionGuard permission="UPDATE_ACQUIRING_CONFIGURATION">
        <Drawer
          anchor="right"
          open={editAttributeOpened != null}
          onClose={() => setEditAttributeOpened(null)}
          PaperProps={{
            sx: {
              backgroundColor: '#F3F7FE',
              width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            },
          }}
        >
          <AttributeForm
            attribute={editAttributeOpened}
            accountHolderId={accountHolderId}
            onClose={() => setEditAttributeOpened(null)}
          />
        </Drawer>
      </PermissionGuard>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
