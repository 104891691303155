export const feeContributorList = [
    'SOURCE',
    'DESTINATION',
    'OTHER'
] as const;

export const feeTransactionContextList = [
    'INCOMING',
    'OUTGOING'
] as const;

export const attributeNameList = [
    'ACQUIRER_BIN',
    'ACQUIRER_MID'
] as const;