import * as Yup from 'yup'

import { permissionGroupsValidationSchema, userDetailsInfoValidationSchema } from "src/components/forms";
import { UserInfo } from '../types-dto';

export const userInfoValidationSchema = (user: UserInfo) =>
    userDetailsInfoValidationSchema().shape({
        email: Yup.string()
            .email('Email format invalid')
            .required('Email is required'),
        ...(!user ? { permissionGroupIds: permissionGroupsValidationSchema() } : {})
    });