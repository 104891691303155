import { Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

export const ColorInputField: React.FC<{
  label: string;
  fieldName: string;
}> = ({ label, fieldName }) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();

  const scriptInputRef = React.useRef<HTMLInputElement>(null);

  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [color, setColor] = useState<{
    hex: string;
  }>({
    hex: values[fieldName] || '#000000',
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color);
    setFieldValue(fieldName, color.hex);
    if (scriptInputRef.current) {
      scriptInputRef.current.focus();
    }
  };

  return (
    <>
      <TextField
        margin="normal"
        error={Boolean(touched[fieldName] && errors[fieldName])}
        helperText={touched[fieldName] && errors[fieldName]}
        fullWidth
        id="outlined-basic"
        variant="outlined"
        label={label}
        inputRef={scriptInputRef} // To focus on the field after clicking icon
        value={values[fieldName]?.toUpperCase()}
        onBlur={() => setFieldTouched(fieldName, true)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              <div>
                <Box
                  component="div"
                  sx={{
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={handleClick}
                >
                  <Box
                    component="div"
                    sx={{
                      width: '36px',
                      height: '14px',
                      borderRadius: '2px',
                      background: `${color.hex}`,
                    }}
                  ></Box>
                </Box>
                {displayColorPicker ? (
                  <Box
                    component="div"
                    sx={{
                      position: 'absolute',
                      zIndex: '2',
                      right: '0',
                      border: '1px solid red',
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={handleClose}
                    ></Box>
                    <SketchPicker color={color} onChange={handleChange} />
                  </Box>
                ) : null}
              </div>
            </>
          ),
        }}
      />
    </>
  );
};
