import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TotpState = {
  required: boolean;
  code:string | null;
};

export const totpSlice = createSlice({
  name: 'totp',
  initialState: {
      required: false,
      code:null
  } as TotpState,
  reducers: {
    setTotpRequired: (
      state,
      {
        payload: { required },
      }: PayloadAction<{
        required: boolean;
      }>,
    ) => {
      state.required = required;
    },
    setTotpCode: (
        state,
        {
          payload: { code },
        }: PayloadAction<{
            code: string | null;
        }>,
      ) => {
        state.code = code;
      },
  },
});

export const { setTotpRequired, setTotpCode } = totpSlice.actions;

