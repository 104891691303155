import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { ApiAcquiringConfig } from 'src/models/configurations';
import { SortOrder } from 'src/models/types';

interface AcquiringsTable {
  acquirings: ApiAcquiringConfig[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  { title: 'Id', id: 'id', doSort: true },
  { title: 'Client Id', id: 'accounHolderId', doSort: true },
  { title: 'Mid', id: 'mid', doSort: true },
  { title: 'Transfer Channel Id', id: 'transferChannelId', doSort: true },
  { title: 'Required Three D', id: 'requiresThreeD', doSort: true },
  { title: 'Required AVS', id: 'requiresAvs', doSort: true },
  { title: 'Allow Non CVV', id: 'allowNonCvv', doSort: true },
  {
    title: 'Transaction Descriptor',
    id: 'transactionDescriptor',
    doSort: true,
  },
  {
    title: 'Return Params Enabled',
    id: 'returnUrlParamsEnabled',
    doSort: true,
  },
  {
    title: 'Transaction Expiry Enabled',
    id: 'transactionExpiryEnabled',
    doSort: true,
  },
  { title: 'API Key', id: 'apiKey', doSort: true },
  { title: 'Country Code', id: 'countryCode', doSort: true },
  { title: 'Enabled', id: 'enabled', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const AcquiringsTable: FC<AcquiringsTable> = ({
  acquirings,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {/* Action is update and delete only so we remove Actions if update and delete is not possible */}
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {acquirings.map((acquiring) => {
              return (
                <TableRow
                  hover
                  key={acquiring.id}
                // onClick={() => navigate(`${acquiring.id}`)}
                >
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(acquiring.createdDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {acquiring.id}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {acquiring.accountHolderId}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {acquiring.mid}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {acquiring.transferChannelId}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Chip
                      label={acquiring.requiresThreeD ? 'Yes' : 'No'}
                      color={acquiring.requiresThreeD ? 'success' : 'error'}
                    />
                  </TableCell>

                  <TableCell>
                    <Chip
                      label={acquiring.requiresAvs ? 'Yes' : 'No'}
                      color={acquiring.requiresAvs ? 'success' : 'error'}
                    />
                  </TableCell>

                  <TableCell>
                    <Chip
                      label={acquiring.allowNonCvv ? 'Yes' : 'No'}
                      color={acquiring.allowNonCvv ? 'success' : 'error'}
                    />
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {acquiring.transactionDescriptor}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Chip
                      label={acquiring.returnUrlParamsEnabled ? 'Yes' : 'No'}
                      color={
                        acquiring.returnUrlParamsEnabled ? 'success' : 'error'
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Chip
                      label={acquiring.transactionExpiryEnabled ? 'Yes' : 'No'}
                      color={
                        acquiring.transactionExpiryEnabled ? 'success' : 'error'
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {acquiring.apiKey}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {acquiring.countryCode}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Chip
                      label={acquiring.enabled ? 'Yes' : 'No'}
                      color={acquiring.enabled ? 'success' : 'error'}
                    />
                  </TableCell>

                  <TableCell>
                    <IconButton component={RouterLink} to={`${acquiring.id}`}>
                      <ArrowForward fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
