import { Chip, colors } from '@mui/material';
import { capitalCase } from 'change-case';

import { AccountType } from 'src/components/forms';
import {
  AccountEntryStatus,
  AccountStatus,
} from 'src/models/accounts';

const statusColors = {
  DELETED: colors.red,
  ACTIVE: colors.green,
  CLOSED: colors.grey,

  SYSTEM: colors.red,
  USER: colors.green,
  FEE: colors.blue,

  CREATED: colors.green,
  PENDING: colors.orange,
  COMPLETED: colors.green,
  REVERTED: colors.grey,
};

export const AccountStatusLabel = ({
  status,
}: {
  status: AccountStatus | AccountType | AccountEntryStatus;
}) => {
  // alert(status);
  const backgroundColor =
    statusColors[status] && `${statusColors[status][50]} !important`;
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={capitalCase(status)} sx={{ backgroundColor, color }} />;
};
