import { ApiCountryLookup } from "src/models/countryStates/types";
import { CreateUpdateAcquiringFormValues } from "src/screens/Configuration/Acquiring/forms/types-dto";
import { ApiAcquiringConfig, ApiAcquiringConfigUpdate } from "../types";


// export const mapApiAcquiringConfigToCreateUpdateAcquiringFormValues = (
//     acquiring: ApiAcquiringConfig, accountHolderIdForHeader: string
// ): CreateUpdateAcquiringFormValues => ({
//     accountHolderIdForHeader,
//     acquiringConfigId: acquiring.id,
//     mid: acquiring.mid,
//     transferChannelId: acquiring.transferChannelId,
//     requiresThreeD: acquiring.requiresThreeD,
//     requiresAvs: acquiring.requiresAvs,
//     allowNonCvv: acquiring.allowNonCvv,
//     transactionDescriptor: acquiring.transactionDescriptor,
//     returnUrlParamsEnabled: acquiring.returnUrlParamsEnabled,
//     transactionExpiryEnabled: acquiring.transactionExpiryEnabled,
//     apiKey: acquiring.apiKey,
//     countryCode: acquiring.countryCode,
// });


export const mapApiAcquiringConfigToCreateUpdateAcquiringFormValues = async (acquiring: ApiAcquiringConfig, apiCountries: ApiCountryLookup[], accountHolderId: string): Promise<CreateUpdateAcquiringFormValues> => {
    return {
        acquiringConfigId: acquiring.id,
        mid: acquiring.mid,
        transferChannelId: acquiring.transferChannelId,
        requiresThreeD: acquiring.requiresThreeD,
        requiresAvs: acquiring.requiresAvs,
        allowNonCvv: acquiring.allowNonCvv,
        transactionDescriptor: acquiring.transactionDescriptor,
        returnUrlParamsEnabled: acquiring.returnUrlParamsEnabled,
        transactionExpiryEnabled: acquiring.transactionExpiryEnabled,
        apiKey: acquiring.apiKey,
        countryCode: apiCountries.find(x => x.code === acquiring.countryCode),
        accountHolderIdForHeader: accountHolderId
    }
}

export const mapCreateUpdateAcquiringFormValuesToApiAcquiringConfigUpdate = (acquiringDto: CreateUpdateAcquiringFormValues): ApiAcquiringConfigUpdate => ({
    acquiringConfigId: acquiringDto.acquiringConfigId,
    mid: acquiringDto.mid,
    transferChannelId: acquiringDto.transferChannelId,
    requiresThreeD: acquiringDto.requiresThreeD,
    requiresAvs: acquiringDto.requiresAvs,
    allowNonCvv: acquiringDto.allowNonCvv,
    transactionDescriptor: acquiringDto.transactionDescriptor,
    returnUrlParamsEnabled: acquiringDto.returnUrlParamsEnabled,
    transactionExpiryEnabled: acquiringDto.transactionExpiryEnabled,
    apiKey: acquiringDto.apiKey,
    countryCode: acquiringDto.countryCode.code,
    accountHolderIdForHeader: acquiringDto.accountHolderIdForHeader.length > 0 ? acquiringDto.accountHolderIdForHeader : null
})