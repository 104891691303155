import { useCallback } from 'react';
import toast from 'react-hot-toast';

export const useCopyText = () => {
  const copyText = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`Copied ${text} to clipboard`);
    } catch (e) {
      toast.error('Copying clipboard failed');
    }
  }, []);
  return copyText;
};
