import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { AccountHolderIdField, emailInitialValue, PermissionGroupsField, permissionGroupsInitialValue, UserDetailsField, userDetailsInitialValues } from 'src/components/forms';
import { useTotp } from 'src/hooks/useTotp';
import { ApiUser, mapUserInfoToApiCreateUserRequest, useAddUserMutation, userStatusList, useUpdateUserMutation } from 'src/models/users';
import { UserInfo } from '../types-dto';
import { userInfoValidationSchema } from './validations';

interface FormProps {
    onClose?: () => void;
    user?: UserInfo;
    accountHolderId: string;
    isGlobal: boolean;
}
export const UserForm: FC<FormProps> = ({
    onClose,
    user,
    accountHolderId,
    isGlobal,
}) => {

    const { t } = useTranslation();
    const [addUser] = useAddUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const formikRef = useRef(null);
    useTotp(formikRef);

    return (

        <Formik<UserInfo> // CreateUpdateUserFormValues
            innerRef={formikRef}
            initialValues={{
                email: emailInitialValue(),
                ...userDetailsInitialValues(),
                permissionGroupIds: permissionGroupsInitialValue(),
                accountHolderIdForHeader: accountHolderId,
                userStatus: null,
                ...(user ? user : {}),
            }}
            validationSchema={userInfoValidationSchema(user)}
            onSubmit={async (
                values,
            ): Promise<void> => {
                try {
                    const userBody = mapUserInfoToApiCreateUserRequest(values);
                    let registerResponse: ApiUser;
                    if (user) {
                        registerResponse = await updateUser(userBody).unwrap();
                    } else {
                        registerResponse = await addUser(userBody).unwrap();
                    }

                    if (registerResponse?.userStatus) {
                        toast.success(`User ${user ? 'updated' : 'created'} successfully.`);
                        onClose();
                    }
                } catch (err) {
                    // toast.error('An error has occured');
                    console.log(err);
                }
            }}
        >
            {({
                handleBlur,
                handleChange,
                errors,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                values,
                touched,
            }): JSX.Element => (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '24px 38px 24px 32px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                sx={{ mr: 2 }}
                                aria-label="close"
                                size="medium"
                                onClick={() => onClose()}
                            >
                                <Close color="inherit" fontSize="inherit" />
                            </IconButton>
                            <Typography color={'textPrimary'} variant="h5">
                                {user ? 'Edit User' : 'Create User'}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider />
                    <Box sx={{ padding: '32px' }}>
                        <form noValidate onSubmit={handleSubmit}>

                            {!user && (
                                <Field
                                    name={`accountHolderIdForHeader`}
                                    component={AccountHolderIdField}
                                    disabled={!isGlobal}
                                    accountHolderId={accountHolderId} />
                            )}

                            <Field
                                component={TextField}
                                name="email"
                                fullWidth
                                label={t('common.emailWillBeUsedForLogin')}
                                value={values.email}
                                margin="normal"
                                error={Boolean(touched.email && !!errors.email)}
                                helperText={
                                    touched.email && t(errors.email as string)
                                }
                                onBlur={(e) => {
                                    setFieldValue('checkEmail', true);
                                    handleBlur(`email`)(e);
                                }}
                                onChange={handleChange(`email`)}
                                variant="outlined"
                            />

                            <Field
                                errors={errors}
                                touched={touched}
                                values={values}
                                component={UserDetailsField}
                                accountHolderId={accountHolderId}
                            />

                            {!user && (
                                <Field
                                    name={"permissionGroupIds"}
                                    component={PermissionGroupsField}
                                    accountHolderId={accountHolderId}
                                />
                            )}
                            {user && (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel
                                        error={Boolean(touched.userStatus && errors.userStatus)}
                                    >
                                        User Status
                                    </InputLabel>
                                    <Select
                                        error={Boolean(touched.userStatus && errors.userStatus)}
                                        value={values.userStatus}
                                        label="User Status"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="userStatus"
                                        variant="outlined"
                                    >
                                        {userStatusList.map((value) => (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {touched.userStatus && errors.userStatus}
                                    </FormHelperText>
                                </FormControl>
                            )}

                            <Box sx={{ mt: '24px' }}>
                                <LoadingButton
                                    color="primary"
                                    loading={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {user ? 'Update' : 'Create'}
                                </LoadingButton>
                            </Box>
                        </form>
                    </Box>
                </>
            )}
        </Formik>
    );
};
