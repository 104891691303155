export const homePath = '/';

export const rootPaths = {
  auth: 'auth',
  authRequired: '401',
  dashboard: '/',
  notFound: '404',
  serverError: '500',
};

export const authPaths = {
  forgotPassword: 'forgot-password',
  forgotPasswordConfirm: 'forgot-password/confirm',
  login: 'login',
  resetPassword: 'reset-password',
  register: 'register',
};

export const dashboardPaths = {
  transactions: '/transactions',
  users: '/users',
  apiKeys: '/api-keys',
  accountHolders: '/account-holders',
};

// export const bankingPath = ':bankingProfileId/external-transfer/*';

type AllPaths = { home: string } & typeof rootPaths & typeof authPaths; // & { banking: string }; //& typeof businessPaths;

const composePaths = <T extends {}>(path: string, subPaths: T) =>
  Object.keys(authPaths).reduce(
    (acc, val) => ({ ...acc, [val]: `${path}/${subPaths[val]}` }),
    {} as T,
  );

export const links: { [key in keyof AllPaths]: string } = {
  home: homePath,
  ...composePaths<typeof dashboardPaths>(
    `/${rootPaths.dashboard}`,
    dashboardPaths,
  ),
  // banking: bankingPath,
  ...composePaths<typeof rootPaths>('/', rootPaths),
  ...composePaths<typeof authPaths>(`/${rootPaths.auth}`, authPaths),
};
