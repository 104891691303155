import { Field } from 'formik';
import { FC } from 'react';

import { PermissionGroupsField } from 'src/components/forms';

export const PermissionGroupsForm: FC<{ accountHolderId: string }> = ({
  accountHolderId,
}) => {

  return (
    <>
      <Field
        name={"permissionGroupIds"}
        component={PermissionGroupsField}
        accountHolderId={accountHolderId}
      />
    </>
  );
};
