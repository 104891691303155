import { ArrowForward, Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import {
  ApiCorrespondentBank,
  useDeleteCorrespondentBankMutation,
} from 'src/models/configurations';
import { CorrespondentBankForm } from './forms/CorrespondentBankForm';

interface CorrespondentBankTable {
  correspondentBanks: ApiCorrespondentBank[];
  accountHolderId: string;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  { title: 'Id', id: 'id', doSort: true },
  { title: 'Bank Name', id: 'bankName', doSort: true },
  // { title: 'Banking Config Id', id: 'bankingConfigId', doSort: false },
  { title: 'Enabled', id: 'enabled', doSort: false },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const CorrespondentBanksTable: FC<CorrespondentBankTable> = ({
  correspondentBanks,
  accountHolderId,
}) => {
  const { hasPermission } = usePermissions();
  const [deleteCorrespondentBank] = useDeleteCorrespondentBankMutation();
  const [editCorrespondentBankOpened, setEditCorrespondentBankOpened] =
    useState<ApiCorrespondentBank>(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const formikRef = useRef(null);
  useTotp(formikRef);
  const isUserActive = useSelector(isAccountHolderStatusActive);
  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            correspondentBankId: null,
          }}
          onSubmit={async (values): Promise<void> => {
            try {
              await deleteCorrespondentBank({
                correspondentBankId: values.correspondentBankId,
                accountHolderIdForHeader: accountHolderId,
              }).unwrap();
            } catch (err) {
              //
            }
          }}
        >
          {({ setFieldValue }): JSX.Element => (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map(({ title, id, doSort }) => (
                    <TableCell key={id}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {correspondentBanks.map((correspondentBank) => {
                  return (
                    <TableRow hover key={correspondentBank.id}>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {format(
                            new Date(correspondentBank.createdDateTime),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap variant="inherit">
                          {correspondentBank.id}
                        </Typography>
                      </TableCell>
                      <TableCell>{correspondentBank.bankName}</TableCell>

                      {/* <TableCell>{correspondentBank.bankingConfigId}</TableCell> */}

                      <TableCell>
                        <Chip
                          label={correspondentBank.enabled ? 'Yes' : 'No'}
                          color={
                            correspondentBank.enabled ? 'success' : 'error'
                          }
                        />
                      </TableCell>

                      {(hasPermission('UPDATE_CORRESPONDENT_BANK') ||
                        hasPermission('DELETE_CORRESPONDENT_BANK')) && (
                          <TableCell align="right">
                            <PermissionGuard permission="UPDATE_CORRESPONDENT_BANK">
                              <IconButton
                                disabled={!isUserActive}
                                onClick={() =>
                                  setEditCorrespondentBankOpened(
                                    correspondentBank,
                                  )
                                }
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </PermissionGuard>
                            <PermissionGuard permission="DELETE_CORRESPONDENT_BANK">
                              <IconButton
                                disabled={!isUserActive}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      'Are you sure to remove this correspondent bank?',
                                    subTitle: 'This operation cannot be undone',
                                    onConfirm: () => {
                                      setFieldValue(
                                        'correspondentBankId',
                                        correspondentBank.id,
                                      );
                                      formikRef?.current?.submitForm();
                                    },
                                  });
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </PermissionGuard>
                          </TableCell>
                        )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Formik>
      </Box>

      <PermissionGuard permission="UPDATE_CORRESPONDENT_BANK">
        <Drawer
          anchor="right"
          open={editCorrespondentBankOpened != null}
          onClose={() => setEditCorrespondentBankOpened(null)}
          PaperProps={{
            sx: [
              {
                backgroundColor: '#F3F7FE',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: `100%`,
                },
                [theme.breakpoints.up('md')]: {
                  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                },
              }),
            ],
          }}
        >
          <CorrespondentBankForm
            correspondentBank={editCorrespondentBankOpened}
            accountHolderId={accountHolderId}
            onClose={() => setEditCorrespondentBankOpened(null)}
          />
        </Drawer>
      </PermissionGuard>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
