import { NavigateNext } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  colors,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import { Formik } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import ConfirmDialog from 'src/components/ConfirmDialog';
import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import Scrollbar from 'src/components/Scrollbar';
import { useTotp } from 'src/hooks/useTotp';
import { isAccountHolderStatusActive } from 'src/models/auth';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import {
  useDeleteTransferChannelMutation,
  useTransferChannelQuery,
} from 'src/models/transferChannels';
import { TransferChannelForm } from 'src/models/transferChannels/components/TransferChannelForm';
import { useTransferChannelTemplatesListQuery } from 'src/models/transferChannelTemplates';
import { TransferChannelTemplateForm } from 'src/models/transferChannelTemplates/components/TransferChannelTemplateForm';
import { TransferChannelProviderTypeLabel } from '../TransferChannels/TransferChannelProviderTypeLabel';
import { TransferChannelTemplatesTable } from './TransferChannelTemplatesTable';

interface DetailRow {
  name: string;
  value: any;
}

export const TransferChannelView = ({
  accountHolderId,
  id,
}: {
  accountHolderId: string;
  id: string;
}) => {
  const [editTransferChannelOpened, setEditTransferChannelOpened] =
    useState<boolean>(false);
  const [addParameterOpened, setAddParameterOpened] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => void 0,
  });
  const [deleteTransferChannel] = useDeleteTransferChannelMutation();
  const navigate = useNavigate();
  const { data: transferChannel } = useTransferChannelQuery(
    {
      accountHolderId,
      id,
    },
    { refetchOnMountOrArgChange: true },
  );
  const isUserActive = useSelector(isAccountHolderStatusActive);
  const { data: transferChannelTemplate, isFetching } =
    useTransferChannelTemplatesListQuery(
      {
        transferChannelId: id,
        accountHolderIdForHeader: accountHolderId,
      },
      { refetchOnMountOrArgChange: true },
    );

  const formikRef = useRef(null);
  useTotp(formikRef);

  const transferChannelInfoData: DetailRow[] = useMemo(
    () =>
      transferChannel
        ? [
            {
              name: 'Id',
              value: (
                <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {transferChannel.id}
                </Typography>
              ),
            },
            {
              name: 'Description',
              value: (
                <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {transferChannel.description}
                </Typography>
              ),
            },
            {
              name: 'Provider Type',
              value: (
                <TransferChannelProviderTypeLabel
                  status={transferChannel.providerType}
                />
              ),
            },
            {
              name: 'Enabled',
              value: (
                <Chip
                  label={transferChannel.enabled ? 'Enabled' : 'Disabled'}
                  color={transferChannel.enabled ? 'success' : 'error'}
                />
              ),
            },
            {
              name: 'Required Permission',
              value: (
                <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {transferChannel.requiredPermission}
                </Typography>
              ),
            },
            {
              name: 'Created Date Time',
              value: (
                <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {format(
                    new Date(transferChannel.createdDateTime),
                    'dd/MM/yyyy HH:mm:ss',
                  )}
                </Typography>
              ),
            },
            {
              name: 'Channels',
              value: (
                <Box>
                  {transferChannel.categories.map((categories) => (
                    <Chip
                      sx={{ m: 1 }}
                      color="primary"
                      variant="outlined"
                      label={categories}
                    />
                  ))}
                </Box>
              ),
            },
          ]
        : [],
    [accountHolderId, transferChannel],
  );

  const breadcrumbs = (description) => [
    <Link
      key="1"
      color={blueGrey[600]}
      component={RouterLink}
      to={'/transfer-channels'}
    >
      <Typography variant="overline">ADMINISTRATION</Typography>
    </Link>,
    <Link
      key="1"
      color={blueGrey[500]}
      component={RouterLink}
      to={`/transfer-channels/${id}`}
    >
      <Typography variant="overline">{description}</Typography>
    </Link>,
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          item
          xs={12}
        >
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {transferChannel && breadcrumbs(transferChannel.description)}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sx={[
              {
                width: '100%',
                display: 'flex',
                gap: 1,
                marginBottom: '10px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '10px',
                },
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
              }),
            ]}
          >
            <PermissionGuard permission="UPDATE_TRANSFER_CHANNEL">
              <Button
                disabled={!isUserActive}
                variant="contained"
                onClick={() => setEditTransferChannelOpened(true)}
              >
                Edit Transfer Channel
              </Button>
            </PermissionGuard>

            {transferChannel?.enabled && (
              <PermissionGuard permission="DELETE_TRANSFER_CHANNEL">
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    transferChannelId: null,
                  }}
                  onSubmit={async (values): Promise<void> => {
                    try {
                      const result = await deleteTransferChannel({
                        id: values.transferChannelId,
                        accountHolderId: accountHolderId,
                      }).unwrap();
                      if (result == null) {
                        toast.success('Transfer Channel removed successfully');
                        navigate(`/transfer-channels`);
                      }
                    } catch (err) {
                      //
                    }
                  }}
                >
                  {({ setFieldValue }): JSX.Element => (
                    <Button
                      disabled={!isUserActive}
                      variant="contained"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            'Are you sure to remove this Transfer Channel?',
                          subTitle: 'This operation cannot be undone',
                          onConfirm: async () => {
                            setFieldValue(
                              'transferChannelId',
                              transferChannel.id,
                            );
                            formikRef?.current?.submitForm();
                          },
                        });
                      }}
                    >
                      Delete Transfer Channel
                    </Button>
                  )}
                </Formik>
              </PermissionGuard>
            )}
          </Grid>
        </Grid>

        {transferChannel ? (
          <>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      disableTypography
                      title={
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="h6">
                            Transfer Channel info
                          </Typography>
                        </Box>
                      }
                    />

                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={2}
                      sx={{ p: '16px' }}
                    >
                      {transferChannelInfoData.map(({ name, value }) => (
                        <Grid item md={6} sm={12} xs={12} key={name}>
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {name}
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              {value}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>

                  {/* <Card
                  sx={{
                    mt: '10px',
                  }}
                  >
                  <CardHeader
                    disableTypography
                    title={
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6">Transfer Channel Template</Typography>
                        <PermissionGuard permission="CREATE_TRANSFER_CHANNEL_TEMPLATE">
                            <Button
                              sx={{ mr: 0.5 }}
                              variant="contained"
                              onClick={() => setAddParameterOpened(true)}
                            >
                              Add Parameter
                            </Button>
                      
                        </PermissionGuard>
                      </Box>
                    }
                  /> */}

                  <Box
                    sx={[
                      {
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '25px',
                        marginTop: '25px',
                      },
                      (theme) => ({
                        [theme.breakpoints.down('md')]: {
                          flexDirection: 'column',
                        },
                        [theme.breakpoints.up('md')]: {
                          flexDirection: 'row',
                        },
                      }),
                    ]}
                  >
                    <Typography color="textPrimary" variant="h5">
                      Transfer Channel Template
                    </Typography>

                    <Box
                      sx={[
                        {
                          display: 'flex',
                          gap: 1,
                          marginBottom: '10px',
                        },
                        (theme) => ({
                          [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column',
                            gap: '10px',
                          },
                          [theme.breakpoints.up('sm')]: {
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          },
                        }),
                      ]}
                    >
                      <PermissionGuard permission="CREATE_TRANSFER_CHANNEL_TEMPLATE">
                        <Button
                          disabled={!isUserActive}
                          sx={[
                            {
                              whiteSpace: 'nowrap',
                            },
                            (theme) => ({
                              [theme.breakpoints.down('sm')]: {
                                minWidth: '100%',
                              },
                              [theme.breakpoints.up('sm')]: {
                                minWidth: 'auto',
                              },
                            }),
                          ]}
                          variant="contained"
                          onClick={() => setAddParameterOpened(true)}
                        >
                          Add Parameter
                        </Button>
                      </PermissionGuard>
                    </Box>
                  </Box>

                  {/* <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ marginBottom: '25px', marginTop: '25px' }}
          xs={12}
        >
          <Grid item  justifySelf="left">
            <Typography color="textPrimary" variant="h5">
              Transfer Channel Template
            </Typography>
          </Grid>
          <Grid item 
                sx={{
                    display: 'flex'
                }}
            >
             <PermissionGuard permission="CREATE_TRANSFER_CHANNEL_TEMPLATE">
                            <Button
                              sx={{ mr: 0.5 }}
                              variant="contained"
                              onClick={() => setAddParameterOpened(true)}
                            >
                              Add Parameter
                            </Button>
                      
                        </PermissionGuard>

          </Grid>
        </Grid> */}
                  <Card>
                    <Scrollbar>
                      {isFetching && <CircularProgress />}

                      {transferChannelTemplate && (
                        <TransferChannelTemplatesTable
                          accountHolderId={accountHolderId}
                          transferChannelTemplate={transferChannelTemplate}
                          // orderBy={sort.orderBy}
                          // sortDirection={sort.direction}
                          // onRequestSort={onRequestSort}
                        />
                      )}
                    </Scrollbar>
                  </Card>
                  {/* </Card> */}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      {transferChannel && (
        <>
          <PermissionGuard permission="UPDATE_TRANSFER_CHANNEL">
            <Drawer
              anchor="right"
              open={editTransferChannelOpened == true}
              onClose={() => setEditTransferChannelOpened(false)}
              PaperProps={{
                sx: {
                  backgroundColor: '#F3F7FE',
                  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                },
              }}
            >
              <TransferChannelForm
                accountHolderId={accountHolderId}
                transferChannel={transferChannel}
                onClose={() => setEditTransferChannelOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
        </>
      )}

      {transferChannel && (
        <>
          <PermissionGuard permission="CREATE_TRANSFER_CHANNEL_TEMPLATE">
            <Drawer
              anchor="right"
              open={addParameterOpened == true}
              onClose={() => setAddParameterOpened(false)}
              PaperProps={{
                sx: [
                  {
                    backgroundColor: '#F3F7FE',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: `100%`,
                    },
                    [theme.breakpoints.up('md')]: {
                      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                    },
                  }),
                ],
              }}
            >
              <TransferChannelTemplateForm
                transferChannelId={transferChannel.id}
                transferChannelTemplate={transferChannelTemplate}
                accountHolderId={accountHolderId}
                onClose={() => setAddParameterOpened(false)}
              />
            </Drawer>
          </PermissionGuard>
        </>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default TransferChannelView;
