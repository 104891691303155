import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';

import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import {
  ApiAccountCurrencyCreate,
  mapCreateAccountCurrencyFormValuesToApiAccountCurrencyCreate,
  useAddAccountCurrencyRestrictionMutation,
  useAddAccountCurrencyRestrictionRequestMutation,
} from 'src/models/restrictions';
import { CreateAccountCurrencyFormValues } from './types-dto';
import { createAccountCurrencyFormValuesInitialValue } from './utils';
import { accountCurrencyFormValidationSchema } from './validations';
interface FormProps {
  onClose?: () => void;
  accountHolderId: string;
  isGlobal: boolean;
  isRequest: boolean;
}
export const AccountCurrencyForm: FC<FormProps> = ({
  onClose,
  accountHolderId,
  isGlobal,
  isRequest,
}) => {


  const [addAccountCurrency] = useAddAccountCurrencyRestrictionMutation();
  const [addAccountCurrencyRequest] =
    useAddAccountCurrencyRestrictionRequestMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  return (
    <Formik<CreateAccountCurrencyFormValues>
      enableReinitialize
      innerRef={formikRef}
      initialValues={createAccountCurrencyFormValuesInitialValue(!isGlobal ? accountHolderId : '')}
      validationSchema={accountCurrencyFormValidationSchema()}
      onSubmit={async (values): Promise<void> => {
        try {
          const accountCurrencyRestrictionRequest: ApiAccountCurrencyCreate = await mapCreateAccountCurrencyFormValuesToApiAccountCurrencyCreate(values);
          if (isRequest == true) {
            await addAccountCurrencyRequest(accountCurrencyRestrictionRequest).unwrap();
            toast.success(
              `Account currency restriction request created successfully.`,
            );
          } else {
            await addAccountCurrency(accountCurrencyRestrictionRequest).unwrap();
            toast.success(`Account currency restriction created successfully.`);
          }

          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Create Account Currency {isRequest ? 'Request' : ''}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal}
                accountHolderId={accountHolderId} />

              <FormikTextField
                name="currencyCode"
                label="Currency Code"
                autoComplete="off"
                margin='normal'
              />

              <FormControlLabel
                control={
                  <Field
                    label="Create on registration"
                    name="createOnRegistration"
                    component={Switch}
                    onChange={(ev, checked) => {
                      setFieldValue('createOnRegistration', checked);
                    }}
                    checked={values.createOnRegistration ? true : false}
                  />
                }
                label="Create on registration"
              />

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
