
import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { ApiAccountCurrency, ApiAccountCurrencyCreate, ApiCardBin, ApiCardBinCreate, ApiCountry, ApiCountryCreate, ApiCurrency, ApiCurrencyCreate, ApiRestrictionRequestUpdate, PageApiRestrictionRequest, RestrictionRequest } from './types';

export const restrictionsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        currencyRestrictionsList: builder.query<ApiCurrency[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader
            }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: 'gateway/restrictions/currencies',
                }
            },
            providesTags: ['Restrictions-Currency']
        }),
        addCurrencyRestriction: builder.mutation<ApiCurrency, ApiCurrencyCreate>({
            query: ({ accountHolderIdForHeader, ...currencyRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: currencyRestriction,
                url: 'gateway/restrictions/currencies',
            }),
            invalidatesTags: ['Restrictions-Currency', 'Restrictions-Pending']
        }),
        deleteCurrencyRestriction: builder.mutation<void, { currencyId: string, accountHolderIdForHeader: string }>({
            query: ({ currencyId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restrictions/currencies/${currencyId}`,
            }),
            invalidatesTags: ['Restrictions-Currency', 'Restrictions-Pending']
        }),

        addCurrencyRestrictionRequest: builder.mutation<ApiCurrency, ApiCurrencyCreate>({
            query: ({ accountHolderIdForHeader, ...currencyRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: currencyRestriction,
                url: 'gateway/restriction-requests/currencies',
            }),
            invalidatesTags: ['Restrictions-Currency', 'Restrictions-Pending']
        }),
        deleteCurrencyRestrictionRequest: builder.mutation<void, { currencyId: string, accountHolderIdForHeader: string }>({
            query: ({ currencyId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restriction-requests/currencies/${currencyId}`,
            }),
            invalidatesTags: ['Restrictions-Currency', 'Restrictions-Pending']
        }),



        countryRestrictionsList: builder.query<ApiCountry[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader
            }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: 'gateway/restrictions/countries',
                }
            },
            providesTags: ['Restrictions-Country']
        }),
        addCountryRestriction: builder.mutation<ApiCountry, ApiCountryCreate>({
            query: ({ accountHolderIdForHeader, ...countryRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: countryRestriction,
                url: 'gateway/restrictions/countries',
            }),
            invalidatesTags: ['Restrictions-Country', 'Restrictions-Pending']
        }),
        deleteCountryRestriction: builder.mutation<void, { countryId: string, accountHolderIdForHeader: string }>({
            query: ({ countryId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restrictions/countries/${countryId}`,
            }),
            invalidatesTags: ['Restrictions-Country']
        }),

        addCountryRestrictionRequest: builder.mutation<ApiCountry, ApiCountryCreate>({
            query: ({ accountHolderIdForHeader, ...countryRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: countryRestriction,
                url: 'gateway/restriction-requests/countries',
            }),
            invalidatesTags: ['Restrictions-Country', 'Restrictions-Pending']
        }),
        deleteCountryRestrictionRequest: builder.mutation<void, { countryId: string, accountHolderIdForHeader: string }>({
            query: ({ countryId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restriction-requests/countries/${countryId}`,
            }),
            invalidatesTags: ['Restrictions-Country', 'Restrictions-Pending']
        }),


        cardBinRestrictionsList: builder.query<ApiCardBin[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader
            }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: 'gateway/restrictions/card-bins',
                }
            },
            providesTags: ['Restrictions-Card-Bin']
        }),
        addCardBinRestriction: builder.mutation<ApiCardBin, ApiCardBinCreate>({
            query: ({ accountHolderIdForHeader, ...cardBinRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: cardBinRestriction,
                url: 'gateway/restrictions/card-bins',
            }),
            invalidatesTags: ['Restrictions-Card-Bin', 'Restrictions-Pending']
        }),
        deleteCardBinRestriction: builder.mutation<void, { cardBinId: string, accountHolderIdForHeader: string }>({
            query: ({ cardBinId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restrictions/card-bins/${cardBinId}`,
            }),
            invalidatesTags: ['Restrictions-Card-Bin']
        }),
        addCardBinRestrictionRequest: builder.mutation<ApiCardBin, ApiCardBinCreate>({
            query: ({ accountHolderIdForHeader, ...cardBinRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: cardBinRestriction,
                url: 'gateway/restriction-requests/card-bins',
            }),
            invalidatesTags: ['Restrictions-Card-Bin', 'Restrictions-Pending']
        }),
        deleteCardBinRestrictionRequest: builder.mutation<void, { cardBinId: string, accountHolderIdForHeader: string }>({
            query: ({ cardBinId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restriction-requests/card-bins/${cardBinId}`,
            }),
            invalidatesTags: ['Restrictions-Card-Bin']
        }),


        accountCurrencyRestrictionsList: builder.query<ApiAccountCurrency[], { accountHolderIdForHeader: string }>({
            query: ({ accountHolderIdForHeader
            }) => {
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    url: 'gateway/restrictions/account-currencies',
                }
            },
            providesTags: ['Restrictions-Account-Currencies']
        }),
        addAccountCurrencyRestriction: builder.mutation<ApiAccountCurrency, ApiAccountCurrencyCreate>({
            query: ({ accountHolderIdForHeader, ...cardBinRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: cardBinRestriction,
                url: 'gateway/restrictions/account-currencies',
            }),
            invalidatesTags: ['Restrictions-Account-Currencies', 'Restrictions-Pending']
        }),
        deleteAccountCurrencyRestriction: builder.mutation<void, { accountCurrencyId: string, accountHolderIdForHeader: string }>({
            query: ({ accountCurrencyId, accountHolderIdForHeader }) => ({
                method: 'DELETE',
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restrictions/account-currencies/${accountCurrencyId}`,
            }),
            invalidatesTags: ['Restrictions-Account-Currencies', 'Restrictions-Pending']
        }),
        addAccountCurrencyRestrictionRequest: builder.mutation<ApiAccountCurrency, ApiAccountCurrencyCreate>({
            query: ({ accountHolderIdForHeader, ...accountCurrencyRestriction }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'POST',
                body: accountCurrencyRestriction,
                url: 'gateway/restriction-requests/account-currencies',
            }),
            invalidatesTags: ['Restrictions-Account-Currencies', 'Restrictions-Pending']
        }),
        deleteAccountCurrencyRestrictionRequest: builder.mutation<void, ApiAccountCurrencyCreate>({
            query: ({ accountHolderIdForHeader, ...accountCurrencyRestriction }) => ({
                method: 'DELETE',
                body: accountCurrencyRestriction,
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                url: `gateway/restriction-requests/account-currencies`,
            }),
            invalidatesTags: ['Restrictions-Account-Currencies', 'Restrictions-Pending']
        }),



        restrictions: builder.query<PageApiRestrictionRequest, RestrictionRequest>({
            query: ({ accountHolderIdForHeader, ...params }) => {
                const parameters = removeEmpty({
                    ...params
                });
                return {
                    headers: {
                        'x-account-holder-id': accountHolderIdForHeader,
                    },
                    method: 'GET',
                    params: parameters,
                    url: 'gateway/restriction-requests',
                }
            },
            providesTags: ['Restrictions-Pending']
        }),


        updateRestriction: builder.mutation<void, ApiRestrictionRequestUpdate>({
            query: ({ restrictionId, accountHolderIdForHeader, ...rest }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'PUT',
                body: { ...rest },
                url: `gateway/restriction-requests/${restrictionId}`,
            }),
            invalidatesTags: ['Restrictions-Currency', "Restrictions-Country", "Restrictions-Card-Bin", "Restrictions-Account-Currencies", 'Restrictions-Pending']
        }),

        deleteRestriction: builder.mutation<void, { restrictionId: string, accountHolderIdForHeader: string }>({
            query: ({ restrictionId, accountHolderIdForHeader }) => ({
                headers: {
                    'x-account-holder-id': accountHolderIdForHeader,
                },
                method: 'DELETE',
                url: `gateway/restriction-requests/${restrictionId}`,
            }),
            invalidatesTags: ['Restrictions-Currency', "Restrictions-Country", "Restrictions-Card-Bin", "Restrictions-Account-Currencies", 'Restrictions-Pending']
        }),

    })
})

export const {
    useCurrencyRestrictionsListQuery,
    useAddCurrencyRestrictionMutation,
    useDeleteCurrencyRestrictionMutation,
    useAddCurrencyRestrictionRequestMutation,
    useDeleteCurrencyRestrictionRequestMutation,

    useCountryRestrictionsListQuery,
    useAddCountryRestrictionMutation,
    useDeleteCountryRestrictionMutation,
    useAddCountryRestrictionRequestMutation,
    useDeleteCountryRestrictionRequestMutation,

    useCardBinRestrictionsListQuery,
    useAddCardBinRestrictionMutation,
    useDeleteCardBinRestrictionMutation,
    useAddCardBinRestrictionRequestMutation,
    useDeleteCardBinRestrictionRequestMutation,


    useAccountCurrencyRestrictionsListQuery,
    useAddAccountCurrencyRestrictionMutation,
    useDeleteAccountCurrencyRestrictionMutation,
    useAddAccountCurrencyRestrictionRequestMutation,
    useDeleteAccountCurrencyRestrictionRequestMutation,


    useRestrictionsQuery,
    useUpdateRestrictionMutation,
    useDeleteRestrictionMutation

} = restrictionsApi;