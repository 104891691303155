import * as Yup from 'yup';

import { accountCurrencyValidationSchema, accountHolderIdValidationSchema, accountTypeValidationSchema } from 'src/components/forms';

export const accountFormValidationSchema = () =>
    Yup.object({
        friendlyName: Yup.string().max(100, 'Friendly Name must be less than 100 characters').required('Friendly Name is required'),
        accountType: accountTypeValidationSchema(),
        currencyCode: accountCurrencyValidationSchema(),
        accountHolderIdForHeader: accountHolderIdValidationSchema(),
    });