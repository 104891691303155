import { Menu } from '@mui/icons-material';
import { AppBar, AppBarProps,Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import type { FC } from 'react';

import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { SIDEBAR_WIDTH } from './utils/config';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = styled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: '#ffffff',
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      // zIndex: theme.zIndex.drawer + 100,
      position: 'absolute',
      left: 0,
      right: 0,
      width: 'auto',
      borderBottom: '1px solid #E4E7EB',
      [theme.breakpoints.up('lg')]: {
        left: `${SIDEBAR_WIDTH}px`
      }
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64, maxHeight: 64 , padding:0}}>
        <IconButton
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <Menu   sx={
            (theme) => ({
            color: theme.palette.primary.main,
             })} 
             fontSize="small" />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        {/* <LanguagePopover /> */}
        {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}
        {/* <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box> */}
        {/* <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
        <Box sx={{ ml: 2 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
