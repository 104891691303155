import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectBankLogo } from 'src/models/cms';
import Logo from '../icons/Logo';
export const LogoText = () => {
  const bankLogoUrl = useSelector(selectBankLogo);

  return bankLogoUrl ? (
    <Box
      sx={{ maxWidth: 225, height: 'auto' }}
      component="img"
      src={`${bankLogoUrl}`}
    />
  ) : (
    <></>
    // <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    //   <Logo sx={{ mr: '16px', height: 48, width: 48 }} />
    //   <Box
    //     sx={{ width: 150, height: 48 }}
    //     component="img"
    //     src="/static/icons/brand.svg"
    //   />
    // </Box>
    // // Aykla logo
    // <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    //   <Logo sx={{ mr: '16px', height: 48, width: 48 }} />
    //   <Box
    //     sx={{ width: 150, height: 48 }}
    //     component="img"
    //     src="/static/icons/brand.png"
    //   />
    // </Box>
  );
};
