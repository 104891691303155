import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { apiKeyTypes } from './constants';

export interface ApiKeyTypeFieldProps {
  disabled: boolean;
}
export const ApiKeyTypeField: FC<
  ApiKeyTypeFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
    const { t } = useTranslation();
    const { hasPermission } = usePermissions();

    return (
      <>
        <FormControl fullWidth margin="normal">
          <InputLabel
            htmlFor={`apiKeyType`}
            error={Boolean(touched[field.name] && errors[field.name])}
          >
            {t('common.apiKeyType')}
          </InputLabel>
          <Field
            component={Select}
            name={`apiKeyType`}
            fullWidth
            label={t('common.apiKeyType')}
            value={values[field.name]}
            error={Boolean(touched[field.name] && errors[field.name])}
            onBlur={handleBlur(`apiKeyType`)}
            onChange={handleChange(`apiKeyType`)}
            disabled={disabled}
          >
            {apiKeyTypes.map((i) => {
              // If not ADMIN permission cannot create a SYSTEM API KEY
              if (i !== 'SYSTEM' || hasPermission('ADMIN')) {
                return (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                )
              }
            })}
          </Field>
          <FormHelperText error>{t(errors[field.name] as string)}</FormHelperText>
        </FormControl>
      </>
    );
  };
