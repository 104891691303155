import { Chip, colors } from '@mui/material';
import React from 'react';

import {
  AccountHolderStatus,
  AccountHolderType,
} from 'src/models/accountHolders';

const statusColors = {
  PENDING: colors.orange,
  ACTIVE: colors.green,
  SUSPENDED: colors.red,
  PENDING_KYC: colors.orange,
  KYC_REQUIRED: colors.orange,
  KYC_COMPLETED: colors.green,
  INDIVIDUAL: colors.lime,
  BUSINESS: colors.teal,
  INTERNAL: colors.blue,
  EXTERNAL: colors.green,
};

export const AccountHolderStatusLabel = ({
  status,
}: {
  status: AccountHolderStatus | AccountHolderType;
}) => {
  // alert(status);
  const backgroundColor =
    statusColors[status] && `${statusColors[status][50]} !important`;
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={status} sx={{ backgroundColor, color }} />;
};
