import { Box, Button, Card, Drawer, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { SIDEBAR_WIDTH } from 'src/components/dashboard/utils/config';
import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { useAttributesQuery } from 'src/models/configurations';
import { AttributesTable } from './AttributesTable';
import { AttributeForm } from './forms/AttributeForm';

export const Attributes: FC<{
  acquiringConfigId: string;
  accountHolderId: string;
}> = ({ acquiringConfigId, accountHolderId }) => {
  const [addAttributeOpened, setAddAttributeOpened] = useState<boolean>(false);

  const { data } = useAttributesQuery(
    {
      accountHolderIdForHeader: accountHolderId,
      acquiringConfigId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const attributes = data || [];

  return (
    <>
      <Helmet>
        <title>Attributes</title>
      </Helmet>

      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',
            marginTop: '25px',
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }),
        ]}
      >
        <Typography color="textPrimary" variant="h5">
          Attributes
        </Typography>

        <Box
          sx={[
            {
              display: 'flex',
              gap: 1,
              marginBottom: '10px',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '10px',
              },
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            }),
          ]}
        >
          <PermissionGuard permission="CREATE_ACQUIRING_CONFIGURATION">
            <Button
              sx={{ mr: 0.5 }}
              variant="contained"
              onClick={() => setAddAttributeOpened(true)}
            >
              Create attribute
            </Button>
          </PermissionGuard>
        </Box>
      </Box>

      <PermissionGuard permission="CREATE_ACQUIRING_CONFIGURATION">
        <Drawer
          anchor="right"
          open={addAttributeOpened}
          onClose={() => setAddAttributeOpened(false)}
          PaperProps={{
            sx: [
              {
                backgroundColor: '#F3F7FE',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: `100%`,
                },
                [theme.breakpoints.up('md')]: {
                  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                },
              }),
            ],
          }}
        >
          <AttributeForm
            acquiringConfigId={acquiringConfigId}
            accountHolderId={accountHolderId}
            onClose={() => setAddAttributeOpened(false)}
          />
        </Drawer>
      </PermissionGuard>
      <PermissionGuard permission="GET_ACQUIRING_CONFIGURATION">
        <Card>
          <AttributesTable
            attributes={attributes}
            accountHolderId={accountHolderId}
          />
        </Card>
      </PermissionGuard>
    </>
  );
};
