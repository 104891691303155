import { format } from "date-fns";

import { AccountReportExportForm, ApiAccountReportExportForm } from ".";

export const mapApiAccountReportExportFormToApiAccountReportExportForm = (filter: AccountReportExportForm): ApiAccountReportExportForm => {
    return {
        startDate: format(filter.startDateTime, 'yyyy-MM-dd'),
        endDate: format(filter.endDateTime, 'yyyy-MM-dd'),
        reportType: filter.reportType
    }
}
