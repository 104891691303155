import { useCallback, useState } from 'react';

type Cb = () => void;

export const useToggle = (initialState: boolean): [boolean, Cb, Cb] => {
  const [isOn, setOn] = useState(initialState);

  const onTurnOn = useCallback(() => {
    setOn(true);
  }, []);

  const onTurnOff = useCallback(() => {
    setOn(false);
  }, []);

  return [isOn, onTurnOn, onTurnOff];
};
