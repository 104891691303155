import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, useState } from 'react';

import { FileInputField } from 'src/components/forms/FileInputField';
import { PreviewImageDialog } from 'src/components/PreviewImageDialog';
import { ApiKycSelfiesFormValues } from './types';

export const SelfieForm: FC = () => {
  const { values } = useFormikContext<ApiKycSelfiesFormValues>();
  const [previewImageDialog, setPreviewImageDialog] = useState({
    isOpen: false,
    title: '',
    file: null,
    onConfirm: () => void 0,
  });
  return (
    <>
      <Box sx={{ mb: '4px', mt: '30px' }}>
        <Typography variant="h6">Confirm your identity</Typography>
        <Typography variant="subtitle2">
          This must include a photo of yourself holding your Identity
          card/Passport{' '}
          <InfoOutlined
            onClick={() => {
              setPreviewImageDialog({
                isOpen: true,
                title: ``,
                file: '/static/selfie.png',
                onConfirm: () => void 0,
              });
            }}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            fontSize="small"
          />
        </Typography>
      </Box>
      <FileInputField
        defaultFileUrl={values.faceAttachmentFileUrl}
        defaultFileName={values.faceAttachmentFileName}
        label={'Selfie Attachment'}
        fileFieldName={'faceAttachment'}
        withPreview={true}
      />

      <PreviewImageDialog
        previewImageDialog={previewImageDialog}
        setPreviewImageDialog={setPreviewImageDialog}
      />
    </>
  );
};
