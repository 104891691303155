import { ApiAddress } from '../models/transactions';

export const createAddressString = (address: ApiAddress) => {
  const addressData = [
    address.postcode,
    address.line1,
    address.line2,
    address.city,
    address.state,
    address.countryCode,
  ].filter((part) => !!part);
  return addressData.length ? addressData.join(', ') : '—';
};
