import * as Yup from 'yup';

import { addressAttachmentTypeList, ApiKycAddressesRequest, ApiKycVerification } from 'src/models/kycVerifications';
import { localConfig } from 'src/services/config';
import { ApiKycAddressesFormValues } from './types';

export const userAddressesFormInitialValues = (accountHolderId: string, kycVerification: ApiKycVerification): ApiKycAddressesFormValues => ({
    accountHolderIdForHeader: accountHolderId,
    kycVerificationId: kycVerification?.id,
    addressAttachmentType: kycVerification?.addressAttachmentType || '',
    addressAttachment: null,


    addressAttachmentFileId: kycVerification?.addressAttachmentFileId,
    addressAttachmentFileName: kycVerification?.addressAttachmentFileName,
    addressAttachmentFileUrl: kycVerification?.addressAttachmentFileUrl,
});


export const userAddressesFormValuesToUserDetailsRequestBody = (
    formValues: ApiKycAddressesFormValues,
): ApiKycAddressesRequest => ({
    accountHolderIdForHeader: formValues.accountHolderIdForHeader,
    kycVerificationId: formValues.kycVerificationId,
    addressAttachmentType: formValues.addressAttachmentType,
    addressAttachment: formValues.addressAttachment,
});

export const updateUserAddressesFormValidationSchema =
    Yup.object().shape({
        addressAttachment: Yup
            .mixed()
            .when(['addressAttachmentFileId', 'addressAttachmentFileName'], {
                is: (addressAttachmentFileId, addressAttachmentFileName) => addressAttachmentFileId != null && addressAttachmentFileName != null,
                then: Yup.mixed().notRequired(),
                otherwise: Yup
                    .mixed()
                    .required("Address file is required")
                    .test("fileSize", "The file is too large", (value) => {
                        return value && value.size <= localConfig.UPLOAD_MAX_FILE_SIZE;
                    })
                    .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf", (value) => {
                        return value && (
                            value.type === "image/jpeg" ||
                            value.type === "image/bmp" ||
                            value.type === "image/png" ||
                            value.type === "application/pdf"
                        );
                    }),
            }),
        addressAttachmentType: Yup.mixed().required().oneOf(addressAttachmentTypeList as any),
        addressAttachmentFileId: Yup
            .string().when('addressAttachment', {
                is: (addressAttachment) => addressAttachment == null,
                then: Yup.string().required('Field is required'),
                otherwise: Yup.string().nullable(),

            }),
        addressAttachmentFileName: Yup
            .string().when('addressAttachment', {
                is: (addressAttachment) => addressAttachment == null,
                then: Yup.string().required('Field is required'),
                otherwise: Yup.string().nullable(),

            })
    }, [['addressAttachment', 'addressAttachmentFileId'], ['addressAttachment', 'addressAttachmentFileName'], ['addressAttachmentFileId', 'addressAttachmentFileName']]);


export const isUserAddressesFormCompleted = (kycVerification: ApiKycVerification): boolean => {
    try {
        updateUserAddressesFormValidationSchema.validateSync(kycVerification);
    } catch (e) {
        return false;
    }
    return true;
}