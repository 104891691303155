import * as Yup from 'yup';

import { clientInfoValidationSchema } from 'src/components/forms/specific/ClientForm';

export const createAccountHolderFormValidationSchema = () =>
    clientInfoValidationSchema().shape({
        parentId: Yup.string()
            .when("hasAdminPermission", {
                is: false,
                then: Yup.string().required('validations.parentClientIsRequired')
            }),
        userId: Yup.string().nullable()

    })