import { CreateUpdateAccountFormValues } from "src/screens/Accounts/forms/types-dto";
import { ApiAccount, ApiAccountCreate, ApiAccountUpdate } from "./types-api";

export const mapApiAccountToCreateUpdateAccountFormValues = (
    account: ApiAccount, accountHolderIdForHeader: string
): CreateUpdateAccountFormValues => ({
    id: account.id,
    friendlyName: account.friendlyName,
    accountType: account.accountType,
    currencyCode: account.currencyCode,
    accountHolderIdForHeader
});

export const mapCreateUpdateAccountFormValuesToApiAccountCreate = (
    accountDto: CreateUpdateAccountFormValues
): ApiAccountCreate => ({
    friendlyName: accountDto.friendlyName,
    accountType: accountDto.accountType,
    currencyCode: accountDto.currencyCode,
    accountHolderIdForHeader: accountDto.accountHolderIdForHeader
});

export const mapCreateUpdateAccountFormValuesToApiAccountUpdate = (
    accountDto: CreateUpdateAccountFormValues
): ApiAccountUpdate => ({
    id: accountDto.id,
    friendlyName: accountDto.friendlyName,
    accountHolderIdForHeader: accountDto.accountHolderIdForHeader
});

