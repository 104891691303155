import type { Theme } from '@mui/material';
import { Box, Button, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBackGroundShadowLogo } from 'src/hooks/useBackGroundShadowLogo';
import {
  resetCredentials,
  selectIsAuthorized,
  useLogoutMutation,
} from 'src/models/auth';
import { resetBank } from 'src/models/cms';

interface IWizardProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: '100%',
    minHeight: '100%',
    zIndex: 1,
    paddingTop: '2em',
    paddingBottom: '2em',
    [theme.breakpoints.up('md')]: {
      // display: 'flex',
      // alignItems: 'center',
      paddingTop: '4.5em',
      paddingBottom: '4.5em',
    },
  },
  wrap: {
    marginLeft: '10%',
    marginRight: '10%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginRight: '0',
    },
  },
}));

const WizardTemplate: FC<IWizardProps> = ({ children }) => {
  const classes = useStyles();
  const [logout] = useLogoutMutation();
  const isAuthorized = useSelector(selectIsAuthorized);
  const dispatch = useDispatch();
  const bgLogo = useBackGroundShadowLogo();
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${bgLogo})`,
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          backgroundSize: '100vh',
          height: '100%',
          width: '100%',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          backgroundImage:
            'linear-gradient(253.55deg, #F6F9FF 9.01%, #F4F8FF 99.52%)',
          position: 'fixed',
          top: 0,
          bottom: 0,
          width: '100%',
          zIndex: -2,
        }}
      />
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.wrap}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {isAuthorized && (
              <Button
                onClick={() => {
                  logout();
                  dispatch(resetCredentials());
                  dispatch(resetBank());
                }}
              >
                Logout
              </Button>
            )}
          </Box>
          <Box>{children}</Box>
        </Box>
      </Container>
    </>
  );
};

WizardTemplate.propTypes = {
  children: PropTypes.node,
};

export default WizardTemplate;
