import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { FileInputField } from 'src/components/forms/FileInputField';
import { addressAttachmentTypeList } from 'src/models/kycVerifications';
import { ApiKycAddressesFormValues } from './types';

export const AddressForm: FC = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<ApiKycAddressesFormValues>();

  return (
    <>
      <Box sx={{ mb: '4px', mt: '30px' }}>
        <Typography variant="h6">Proof of address</Typography>
        <Typography variant="subtitle2">
          Document must include name, surname, full address and date of issue.
          The document should not be older than three months.
        </Typography>
      </Box>
      <FormControl fullWidth margin="normal">
        <InputLabel
          error={Boolean(
            touched.addressAttachmentType && errors.addressAttachmentType,
          )}
        >
          Address Attachment Type
        </InputLabel>
        <Select
          error={Boolean(
            touched.addressAttachmentType && errors.addressAttachmentType,
          )}
          value={values.addressAttachmentType}
          label="Address Attachment Type"
          onBlur={handleBlur}
          onChange={handleChange}
          name="addressAttachmentType"
          variant="outlined"
        >
          {addressAttachmentTypeList.map((i) => (
            <MenuItem key={i} value={i}>
              {capitalCase(i)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {touched.addressAttachmentType && errors.addressAttachmentType}
        </FormHelperText>
      </FormControl>

      <FileInputField
        defaultFileUrl={values.addressAttachmentFileUrl}
        defaultFileName={values.addressAttachmentFileName}
        label={'Address Attachment'}
        fileFieldName={'addressAttachment'}
        withPreview={true}
      />
    </>
  );
};
