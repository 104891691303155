import { dateOfBirthInitialValue, nationalityInitialValue } from "../.."
import { addressInitialValues } from "../Address/utils"
import { phoneInitialValues } from "../Phone/utils"
import { UserDetailsInfo } from "./types"
import { userDetailsInfoValidationSchema } from "./validations"

export const userDetailsInitialValues = (userDetailsInfo?: UserDetailsInfo): UserDetailsInfo => {
    return {
        phone: phoneInitialValues(),
        firstName: '',
        lastName: '',
        dateOfBirth: dateOfBirthInitialValue(),
        nationality: nationalityInitialValue(),
        idPassportNo: '',
        address: addressInitialValues(),
        ...userDetailsInfo
    }
}


export const isUserDetailsFormCompleted = async (userDetailsInfo?: UserDetailsInfo): Promise<boolean> => {
    try {
        await userDetailsInfoValidationSchema().validate(userDetailsInfo);
    } catch (e) {
        return false;
    }
    return true;
}
