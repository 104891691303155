import { removeEmpty } from '../../utils/removeEmpty';
import { apiWithTag } from '../emptySplitApi';
import { ApiTransferChannel } from '../transferChannels';
import {
  AccountEntryListRequest,
  ApiAcquiringDataProcessing,
  ApiAdjustmentRequest,
  ApiPartnerBank,
  ApiTransactionSimulation,
  ApiTransferRequest,
  CardTokenRequest,
  CardTokenResponse,
  PageApiAccountEntry,
  Transaction,
  TransactionListRequest,
  TransactionListResponse,
  TransactionLogsRequest,
  TransactionLogsResponse,
  TransactionRequest,
  TransactionResponse,
} from './types';

export const transactionsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    transaction: builder.query<TransactionResponse, TransactionRequest>({
      query: ({ accountHolderId, id }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'GET',
        url: `gateway/transactions/${id}`,
      }),
    }),
    transactionsList: builder.query<
      TransactionListResponse,
      TransactionListRequest
    >({
      query: ({ accountHolderIdForHeader, ...params }) => {
        const parameters = removeEmpty({
          ...params,
          ...(params.startDateTime
            ? { startDateTime: params.startDateTime.toISOString() }
            : {}),
          ...(params.startDateTime
            ? { endDateTime: params.endDateTime.toISOString() }
            : {}),
        });

        return {
          headers: {
            'x-account-holder-id': accountHolderIdForHeader,
          },
          method: 'GET',
          params: parameters,
          url: 'gateway/transactions',
        };
      },
    }),

    accountEntries: builder.query<
      PageApiAccountEntry,
      AccountEntryListRequest
    >({
      query: ({ transactionId, accountHolderId, ...params }) => {
        return {
          headers: {
            'x-account-holder-id': accountHolderId,
          },
          method: 'GET',
          params,
          url: `gateway/transactions/${transactionId}/account-entries`,
        };
      },
    }),
    acquiringProcessing: builder.query<
      ApiAcquiringDataProcessing,
      { id: string, accountHolderId: string }
    >({
      query: ({ id, accountHolderId }) => {
        return {
          headers: {
            'x-account-holder-id': accountHolderId,
          },
          method: 'GET',
          // params: parameters,
          url: `gateway/transactions/${id}/acquiring-processing`,
        };
      },
    }),
    transactionLogs: builder.query<
      TransactionLogsResponse,
      TransactionLogsRequest
    >({
      query: ({ accountHolderId, id, logType }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'GET',
        params: {
          ...(logType ? { logType } : {}),
        },
        url: `gateway/transactions/${id}/transaction-logs`,
      }),
    }),
    cardTokens: builder.query<CardTokenResponse, CardTokenRequest>({
      query: ({ accountHolderId, id }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'GET',
        url: `gateway/card-tokens/${id}`,
      }),
    }),
    transferChannels: builder.query<ApiTransferChannel[], { accountHolderId: string }>({
      query: ({ accountHolderId }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'GET',
        url: `gateway/transactions/transfer-channels`,
      }),
    }),
    TransactionPartnerBanks: builder.query<ApiPartnerBank[], { accountHolderId: string }>({
      query: ({ accountHolderId }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        method: 'GET',
        url: `gateway/transactions/partner-banks`,
      }),
    }),
    performTransaction: builder.mutation<Transaction, ApiTransferRequest>({
      query: ({ accountHolderId, ...body }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        body,
        method: 'POST',
        // params: {
        // },
        url: `gateway/transfers`,
      }),
    }),
    simulateTransaction: builder.mutation<ApiTransactionSimulation, ApiTransferRequest>({
      query: ({ accountHolderId, ...body }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        body,
        method: 'POST',
        // params: {
        // },
        url: `gateway/transfers/simulate`,
      }),
    }),
    performAdjustment: builder.mutation<Transaction, ApiAdjustmentRequest>({
      query: ({ accountHolderId, ...body }) => ({
        headers: {
          'x-account-holder-id': accountHolderId,
        },
        body,
        method: 'POST',
        url: `gateway/adjustments`,
      }),
    }),
  }),

});

export const {
  useLazyTransactionQuery,
  useTransactionQuery,
  useTransactionsListQuery,
  useAccountEntriesQuery,
  useAcquiringProcessingQuery,
  useTransactionLogsQuery,
  useCardTokensQuery,
  useTransferChannelsQuery,
  useTransactionPartnerBanksQuery,
  usePerformTransactionMutation,
  useSimulateTransactionMutation,
  usePerformAdjustmentMutation
} = transactionsApi;
