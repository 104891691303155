import { Box, Card, CardContent, Link } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import AuthTemplate from 'src/components/AuthTemplate';
import {
  resetCredentials,
  useLogoutMutation,
  useUserVerificationConfirmMutation,
} from 'src/models/auth';
import { resetBank } from 'src/models/cms';
import { links } from 'src/navigation/constants';

const UserVerificationConfirm: FC = () => {
  const { verificationId, verificationCode } =
    useParams<{ verificationId: string; verificationCode: string }>();
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const [userVerificationConfirm, { isLoading, isSuccess, isError, error }] =
    useUserVerificationConfirmMutation({});

  useEffect(() => {
    logout();
    dispatch(resetCredentials());
    dispatch(resetBank());
    userVerificationConfirm({ verificationId, verificationCode });
  }, []);

  return (
    <>
      <Helmet>
        <title>User Verification</title>
      </Helmet>
      <AuthTemplate>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline', mb: '16px' }}>
              <Box sx={{ typography: 'h4' }}>User Verification</Box>
            </Box>
            {isLoading && <CircularProgress />}
            {isSuccess && (
              <Box sx={{ typography: 'body1', mb: '16px' }}>
                {
                  <>
                    Account Verified, click{' '}
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to={links.login}
                      variant="body1"
                    >
                      here
                    </Link>{' '}
                    to login.
                  </>
                }
              </Box>
            )}
            {isError && (
              <Box sx={{ typography: 'body1', mb: '16px' }}>
                {/* {JSON.stringify(error)}; */}
                {error?.['data'].errorCode == 'VERIFICATION_EXPIRED' && (
                  <>
                    It seems that the link you are trying to access has expired.
                    Click{' '}
                    <Link
                      color="textSecondary"
                      component={RouterLink}
                      to="/user/verification"
                      variant="body1"
                    >
                      {' '}
                      here
                    </Link>{' '}
                    to recieve a new link.
                  </>
                )}
                {error?.['data'].errorCode ==
                  'VERIFICATION_RETRIES_EXCEEDED' && (
                  <>
                    There has been too many verification retry attempts. Please
                    wait before trying again.
                  </>
                )}
                {error?.['data'].errorCode == 'VERIFICATION_FAILED' && (
                  <>
                    The verification code is invalid. Please retry your
                    verification
                    <Link
                      color="textSecondary"
                      component={RouterLink}
                      to="/user/verification"
                      variant="body1"
                    >
                      {' '}
                      here{' '}
                    </Link>
                    .
                  </>
                )}
              </Box>
            )}
          </CardContent>
        </Card>
      </AuthTemplate>
    </>
  );
};

export default UserVerificationConfirm;
