import { Box, Card, CardContent } from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import AuthTemplate from 'src/components/AuthTemplate';
import { RegisterWizard } from './RegisterWizard';

const AccountHolderRegisterScreen: FC = () => {
  //
  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <AuthTemplate>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box sx={{ typography: 'h4', mb: '32px' }}>Register Client</Box>
            <RegisterWizard />
          </CardContent>
        </Card>
      </AuthTemplate>
    </>
  );
};

export default AccountHolderRegisterScreen;
