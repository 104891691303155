import { Chip, colors } from '@mui/material';
import { capitalCase } from 'change-case';
import React from 'react';

import { UserStatus } from 'src/models/users';

const statusColors = {
  ACTIVE: colors.green,
  DORMANT: colors.grey,
  EXPIRED: colors.grey,
  LOCKED: colors.red,
  PENDING: colors.orange,
  PENDING_EMAIL: colors.orange,
  PENDING_INVITATION: colors.orange,
  PENDING_SMS: colors.orange,
  SUSPENDED: colors.red,
};


export const UserStatusLabel = ({
  status,
}: {
  status: UserStatus;
}) => {
  // alert(status);
  const backgroundColor = statusColors[status] && `${statusColors[status][50]} !important`;
  const color = statusColors[status] && statusColors[status][400];
  return <Chip label={capitalCase(status)} sx={{ backgroundColor, color }} />;
};
