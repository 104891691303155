import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import React, { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { CountryFlag } from 'src/components/CountryFlag';
import { AccountHolderIdField } from 'src/components/forms';
import { FormikTextField } from 'src/components/forms/TextField';
import { useTotp } from 'src/hooks/useTotp';
import { useCountriesQuery } from 'src/models/countryStates/api';
import { ApiCountryLookup } from 'src/models/countryStates/types';
import {
  ApiCountryCreate,
  mapCreateCountryFormValuesToApiCountryCreate,
  useAddCountryRestrictionMutation,
  useAddCountryRestrictionRequestMutation,
} from 'src/models/restrictions';
import { Nullable } from 'src/utils/types';
import { CreateCountryFormValues } from './types-dto';
import { createCountryFormValuesInitialValue } from './utils';
import { countryFormValidationSchema } from './validations';
interface FormProps {
  onClose?: () => void;
  accountHolderId: string;
  isGlobal: boolean;
  isRequest: boolean;
}
export const CountryForm: FC<FormProps> = ({
  onClose,
  accountHolderId,
  isGlobal,
  isRequest,
}) => {

  const [addCountry] = useAddCountryRestrictionMutation();
  const [addCountryRequest] = useAddCountryRestrictionRequestMutation();
  const formikRef = useRef(null);
  useTotp(formikRef);

  const countries = useCountriesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { t } = useTranslation();

  return (
    <Formik<CreateCountryFormValues>
      innerRef={formikRef}
      initialValues={{
        ...createCountryFormValuesInitialValue(!isGlobal ? accountHolderId : '')
      }}
      validationSchema={countryFormValidationSchema}
      onSubmit={async (values): Promise<void> => {
        try {
          const req = {
            countryCode: values.countryCode,
            accountHolderIdForHeader: values.accountHolderIdForHeader,
            ...(values.displayNameOverride.length > 0
              ? { displayNameOverride: values.displayNameOverride }
              : {}),
          }
          const countryRestrictionRequest: ApiCountryCreate = await mapCreateCountryFormValuesToApiCountryCreate(values);
          if (isRequest == true) {
            await addCountryRequest(countryRestrictionRequest).unwrap();
            toast.success(`Country restriction request created successfully.`);
          } else {

            await addCountry(countryRestrictionRequest).unwrap();
            toast.success(`Country restriction created successfully.`);
          }

          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
      }): JSX.Element => (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 38px 24px 32px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={'textPrimary'} variant="h5">
                Create Country {isRequest ? 'Request' : ''}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: '32px' }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`accountHolderIdForHeader`}
                component={AccountHolderIdField}
                disabled={!isGlobal}
                accountHolderId={accountHolderId} />

              {countries && countries?.data && countries?.data?.length > 0 && (
                <FormControl fullWidth margin="normal">
                  <Field
                    component={Autocomplete}
                    name={`countryCode`}
                    fullWidth
                    value={values.countryCode}
                    onBlur={handleBlur(`countryCode`)}
                    onChange={(e: any, value: ApiCountryLookup) => {
                      setFieldValue(
                        `countryCode`,
                        value !== null ? value : null,
                      ); // Set Country
                    }}
                    disabled={countries?.data?.length === 1}
                    options={countries.data}
                    autoHighlight
                    variant="outlined"
                    filterOptions={(
                      options: Array<ApiCountryLookup>,
                      state: { inputValue: string },
                    ): Array<ApiCountryLookup> => {
                      // const value = state.inputValue;
                      return options.filter(
                        (x) =>
                          x.name
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()) ||
                          x.code
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()) ||
                          x.alpha3
                            ?.toLowerCase()
                            ?.includes(state.inputValue.toLowerCase()),
                      );
                    }}
                    isOptionEqualToValue={(
                      option: ApiCountryLookup,
                      value: Nullable<ApiCountryLookup>,
                    ) => option.code === value?.code}
                    getOptionLabel={(option: ApiCountryLookup) =>
                      `${option.name} (${option.code})` || ''
                    }
                    renderOption={(props: any, option: ApiCountryLookup) => (
                      <Box
                        key={option}
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <CountryFlag countryCode={option.code} />
                        {option.name} ({option.code})
                      </Box>
                    )}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <>
                        <TextField
                          {...params}
                          label={t('common.country')}
                          error={Boolean(
                            touched.countryCode &&
                            errors.countryCode,
                          )}
                          helperText={
                            touched.countryCode &&
                            t(typeof errors.countryCode === 'object' ? errors?.countryCode?.alpha3 : errors.countryCode)
                          }
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                          InputProps={{
                            ...params.InputProps,
                            ...(values.countryCode?.code
                              ? {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <>
                                      <Box sx={{ ml: '8px' }}>
                                        <CountryFlag
                                          countryCode={
                                            values.countryCode?.code
                                          }
                                        />
                                      </Box>
                                      {/* {values[field.name]?.countryCode?.name}  ({values[field.name]?.countryCode?.code}) */}
                                    </>
                                  </InputAdornment>
                                ),
                              }
                              : {}),
                          }}
                        />
                      </>
                    )}
                  ></Field>
                </FormControl>
              )}

              {(!isRequest &&
                <FormikTextField
                  name="displayNameOverride"
                  label="Display Name Override"
                  autoComplete="off"
                  margin='normal'
                />)}

              <Box sx={{ mt: '24px' }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
