import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { ApiKycVerification } from 'src/models/kycVerifications';
import { SortOrder } from 'src/models/types';
import { KycVerificationStatusLabel } from './KycVerificationStatusLabel';

interface AccountHolderTable {
  kycVerifications: ApiKycVerification[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
  accountHolderId: string;
}

const headCells = [
  // { title: 'Created date', id: 'createdDateTime', doSort: true },
  { title: 'Updated date', id: 'updatedDateTime', doSort: true },
  // { title: 'Id', id: 'id', doSort: true },
  { title: 'Status', id: 'kycStatus', doSort: false },
  // {
  //   title: 'Identity Attachment Type',
  //   id: 'identityAttachmentType',
  //   doSort: false,
  // },
  // {
  //   title: 'Identity Attachment File name',
  //   id: 'identityAttachmentFileName',
  //   doSort: false,
  // },
  // {
  //   title: 'Identity Attachment File Id',
  //   id: 'identityAttachmentFileId',
  //   doSort: false,
  // },
  // {
  //   title: 'Aditional Identity Attachment Type',
  //   id: 'additionalIdentityAttachmentType',
  //   doSort: false,
  // },
  // {
  //   title: 'Aditional Identity Attachment File name',
  //   id: 'additionalIdentityAttachmentFileName',
  //   doSort: false,
  // },
  // {
  //   title: 'Aditional Identity Attachment File Id',
  //   id: 'additionalIdentityAttachmentFileId',
  //   doSort: false,
  // },
  { title: 'Passport Id Number', id: 'idPassportNo', doSort: false },
  // { title: 'Passport Issue Date', id: 'idPassportIssueDate', doSort: true },
  // { title: 'Passport Expiry Date', id: 'idPassportExpiryDate', doSort: true },
  // {
  //   title: 'Address Attachment File Name',
  //   id: 'addressAttachmentFileName',
  //   doSort: true,
  // },
  // {
  //   title: 'Address Attachment File Id',
  //   id: 'addressAttachmentFileId',
  //   doSort: true,
  // },
  // {
  //   title: 'Address Attachment Type',
  //   id: 'addressAttachmentType',
  //   doSort: true,
  // },
  // {
  //   title: 'Face Attachment File Name',
  //   id: 'faceAttachmentFileName',
  //   doSort: true,
  // },
  // {
  //   title: 'Face Attachment File Id',
  //   id: 'faceAttachmentFileId',
  //   doSort: true,
  // },
  // { title: 'Client Id', id: 'accountHolderId', doSort: true },
  { title: 'Email', id: 'email', doSort: true },
  { title: 'First Name', id: 'firstName', doSort: true },
  { title: 'Last Name', id: 'lastName', doSort: true },
  { title: 'Date of Birth', id: 'dateOfBirth', doSort: true },
  { title: 'Nationality', id: 'nationality', doSort: true },
  // { title: 'Address line 1', id: 'addressLine1', doSort: true },
  // { title: 'Address line 2', id: 'addressLine2', doSort: true },
  // { title: 'City', id: 'city', doSort: true },
  // { title: 'Post Code', id: 'zipPostcode', doSort: true },
  // { title: 'State', id: 'countryState', doSort: true },
  // { title: 'Country', id: 'country', doSort: true },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const KycVerificationsTable: FC<AccountHolderTable> = ({
  kycVerifications,
  orderBy,
  sortDirection,
  onRequestSort,
  accountHolderId,
}) => {
  const navigate = useNavigate();
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  // const [editAccountHolderOpened, setEditAccountHolderOpened] = useState<ApiAccountHolder>(null);
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* In account holder listing if NOT Internal hide current account holder from the list */}
            {kycVerifications.map((kycVerification) => {
              return (
                <TableRow
                  hover
                  key={kycVerification.id}
                // onClick={() => navigate(`${kycVerification.id}`)}
                >
                  {/* <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(kycVerification.createdDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell> */}
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(kycVerification.updatedDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography noWrap variant="inherit">
                      {kycVerification.id}
                    </Typography>
                  </TableCell> */}

                  <TableCell>
                    <KycVerificationStatusLabel
                      status={kycVerification.kycStatus}
                    />
                  </TableCell>

                  {/* <TableCell>
                    <KycVerificationStatusLabel
                      status={kycVerification.identityAttachmentType}
                    />
                  </TableCell> */}

                  {/* <TableCell>
                    {kycVerification.identityAttachmentFileName}
                  </TableCell> */}
                  {/* <TableCell>
                    {kycVerification.identityAttachmentFileId}
                  </TableCell> */}

                  {/* <TableCell>
                    <KycVerificationStatusLabel
                      status={kycVerification.additionalIdentityAttachmentType}
                    />
                  </TableCell> */}

                  {/* <TableCell>
                    {kycVerification.additionalIdentityAttachmentFileName}
                  </TableCell> */}
                  {/* <TableCell>
                    {kycVerification.additionalIdentityAttachmentFileId}
                  </TableCell> */}
                  <TableCell>{kycVerification.idPassportNo}</TableCell>
                  {/* <TableCell>
                    {moment
                      .utc(kycVerification.idPassportIssueDate)
                      .format('DD/MM/yyyy')}
                  </TableCell> */}
                  {/* <TableCell>
                    {moment
                      .utc(kycVerification.idPassportExpiryDate)
                      .format('DD/MM/yyyy')}
                  </TableCell> */}
                  {/* <TableCell>
                    {kycVerification.addressAttachmentFileName}
                  </TableCell> */}
                  {/* <TableCell>
                    {kycVerification.addressAttachmentFileId}
                  </TableCell> */}

                  {/* <TableCell>
                    <KycVerificationStatusLabel
                      status={kycVerification.addressAttachmentType}
                    />
                  </TableCell> */}

                  {/* <TableCell>
                    {kycVerification.faceAttachmentFileName}
                  </TableCell> */}
                  {/* <TableCell>{kycVerification.faceAttachmentFileId}</TableCell> */}
                  {/* <TableCell>{kycVerification.accountHolderId}</TableCell> */}
                  <TableCell>{kycVerification.email}</TableCell>
                  <TableCell>{kycVerification.firstName}</TableCell>
                  <TableCell>{kycVerification.lastName}</TableCell>
                  <TableCell>
                    {moment
                      .utc(kycVerification.dateOfBirth)
                      .format('DD/MM/yyyy')}
                  </TableCell>
                  <TableCell>{kycVerification.nationality}</TableCell>
                  {/* <TableCell>{kycVerification.addressLine1}</TableCell> */}
                  {/* <TableCell>{kycVerification.addressLine2}</TableCell> */}
                  {/* <TableCell>{kycVerification.city}</TableCell> */}
                  {/* <TableCell>{kycVerification.zipPostcode}</TableCell> */}
                  {/* <TableCell>{kycVerification.countryState}</TableCell> */}
                  {/* <TableCell>{kycVerification.country}</TableCell> */}

                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={`${kycVerification.id}`}
                    >
                      <ArrowForward fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
