import type { RootState } from 'src/services/redux';

export const selectBankLogo = (state: RootState) =>
  state.cms?.bank?.logoUrl;

export const selectAllowRegistration = (state: RootState): boolean =>
  state.cms?.bank?.allowRegistration || false;

export const selectBank = (state: RootState) =>
  state.cms?.bank;

export const selectThemeColour = (state: RootState) =>
  state.cms?.bank?.themeColourHex;

export const selectBankDisplayName = (state: RootState) =>
  state.cms?.bank?.displayName;


export const selectkycCheckEnabled = (state: RootState) =>
  state.cms?.bank?.kycCheckEnabled;

