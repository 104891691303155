import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { Link as RouterLink } from 'react-router-dom';

import { ApiAccount } from 'src/models/accounts';
import { SortOrder } from 'src/models/types';
import { AccountStatusLabel } from './AccountStatusLabel';

interface AccountTable {
  accounts: ApiAccount[];
  orderBy?: string;
  onRequestSort?: (property: string) => void;
  sortDirection?: SortOrder;
}

const headCells = [
  { title: 'Created date', id: 'createdDateTime', doSort: true },
  // { title: 'Id', id: 'id', doSort: true },
  { title: 'Name', id: 'friendlyName', doSort: true },
  { title: 'Type', id: 'accountType', doSort: false },
  { title: 'Status', id: 'accountStatus', doSort: false },
  { title: 'Account Number', id: 'accountNumber', doSort: false },
  { title: 'Currency', id: 'currencyCode', doSort: false },
  { title: 'Actual Balance', id: 'actualBalance', doSort: false },
  { title: 'Available Balance', id: 'availableBalance', doSort: false },
  { title: 'External Reference', id: 'externalReference', doSort: true },

  // { title: 'Client Id', id: 'accountHolderId', doSort: false },
  // { title: 'Old Account Number', id: 'oldAccountNumber', doSort: false },
  { title: 'Actions', id: 'actions', doSort: false },
];

export const AccountsTable: FC<AccountTable> = ({
  accounts,
  orderBy,
  sortDirection,
  onRequestSort,
}) => {
  const sortEnabled = orderBy && sortDirection && onRequestSort;
  // const [editAccountOpened, setEditAccountOpened] = useState<ApiAccount>(null);
  const createSortHandler = (property: string) => () => {
    if (sortEnabled) {
      onRequestSort(property);
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 700 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headCells.map(({ title, id, doSort }) => (
                <TableCell
                  key={id}
                  sortDirection={
                    sortEnabled && doSort && orderBy === id
                      ? sortDirection
                      : false
                  }
                >
                  {sortEnabled && doSort ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? sortDirection : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => {
              return (
                <TableRow
                  hover
                  key={account.id}
                // onClick={() => navigate(`${account.id}`)}
                >
                  <TableCell>
                    <Typography noWrap variant="inherit">
                      {format(
                        new Date(account.createdDateTime),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {account.id}
                  </Typography>
                </TableCell> */}
                  <TableCell>{account.friendlyName}</TableCell>
                  <TableCell>
                    <AccountStatusLabel status={account.accountType} />
                  </TableCell>
                  <TableCell>
                    <AccountStatusLabel status={account.accountStatus} />
                  </TableCell>
                  <TableCell>{account.accountNumber}</TableCell>
                  {/* <TableCell>{account.currencyCode}</TableCell> */}
                  <TableCell>
                    <Chip
                      sx={{ pl: '5px', m: 1 }}

                      label={`${account.currencyCode}`}
                      icon={<CurrencyFlag currency={account.currencyCode.toLocaleLowerCase()} size="md" />}
                    />
                  </TableCell>
                  <TableCell>{account.actualBalance.displayText}</TableCell>
                  <TableCell>{account.availableBalance.displayText}</TableCell>
                  <TableCell>{account.externalReference}</TableCell>
                  {/* <TableCell>
                  <Typography noWrap variant="inherit">
                    {account.accountHolderId}
                  </Typography>
                </TableCell>  */}
                  {/* <TableCell>{account.oldAccountNumber}</TableCell> */}
                  <TableCell align="right">
                    <IconButton component={RouterLink} to={`${account.id}`}>
                      <ArrowForward fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
