import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsAuthorized } from '../selectors';
import { resetCredentials as resetCreds } from '../slice';

interface Props {
  immediately?: boolean;
}

export const useResetCredentials = ({ immediately = false }: Props) => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(selectIsAuthorized);

  const resetCredentials = useCallback(() => {
    if (isAuthorized) {
      dispatch(resetCreds());
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (immediately) {
      resetCredentials();
    }
  }, [immediately]);

  return {
    resetCredentials,
  };
};
