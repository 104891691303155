import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Step,
    StepButton,
    Stepper,
    Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { FC, ReactElement, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import ObjectSchema, { AnyObject, TypeOfShape } from 'yup/lib/object';

import { accountHolderTypes } from 'src/components/forms';
import { useTotp } from 'src/hooks/useTotp';
import { mapOnboardingInfoToApiOnboardingRequest, useAddAccountHolderMutation } from 'src/models/accountHolders';
import { useRefreshTokenMutation, useUserExistsVerificationMutation } from 'src/models/auth';
import { usePermissions } from 'src/models/auth/hooks/usePermissions';
import { Nullable } from 'src/utils/types';
import { AccountHolderDetailsForm, accountHolderDetailsInitialValues, createAccountHolderFormValidationSchema, isCreateAccountHolderFormCompleted } from './AccountHolderDetailsForm';
import { createAccountHolderInviteUserFormValidationSchema, InviteUserForm, inviteUserInitialValues, isInviteUserFormCompleted } from './InviteUserForm';
import { createAccountHolderPermissionGroupFormValidationSchema, isAccountHolderPermissionGroupFormCompleted, permissionGroupInitialValues, PermissionGroupsForm } from './PermissionGroupsForm';
import { AccountHolderInfo } from './types-dto';
import { accountHolderUserDetailsInitialValues, createAccountHolderUserFormValidationSchema, isUserDetailsFormCompleted, UserDetailsForm } from './UserDetailsForm';


export const CreateAccountHolderWizard: FC<{
    onClose?: () => void;
    accountHolderIdForHeader: string;
    accountHolderIdOverride?: string;
}> = ({
    accountHolderIdOverride,
    accountHolderIdForHeader,
    onClose,
}) => {
        const { t } = useTranslation();
        const { hasPermission } = usePermissions();
        const formikRef = useRef(null);
        useTotp(formikRef);

        const [currentStep, setCurrentStep] = React.useState<number>(0);
        const CLIENT = 0, USER_DETAILS = 1, USER_INVITE = 2, PERMISSIONS = 3;
        const [steps, setSteps] = React.useState<number[]>([
            CLIENT, USER_DETAILS, PERMISSIONS
        ]);

        const [currentValues, setCurrentValues] =
            React.useState<Nullable<AccountHolderInfo>>(null);
        const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

        const userExists = useUserExistsVerificationMutation();
        const [createAccountHolder] = useAddAccountHolderMutation();
        const [refreshToken] = useRefreshTokenMutation();

        const availableSteps = React.useMemo(
            (): {
                name: string;
                validationSchema: ObjectSchema<any, AnyObject, TypeOfShape<any>>;
                component: ReactElement<any, any>;
                completed: (values: AccountHolderInfo) => Promise<boolean>;
            }[] => [
                    {
                        name: t('common.client'),
                        validationSchema: createAccountHolderFormValidationSchema(),
                        component: <AccountHolderDetailsForm
                            accountHolderId={accountHolderIdForHeader}
                            accountHolderIdOverride={accountHolderIdOverride}
                            onValueChange={(value: string) => {
                                if (value == 'create_new_user') {
                                    setSteps([CLIENT, USER_DETAILS, PERMISSIONS]);
                                    setCompleted(Array(3).fill(false));
                                } else if (value == 'invite_a_user') {
                                    setSteps([CLIENT, USER_INVITE, PERMISSIONS]);
                                    setCompleted(Array(3).fill(false));
                                } else if (value == 'without_user') {
                                    setSteps([CLIENT]);
                                    setCompleted(Array(1).fill(false));
                                } else {
                                    setSteps([CLIENT, PERMISSIONS]);
                                    setCompleted(Array(2).fill(false));
                                }
                            }}
                        />,
                        completed: isCreateAccountHolderFormCompleted(),
                    },
                    {
                        name: t('common.userDetails'),
                        validationSchema: createAccountHolderUserFormValidationSchema(userExists),
                        component: <UserDetailsForm accountHolderId={accountHolderIdForHeader} />,
                        completed: isUserDetailsFormCompleted(userExists),
                    },
                    {
                        name: t('common.inviteUser'),
                        validationSchema: createAccountHolderInviteUserFormValidationSchema(userExists),
                        component: <InviteUserForm />,
                        completed: isInviteUserFormCompleted(userExists),
                    },
                    {
                        name: t('common.permissions'),
                        validationSchema: createAccountHolderPermissionGroupFormValidationSchema(),
                        component: <PermissionGroupsForm accountHolderId={accountHolderIdForHeader} />,
                        completed: isAccountHolderPermissionGroupFormCompleted(),
                    },
                ],
            [t, userExists],
        );

        const [completed, setCompleted] = React.useState<boolean[]>(
            Array(steps.length).fill(false),
        );

        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '24px 38px 24px 32px',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            sx={{ mr: 2 }}
                            aria-label="close"
                            size="medium"
                            onClick={() => onClose()}
                        >
                            <Close color="inherit" fontSize="inherit" />
                        </IconButton>
                        <Typography color={'textPrimary'} variant="h5">
                            {t('common.createClient')}
                        </Typography>
                    </Box>
                </Box>

                <Divider />
                <Box sx={{ padding: '32px' }}>
                    <Stepper nonLinear activeStep={currentStep} alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={availableSteps[step].name} completed={completed[index]}>
                                <StepButton onClick={() => setCurrentStep(index)}>
                                    {availableSteps[step].name}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Formik<AccountHolderInfo>
                        enableReinitialize
                        key={currentStep}
                        innerRef={formikRef}
                        initialValues={{
                            ...accountHolderUserDetailsInitialValues(),
                            ...accountHolderDetailsInitialValues(
                                hasPermission('ADMIN'),
                                accountHolderIdOverride || '',
                                accountHolderTypes[0],
                            ),
                            ...inviteUserInitialValues(),
                            ...permissionGroupInitialValues(),
                            ...currentValues,
                        }}
                        validationSchema={availableSteps[steps[currentStep]].validationSchema}
                        onSubmit={async (values, { resetForm }): Promise<void> => {
                            setSubmitting(true);

                            if (currentStep < steps.length) {

                                setCurrentValues(values);
                                let isValidationPassed = true;
                                let completedTemp = [...completed];
                                for (let i = 0; i < steps.length; i++) {
                                    if (!(await availableSteps[steps[i]].completed(values))) {
                                        completedTemp = [
                                            ...completedTemp.map((v, index) =>
                                                index === i ? false : v,
                                            ),
                                        ];
                                        if (isValidationPassed) {
                                            setCurrentStep(i);
                                        }
                                        isValidationPassed = false;
                                    } else {
                                        completedTemp = [
                                            ...completedTemp.map((v, index) =>
                                                index === i ? true : v,
                                            ),
                                        ];
                                    }
                                }
                                setCompleted(completedTemp);

                                if (isValidationPassed && currentStep === steps.length - 1) {
                                    try {
                                        const createAccountHolderBody =
                                            await mapOnboardingInfoToApiOnboardingRequest(values, accountHolderIdForHeader);
                                        const registerResponse = await createAccountHolder(
                                            createAccountHolderBody,
                                        ).unwrap();
                                        if (registerResponse.status == 'PENDING') {
                                            toast.success(t(`common.clientSuccessfullyCreated`));
                                            await refreshToken().unwrap();
                                            onClose();
                                        }
                                    } catch (err) {
                                        if (err?.data?.errorCode == 'EMAIL_ALREADY_USED') {
                                            toast.error(t('common.emailAlreadyUsed'));
                                            onClose();
                                        }
                                        if (err?.data?.errorCode == 'ACCOUNT_HOLDER_LIMIT_REACHED') {
                                            toast.error(t('common.clientNotCreatedLimitReached'));
                                            onClose();
                                        }
                                        console.log(err);
                                    }
                                }

                            } else {
                                resetForm(); // resets touched/ errors etc
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({ handleSubmit }): JSX.Element => (
                            <>
                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                                <form noValidate onSubmit={handleSubmit}>
                                    {!isSubmitting &&
                                        availableSteps[steps[currentStep]].component}
                                    {isSubmitting && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    )}

                                    {!isSubmitting && (
                                        <Box sx={{ mt: '24px' }}>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                {currentStep === steps.length - 1
                                                    ? t('common.submit')
                                                    : t('common.next')}
                                            </Button>
                                        </Box>
                                    )}
                                </form>
                            </>
                        )}
                    </Formik>
                </Box>
            </>
        );
    };
