import { FilterAlt } from '@mui/icons-material';
import { Box, Drawer, Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { PermissionGuard } from 'src/models/auth/components/PermissionGuard';
import { InvitationList } from './InvitationList';
import { InvitationsFilterForm } from './InvitationsFilterForm';
import { InvitationFilterForm } from './types';


export const Invitations: FC<{accountHolderId:string}> = ({accountHolderId}) => {
    const [filter, setFilter] = useState<InvitationFilterForm>();
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        setFilterOpen(false);
      }, [filter]);
    
    
   
    useEffect(() => {
        setFilter(    
           { 
            status:'PENDING',
           });
    }, []);
    
  return (
    <>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ marginBottom: '25px', marginTop: '25px' }}
          xs={12}
        >
          <Grid item  justifySelf="left">
            <Typography color="textPrimary" variant="h5">
              Invitations 
            </Typography>
          </Grid>
          <Box sx={[{  display:'flex', justifyContent:'flex-end' },
                        (theme) => ({
                          [theme.breakpoints.down('sm')]: {
                            width:'100%'
                          },
                          [theme.breakpoints.up('sm')]: {
                            width: 'auto'
                          },
                      })]}>
          <FilterAlt
              color="primary"
              fontSize="large"
              sx={{ marginLeft: '10px' }}
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            />
            </Box>
        </Grid>

        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sx={{ padding: '20px' }}
            xs={12}
          >
            <Grid item xs={12}>
              <InvitationsFilterForm onFilterApply={setFilter} />
            </Grid>
          </Grid>
        </Drawer>
                     
        <PermissionGuard permission="GET_USER">
         {filter && <InvitationList  accountHolderId={accountHolderId} filter={filter} />}
        </PermissionGuard>
    </>
  );
};

