import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';

import { MCCLoader } from 'src/components/MCCLoader';
import { resetBank } from 'src/models/cms';
import { useLazyMeQuery } from 'src/models/user';
import { useDispatch, useSelector } from 'src/services/redux';
import { useLogoutMutation } from '../api';
import { selectAccountHolderId } from '../selectors';
import { resetCredentials } from '../slice';

export const AuthManager: FC<any> = ({ children }) => {
  const accountHolderId = useSelector(selectAccountHolderId);
  const [logout] = useLogoutMutation();

  const [isLoading, setIsLoading] = useState(true);
  const [fetchMe] = useLazyMeQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    if (accountHolderId?.length > 0) {
      fetchMe({ accountHolderId })
        .unwrap()
        .catch(() => {
          logout();
          dispatch(resetCredentials());
          dispatch(resetBank());
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [accountHolderId]);

  return isLoading ? <MCCLoader /> : <Box sx={{
    paddingBottom: '20px'
  }}> {children}</Box >;
};
