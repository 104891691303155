import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { AccountCurrency } from './AccountCurrency';
import { CardBin } from './CardBin';
import { Country } from './Country';
import { Currency } from './Currency';
import { Pending } from './Pending';

export const RestrictionRoutes: FC<{
  accountHolderId: string;
  isGlobal: boolean;
}> = ({ accountHolderId, isGlobal }) => {
  return (
    <Routes>
      <Route
        path="currency"
        element={
          <Currency accountHolderId={accountHolderId} isGlobal={isGlobal} />
        }
      />
      <Route
        path="country"
        element={
          <Country accountHolderId={accountHolderId} isGlobal={isGlobal} />
        }
      />
      <Route
        path="card-bin"
        element={
          <CardBin accountHolderId={accountHolderId} isGlobal={isGlobal} />
        }
      />
      <Route
        path="account-currency"
        element={
          <AccountCurrency
            accountHolderId={accountHolderId}
            isGlobal={isGlobal}
          />
        }
      />
      <Route
        path="pending"
        element={
          <Pending accountHolderId={accountHolderId} isGlobal={isGlobal} />
        }
      />
      <Route path="*" element={<Navigate to="currency" replace />} />
    </Routes>
  );
};
