import { TransactionExportForm } from "."

export const transactionExportFormFormInitialValues = (): TransactionExportForm => {
    return {
        id: '',
        transactionStatus: [],
        accountId: '',
        accountNumber: '',
        acquiringConfigId: '',
        customerDetailId: '',
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        referenceNo: '',
        transactionType: [],
        startDateTime: null,
        endDateTime: null,
        bankingConfigId: '',
        accountHolderId: '',
        includeSubAccountHolders: false,
        transactionNumber: '',
        source: '',
        destination: '',
    }
}