import * as Yup from "yup";

import { PASSWORD_SUBMIT_YUP_RULE } from "src/models/validations";
import { emailValidationSchema } from "../../elements";

export const emailInfoValidationSchema = (userExists: any) =>
    Yup.object({
        email: emailValidationSchema(userExists),
        password: PASSWORD_SUBMIT_YUP_RULE

    });
